import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
//
import Button from "../../../../common/components/Button/Button";
//
import purpleSmoke from "../../../../common/assets/images/purple-smoke.svg";
import greenSmoke from "../../../../common/assets/images/green-smoke.svg";
//
import "./EndTheSubscription.scss";

const EndTheSubscription = ({ onAccept, visible, name, planName }) => {
    const { t } = useTranslation();

    return (
        visible && (
            <div className="end-the-subscription">
                <img src={greenSmoke} className="smoke-img green-smoke top" alt="green smoke" />

                <img src={greenSmoke} className="smoke-img green-smoke left" alt="green smoke" />

                <img src={purpleSmoke} className="smoke-img purple-smoke right" alt="purple smoke" />

                <img src={purpleSmoke} className="smoke-img purple-smoke bottom" alt="purple smoke" />

                <div className="container-end-the-subscription" role="presentation">
                    <iframe
                        src="https://www.youtube.com/embed/NzBGog2xSk0?si=Zt0iGagudtgRVuR7"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                    ></iframe>

                    <h2 className="title">
                        {t("Parabens")}, {name}!
                    </h2>

                    <p className="text">
                        Você finalizou sua assinatura do
                        <br />
                        <b>Plano {planName} - Hypolake</b>
                        <br />
                        Agora você pode aproveitar a nossa plataforma!
                    </p>

                    <Button
                        width="100%"
                        className="button-submit"
                        d
                        text={t("Ir para o painel")}
                        onClick={() => onAccept()}
                    />
                </div>
            </div>
        )
    );
};

EndTheSubscription.defaultProps = {
    visible: true,
};

EndTheSubscription.propTypes = {
    onAccept: PropTypes.func.isRequired,
    visible: PropTypes.bool,
    name: PropTypes.string,
    planName: PropTypes.string,
};

export default EndTheSubscription;
