import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    value: null
}

export const menu = createSlice({
    name: "menu",
    initialState: initialState,
    reducers: {
        changeIndex: (state, action) => {
            state.value = action.payload
        }
    }
})

export const { changeIndex } = menu.actions;

export const selectIndex = (state) => state.menu.value

export default menu.reducer;