import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
// 
import { Form, Modal } from "antd";
import Button from "../../../Button/Button";
// 
import "./ModalValue.scss"
import CurrencyFormat from "react-currency-format";

const ModalValue = ({ 
    title,
    open,
    min,
    max,
    onCancel,
    onUpdate
}) => {
    const { t } = useTranslation()
    // 
    const [form] = Form.useForm()

    const onFinish = ({ min, max }) => {
        if(min > max) {
            form.setFields([{ name: "min", value: min, errors: [t("Verifique os valores")] }])
        } else {
            const parseMin = parseFloat(`${min}`.replace(".", ""))
            const parseMax = parseFloat(`${max}`.replace(".", ""))
            
            onUpdate({ min: parseMin, max: parseMax })
        }
    }

    useEffect(() => {
        form.resetFields()
    }, [open])

    return <Modal open={true} visible={open} onCancel={onCancel} className="modal-values" title={title || t("Número de seguidores")}>
        <Form 
            form={form} 
            onFinish={onFinish}
            initialValues={{ min, max }}
        >
            <span className="label-item">{t("de ")}</span>
            <Form.Item 
                name="min"
                rules={[
                    () => ({
                        validator(_, value) {
                            const numberValue = !value ? 0 : parseInt(`${value}`.replace(".", ""))
                            
                            if (!numberValue || numberValue < min) {
                                return Promise.reject(new Error(t("Informe um valor válido")))
                            }
                            return Promise.resolve()
                        },
                    }),
                ]}
            >
                <CurrencyFormat  
                    fixedDecimalScale
                    decimalSeparator="," 
                    thousandSeparator="." 
                    prefix=""
                    min={min}
                    defaultValue={min}
                />
            </Form.Item>

            <span className="label-item">{t("a ")}</span>

            <Form.Item 
                name="max"
                rules={[
                    () => ({
                        validator(_, value) {
                            const numberValue = !value ? 0 : parseInt(`${value}`.replace(".", ""))

                            if (!numberValue || numberValue > max) {
                                return Promise.reject(new Error(t("Informe um valor válido")))
                            }
                            return Promise.resolve()
                        },
                    }),
                ]}
            >
                <CurrencyFormat  
                    fixedDecimalScale
                    decimalSeparator="," 
                    thousandSeparator="." 
                    prefix="" 
                    max={max}
                    defaultValue={min}
                />
            </Form.Item>
        </Form>
        
        <Button 
            color="secondary" 
            text={t("Confirmar")}
            onClick={() => form.submit()}
        />
    </Modal>
}

ModalValue.propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    onUpdate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}

export default ModalValue