import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
// 
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
// 
import "../Charts.scss";

const DonutsChart = ({ data }) => {
    const [series, setSeries] = useState([]);
    // 
    const colors = [
        "#3BB481",
        "#6A53BD",
    ]

    useEffect(() => {
        const newSeries = [];

        data.forEach((item, i) => {
            newSeries.push({ 
                name: item.label, 
                y: parseFloat(item.value), 
                color: colors[i] 
            })
        })

        setSeries(newSeries);
    }, [data])

    const options = {
        chart: {
            type: "pie",
        },
        title: {
          text: ""
        },
        yAxis: {
          title: ""
        },
        xAxis: {
          visible: false
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: {
                    enabled: true,
                    format: "{point.name}: {y} %"
                },
                showInLegend: true
            }
        },
        tooltip: {
            formatter: function () {
                return `<div class="high-tooltip">
                    ${this.key}: <b>${this.y}%</b>
                </div>` 
            }
        },

        series: 
            series.length < 1 ? [] :
            [{
                name: "Tiktok audience",
                colorByPoint: true,
                innerSize: "75%",
                data: series
            }]
    };

    return series && <HighchartsReact
        highcharts={Highcharts}
        options={options}
    />
}

DonutsChart.propTypes = {
    data: PropTypes.any,
    formatter: PropTypes.func,
    height: PropTypes.string,
    className: PropTypes.string,
}

export default DonutsChart;