import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
//
import { useDispatch, useSelector } from "react-redux";
import { fetchCampaigns } from "./HomeSlice";
//
import { Spin, Tooltip } from "antd";
import Button from "../../common/components/Button/Button";
import DropDown from "../../common/components/DropDown/DropDown";
import Header from "../../common/components/Header/Header";
import Input from "../../common/components/Input/Input";
import CampaignList from "./components/CampaignList/CampaignList";
//
import "./Home.scss";
import { selectProfile } from "../Login/LoginSlice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Home = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const profile = useSelector(selectProfile);
    const history = useHistory();
    let delayTimer;

    const [filter, setFilter] = useState("");
    const [loading, setLoading] = useState(false);
    const campaignLimitReached = profile.data.plan?.campaign_limit
        ? (profile.data.stats?.campaigns || 0) >= profile.data.plan?.campaign_limit
        : false;
    const optionsDropDown = [
        { key: "all", value: t("Todas as campanhas") },
        { key: "status-active", value: t("Campanhas ativas") },
        { key: "status-filed", value: t("Campanhas arquivadas") },

        { key: "date-lower", value: t("Data - menor para maior") },
        { key: "date-higher", value: t("Data - maior para menor") },

        { key: "performance-worse", value: t("Desempenho - melhor para pior") },
        { key: "performance-better", value: t("Desempenho - pior para melhor") },
    ];

    const searchCampaign = (text = "") => {
        dispatch(fetchCampaigns({ page: 1, text }));
    };

    return (
        <div className="home-page background-page">
            <Header title={t("Campanhas")} className="home-header" />

            <div className="scroll-content">
                <div className="home-content">
                    <div className="home-buttons">
                        <Tooltip
                            title={t(
                                campaignLimitReached
                                    ? "Limite de campanhas atingido, atualize seu plano ou remova campanhas."
                                    : "Criar campanha"
                            )}
                            onClick={(e) => {
                                if (campaignLimitReached) {
                                    e.preventDefault();
                                    return;
                                }
                                history.push("/create-campaign/type");
                            }}
                        >
                            <Link to="/create-campaign/type">
                                <Button color="success" icon="plus" />
                            </Link>
                        </Tooltip>
                        {/* search and filter */}
                        <div className="search-filter">
                            {loading && <Spin size={8} />}

                            <Input
                                colorName="white"
                                placeholder={t("Pesquisar")}
                                onChange={(text) => {
                                    clearTimeout(delayTimer);

                                    if (text.length >= 2 || text.length === 0) {
                                        delayTimer = setTimeout(() => {
                                            setLoading(true);
                                            searchCampaign(text);
                                            setLoading(false);
                                        }, 500);
                                    }
                                }}
                                prefix={<i className="icon icon-search" />}
                            />

                            <DropDown
                                className="filter-dropdown"
                                style={{ backgroundColor: "#666666", color: "#FFF" }}
                                options={optionsDropDown}
                                onClick={(value) => {
                                    setFilter(value.key);
                                }}
                                child={
                                    <>
                                        <i className="icon icon-filter" />
                                        <span style={{ marginLeft: "8px" }}>{t("Filtrar")}</span>
                                    </>
                                }
                            />
                        </div>
                    </div>

                    <div className="home-campaigns">
                        <CampaignList filter={filter} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Home;
