import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
// 
import { useTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
// 
import Avatar from "../Avatar/Avatar";
// 
import { AirUtils } from "../../../utils/utils";
import { humanize } from "../../../utils/formatters";
import "./InfluencerTable.scss";

const InfluencerDetailsTableEC = ({ influencers = [], symbolCoin, campaignId }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const airUtils = new AirUtils();

    const [sorts, setSorts] = useState(["name"]);
    const [boolBigger, setBoolBigger] = useState(false);
    const [arrowLocation, setArrowLocation] = useState(2);
    const [orderInfluencers, setOrderInfluencers] = useState([]);

    const [bigger] = useState({
        clicks: null,
        costPerClick: null,
        conversionRate: null,
        conversions: null,
        costPerConversion: null,
        roi: null,
    });

    const [minors] = useState({
        clicks: null,
        costPerClick: null,
        conversionRate: null,
        conversions: null,
        costPerConversion: null,
        roi: null,
    });

    const columns = [
        { alias: " ", title: " ", span: 1 },
        { alias: "network", title: t("rede"), span: 2 },
        { alias: "name", title: t("influenciador"), span: 2 },
        { alias: "clicks", title: t("Nº cliques"), span: 1 },
        { alias: "costPerClick", title: t("custo cliques"), span: 2 },
        { alias: "conversionRate", title: t("taxa conversão"), span: 1 },
        { alias: "conversions", title: t("conversões"), span: 1 },
        { alias: "costPerConversion", title: t("custo conversão"), span: 2 },
        { alias: "roi", title: t("roi"), span: 1 },
    ];

    const orderBy = (obj = "perNumber", index) => {
        setArrowLocation(index);

        if (!sorts.includes(obj)) {
            const newOrder = [...orderInfluencers].sort((a, b) => {
                if (obj === "name" || obj === "network") {
                    return a[obj].toLowerCase() > b[obj].toLowerCase() ? -1 : 1;
                } else {
                    return a[obj] > b[obj] ? -1 : 1;
                }
            });
            setBoolBigger(true);
            setOrderInfluencers(newOrder);
            setSorts((oldArray) => [...oldArray, obj]);
        } else {
            const newOrder = [...orderInfluencers].sort((a, b) => {
                if (obj === "name" || obj === "network") {
                    return a[obj].toLowerCase() < b[obj].toLowerCase() ? -1 : 1
                } else {
                    return a[obj] < b[obj] ? -1 : 1;
                }
            });
            setBoolBigger(false);
            setOrderInfluencers(newOrder);
            setSorts(sorts.filter(item => item !== obj))
        }
    }

    useEffect(() => {
        if (influencers.length > 0) {
            setOrderInfluencers(influencers);

            influencers.map(inf => {
                if (inf.clicks > bigger.clicks || bigger.clicks == null) {
                    bigger.clicks = inf.clicks;
                }
                if (inf.costPerClick > bigger.costPerClick || bigger.costPerClick == null) {
                    bigger.costPerClick = inf.costPerClick;
                }
                if (inf.conversionRate > bigger.conversionRate || bigger.conversionRate == null) {
                    bigger.conversionRate = inf.conversionRate;
                }
                if (inf.conversions > bigger.conversions || bigger.conversions == null) {
                    bigger.conversions = inf.conversions;
                }
                if (inf.costPerConversion > bigger.costPerConversion || bigger.costPerConversion == null) {
                    bigger.costPerConversion = inf.costPerConversion;
                }
                if (inf.roi > bigger.roi || bigger.roi == null) {
                    bigger.roi = inf.roi;
                }
                // 
                if (inf.clicks < minors.clicks || minors.clicks == null) {
                    minors.clicks = inf.clicks;
                }
                if (inf.costPerClick < minors.costPerClick || minors.costPerClick == null) {
                    minors.costPerClick = inf.costPerClick;
                }
                if (inf.conversionRate < minors.conversionRate || minors.conversionRate == null) {
                    minors.conversionRate = inf.conversionRate;
                }
                if (inf.conversions < minors.conversions || minors.conversions == null) {
                    minors.conversions = inf.conversions;
                }
                if (inf.costPerConversion < minors.costPerConversion || minors.costPerConversion == null) {
                    minors.costPerConversion = inf.costPerConversion;
                }
                if (inf.roi < minors.roi || minors.roi == null) {
                    minors.roi = inf.roi;
                }
            })
        }

    }, [influencers]);

    return <table className="table-influencer table-influencer-ec table-influencer-details">
        <thead>
            <tr className="table-c-titles">
                {columns.map((item, key) => {
                    return (item.alias == "") ? null : (
                        <th
                            key={key}
                            rowSpan={item.span}
                            className={`th-${item.alias} ${item.alias == "" ? "default" : ""}`}
                            onClick={() => item.alias != "" && orderBy(item.alias, key, item.withTotal)}
                        >
                            <span className={`name-column ${key === arrowLocation ? "space" : ""}`}>{item.title}</span>
                            {key === arrowLocation && <i className={`icon icon-down ${boolBigger ? "bigger" : "smaller"}`} />}
                        </th>
                    )
                })}
            </tr>
        </thead>
        <tbody>
            {
                orderInfluencers.length > 0 && orderInfluencers.map((influencer, key) => {
                    return <tr key={key}>
                        <th className="th-post" width="5%">
                            {key + 1}º
                        </th>

                        <th className={`th-network color-${influencer.network}`}>
                            <i className={`icon icon-${influencer.network}-circle`} />
                        </th>

                        <th className="th-profile">
                            <Avatar
                                size="24px"
                                network={influencer.network}
                                src={influencer.avatar}
                                onClick={() => { history.push(`/campaign/${campaignId}/influencer/${influencer.id}`) }}
                            />
                            {influencer.name}
                        </th>

                        <th className={`th-num-clicks ${influencer.clicks == bigger.clicks && "bigger"} ${influencer.clicks == minors.clicks && "minor"}`}>
                            {humanize(influencer.clicks)}
                        </th>

                        <th className={`th-cost-per-click ${influencer.costPerClick == bigger.costPerClick && "bigger"} ${influencer.costPerClick == minors.costPerClick && "minor"}`}>
                            {symbolCoin} {airUtils.buildValue("fixed", influencer.costPerClick)}
                        </th>

                        <th className={`th-conversion-rate ${influencer.conversionRate == bigger.conversionRate && "bigger"} ${influencer.conversionRate == minors.conversionRate && "minor"}`}>
                            {airUtils.buildValue("fixed", influencer.conversionRate)}%
                        </th>

                        <th className={`th-conversions ${influencer.conversions == bigger.conversions && "bigger"} ${influencer.conversions == minors.conversions && "minor"}`}>
                            {humanize(influencer.conversions)}
                        </th>

                        <th className={`th-cost-per-conversion ${influencer.costPerConversion == bigger.costPerConversion && "bigger"} ${influencer.costPerConversion == minors.costPerConversion && "minor"}`}>
                            {symbolCoin} {airUtils.buildValue("currency", influencer.costPerConversion, null, 2)}
                        </th>

                        <th className={`th-roi ${influencer.roi == bigger.roi && "bigger"} ${influencer.roi == minors.roi && "minor"}`}>
                            {airUtils.buildValue("fixed", influencer.roi)}%
                        </th>
                    </tr>
                })
            }
        </tbody>
    </table>
}

InfluencerDetailsTableEC.propTypes = {
    influencers: PropTypes.array,
    symbolCoin: PropTypes.string,
    campaignId: PropTypes.any
}

export default InfluencerDetailsTableEC;