import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types'
// 
import { Input as AntInput } from 'antd';
// 
import './Input.scss';

const Input = ({
    focus,
    error,
    onlyNum,
    outline,
    disabled,
    password,
    value,
    type,
    width,
    height,
    textError,
    colorName,
    placeholder,
    defaultValue,
    onBlur,
    onClick,
    onChange,
    prefix,
    suffix,
    maxLength,
    suffixStyle,
}) => {
    const inputRef = useRef(null);
    const [val, updateVal] = useState()
    const [length, updateLength] = useState(0)
    const [showPass, updateShowPass] = useState(true)

    const callBlur = (v) => {
        if(onBlur) onBlur(v)
    }

    const callChange = (event) => {
        updateVal(event.target.value);
        
        if(!onlyNum) updateLength(event.target.value.length) 

        if(onChange) onChange(event.target.value)
    }

    useEffect(() => {
        if(focus) inputRef.current.focus({ cursor: 'start' });
    }, [focus])

    useEffect(() => {
        if(value) updateLength(value.length)
    }, [value])

    return <div className="input-container" style={{ width, height }}>
        <div
            onClick={onClick}
            style={{ width: "100%" }} 
            className={`input-junction ${password ? "input-container-password" : ""}`}>
            
            <AntInput
                size="large" 
                ref={inputRef}
                // 
                placeholder={placeholder || ""}
                type={type != "password" ? type : (showPass ? "password" : "text")}
                className={`input ${error ? "input-error" : ""} input-${outline ? "outline" : "full"} ${ colorName }`} 
                // 
                value={value != null ? value : val}
                prefix={prefix}
                disabled={disabled}
                defaultValue={defaultValue}
                maxLength={maxLength}
                // 
                onChange={callChange}
                onBlur={(event) => { callBlur(event.target.value) } } 
            />

            { suffix && <div className="suffix" style={suffixStyle}> {suffix} </div> }

            { password && 
                <div className="icon-password" onClick={() => { updateShowPass(!showPass )}}>
                    <i className={`icon icon-${showPass ? "eye-blocked" : "eye"}`} />
                </div>
            }
            
            { maxLength && <p className="input-count">{length}/30</p> }
        </div> 

        { error && <span className="text-error">{textError}</span> }
    </div>
}

Input.defaultProps = { 
    focus: false,
    error: false,
    onlyNum: false,
    outline: false,
    disabled: false,
    password: false,
    value: null,
    type: null,
    width: 250,
    height: 40,
    textError: null,
    colorName: null,
    placeholder: null,
    defaultValue: null,
    onBlur: null,
    onClick: null,
    onSubmit: null,
    onChange: null,
    prefix: null,
    suffix: null,
    maxLength: null,
    suffixStyle: null,
}

Input.propTypes = {
    focus: PropTypes.bool,
    error: PropTypes.bool,
    onlyNum: PropTypes.bool,
    outline: PropTypes.bool,
    disabled: PropTypes.bool,
    password: PropTypes.bool,
    value: PropTypes.string,
    type: PropTypes.string,
    width: PropTypes.any,
    height: PropTypes.any,
    textError: PropTypes.string,
    colorName: PropTypes.string,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.string,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
    prefix: PropTypes.element,
    suffix: PropTypes.element,
    maxLength: PropTypes.number,
    suffixStyle: PropTypes.object,
}

export default Input