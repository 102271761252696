import React from 'react';
import { useTranslation } from "react-i18next";
// 
import Header from '../../common/components/Header/Header';
// 
import './TrackingLinks.scss';

const TrackingLinks = () => {
    const { t } = useTranslation();

    return <div className="traking-page background-page">
        <Header 
            title={t("Links de rastreamento")} 
            className="home-header">
        </Header>
    </div>
}

export default TrackingLinks;