const span = {
    // MULTIPLES
    Desempenho: "Rendimiento",
    Voltar: "Atrás",
    Próximo: "Siguiente",
    de: "-",
    e: "y",
    "Solicitar conecte": "Solicitar conexión",
    "Conecte autorizado": "Conectar autorizado",
    Campanhas: "Campañas",
    rede: "red",
    influenciadores: "influenciadores",
    "redes socias": "redes sociales",
    Alcance: "Rango",
    "DD/MM/AAAA": "DD/MM/AAAA",

    //     // LOGIN
    "Não foi possível fazer o login. Favor usar uma conta existente!":
        "Imposible iniciar sesión. ¡Utilice una cuenta existente!",
    "E-mail": "Correo electrónico",
    "E-mail inválido": "Correo inválido",
    "A senha precisa ter no mínimo 8 caracteres contendo": "La contraseña debe tener al menos 8 caracteres y contener",
    "letra maiúscula, letra minúscula, caracter especial e número":
        "letra mayúscula, letra minúscula, carácter especial y número",
    Senha: "Contraseña",
    "Informe sua senha": "Informa tu contraseña",
    "Esqueci a senha": "Olvidé la contraseña",
    Entrar: "Iniciar sesión",
    "Cadastrar-se": "Regístrate",

    //     // REGISTER
    cadastro: "registrarse",
    endereço: "dirección",
    pagamento: "pago",
    "Dados de Cadastro": "Datos de registro",
    "Preencha os dados abaixo para realizar o cadastro": "Rellene los datos a continuación para registrarse",
    Plano: "Plano",
    //     // "$": "$",

    //     // REGISTRO - CONTRATO
    //     "Contrato": "Contrato",

    //     // REGISTRO - FACTURACION
    //     "CEP": "Código postal",
    //     "CEP obrigatório": "Código postal obligatorio",
    //     "Informe seu CEP": "Indica tu código postal",
    //     "Endereço": "Dirección",
    //     "Endereço Obrigatório!": "¡Dirección requerida!",
    //     "Informe seu endereço": "Informa tu dirección",
    //     "Continuar compra": "Continuar compra",

    //     // REGISTRO - PAGO
    //     "Cupón de desconto": "Cupón de descuento",
    //     "Informe o cupom de desconto": "Ingrese el cupón de descuento",
    //     "Clicando no botão abaixo você concorda com nossos": "Al hacer clic en el botón de arriba, aceptas nuestras",
    //     "terms de uso e política de privacidade": "términos de uso y política de privacidad",
    //     "Receba dicas e novidades da HypoLake": "Obtenga consejos y noticias de HypoLake",
    //     "Aguarde, procesando pago.": "Espera, procesando pago.",

    //     // REGISTRO - REGISTRO
    //     "Mínimo 6 caracteres;": "Mínimo 6 caracteres;",
    //     "Letras maiúscula;": "Mayúsculas;",
    //     "Letras minúsculas;": "Letras minúsculas;",
    //     "Pelo menos um numeral;": "Al menos un numeral;",
    //     "Pelo menos um caractere especial (@, #, $ e etc);": "Al menos un carácter especial (@, #, $ y etc);",
    //     "Nombre": "Nombre",
    //     "Nome inválido!": "¡Nombre inválido!",
    //     "Informe seu nome": "Ingrese su nombre",
    //     "Sobrenome": "Apellido",
    //     "Sobrenome inválido!": "¡Apellido inválido!",
    //     "Informe seu sobrenome": "Ingrese su apellido",
    //     "Nome da empresa": "Nombre de la empresa",
    //     "Nome da empresa inválido!": "¡Nombre de empresa inválido!",
    //     "Informe o nome da empresa": "Proporcione el nombre de la empresa",
    //     // "Correo electrónico": "Correo electrónico",
    //     "E-mail inválido!": "¡E-mail inválido!",
    //     "Informar a su correo electrónico": "Tu correo electrónico",
    //     "Teléfono": "Teléfono",
    //     "Informe seu número de telefone": "Proporcione su número de teléfono",
    //     "Teléfono inválido!": "¡Teléfono inválido!",
    //     "Configurar uma senha": "Elija una contraseña",
    //     "Senha inválida": "Contraseña no válida",
    //     "Informar a senha": "Ingrese la contraseña",
    //     "Confirmar senha": "Confirmar contraseña",
    //     // "Senha inválida": "Contraseña no válida",
    //     "As senhas não coincidenm": "Las contraseñas no coinciden",
    //     "Confirme a senha": "Confirme la contraseña",
    //     // "Receba dicas e novidades da HypoLake": "Obtenga consejos y noticias de HypoLake",
    //     "Sua senha tem que possuir": "Tu contraseña debe tener",

    //     // ENCABEZADO
    //     "Adicionar marcas e temas": "Agregar etiquetas y temas",
    //     "Configurar conta": "Configurar cuenta",
    //     "Idioma": "Idioma",
    //     "Sair": "Cerrar sesión",
    //     "Seleccionar": "Seleccionar",
    //     "período": "curso del tiempo",
    //     "Seleccionar periodo": "Seleccionar periodo",

    //     // MENÚ
    //     "Resumo campanha": "Resumen de la campaña",
    //     "Criar campanha": "Crear campaña",
    //     "Rastreo de enlaces": "Rastreo de enlaces",

    //     // TRADUCIR
    //     "Português": "portugués",
    //     "Inglês": "inglés",
    //     "Español": "español",
    //     "Selectione o idioma deseado": "Seleccione el idioma deseado",

    //     // ETIQUETAS

    //     // ETIQUETAS - GRÁFICOS
    //     "total_posts": "Total de publicaciones",
    //     "total_posts_per_hashtags": "Total de publicaciones por hashtags",
    //     "total_interactions": "Interacciones totales",
    //     "interacciones": "Interacciones totales",
    //     "engagement_rate": "Tasa de participación",
    //     "inversion": "Inversion",
    //     "base": "Suma de bases",
    //     "sum_of_bases": "Suma de bases",
    //     "cost_per_interaction": "Costo por interacción",
    //     "puntuación aérea": "puntuación aérea",
    //     "publicaciones": "Publicaciones",
    //     "reach_rate": "Tasa de alcance",
    //     "retention_rate": "Tasa de retención",
    //     "exit_rate": "Tasa de salida",
    //     "cost_per_interaaction": "Costo por interacción",
    //     "clics": "Número de clics",
    //     "number_clicks": "Número de clics",
    //     "cost_per_click": "Coste por clic",
    //     "conversion_rate": "Tasa de conversión",
    //     "conversiones": "Conversiones",
    //     "cost_per_conversion": "Coste por conversión",
    //     "roi": "ROI",
    //     "feed_hired": "Contratistas de alimentación",
    //     "stories_hired": "Historias contratadas",
    //     "best_performance": "Mejor rendimiento",
    //     "total_post_hashtags": "Publicación total por hashtags",
    //     "interactions_total": "Interacciones totales",

    //     // ETIQUETAS - POSTS
    //     "vistas": "Vistas",
    //     "me gusta": "me gusta",
    //     "no me gusta": "No me gusta",
    //     "like-heart1": "Me gusta",
    //     "comentarios": "Comentarios",
    //     "acciones": "Acciones",
    //     "alfileres": "alfileres",
    //     "Adicionar ou remover post da campanha": "Agregar o quitar publicación de la campaña",

    //     // ETIQUETAS - RESUMEN
    //     "Resumo das campanhas": "Resumen de la campaña",
    //     "active_campaigns": "Total de campañas activas",
    //     "total_investment": "Inversión total",
    //     "mean_investient": "Inversión promedio",
    //     "influencers": "Influencers totales",
    //     "mean_influencers": "Influenciadores promedio",
    //     "total_conversions": "Conversiones totales",
    //     "publicaciones": "Total de publicaciones",
    //     "historias": "Historias totales",
    //     "mean_impressions": "Promedio de impresiones",
    //     "average_airscore": "Puntaje aéreo promedio",
    //     "mean_engagement": "Interacción media",
    //     "mean_cpi": "IPC medio",
    //     "connected_profiles": "Perfiles conectados",
    //     //
    //     "page_tracks": "Seguimiento de página",
    //     "pistas": "Pistas",
    //     "total_investment": "Inversión total",
    //     "mean_investment": "Inversión promedio",
    //     "ga_traffic": "Tráfico total de Google Analytics",
    //     "mean_traffic": "Tráfico promedio de la campaña",
    //     "total_clicks": "Clics totales",
    //     "cost_per_traffic": "Costo por tráfico",
    //     //
    //     "reach_potential": "Potencial de alcance",
    //     "impresiones": "Impresiones",
    //     "reactions": "Reacciones (compromiso)",
    //     "traffic_link": "Tráfico sin procesar + enlace acortado",
    //     "content_estimate": "Estimación de contenido",

    //     // CASA
    //     "Filtrar": "Filtrar",
    //     "Pesquisar": "Buscar",
    //     //
    //     "Todas as campanhas": "Todas las campañas",
    //     "Campanhas activas": "Campañas activas",
    //     "Campanhas arquivadas": "Campañas archivadas",
    //     "Data - menor para maior": "Fecha: de menor a mayor",
    //     "Data - maior para menor": "Fecha: de mayor a menor",
    //     "Desempenho - melhor para pior": "Rendimiento: mejor para peor",
    //     "Desempenho - pior para melhor": "Rendimiento: peor para mejor",

    //     // INICIO - VISTA PREVIA DE LA CAMPAÑA
    //     "campanha em criação": "campaña en creación",
    //     "Tipo": "Tipo",
    //     "Objetivo": "Objetivo",
    //     "sitio/loja virtual": "sitio web/tienda virtual",
    //     "Você ainda não possui campanhas": "Todavía no tienes campañas",
    //     "A pesquisa por": "La búsqueda de",
    //     "não retornou resultados.": "no devolvió resultados.",
    //     "ativa": "activa",
    //     "arquivada": "archivado",
    //     "inativa": "inactiva",
    //     "Editar campanha": "Editar campaña",
    //     "Duplicar campanha": "Duplicar campaña",
    //     "Exportar campanha": "Campaña de exportación",
    //     "Arquivar campanha": "Archivo campaña",
    //     "estado": "estado",
    //     "campanha": "campaña",
    //     "em criação": "en la creación",
    //     "Período": "Curso del tiempo",
    //     "rascunho": "borrador",
    //     "Continuar criação": "Continuar la creación",
    //     "Analisar campanha": "Analizar campaña",
    //     // "Exportar campanha": "Exportar campaña",
    //     "Campanha arquivada com sucesso!": "¡Campaña archivada con éxito!",
    //     "Campanha exportada com sucesso!": "Campaña exportada con éxito",
    //     "Deseja realmente arquivar essa campanha? (Essa ação não pode ser desfeita)": "¿Realmente desea archivar esta campaña? (Esta acción no se puede deshacer",
    //     "Sim, arquivar campanha": "Sí, archivar campaña",
    //     "Não, manter campanha": "No, mantener campaña",

    //     // ENLACE DE SEGUIMIENTO
    //     "Enlaces de rastreo": "Enlaces de seguimiento",

    //     // DETALLES
    //     // "Editar campaña": "Editar campaña",
    //     // "Duplicar campaña": "Duplicar campaña",
    //     // "Exportar campanha": "Exportar campaña",
    //     // "Arquivar campanha": "Archivar campaña",
    //     "Campanha": "Campaña",
    //     "período de": "período de",
    //     "tipo": "tipo",
    //     "objetivo": "objetivo",
    //     "vendas": "ventas",
    //     "Filtrar una campaña por un período específico.": "Filtrar la campaña por un período específico.",
    //     //
    //     "Últimas postales": "Últimas publicaciones",
    //     "Maior desempenho": "Mayor rendimiento",
    //     "Menor desempenho": "menor rendimiento",
    //     "Maior interação": "Mayor interacción",
    //     "Menor interação": "Menos interacción",
    //     //
    //     "Hashtags oficiais da campanha": "Official campaign hashtags",
    //     "Sem hashtahgs ou perfil na campanha": "No hashtahgs or profile in the campaign",
    //     "Performance da campanha em seu site/loja virtual": "Campaign performance on your website/virtual store",
    //     "Publicações da campanha": "Campaign publications",
    //     "Nenhum post foi feito pelos influenciadores": "No posts were made by influencers",
    //     "Nenhum storie foi feito pelos influenciadores": "No stories were made by influencers",
    //     "Tabela comparativa de influenciadores": "Comparative table of influencers",
    //     "geral": "general",
    //     "A campanha não possui influenciadores": "The campaign has no influencers",
    //     "Ver tabela comparativa": "See comparative table",
    //     "Desempenho do contratante no período da campanha": "Contractor performance during the campaign period",
    //     "Ver todas as publicações do contratante": "View all contractor publications",
    //     "Histórico de base do contratante no período da campanha": "Contractor base history during the campaign period",
    //     "Primeira": "First",
    //     "Última": "Last",

    //     // DETAILS - CONTRACTOR TABLE
    //     "contratante": "contractor",
    //     "conecte contratante": "connect contractor",

    //     // DETAILS - INFLUENCERS TABLE
    //     // "rede": "network", -> already exists
    //     "influenciador": "influencer",
    //     "seguidores": "followers",
    //     "cpi": "cpi",
    //     "interações": "interactions",
    //     "desempenho": "performance",
    //     "txe": "txe",
    //     "conecte": "plug it in",
    //     "Nº cliques": "number of clicks",
    //     "custo cliques": "cost clicks",
    //     "taxa conversão": "conversion rate",
    //     "conversões": "conversions",
    //     "custo conversão": "conversion cost",
    //     "investimento": "investment",
    //     "conecte influenciador": "connect influencer",

    //     // DETAILS - CARD STORIES
    //     "Data": "Date",
    //     "Visualizações": "Views",
    //     "Ver todos os dados do story": "See all story data",
    //     "Ver dados": "View data",
    //     "Adicionar ou remover storie da campanha": "Add or remove storie from campaign",
    //     // DETALLES - TABLA
    //     "contratado": "contratado",
    //     "entregue": "entregado",
    //     "Base de seguidores no início da campanha": "Base de seguidores al inicio de la campaña",
    //     "Crescimento de seguidores": "Crecimiento de seguidores",
    //     "Por datos": "Por fecha",
    //     "Por perfil": "Por perfil",
    //     "A campanha ainda não possui dados para esse gráfico": "La campaña aún no tiene datos para este gráfico",
    //     "Por interação": "Por interacción",
    //     "General": "General",
    //     "Alimentar": "Alimentar",
    //     "Historias": "Historias",

    //     // DETALLES - MODAL DE HISTORIAS
    //     "Engajamento": "Compromiso",
    //     // "Alcance": "Alcance", -> ya existe
    //     "Interações": "Interacciones",
    //     "Impressões": "Impresiones",
    //     "Respostas": "Respuestas",
    //     "Próxima": "Siguiente",
    //     "Reações": "Reacciones",
    //     "Saída": "Salir",
    //     "Taxa de participación (TXE)": "Tasa de participación (TXE)",
    //     "Taxa de alcance (TXE)": "Range Rate (TXE)",

    //     // DETALLES - TABLA COMPARATIVA
    //     // todo -> ya existe
    //     "Perfil conectado": "Perfil conectado",
    //     "Gear link para conexão": "Generar enlace de conexión",
    //     "Copiar enlace de rastreo": "Copiar enlace de seguimiento",
    //     "URL de rastreo": "URL de rastreo",

    //     // DETALLES - PUESTOS DE CONTRATISTA
    //     "Publicações do contratante no período da campanha": "Publicaciones del contratista durante el período de campaña",
    //     "O contratante não fez posts durante o período da campanha": "El contratista no publicó durante el período de campaña",

    //     // DETALLES - DETALLES DE INFLUENCERS
    //     "Performance do influenciador na campanha": "Performance del influencer en la campaña",
    //     "Publicaciones del influenciador": "Publicaciones de influencers",
    //     "Nenhum post foi feito pelo influenciador": "El influencer no hizo ninguna publicación",
    //     "Nenhum storie foi feito pelo influenciador": "Ninguna historia fue hecha por el influencer",

    //     // DETALLES - PUBLICACIONES DE LA CAMPAÑA
    //     "Publicaciones da campanha": "Publicaciones de campaña",

    //     // CONFIGURACION
    //     "Configuraciones": "Configuraciones",
    //     "Perfil": "Perfil",
    //     "Cobrança": "Cargar",
    //     "Pagamentos": "Pagos",

    //     // CONFIGURACION - FACTURACION
    //     "Fatura": "Factura",
    //     "Histórico de pagamento": "Historial de pagos",
    //     "Veja seu histórico de pagamento e gerencie suas faturas": "Consulte su historial de pagos y gestione sus facturas",
    //     "Aguarde o download que está em adamento acabar": "Espere a que termine la descarga que está en adamento",
    //     "Sem faturas": "Sin facturas",
    //     "Número Fatura": "Número de Factura",
    //     "Valor": "Valor",
    //     "Baixar Fatura": "Descargar Factura",

    //     // CONFIGURACION - PAGO
    //     "Pagamento": "Pago",
    //     "mensal": "mensual",
    //     "$": "$",
    //     "Plano contratado": "Plan contratado",
    //     "Cancelar plano": "Cancelar plano",
    //     "Cancelamento de plano": "Cancelación de plan",
    //     "Antes de cancelar o plano, leia e selecione os itens abaixo": "Antes de cancelar el plan, lea y seleccione los elementos a continuación",
    //     "O cancelamento ocorrerá no final do seu ciclo atual de pagamento.": "La cancelación ocurrirá al final de su ciclo de pago actual.",
    //     "Você perderá o acesso à nossa plataforma, incluindo todos os dados cadastrados.": "Perderá el acceso a nuestra plataforma, incluidos todos los datos registrados.",

    //     // CONFIGURACION - PERFIL
    //     "Cancelar": "Cancelar",
    //     "Salvar": "Para salvar",
    //     "Editar": "Para editar",
    //     "Informe um nome válido": "Por favor ingrese un nombre válido",
    //     // "Nombre": "Nombre",
    //     // "Sobrenombre": "Apellido",
    //     "Informe um sobrenome válido": "Por favor proporcione un apellido válido",
    //     "Informe um e-mail válido": "Por favor proporcione un correo electrónico válido",
    //     "Erro ao actualizar el correo": "Error al actualizar el correo",
    //     "Um e-mail de confirmação foi enviado para seu e-mail": "Se ha enviado un correo electrónico de confirmación a su correo electrónico",
    //     // "Correo electrónico": "Correo electrónico",
    //     "Erro ao actualizar senha": "Error al actualizar contraseña",
    //     "Senha atualizada com sucesso": "Contraseña actualizada con éxito",
    //     // "Senha": "Contraseña",
    //     "Senha atual": "Contraseña actual",
    //     "Informe a senha atual": "Ingrese la contraseña actual",
    //     "Nova senha": "Nueva contraseña",
    //     "Informe a nova senha": "Ingrese la nueva contraseña",
    //     "Confirme sua nova senha": "Confirme su nueva contraseña",
    //     // "As senhas não coincidenm": "Las contraseñas no coinciden",
    //     // "Senha inválida": "Contraseña no válida",

    //     // RESUMEN DE LA CAMPAÑA
    //     "Total da campanha": "Total de la campaña",
    //     "Por campanha": "Por campaña",
    //     "Últimos 7 dias": "Últimos 7 días",
    //     "Últimos 14 dias": "Últimos 14 días",
    //     "Últimos 21 dias": "Últimos 21 días",
    //     "Últimos 29 dias": "Últimos 29 días",
    //     "minhas campanhas": "mis campañas",
    //     "rastreamento de enlace": "seguimiento de enlace",
    //     "dados": "datos",

    //     // CREAR CAMPAÑA TIKTOK - INTEGRACIÓN
    //     "Integración con TikTok": "Integración de TikTok",
    //     "Para que você consiga criar uma campanha e verificar o desempenho dos perfis, precisamos que faça login com sua conta business do TikTok.": "Para que pueda crear una campaña y verificar el rendimiento de los perfiles, necesitamos que inicie sesión con su cuenta comercial de TikTok.",
    //     "Ao clicar no botão de login com o TikTok, você will be redirecionado to a page e efetuar o login.": "Al hacer clic en el botón de inicio de sesión con TikTok, será redirigido a la página e iniciar sesión.",
    //     "Entrar com o TikTok": "Iniciar sesión con TikTok",
    //     "Conecte sua conta do TikTok para poder prosseguir!": "¡Conecta tu cuenta de TikTok para usar!",

    //     // CREAR CAMPAÑA TIKTOK - INFORMACION
    //     // "Informações da campanha": "Información de la campaña",
    //     // "Nome da campanha": "Nombre de la campaña",
    //     // "Informe o nome da campanha!": "¡Introduzca el nombre de la campaña!",
    //     // "Campaña x": "Campaña x",
    //     // "Datos de inicio": "Fecha de inicio",
    //     // "Datos de término": "Fecha de finalización",
    //     // "Informe o período da campanha!": "¡Ingrese el período de la campaña!",
    //     "Orçamento": "Presupuesto",
    //     "Escolher tipo de orçamento": "Elegir tipo de presupuesto",
    //     "Selectione o tipo de orçamento": "Seleccione el tipo de presupuesto",
    //     "Seleccionar tipo": "Seleccionar tipo",
    //     "Diário": "Diario",
    //     "Estático": "Estático",
    //     "Flexível": "Flexible",
    //     "Continuo": "Continuo",
    //     "Matricial": "Matriz",
    //     //
    //     "Insira o valor": "Introducir valor",
    //     // "Informe o investimento": "Informe de inversión",
    //     "Revisado": "Revisado",
    //     "Tráfego": "Tráfico",
    //     "Conversión": "Conversión",
    //     // "Alcance": "Rango",
    //     // "Visualizaciones": "Vistas",
    //     // "Compromiso": "Compromiso",
    //     "Geração de Lead": "Generación de Leads",
    //     "Catálogo de vendas": "Catálogo de Ventas",
    //     "Objetivo da campanha": "Objetivo de la campaña",
    //     "Selectione o objetivo que desea": "Seleccione el objetivo que desee",
    //     "Selectione o objetivo": "Seleccionar objetivo",
    //     "Seleccionar objetivo": "Seleccionar objetivo",

    //     // CREAR CAMPAÑA TIKTOK - INFLUENCERS
    //     "Insira a URL/ID do perfil público do influenciador": "Ingresa la URL/ID del perfil público del influencer",
    //     "URL inválida. Verifique se você já adicionou essa url": "URL no válida. Verifique si ya agregó esta url",
    //     "Adicione ao menos um influenciador para poder prosseguir": "Añadir al menos un influencer para proceder",

    //     // CREAR CAMPAÑA TIKTOK - AUDIENCIA
    //     "Audiência": "Audiencia",

    //     // ------------------------------------------------ --------------------

    //     // CREAR CAMPAÑA
    //     // "Nombre": "Nombre",
    //     // "Tipo": "Tipo",
    //     "Contratante": "Contratista",
    //     "Hashtags e perfis": "Hashtags y perfiles",
    //     "Etiquetas": "Etiquetas",
    //     // "Objetivo": "Objetivo",

    //     "Real brasileiro": "real brasileño",
    //     "Dólar estadunidense": "Dólar estadounidense",
    //     "Euro": "Euro",
    //     "Integração": "Integración",
    //     "Sitio/Loja virtual": "Sitio Web/Tienda Online",
    //     "Influenciadores": "Influenciadores",
    //     "Rastrear enlaces": "Rastrear enlaces",
    //     // "Salvar": "Guardar",
    //     "Preencha o passo": "Rellenar el paso",
    //     "para poder prosseguir": "para poder proceder",
    //     "Erro na criação da campanha": "Error de creación de campaña",
    //     "Entre em contato conosco para resolvermos o problema.": "Por favor contáctenos para resolver el problema.",
    //     // "Editar campaña": "Editar campaña",
    //     // "Criar campanha": "Crear campaña",

    //     // CREAR CAMPAÑA - CONTRATISTA
    //     "Adicion o perfil social do contratante da campanha": "Añadir el perfil social del contratante de la campaña",
    //     "Insira a URL completa do perfil do contratante (público)": "Ingrese la URL completa del perfil del contratante (público)",
    //     "URL inválida ou perfil/fanpage não é pública. Verifique se você já adicionou essa url": "URL inválida o perfil/fanpage no es público. Compruebe si ya ha agregado esta url",
    //     "Agregar perfil": "Agregar perfil",
    //     "Adicione ao menos um contratante para poder prosseguir": "Agregar al menos un contratista para continuar",
    //     "Salvar e voltar": "Guardar y volver",
    //     "Salvar e avanzar": "Guardar y siguiente",

    //     // CREAR CAMPAÑA - INFLUENCERS
    //     "Agregar influenciador": "Agregar influenciador",
    //     "Importar influenciadores": "Importar influenciadores",
    //     "Exportar influenciadores": "Exportar influenciadores",
    //     "Adicione ao menos um influenciador para poder prosseguir!": "¡Añadir al menos un influencer para continuar!",
    //     "Caché ($)": "Caché ($)",
    //     "Permuta": "Intercambio",
    //     "Insira a URL completa do perfil público do influenciador": "Introduce la URL completa del perfil público del influencer",
    //     // "URL inválida ou perfil/fanpage não é pública. Verifique se você já adicionou essa url": "URL inválida o perfil/fanpage no es público. Verifique si ya agregó esta url",
    //     "Mensajes contratados do influenciador": "Puestos contratados de influencers",
    //     "Informar una cantidad": "Informar la cantidad",
    //     "Quantidade contratada": "Cantidad contratada",
    //     "Investimento": "Inversión",
    //     "Informe o investimento": "Reportar la inversión",
    //     "Historias contratadas do influenciador": "Hired Stories from the Influencer",
    //     "Valor total pago para o influenciador fazer as publicações da campanha.": "Importe total pagado por el influencer para publicar la campaña.",
    //     "Quantidade de post que o influenciador foi contratado para fazer na campanha.": "Número de publicaciones que el influencer fue contratado para realizar en la campaña.",
    //     "Quantidade de stories que o influenciador foi contrató para fazer na campanha.": "Número de stories que el influencer fue contratado para hacer en la campaña.",
    //     // "Informar una cantidad": "Informar la cantidad",
    //     // "Quantidade contratada": "Cantidad contratada",
    //     "Inversion total": "Inversion total",
    //     "Tipo de contacto": "Tipo de contacto",
    //     // "Agregar perfil": "Agregar perfil",
    //     "Insira uma lista para inclusão dos influenciadores": "Ingrese una lista para la inclusión de influenciadores",
    //     "Escolha um arquivo válido": "Elegir un archivo válido",
    //     "Escolha um arquivo": "elegir un archivo",
    //     // "Salvar": "Guardar",
    //     "Procurar": "Para buscar",
    //     "Orientação importante": "orientación importante",
    //     "Para importar influenciadores siga os passos abaixo.": "Para importar influenciadores siga los pasos a continuación.",
    //     "1º paso - Baixar o nosso modelo em Excel.": "Paso 1 - Descarga nuestra plantilla en Excel.",
    //     "2º paso - Habilite a edição no Excel, preencha as informações corretamente e salve o arquivo.": "2do paso - Habilite la edición en Excel, complete la información correctamente y guarde el archivo.",
    //     "3º paso - Selecione o arquivo haciendo clic en “procurar”.": "3er paso - Seleccione el archivo haciendo clic en “navegar“.",
    //     "4º paso - Importe para a campanha haciendo clic en “enviar”.": "4º paso - Importe a la campaña haciendo clic en “enviar”.",
    //     "Baixar modelo": "Descargar plantilla",
    //     "Influenciadores adicionados": "Influencers añadidos",

    //     // CREAR CAMPAÑA - INTEGRACIÓN GOOGLE
    //     "Sua conta do Google foi conectado com sucesso!": "¡Su cuenta de Google se ha conectado correctamente!",
    //     "Erro ao fazer login com sua conta do google!": "¡Error al iniciar sesión con su cuenta de google!",
    //     "Integração con Google Analytics": "Integración con Google Analytics",
    //     "Para que você consiga mensurar as vendas e conversões geradas por seus influenciadores, precisamos que faça login com sua conta do Google Analytics.": "Para que puedas medir las ventas y conversiones generadas por tus influencers, te necesitamos para iniciar sesión con su cuenta de Google Analytics.",
    //     "Ao clic no botão de login com o Google, você be redirecionado to a page da Web onde você precisa selecionar sua conta do Google Analytics. Selecione a conta necessária e dê acesso ao HypoLake": "Al hacer clic en el botón de inicio de sesión con Google, será redirigido a la página web donde debe seleccionar su cuenta de Google Analytics. Seleccione la cuenta requerida y dé acceso a HypoLake",
    //     "Fazer login com o Google": "Iniciar sesión con Google",
    //     "Trocar conta": "Cambiar de cuenta",
    //     "Conecte sua conta Google Analytics para poder prosseguir!": "¡Conecte su cuenta de Google Analytics para continuar!",

    //     // CREAR CAMPAÑA - REDES DE INTEGRACIÓN
    //     "Entrar com o Facebook": "Iniciar sesión con Facebook",
    //     "Entrar com o Instagram": "Iniciar sesión con Instagram",
    //     "Entrar com o Twitter": "Iniciar sesión con Twitter",
    //     "Entrar com o Youtube": "Iniciar sesión con Youtube",
    //     "Integração com as redes sociais": "Integración con redes sociales",
    //     "Selecione como redes que desea agregar y conectar-se.": "Seleccione las redes que desea agregar y conectar.",
    //     "Sua conta do": "Tu cuenta desde",
    //     "foi conectado com sucesso": "ha sido conectado exitosamente",
    //     "Conta conectada com sucesso": "Cuenta conectada correctamente",
    //     "Integre ao menos uma rede para porder prosseguir": "Integre al menos una red para poder continuar",

    //     // CREAR CAMPAÑA - MENCIONES
    //     "Hashtags citadas/marcadas nos posts da campanha": "Hashtags citados/etiquetados en publicaciones de campaña",
    //     "Hashtags e perfis citados/marcados nos posts da campanha": "Hashtags y perfiles citados/etiquetados en publicaciones de campaña",
    //     "Insira abaixo as #hashtags que desea monitorear no period da campanha": "Ingrese a continuación los #hashtags que desea monitorear durante el período de la campaña",
    //     "Adicione menções para poder prosseguir": "Añadir menciones para poder continuar",

    //     // CREAR CAMPAÑA - NOMBRE
    //     "Informações da campanha": "Información de la campaña",
    //     "Nome da campanha": "Nombre de la campaña",
    //     "Informe o nome da campanha!": "¡Introduce el nombre de la campaña!",
    //     "Campanha x": "Campaña x",
    //     "Datos de inicio": "Fecha de inicio",
    //     "Datos de término": "Fecha de finalización",
    //     "Informe o período da campanha!": "¡Ingrese el período de la campaña!",
    //     "Moeda": "Moneda",
    //     "Selectione o tipo de moeda": "Seleccione el tipo de moneda",
    //     "Seleccionar moneda": "Seleccionar moneda",

    //     // CREAR CAMPAÑA - OBJETIVO
    //     "Redes sociais / influenciador": "Redes sociales / influenciador",
    //     "Selectione redes sociais / influenciador se o seu principal objetivo de campanha for engajamento e awareness em social.": "Seleccione social media/influencer si el principal objetivo de su campaña es la participación social y la concienciación.",
    //     "Sitio/loja virtual": "Sitio web/tienda virtual",
    //     "Selecione site / loja virtual se o seu objetivo for tráfego e conversões em site ou loja virtual.": "Seleccione Sitio web / Tienda en línea si su objetivo es el tráfico y las conversiones en el sitio web o en la tienda en línea.",
    //     "Selectione o objetivo da campanha": "Seleccione el objetivo de la campaña",
    //     "Selecione ao menos uma das opções para porder prosseguir": "Seleccione al menos una de las opciones para poder continuar",

    //     // CREAR CAMPAÑA - SITIO
    //     "Seleccionar todo": "Seleccionar todo",
    //     "Comécio eletrônico": "Comercio electrónico",
    //     "Meta": "Objetivo",
    //     "Informações site/loja virtual": "Sitio web de información/tienda virtual",
    //     "Insira a URL de domínio do contratante": "Ingrese la URL del dominio del contratante",
    //     "Informar una url de domínio": "Ingrese la url del dominio",
    //     "É o endereço de seu site": "Es la dirección de su sitio web",
    //     "Insira a URL de destino": "Ingrese la URL de destino",
    //     "Informar a url de destino": "Ingrese la url de destino",
    //     "Especifique uma URL completa para determinar a página de destino à qual as pessoas serão direcionadas": "Especifique una URL completa para determinar la página de destino a la que se llevará a las personas",
    //     "Integração Site / loja virtual com Google Analytics": "Sitio web / Tienda en línea Integración con Google Analytics",
    //     "Selectione uma das opções": "Seleccione una de las opciones",
    //     "Caso você tenha um e-commerce e tenha o 'comércio eletrônico' devidamente configurado no Google Analytics, selecione Comércio Eletrônico. Caso você tenha como meta a geração de leads ou outros tipos de conversão configurados no Google Analytics, selecione a opção Meta": "Si tienes un e - commerce y tienes 'e-commerce' configurado correctamente en Google Analytics, selecciona E - commerce.Si tu objetivo es la generación de leads u otros tipos de conversión configurados en Google Analytics, selecciona la opción Goal",
    //     // "Meta": "Objetivo",
    //     "Nome Metas": "Nombre Metas",
    //     // "Pesquisar": "Buscar",
    //     "Sem metas": "Sin goles",
    //     "Selectiona ao menos uma meta para poder prosseguir": "Seleccione al menos un objetivo para continuar",

    //     // CREAR CAMPAÑA - ENLACE DE SEGUIMIENTO
    //     "Rastreamento de link do influenciador": "Seguimiento de enlaces de influencers",
    //     "O e-mail foi enviado para o influenciador": "El email fue enviado al influencer",
    //     // "Insira a URL de domínio do contratante": "Ingrese la URL del dominio del contratante",
    //     "Informe uma url de domínio válido!": "¡Proporcione una url de dominio válida!",
    //     "www.nomemarca.com.br": "www.namebrand.com.br",
    //     "Insira o e-mail do influenciador": "Ingresar e-mail del influencer",
    //     "Informe um e-mail válido!": "¡Proporcione un correo electrónico válido!",
    //     "aire@ejemplo.com": "aire@ejemplo.com",
    //     "Enviar URL Encurtada": "Enviar URL Acortada",
    //     "Reenviar Enlace": "Reenviar Enlace",

    //     // CREAR CAMPAÑA - TIPO
    //     "Criar campanha por hashtags": "Crear campaña por hashtags",
    //     "Campanha contendo apenas as hashtags que desea, sem precisar adicionar influenciador, valores entre outros.": "Campaña que contiene solo los hashtags que quieras, sin tener que agregar influencers, valores, entre otros.",
    //     "Criar campanha por influenciadores": "Crear campaña por influencers",
    //     "Campanhas contendo informações como: influenciadores, valores, hashtags, analytics site entre outros.": "Campañas que contienen información como: influencers, valores, hashtags, website analytics, entre otros.",
    //     "Selecione o tipo de campaña que desea criar": "Seleccione el tipo de campaña que desea crear",

    //     // CREAR CAMPAÑA - GUARDAR
    //     "Salvar campanha": "Guardar campaña",
    //     "Sitio/loja": "Sitio web/tienda virtual",
    //     "sitio/loja virtual": "sitio web/tienda virtual",
    //     "Redes sociais": "Redes sociales",
    //     "redes sociais": "redes sociales",
    //     "Período da campanha": "Período de campaña",
    //     "Campanha por influenciadores": "Campaña de influencers",
    //     "Campanha por hashtags": "Campaña por hashtags",
    //     // "Objetivo": "Objetivo",
    //     // "Moeda": "Moneda",
    //     "Perfil do contratante da campanha": "Perfil del contratante de campaña",
    //     "Hashtags e perfis marcados nos posts da campanha": "Hashtags y perfiles etiquetados en publicaciones de campaña",
    //     "Hashtags marcadas nos posts da campanha": "Hashtags etiquetados en publicaciones de campaña",
    //     // "Integración con Google Analytics": "Integración con Google Analytics",
    //     "Conta": "Cuenta",
    //     // "Meta": "Objetivo",
    //     "Sitio de informações / loja virtual": "Sitio de información / tienda virtual",
    //     "URL de domínio do contratante": "URL de dominio del contratista",
    //     "URL de destino do contratante": "URL de destino del contratista",
    //     "Perfil dos influenciadores da campanha": "Perfil de los influencers de la campaña",
    //     "Sua campanha foi criada com sucesso!": "¡Tu campaña ha sido creada con éxito!",
    //     "Sua campanha foi atualizada com sucesso!": "Sua campanha foi atualizada com sucesso!",
    //     "Erro ao criar campanha. Tente novamente mais tarde.": "Error al crear la campaña. Inténtalo de nuevo más tarde.",

    //     // RESUMEN - VISTA PREVIA DE LA CAMPAÑA
    //     "tooltip_total_post_hashtags": "Número total de publicaciones en relación con los hashtags de la campaña.",
    //     "tooltip_interactions_total": "Total de interacciones generadas en publicaciones de campaña publicadas por Influencers.",
    //     "tooltip_engagement_rate": "Porcentaje promedio de todos los hashtags agregados en la campaña",
    //     "tooltip_investment": "La suma de la inversión que agregaste para cada Influencer.ma de la inversión que agregaste para cada Influencer.",
    //     "tooltip_base": "Suma de la audiencia de todos los Influencers. Sabemos que esto difícilmente se conseguirá sin una inversión media",
    //     "tooltip_feed_hired": "Número de publicaciones contratadas para el feed del influencer",
    //     "tooltip_stories_hired": "Número de publicaciones contratadas de historias de influencers",
    //     "tooltip_clicks": "Esta es la suma de todos los clics en enlaces relacionados con la campaña.",
    //     "tooltip_cost_per_click": "Es el costo por visita de cada perfil contratado en la campaña",
    //     "tooltip_conversion_rate": "Porcentaje total de ventas generado por la campaña.",
    //     "tooltip_roi": "Es el retorno total de la inversión que trajo la campaña.",
    //     "É o desempenho dos posts da campanha em relação ao desempenho médio das postagens do influenciador antes do início da campanha.": "Es el rendimiento de las publicaciones de la campaña en relación con el rendimiento promedio de las publicaciones del influencer antes de que comenzara la campaña.",

    //     // RESUMEN - MIS CAMPAÑAS
    //     "tooltip_active_campaigns": "Número total de campañas en curso.",
    //     "tooltip_total_investment": "Suma de la inversión de todas las campañas.",
    //     "tooltip_mean_investment": "Inversión total de todas las campañas activas.",
    //     "tooltip_influencers": "Suma de todos los perfiles en campañas.",
    //     "tooltip_mean_influencers": "Perfiles totales divididos por campaña.",
    //     "tooltip_impressions": "Suma de impresiones de publicaciones + historias.",
    //     "tooltip_mean_impressions": "Impresión dividida por alcance (publicación e Historias), luego haremos el promedio total. Aquí tenemos que hacer una tasa por influenciador y luego hacemos un promedio total.",
    //     "tooltip_mean_engagement": "Es el número promedio de interacciones de las publicaciones de perfil.",
    //     "tooltip_mean_cpi": "Costo por interacción: Promedio por interacción con publicaciones de perfil de campaña.",
    //     "tooltip_total_conversions": "Ventas totales generadas por la campaña.",
    //     "tooltip_connected_profiles": "Número de perfiles conectados (conexión habilitada) en campañas.",

    //     // RESUMEN - ENLACES DE SEGUIMIENTO
    //     "tooltip_page_tracks": "Cantidad de páginas enlazadas con Google Analytics.",
    //     "tooltip_tracks": "Número de personas influyentes con enlace rastreado activo por la herramienta.",
    //     "tooltip_total_investment": "Suma de todas las inversiones declaradas en campañas.",
    //     "tooltip_mean_investment": "Es la inversión promedio en relación a las campañas activas.",
    //     "tooltip_ga_traffic": "La suma de todo el tráfico generado por el enlace de Google Analytcs.",
    //     "tooltip_mean_traffic": "Suma de todo el tráfico diario por campaña total.",
    //     "tooltip_total_clicks": "Suma de todos los clics del acortador de perfil.",
    //     "tooltip_cost_per_traffic": "Es el costo de todo el tráfico generado por el enlace de Google Analytics.",

    //     // ALERTAS
    //     "éxito": "éxito",
    //     "Sucesso": "Éxito",
    //     "error": "error",
    //     "Error": "Error",
    //     "ERROR": "ERROR",
    //     "alerta": "alerta",
    //     "Alerta": "Alerta",
    //     "atención": "Alerta",
    //     //
    //     "Link copiado! Através deste link, o contratante poderá se catastrar no Airscore e assim você terá métricas mais completas sobre sua campanha": "¡Link copiado! A través de este enlace, el contratista podrá registrarse en Airscore y así tendrás más métricas completas sobre tu campaña",
    //     "Link copiado! Envie este link para o influenciador. Através deste link, o influenciador podrá se catastrar no Airscore e assim você terá métricas mais completas sobre su a campanha.": "¡Link copiado! Envía este enlace al influencer. A través de este enlace, el influencer podrá registrarse en Airscore y así tendrás métricas más completas sobre tu campaña.",
    //     "Link copiado! Envie este link para o influenciador. Através deste link, o influenciador podrá compartir seu link próprio para medirmos seu desempenho.": "¡Link copiado! Envía este enlace al influencer. A través de este enlace, el influencer podrá comparte su propio enlace para que podamos medir su rendimiento.",
    //     "Deseja eliminar esse perfil da campanha": "¿Quieres eliminar este perfil de la campaña?",

    //     "Notamos que a URL passada está usando UTM SOURCE. É de extrema importâcia que exista um link para cada influenciador, pois os tráfegos que não pertencem a eles serão contabilizados como pertencentes.": "We noticed that the URL passed is using UTM SOURCE. It is extremely important that there is a link for each influencer, as traffic that does not belong to them will be counted as belonging.",
    //     "Deseja continuar usando esse link?": "Do you want to continue using this link?",
    //     "Não, enviar sem UTM SOURCE": "No, send without UTM SOURCE",
    //     "Sim": "Yes",

    //     "Sim, remover perfil": "Yes, remove profile",
    //     "Não, manter perfil": "No, keep profile",
    //     "Campanha duplicada com sucesso": "Successfully duplicated campaign",
    //     "E-mail atualizado com sucesso": "Email successfully updated",
    //     "Não foi possível atualizar seu e-mail. Tente novamente": "Your email could not be updated. Try again",
    //     "Erro ao arquivar campanha!": "Error archiving campaign!",
    //     "Erro ao duplicar campanha!": "Error duplicating campaign!",
    //     "Erro ao exportar campanha!": "Error exporting campaign!",
};

export default span;
