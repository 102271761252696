import React from "react";
import PropTypes from "prop-types";
//
import { Pagination as AntPagination } from "antd";
//
import "./Pagination.scss";

const Pagination = ({ pageSize, total, current, isDisabled, onChange }) => {
    return (
        <AntPagination
            className="simple-pagination"
            pageSize={pageSize}
            total={total}
            current={current}
            disabled={isDisabled}
            onChange={onChange}
            showSizeChanger={false}
        />
    );
};

Pagination.propTypes = {
    pageSize: 10,
    total: 1,
    current: 4,
    isDisabled: false,
    onChange: () => {},
};

Pagination.propTypes = {
    pageSize: PropTypes.number,
    count: PropTypes.number,
    current: PropTypes.number,
    isDisabled: PropTypes.bool,
    onChange: PropTypes.func,
};

export default Pagination;
