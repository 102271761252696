import React from "react";
import PropTypes from 'prop-types';
import { Tooltip as AntTooltip} from 'antd';
// 
import './Tooltip.scss';

const Tooltip = ({ child }) => <AntTooltip title={child}>
    <i className="icon icon-tooltip" />
</AntTooltip>

Tooltip.propTypes = {
    child: PropTypes.string
}

export default Tooltip
