import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// 
import { useDispatch, useSelector } from 'react-redux';
import { saveStep, selectCampaign, sendInfluencerLinkBatch, updateTrackedLink } from '../../createCampaignSlice';
// 
import Button from '../../../../common/components/Button/Button'
import Card from '../../../../common/components/Card/Card';
import TrackingInfluencerLink from './TrackingInfluencerLink/TrackingInfluencerLink';
// 
import "./StepTrackingLink.scss";
import CardFooter from '../../components/CardFooter/CardFooter';
import StateStatus from '../../../../utils/stateStatus';
import Radio from '../../../../common/components/Radio/Radio';
import Input from '../../../../common/components/Input/Input';
import { changeObjAlert } from '../../../../common/components/Alert/alertSlice';

import { Typography } from 'antd';


const StepTrackingLink = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const objCampaign = useSelector(selectCampaign);

    const [selectedOption, setSelectedOption] = useState(objCampaign.campaign.trackedLink ? "tracked_link" : "generate_tracked_link")
    const [url, setUrl] = useState("")
    const [error, setError] = useState(false)

    const optionDefaultValue = objCampaign.campaign.trackedLink ? 0 : 1

    const sendLinks = () => {
        const influencers = objCampaign.campaign.influencers
        dispatch(sendInfluencerLinkBatch({ campaignId: objCampaign.campaign.id, items: influencers }))
    }

    const options = [
        { value: "tracked_link", label: t("Meu link rastreado") },
        { value: "generate_tracked_link", label: t("Gerar link rastreado") },
    ];

    useEffect(() => {
        if (objCampaign.campaign.trackedLink) {
            setUrl(objCampaign.campaign.trackedLink)
        }
    }, [objCampaign.campaign])

    useEffect(() => {
        if (url.length > 0) {
            const urlRegex = new RegExp('^(https?:\\/\\/)?' + // validate protocol
                '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
                '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
                '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
                '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
                '(\\#[-a-z\\d_]*)?$', 'i'); // validate fragment locator

            if (!url || !urlRegex.test(url)) {
                setError(true)
            } else {
                setError(false)
            }
        }
    }, [url])

    return (
        <Card
            child={
                <>
                    <h2 className="step-name">{t("Rastreamento de link do influenciador")}</h2>
                    <Radio
                        cardStyle={false}
                        options={options}
                        initialValue={optionDefaultValue}
                        onChange={(option) => { setSelectedOption(option.value); }}
                    />
                    {selectedOption === "tracked_link" && <div className="link-input">

                        <div className="input-junction">
                            <span className="label-input">{t("Insira a URL de domínio do contratante")}</span>
                            <Input
                                width="100%"
                                error={error}
                                textError={t("Informe uma url de domínio válida!")}
                                value={url}
                                placeholder={t("Insira o link")}
                                onChange={(text) => {
                                    setUrl(text)
                                }}
                                onBlur={(text) => {
                                    if (text.includes("utm_")) {
                                        dispatch(changeObjAlert({
                                            show: true,
                                            type: "warning",
                                            title: t("atenção"),
                                            text: t("Notamos que a URL passada está usando UTM SOURCE. É de extrema importâcia que exista um link para cada influenciador, pois os tráfegos que não pertencem a eles serão contabilizados como pertencentes."),
                                            question: t("Deseja continuar usando esse link?"),
                                            okText: t("Sim"),
                                            cancelText: t("Não, enviar sem UTM SOURCE"),
                                            onCancel: () => {
                                                const newUrl = url.replace(/(\?)utm[^&]*(?:&utm[^&]*)*&(?=(?!utm[^\s&=]*=)[^\s&=]+=)|\?utm[^&]*(?:&utm[^&]*)*$|&utm[^&]*/gi, '$1')
                                                setUrl(newUrl)
                                            },
                                            onOk: () => {
                                            }
                                        }))
                                    }
                                }}
                            />
                        </div>
                    </div>
                    }
                    {selectedOption === "generate_tracked_link" && <>
                        <div className="junction-influencers">
                            {objCampaign.campaign.influencers.map((influencer, key) => {
                                return <TrackingInfluencerLink campaignId={objCampaign.campaign.id} loading={objCampaign.status == "loading"} key={key} index={key} influencer={influencer} />
                            })
                            }

                        </div>
                        <div className="junction-send-button">
                            <div>
                                {objCampaign.statusLinkBatch === StateStatus.succeeded && <Typography color="green">{t("E-mails enviados")}</Typography>}
                                <Button
                                    color="success"
                                    key={objCampaign.campaign.influencers}
                                    disabled={(objCampaign.campaign.influencerLinkTrackings).some((item) => item !== -1) || !objCampaign.campaign.influencers.some(item => item.email && item.email.length > 0)}
                                    onClick={() => { sendLinks() }}
                                    loading={objCampaign.statusLinkBatch === StateStatus.loading}
                                    text={t("Enviar para todos")}
                                />
                            </div>
                        </div>
                    </>}
                </>
            }
            footer={
                <CardFooter
                    loading={objCampaign.status == "loading"}
                    onBack={() => dispatch(saveStep({ step: "tracking", index: objCampaign.index - 1 }))}
                    onNext={() => {
                        if (selectedOption === "tracked_link") {
                            dispatch(updateTrackedLink(url))
                        }
                        else {
                            dispatch(updateTrackedLink(null))
                        }
                        dispatch(saveStep({ step: "tracking", index: objCampaign.index + 1, selectedOption, globalUrl: url }))
                    }
                    }
                />
            }
        />
    );
}

export default StepTrackingLink;