import moment from "moment";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Calendar from "../../../../common/components/Calendar/Calendar";
//
import CardFooter from "../../components/CardFooter/CardFooter";
import Card from "../../../../common/components/Card/Card";
import Input from "../../../../common/components/Input/Input";
import Select from "../../../../common/components/Select/Select";
//
import {
    saveStep,
    selectCampaign,
    updateDates,
    updateName,
    updateCoin,
    updatePublicName,
    changeStep,
    updateDescription,
} from "../../createCampaignSlice";
//
import "./StepName.scss";

const StepName = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // const { id } = useParams();

    const objCampaign = useSelector(selectCampaign);

    const [error, setError] = useState(-1);

    const isValid = () => {
        if (!objCampaign.campaign.name) {
            setError(0);
        } else if (objCampaign.campaign.type === "influencers" && !objCampaign.campaign.publicName) {
            setError(1);
        } else if (
            objCampaign.campaign.type === "influencers" &&
            (!objCampaign.campaign.description ||
                objCampaign.campaign.description.length < 3 ||
                objCampaign.campaign.description.length > 500)
        ) {
            setError(2);
        } else if (!objCampaign.campaign.startDate || !objCampaign.campaign.endDate) {
            setError(3);
        } else if (objCampaign.campaign.type === "influencers" && !objCampaign.campaign.coin) {
            setError(4);
        } else {
            dispatch(saveStep({ step: "name", index: objCampaign.index + 1 }));
        }
    };

    return (
        <Card
            child={
                <>
                    <h2 className="step-name">{t("Informações da campanha")}</h2>

                    <div className="inputs inputs-step-name">
                        <div className="junction-input junction-name">
                            <span className="label-input">{t("Nome da campanha interna")}</span>
                            <Input
                                width="100%"
                                maxLength={30}
                                outline={false}
                                error={error == 0}
                                textError={t("Informe o nome da campanha!")}
                                placeholder={t("Campanha x")}
                                value={objCampaign.campaign.name}
                                onChange={(value) => {
                                    dispatch(updateName(value));
                                    setError(-1);
                                }}
                            />
                        </div>

                        <div className="junction-input junction-name">
                            {objCampaign.campaign.type == "influencers" && (
                                <>
                                    <span className="label-input">
                                        {t("Nome da campanha visível para o influenciador")}
                                    </span>
                                    <Input
                                        width="100%"
                                        maxLength={30}
                                        outline={false}
                                        error={error == 1}
                                        textError={t("Informe o nome da campanha!")}
                                        placeholder={t("Campanha x")}
                                        value={objCampaign.campaign.publicName}
                                        onChange={(value) => {
                                            dispatch(updatePublicName(value));
                                            setError(-1);
                                        }}
                                    />
                                </>
                            )}
                        </div>

                        <div className="junction-input junction-description">
                            {objCampaign.campaign.type == "influencers" && (
                                <>
                                    <span className="label-input">
                                        {t("Descrição da campanha (visível para o influenciador)")}
                                    </span>
                                    <textarea
                                        width="100%"
                                        className={`${error == 2 ? "input-error" : ""}`}
                                        value={objCampaign.campaign.description}
                                        onChange={(e) => {
                                            dispatch(updateDescription(e.target.value));
                                            setError(-1);
                                        }}
                                    />
                                    {error === 2 && (
                                        <span className="text-error">
                                            {t("Informe uma descrição entre três e quinhentos caracteres")}
                                        </span>
                                    )}
                                </>
                            )}
                        </div>

                        <div className="junction-input junction-date-coin">
                            <div className="junction-date">
                                <div className="inputs-labels">
                                    <span className="label-input">{t("Data de início")}</span>
                                    <span className="label-input">{t("Data de término")}</span>
                                </div>

                                <Calendar
                                    oneInput={true}
                                    error={error == 3}
                                    textError={t("Informe o período da campanha!")}
                                    placeholderEndDate="xx/xx/xxxx"
                                    placeholderStartDate="xx/xx/xxxx"
                                    value={
                                        !objCampaign.campaign.startDate
                                            ? null
                                            : [
                                                  moment(objCampaign.campaign.startDate.date),
                                                  moment(objCampaign.campaign.endDate.date),
                                              ]
                                    }
                                    onChange={(date, dateString) => {
                                        setError(-1);
                                        dispatch(
                                            updateDates([
                                                { date: date[0]._d, dateFormat: dateString[0] },
                                                { date: date[1]._d, dateFormat: dateString[1] },
                                            ])
                                        );
                                    }}
                                />
                            </div>
                            {objCampaign.campaign.type == "influencers" && (
                                <div className="junction-coin">
                                    <span className="label-input">{t("Moeda")}</span>
                                    <Select
                                        error={error == 4}
                                        width="100%"
                                        textError={t("Selecione o tipo de moeda")}
                                        defaultValue={
                                            objCampaign.campaign.coin ? t(objCampaign.campaign.coin.key) : null
                                        }
                                        placeholder={t("Selecionar moeda")}
                                        onChange={(coin) => {
                                            dispatch(updateCoin(coin));
                                            if (error == 2) setError(-1);
                                        }}
                                        options={objCampaign.coins.map((coin) => {
                                            return { value: coin.alias, key: t(coin.label), symbol: coin.symbol };
                                        })}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </>
            }
            footer={
                <CardFooter
                    textBack={t("Voltar")}
                    loading={objCampaign.status == "loading"}
                    onBack={() => dispatch(changeStep(objCampaign.index - 1))}
                    onNext={() => isValid()}
                />
            }
        />
    );
};

export default StepName;
