import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next'
// 
import { Modal } from 'antd';
// 
import Button from '../../../../../../common/components/Button/Button';
import CheckBox from '../../../../../../common/components/CheckBox/CheckBox';
import "./PlanCancelModal.scss"

const PlanCancelModal = ({ show, onOk, onCancel, loading }) => {
    const { t } = useTranslation()

    const [cancellation, setCancellation] = useState(false);
    const [lossAccess, setLossAccess] = useState(false);

    useEffect(() => {
        setCancellation(false);
        setLossAccess(false);
    }, [show])

    return <Modal
        title={t("Cancelamento de plano")}
        visible={show}
        onCancel={() => onCancel()}
        className="modal-plan-cancel"
    >
        <div className="content-plan-canel">
            <p className="text">{t("Antes de cancelar o plano, leia e selecione os itens abaixo")}.</p>

            <CheckBox
                checked={cancellation}
                onChange={() => setCancellation(!cancellation)}
                text={t("O cancelamento ocorrerá no final do seu ciclo atual de pagamento.")}
            />

            <CheckBox
                checked={lossAccess}
                onChange={() => setLossAccess(!lossAccess)}
                text={t("Você perderá o acesso à nossa plataforma, incluindo todos os dados cadastrados.")}
            />

        </div>

        <footer className="footer-plan-canel">
            <Button
                loading={loading}
                disabled={!cancellation || !lossAccess}
                color="danger"
                onClick={() => onOk()}
                text={t("Cancelar plano")}
            />
        </footer>
    </Modal>
}

PlanCancelModal.defaultProps = {
    show: false,
    loading: false,
    onOk: null,
    onCancel: null,
}

PlanCancelModal.propTypes = {
    show: PropTypes.bool,
    loading: PropTypes.bool,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
}

export default PlanCancelModal;