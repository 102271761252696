import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
// 
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { changeObjAlert, showAlert } from '../../../common/components/Alert/alertSlice';
// 
import Avatar from "../Avatar/Avatar";
// import { Tooltip } from "antd";
// 
import iconTikTok from "../../assets/icons/tiktok.svg"
// import logo from "../../assets/icons/o.svg";
import "./InfluencerTable.scss";
import ConnectStatus from "../ConnectStatus/ConnectStatus";

const InfluencerTable = ({ influencers, deleteInfluencer, openEdit, symbolCoin }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [sorts, setSorts] = useState(["name"]);
  const [boolBigger, setBoolBigger] = useState(false);
  const [arrowLocation, setArrowLocation] = useState(2);
  const [orderInfluencers, setOrderInfluencers] = useState([]);

  const columns = [
    { alias: "name", title: t("influenciador"), span: 2 },
    { alias: "network", title: t("rede"), span: 2 },
    { alias: "quantVideos", title: "videos / reels", span: 1 },
    { alias: "quantPosts", title: "posts", span: 1 },
    { alias: "quantStories", title: "stories", span: 1 },
    { alias: "investmentTotal", title: t("investimento"), span: 2 },
    { alias: "inAirscore", title: t("connect"), span: 2 },
    { alias: "", title: t("actions"), span: 2 },
  ];

  const orderBy = (obj = "perNumber", index) => {
    setArrowLocation(index);

    if (!sorts.includes(obj)) {
      const newOrder = [...orderInfluencers].sort((a, b) => {
        if (obj === "name" || obj === "network") {
          return a[obj].toLowerCase() > b[obj].toLowerCase() ? -1 : 1;
        } else {
          return a[obj] > b[obj] ? -1 : 1;
        }
      });
      setBoolBigger(true);
      setOrderInfluencers(newOrder);
      setSorts((oldArray) => [...oldArray, obj]);
    } else {
      const newOrder = [...orderInfluencers].sort((a, b) => {
        if (obj === "name" || obj === "network") {
          return a[obj].toLowerCase() < b[obj].toLowerCase() ? -1 : 1
        } else {
          return a[obj] < b[obj] ? -1 : 1;
        }
      });
      setBoolBigger(false);
      setOrderInfluencers(newOrder);
      setSorts(sorts.filter(item => item !== obj))
    }
  }

  const generateLink = (text) => {
    navigator.clipboard.writeText(text);

    dispatch(changeObjAlert({
      show: true,
      type: "success",
      title: t("sucesso"),
      text: t("Link copiado! Envie este link para o influenciador. Através deste link, o influenciador poderá se cadastrar no Airscore e assim você terá métricas mais completas sobre sua campanha."),
      onCancel: () => { dispatch(showAlert(false)) },
    }))
  }

  useEffect(() => {
    if (influencers != null) {
      setOrderInfluencers(influencers);
    }
  }, [influencers]);

  return <table className="table-influencer">
    <thead>
      <tr className="table-c-titles">
        {columns.map((item, key) => {
          return (item.alias == "" && deleteInfluencer == null) ? null : (
            <th
              key={key}
              rowSpan={item.span}
              className={`th-influencer ${item.alias == "" ? "default" : ""}`}
              onClick={() => item.alias != "" && orderBy(item.alias, key, item.withTotal)}
            >
              <span className="name-column">{item.title}</span>
              {key === arrowLocation && <i className={`icon icon-down ${boolBigger ? "bigger" : "smaller"}`} />}
            </th>
          )
        })}
      </tr>
    </thead>
    <tbody>
      {
        orderInfluencers.length > 0 && orderInfluencers.map((influencer, key) => {
          return <tr key={key}>
            <th className="th-profile">
              <Avatar
                size="24px"
                network={influencer.network}
                src={influencer.avatar}
              />
              {influencer.name}
            </th>

            <th className={`th-network color-${influencer.network}`}>
              {influencer.network === "tiktok" ?
                <img className="tiktok-icon-circle" alt="tiktok icon" src={iconTikTok} /> :
                <i className={`icon icon-${influencer.network}-circle`} />
              }
            </th>

            <th className="th-videos">
              {influencer.contractVideos || "-"}
            </th>

            <th className="th-post">
              {influencer.contractFeeds || "-"}
            </th>

            <th className="th-stories">
              {influencer.contractStories || "-"}
            </th>

            <th className="th-investment-total">
              {symbolCoin} {(influencer.investmentVideos + influencer.investmentFeeds + influencer.investmentStories).toLocaleString("pt-BR")}
            </th>

            <th className={`th-connect-status ${!influencer.connected && "in-airscore"}`} onClick={() => { !influencer.connected && !influencer.waitingTcm && generateLink(influencer.connectUrl); }}>
              {
                <ConnectStatus status={influencer.connectStatus} />
              }
            </th>

            {
              deleteInfluencer != null && <th className="th-actions">
                <i className="icon icon-edit" onClick={() => { openEdit(influencer, key); }} />

                <i className="icon icon-trash" onClick={() => {
                  dispatch(changeObjAlert({
                    show: true,
                    type: "warning",
                    title: t("atenção"),
                    text: `${t("Deseja excluir esse perfil da campanha")}?`,
                    okText: t("Sim, remover perfil"),
                    cancelText: t("Não, manter perfil"),
                    onCancel: () => { dispatch(showAlert(false)) },
                    onOk: () => { setTimeout(() => { deleteInfluencer(influencer.id); }, 300) }
                  }))
                }} />
              </th>
            }
          </tr>
        })
      }
    </tbody>
  </table>
}

InfluencerTable.propTypes = {
  openEdit: PropTypes.func,
  influencers: PropTypes.array,
  deleteInfluencer: PropTypes.func,
  symbolCoin: PropTypes.string
}

export default InfluencerTable;