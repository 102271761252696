import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import connectIcon from "../../assets/icons/waiting_connect.svg";
import waitingIcon from "../../assets/icons/waiting_ttcm.svg";
import connectedIcon from "../../assets/icons/connected.svg";

import "./ConnectStatus.scss";

const Connected = () => {
    const { t } = useTranslation();
    return (
        <div className="tag-connected">
            <img src={connectedIcon} />
            {t("Conectado")}
        </div>
    );
};

const Error = () => {
    const { t } = useTranslation();
    return (
        <div className="tag-error">
            <img src={waitingIcon} />
            {t("Verificando")}
        </div>
    );
};

const Waiting = () => {
    const { t } = useTranslation();
    return (
        <div className="tag-waiting">
            <img src={connectIcon} />
            <span>{t("Solicitar Conecte")}</span>
        </div>
    );
};

const Expired = () => {
    const { t } = useTranslation();
    return (
        <div className="tag-expired">
            <img src={connectIcon} />
            <span>{t("Expirado - Solicitar Conecte Novamente")}</span>
        </div>
    );
};

const WaitingTcm = () => {
    const { t } = useTranslation();
    return (
        <div className="tag-waiting-ttcm">
            <img src={waitingIcon} />
            {t("Aguardando entrada no TTCM")}
        </div>
    );
};

const ConnectStatus = ({ status }) => {
    if (status === "connected") {
        return <Connected />;
    } else if (status === "error") {
        return <Error />;
    } else if (["waiting", "cancelled", "revoked"].includes(status)) {
        return <Waiting />;
    } else if (status === "expired") {
        return <Expired />;
    } else if (status === "waiting_tcm") {
        return <WaitingTcm />;
    }

    return <div />;
};

ConnectStatus.propTypes = {
    status: PropTypes.string,
};

export default ConnectStatus;
