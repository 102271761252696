import api from '../../service/api'

export const getCampaignData = (id) => api.get(`campaign/${id}`); 

export const updType = (id, data) => api.put(`campaign/${id}/type`, data); 
// data = { type: "" }

export const updObjective = (id, data) => api.put(`campaign/${id}/objective`, data); 
// data = { objective: "" }

export const addName = (data) => api.post(`campaign/create`, data); 
// data = { name: "", start_date: "", end_date: "" }

export const updName = (id, data) => api.put(`campaign/${id}`, data); 
// data = { name: "", start_date: "", end_date: "" currency: "" }

export const updBrand = (id, data) => api.put(`campaign/${id}/brand`, data); 
// data = { brand_name: "", brand_email: "", brand_contact_name: "", brand_phone: "" }

export const addContractor = (id, data) => api.post(`campaign/${id}/contractors`, data); 
// data = { profile_url: "" }

export const addIntegration = (id, data) => api.post(`campaign/${id}/integrations`, data);
// data = { profile_url: "" }


export const rmvContractor = (id, contractorId) => api.delete(`campaign/${id}/contractors/${contractorId}`); 

export const getGoals = (id) => api.get(`campaign/${id}/goals`); 

export const updSite = (id, data) => api.put(`campaign/${id}/analytics`, data); 
// data = { analytics_integration_type: "goal", goal: [{ name: "", account_id: "", track_id: "", goal_id: "" }], site_domain: "", site_url: "" }

export const addInfluencer = (id, data) => api.post(`campaign/${id}/influencers`, data); 
// data = { contract_type: "", profile_url: "", posts_count: 1, posts_value: 1, stories_count: 1, stories_value: 1 }

export const updInfluencer = (id, contractId, data) => api.put(`campaign/${id}/influencers/${contractId}`, data); 

export const imptInfluencers = (id, file) => api.post(`campaign/${id}/influencers/import`, file); 

export const rmvInfluencer = (id, contractId) => api.delete(`campaign/${id}/influencers/${contractId}`); 

export const influencerLink = (id, contractId, data) => api.post(`campaign/${id}/influencers/${contractId}/link/send`, data); 
export const influencerLinkBatch = (id, data) => api.put(`campaign/${id}/influencers/link/batch`, data); 


export const influencersLink = (id, data) => api.put(`campaign/${id}/influencers/link/batch`, data); 
export const influencersLinkGlobal = (id, link) => api.put(`campaign/${id}/influencers/link/global`, {link});

// data = [{ link: "", contract_id: "", email: "" }]

export const updMentions = (id, data) => api.put(`campaign/${id}/markers`, data); 
// data = { markers: [ "#a", "@a" ] }

export const updIntegration = (id, data) => api.post(`campaign/${id}/integrations`, data); 
// data = { provider: "instagram", external_id: "123124131223d1" }    obs: aparentemente serve para o google e para as redes

export const rmvIntegration = (id, idAuthenticator) => api.delete(`campaign/${id}/integrations/${idAuthenticator}`); 

export const exportInfluencers = (id) => api.get(`campaign/${id}/influencers/export`);

export const saveCampaign = (id) => api.post(`campaign/${id}/save`); 
