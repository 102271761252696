import React from 'react';
import PropTypes from  'prop-types'
// 
import { Checkbox as AntCheckbox, Tooltip } from 'antd';
// 
import "./CheckBox.scss";

const CheckBox = ({
    text,
    description,
    checked,
    cardStyle,
    onChange,
    tooltip,
    disabled,
}) => 
    <Tooltip title={tooltip}>
        <div className={`checkbox-container ${cardStyle ? "checkbox-card" : ""}`}>
            <AntCheckbox checked={checked} disabled={disabled} onChange={(value) => { onChange && onChange(value) }}>
                <span className="checkbox-text">{text}</span>
            </AntCheckbox>

            { description && <p className="checkbox-description"> { description } </p> }
        </div> 
    </Tooltip> 

CheckBox.propTypes = {
    text: PropTypes.any,
    description: PropTypes.string,
    checked: PropTypes.bool,
    cardStyle: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    tooltip: PropTypes.func,
}

export default CheckBox;