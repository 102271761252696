import React, { useState } from 'react';
import { useTranslation } from "react-i18next"
import PropTypes from 'prop-types'
// 
import { Button as AntButton } from 'antd'
// 
import "./CardFooter.scss"

const CardFooter = ({ onBack, onNext, hasBack, hasNext, textBack, textNext, loading }) => {
    const { t } = useTranslation();

    const [btnClicked, setBtnClicked] = useState("next")

    return <div className={`cc-card-footer ${(hasBack && hasNext) ? "beetwen" : "end"}`}>
        { hasBack &&
            <AntButton 
                className="button-step"
                icon={<i className="icon icon-back" />}
                loading={loading && btnClicked === "back"}
                onClick={() => { setBtnClicked("back"); onBack() }}
            >
                <span className="button-text deselect-text text-black">{t(textBack)}</span>
            </AntButton>
        }

        { hasNext &&
            <AntButton 
                className="button-step button-next"
                icon={<i className="icon icon-next" />}
                loading={loading && btnClicked === "next"}
                onClick={() => { setBtnClicked("next"); onNext() }}
            >
                <span className="button-text deselect-text">{t(textNext)}</span>
            </AntButton>
        }
    </div>
}

CardFooter.defaultProps = {
    onBack: null, 
    onNext: null, 
    hasBack: true,
    hasNext: true,
    loading: false,
    textBack: "Salvar e voltar",
    textNext: "Salvar e avançar",
}

CardFooter.propTypes = {
    onBack: PropTypes.func, 
    onNext: PropTypes.func, 
    hasBack: PropTypes.bool,
    hasNext: PropTypes.bool,
    loading: PropTypes.bool,
    textBack: PropTypes.string,
    textNext: PropTypes.string,
}

export default CardFooter