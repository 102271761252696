import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import StateStatus from '../../utils/stateStatus'
//
import {
	updName,
	updEmail,
	updPassword,
	// 
	invoices,
	downloadInvoice,
	// 
	deletePlan,
	contractedPlan,
	getAccounts,
	saveNewAccount,
	updAccountName,
	updAccountEmail,
	updAccountPassword,
	removeAccount,
	subscription
} from './ConfigurationServices'
import { profile } from '../Login/LoginSlice'

const initialState = {
	// profile
	statusName: StateStatus.idle,
	statusEmail: StateStatus.idle,
	statusPassword: StateStatus.idle,
	// financial
	statusInvoices: StateStatus.idle,
	statusLoadInvoice: StateStatus.idle,
	// payment
	statusCancel: StateStatus.idle,
	statusSubscription: StateStatus.idle,
	// contracted plan
	statusContractedPlan: StateStatus.idle,
	statusLoadAccounts: StateStatus.idle,
	statusCreateAccount: StateStatus.idle,
	statusAccountName: StateStatus.idle,
	statusAccountEmail: StateStatus.idle,
	statusAccountPassword: StateStatus.idle,
	statusDeleteAccount: StateStatus.idle,
	msg: null,

	// invoices
	objInvoices: {
		total: 0,
		totalPages: 0,
		itemsPerPage: 0,
	},
	invoice: null,
	invoices: [
		// { payment_date: "Dez 15, 2021", invoice_id: "AIRM080014188BR", value: "$ 129,00", plan_name: "https://www.google.com/" },
		// { payment_date: "Dez 15, 2021", invoice_id: "AIRM080014188BR", value: "$ 129,00", plan_name: "https://www.google.com/" },
		// { payment_date: "Dez 15, 2021", invoice_id: "AIRM080014188BR", value: "$ 129,00", plan_name: "https://www.google.com/" },
		// { payment_date: "Dez 15, 2021", invoice_id: "AIRM080014188BR", value: "$ 129,00", plan_name: "https://www.google.com/" },
	],
	editingAccount: null,  
	subscription: {},
	accounts: {
		pagination: {
			total: 0,
			totalPages: 1,
			itemsPerPage: 10
		},
		items: []
	},
	// payment
	objPayment: {
		name: "-",
		value: "-",
	},
}

// PROFILE
export const updateName = createAsyncThunk('config/update-name', async (data) => {
	const response = await updName(data)

	return response
})
export const updateEmail = createAsyncThunk('config/update-email', async (data) => {
	const response = await updEmail(data)

	return response
})
export const updatePassword = createAsyncThunk('config/update-password', async (data) => {
	const response = await updPassword(data)

	return response
})

export const getContractedPlan = createAsyncThunk('config/get-contracted-plan', async () => {
	const response = await contractedPlan()
	return response.data
})

// FINANCIAL
export const getInvoices = createAsyncThunk('config/get-invoices', async (data) => {
	const response = await invoices(data)

	const pagination = {
		total: response.data.count,
		totalPages: response.data.pages,
		itemsPerPage: response.data.items_per_page
	}

	return { pagination, items: response.data.items }
})
export const loadInvoice = createAsyncThunk('config/download-invoices', async (data) => {
	const response = await downloadInvoice(data)

	return response
})

// PAYMENT
export const cancelPlan = createAsyncThunk('config/cancel-plan', async () => {
	const response = await deletePlan()

	return response
})


export const loadAccounts = createAsyncThunk('config/load-accounts', async () => {
	const response = await getAccounts()

	return { items: response.data.items }
})


export const createAccount = createAsyncThunk('config/create-account', async (values) => {
	const response = await saveNewAccount(values)
	return response
})


export const updateAccountName = createAsyncThunk('config/update-account-name', async (values) => {
	const {id, data} = values
	const response = await updAccountName(id, data)

	return response
})
export const updateAccountEmail = createAsyncThunk('config/update-account-email', async (values) => {
	const { id, data } = values
	const response = await updAccountEmail(id, data)

	return response
})
export const updateAccountPassword = createAsyncThunk('config/update-account-password', async (values) => {
	const { id, data } = values
	const response = await updAccountPassword(id, data)

	return response
})

export const deleteAccount = createAsyncThunk('config/delete-account', async (values) => {
	const { id } = values
	const response = await removeAccount(id)

	return response
})

export const getSubscription = createAsyncThunk('config/subscription', async () => {
	const response = await subscription()

	return response
})


export const configuration = createSlice({
	name: 'configuration',
	initialState,
	reducers: {
		clearStatus: (state) => {
			state.statusName = StateStatus.idle
			state.statusEmail = StateStatus.idle
			state.statusPassword = StateStatus.idle
			// 
			state.statusFinancial = StateStatus.idle
			state.statusLoadInvoice = StateStatus.idle
			// 
			state.statusCancel = StateStatus.idle
			state.statusDeleteAccount = StateStatus.idle
			// 
			state.statusLoadAccounts = StateStatus.idle
			state.statusCreateAccount = StateStatus.idle
			state.statusAccountName = StateStatus.idle
			state.statusAccountEmail = StateStatus.idle
			state.statusAccountPassword = StateStatus.idle
			state.msg = null
		},
		clearSomeStates: (state) => {
			state.statusName = StateStatus.idle
			state.statusEmail = StateStatus.idle
			state.statusPassword = StateStatus.idle
			// 
			state.statusFinancial = StateStatus.idle
			state.statusLoadInvoice = StateStatus.idle
			// 
			state.statusCancel = StateStatus.idle
			state.statusDeleteAccount = StateStatus.idle
			// 
			state.statusCreateAccount = StateStatus.idle
			state.statusAccountName = StateStatus.idle
			state.statusAccountEmail = StateStatus.idle
			state.statusAccountPassword = StateStatus.idle
			state.msg = null
		}
	},
	extraReducers: {
		// PROFILE
		[updateName.pending]: (state) => {
			state.statusName = StateStatus.loading
		},
		[updateName.fulfilled]: (state) => {
			state.statusName = StateStatus.succeeded
		},
		[updateName.rejected]: (state, action) => {
			state.msg = action.error.message
			state.statusName = StateStatus.failed
		},
		// 
		[updateEmail.pending]: (state) => {
			state.statusEmail = StateStatus.loading
		},
		[updateEmail.fulfilled]: (state) => {
			state.statusEmail = StateStatus.succeeded
		},
		[updateEmail.rejected]: (state, action) => {
			state.msg = action.error.message
			state.statusEmail = StateStatus.failed
		},
		// 
		[updatePassword.pending]: (state) => {
			state.statusPassword = StateStatus.loading
		},
		[updatePassword.fulfilled]: (state) => {
			state.statusPassword = StateStatus.succeeded
		},
		[updatePassword.rejected]: (state, action) => {
			state.msg = action.error.message
			state.statusPassword = StateStatus.failed
		},

		// FINANCIAL
		[getInvoices.pending]: (state) => {
			state.statusInvoices = StateStatus.loading
		},
		[getInvoices.fulfilled]: (state, action) => {
			state.invoices = action.payload.items;
			state.objInvoices = action.payload;

			state.statusInvoices = StateStatus.succeeded
		},
		[getInvoices.rejected]: (state, action) => {
			state.msg = action.error.message
			state.statusInvoices = StateStatus.failed
		},
		// 
		[loadInvoice.pending]: (state) => {
			state.statusLoadInvoice = StateStatus.loading
		},
		[loadInvoice.fulfilled]: (state, action) => {
			state.invoice = action.payload.data
			state.statusLoadInvoice = StateStatus.succeeded
		},
		[loadInvoice.rejected]: (state, action) => {
			state.msg = action.error.message
			state.statusLoadInvoice = StateStatus.failed
		},

		// PAYMENT
		[cancelPlan.pending]: (state) => {
			state.statusCancel = StateStatus.loading
		},
		[cancelPlan.fulfilled]: (state) => {
			state.statusCancel = StateStatus.succeeded
		},
		[cancelPlan.rejected]: (state, action) => {
			state.msg = action.error.message
			state.statusCancel = StateStatus.failed
		},
		[getSubscription.pending]: (state) => {
			state.statusSubscription = StateStatus.loading
		},
		[getSubscription.fulfilled]: (state, action) => {
			state.subscription = action.payload.data 
			state.statusSubscription = StateStatus.succeeded
		},
		[getSubscription.rejected]: (state, action) => {
			state.msg = action.error.message
			state.statusSubscription = StateStatus.failed
		},

		// ACCOUNT
		[loadAccounts.pending]: (state) => {
			state.statusLoadAccounts = StateStatus.loading
		},
		[loadAccounts.fulfilled]: (state, action) => {
			state.accounts = action.payload
			state.statusLoadAccounts = StateStatus.succeeded
		},
		[loadAccounts.rejected]: (state, action) => {
			state.msg = action.error.message
			state.statusLoadAccounts = StateStatus.failed
		},
		[createAccount.pending]: (state) => {
			state.statusCreateAccount = StateStatus.loading
		},
		[createAccount.fulfilled]: (state) => {
			state.statusCreateAccount = StateStatus.succeeded
			profile.actions.increaseAccountsCount()
		},
		[createAccount.rejected]: (state, action) => {
			state.msg = action.error.message
			state.statusCreateAccount = StateStatus.failed
		},
		[deleteAccount.pending]: (state) => {
			state.statusDeleteAccount = StateStatus.loading
		},
		[deleteAccount.fulfilled]: (state) => {
			state.statusDeleteAccount = StateStatus.succeeded
		},
		[deleteAccount.rejected]: (state, action) => {
			state.msg = action.error.message
			state.statusDeleteAccount = StateStatus.failed
		},


		[updateAccountName.pending]: (state) => {
			state.statusAccountName = StateStatus.loading
		},
		[updateAccountName.fulfilled]: (state) => {
			state.statusAccountName = StateStatus.succeeded
		},
		[updateAccountName.rejected]: (state, action) => {
			state.msg = action.error.message
			state.statusAccountName = StateStatus.failed
		},
		// 
		[updateAccountEmail.pending]: (state) => {
			state.statusAccountEmail = StateStatus.loading
		},
		[updateAccountEmail.fulfilled]: (state) => {
			state.statusAccountEmail = StateStatus.succeeded
		},
		[updateAccountEmail.rejected]: (state, action) => {
			state.msg = action.error.message
			state.statusAccountEmail = StateStatus.failed
		},
		// 
		[updateAccountPassword.pending]: (state) => {
			state.statusAccountPassword = StateStatus.loading
		},
		[updateAccountPassword.fulfilled]: (state) => {
			state.statusAccountPassword = StateStatus.succeeded
		},
		[updateAccountPassword.rejected]: (state, action) => {
			state.msg = action.error.message
			state.statusAccountPassword = StateStatus.failed
		},

		// CONTRACTED PLAN
		[getContractedPlan.pending]: (state) => {
			state.statusContractedPlan = StateStatus.loading
		},
		[getContractedPlan.fulfilled]: (state, action) => {
			state.statusContractedPlan = StateStatus.succeeded
			state.objPayment = action.payload
		},
		[getContractedPlan.rejected]: (state, action) => {
			state.statusContractedPlan = StateStatus.failed
			state.msg = action.error.message
		}
	},
})

export const { clearStatus, clearSomeStates } = configuration.actions

export const selectConfiguration = (state) => state.configuration

export default configuration.reducer
