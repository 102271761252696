import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
//
import { useDispatch, useSelector } from "react-redux";
//
import Card from "../../../../common/components/Card/Card";
import Radio from "../../../../common/components/Radio/Radio";
import CheckBox from "../../../../common/components/CheckBox/CheckBox";
import CardFooter from "../../components/CardFooter/CardFooter";
import Input from "../../../../common/components/Input/Input";
import Loading from "../../../../common/components/Loading/Loading";
//
import { selectCampaign, saveStep, updateGoals, updateGoEc, updateDomainUrl, updateDestinyUrl, fetchGoals } from "../../createCampaignSlice";
//
import "./StepSite.scss";
import StepIntegrationGO from "../integrationGO/StepIntegrationGO";
import { selectProfile } from "../../../Login/LoginSlice";
import StateStatus from "../../../../utils/stateStatus";
import { whyConnectGA } from "../../../../utils/externalLinks";
updateDomainUrl, updateDestinyUrl;
const StepSite = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    //
    const objCampaign = useSelector(selectCampaign);
    const profile = useSelector(selectProfile);
    const site = useSelector(selectCampaign).campaign.site;
    //
    const [error, setError] = useState(-1);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [filterGoals, setFilteGoals] = useState([]);

    const hasGoogleAccountConnected = Object.keys(objCampaign.campaign.integrations).includes("google");

    const options = [
        { value: "comercial", label: t("Comércio eletrônico") },
        { value: "goal", label: t("Metas") },
    ];

    const validateUrl = (url) => {
        const urlRegex = new RegExp(
            "^(https?:\\/\\/)?" + // validate protocol
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
                "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
                "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
                "(\\#[-a-z\\d_]*)?$",
            "i"
        ); // validate fragment locator

        return urlRegex.test(url);
    };

    const isValid = (action) => {
        if (site.domain && !validateUrl(site.domain)) {
            setError(0);
        } else if (site.destiny && !validateUrl(site.destiny)) {
            setError(1);
        } else {
            dispatch(saveStep({ step: "site", index: action == "back" ? objCampaign.index - 1 : objCampaign.index + 1 }));
        }

        if (action == "back") dispatch(saveStep({ step: "pattern", index: objCampaign.index - 1 }));
    };

    const searchGoals = (text) => {
        setFilteGoals(objCampaign.goals.filter((goal) => goal.name.toLowerCase().includes(text.toLowerCase())));
    };

    useEffect(() => {
        if (objCampaign.goals.length < 1) {
            setLoading(true);
            if (site.type == "goal" && Object.keys(objCampaign.campaign.integrations).includes("google")) {
                dispatch(fetchGoals(objCampaign.campaign.id)).then(() => {
                    setLoading(false);
                });
            }
        }
    }, [objCampaign.campaign.integrations, site.type]);

    useEffect(() => {
        setFilteGoals(objCampaign.goals);
    }, [objCampaign.goals]);

    return (
        <Card
            child={
                <>
                    <h2 className="step-name">{t("Informações site/loja virtual")}</h2>
                    <div className="junction-e-options">
                        <div className="actions">
                            {/* <p className="integration-title">{t("Integração Site / loja virtual com Google Analytics")}</p> */}
                            <span className="label-input">{t("Selecione uma das opções")}</span>

                            <Radio
                                cardStyle={false}
                                options={options.filter((option) => {
                                    if (option.value === "goal") {
                                        if (!profile.data?.plan?.google_analytics_permission || profile.status.getProfile != StateStatus.succeeded) {
                                            return false;
                                        }
                                    }

                                    return true;
                                })}
                                initialValue={site.type == "goal" ? 1 : 0}
                                onChange={(option) => {
                                    dispatch(updateGoEc(option.value));
                                }}
                            />
                        </div>

                        {!hasGoogleAccountConnected && (
                            <p className="description">
                                {t(
                                    "Caso você tenha um e-commerce e tenha o 'comércio eletrônico' devidamente configurado no Google Analytics, selecione Comércio Eletrônico. Caso você tenha como meta a geração de leads ou outros tipos de conversão configurados no Google Analytics, selecione a opção Meta"
                                )}
                                .
                            </p>
                        )}
                        <div className="section-go">
                            {site.type == "goal" && (
                                <>
                                    {objCampaign.campaign && !hasGoogleAccountConnected && (
                                        <div className="integraion-go">
                                            <div className="step-name">
                                                <h3>{t("Integração com o Google Analytics")}</h3>

                                                <a href={whyConnectGA} target="__blank">
                                                    {t("Por que conectar minha conta?")}
                                                </a>
                                            </div>
                                            <p className="text">
                                                {t(
                                                    "Para que você consiga mensurar as vendas e conversões geradas por seus influenciadores, precisamos que faça login com sua conta do Google Analytics."
                                                )}
                                            </p>
                                        </div>
                                    )}

                                    <StepIntegrationGO connectLink={objCampaign.campaign.analyticsConnectUrl} />
                                </>
                            )}
                        </div>
                        {!hasGoogleAccountConnected && (
                            <p className="description">
                                {t(
                                    "Ao clicar no botão de login com o Google, você será redirecionado para a página da Web onde você precisa selecionar sua conta do Google Analytics. Selecione a conta necessária e dê acesso ao HypoLake"
                                )}
                            </p>
                        )}
                    </div>

                    {/* metas */}
                    {site.type == "goal" && (
                        <>
                            {hasGoogleAccountConnected && (
                                <>
                                    <div className="container-goals">
                                        <p className="title-goals">{t("Metas")}</p>

                                        <div className={`header-container-goals ${search != null && search.length > 0 ? "end" : ""}`}>
                                            {search.length <= 0 && (
                                                <CheckBox
                                                    tooltip={t("Selecionar tudo")}
                                                    checked={site.goals.length == objCampaign.goals.length}
                                                    text={t("Nome Metas")}
                                                    onChange={() => {
                                                        dispatch(updateGoals(site.goals.length == objCampaign.goals.length ? "unset" : "set"));
                                                        if (error == 2) setError(-1);
                                                    }}
                                                />
                                            )}
                                            <Input
                                                error={false}
                                                colorName="white"
                                                placeholder={t("Pesquisar")}
                                                onChange={(value) => {
                                                    setSearch(value);
                                                    searchGoals(value);
                                                }}
                                                prefix={<i className="icon icon-search" />}
                                            />
                                        </div>

                                        <div className="all-goals">
                                            {loading ? (
                                                <Loading />
                                            ) : filterGoals.length > 0 ? (
                                                filterGoals.map((item, key) => {
                                                    return (
                                                        <CheckBox
                                                            key={key}
                                                            checked={site.goals.some((goal) => goal?.selfLink == item.selfLink)}
                                                            text={<span className="text-goal">{item.name}</span>}
                                                            onChange={() => {
                                                                dispatch(updateGoals(item));
                                                                if (error == 2) setError(-1);
                                                            }}
                                                        />
                                                    );
                                                })
                                            ) : (
                                                <p className="empty-goals">{t("Sem metas")}</p>
                                            )}
                                        </div>
                                    </div>

                                    {error == 2 && (
                                        <>
                                            {" "}
                                            <br></br>
                                            <p className="text-empty">{t("Seleciona ao menos uma meta para poder prosseguir")}!</p>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </>
            }
            footer={<CardFooter loading={objCampaign.status == "loading"} onBack={() => isValid("back")} onNext={() => isValid("next")} />}
        />
    );
};

export default StepSite;
