import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
//
import { addInfluencer, getInfluencers, getPossibleCampaigns, getPosts } from "./TikTokDiscoveryService";
//
import Video from "../../../models/Video";
//
import StateStatus from "../../../utils/stateStatus";
import Influencer from "../../../models/Influencer";

const initialState = {
    page: 1,
    totalPages: 0,
    count: 0,
    influencers: [],
    //
    filters: {},
    filtersText: {},
    influencer: {},
    influencerSelected: null,
    //
    posts: [],
    cursor: null,
    hasMore: true,
    //
    campaigns: [],
    //
    status: StateStatus.idle,
    statusDetails: StateStatus.idle,
    statusPosts: StateStatus.idle,
    statusPossibleCampaigns: StateStatus.idle,
    statusAddInfluencerInCampaign: StateStatus.idle,
    //
    msgError: null,
};

export const fetchInfluencers = createAsyncThunk("tiktok-discovery", async (data) => {
    const { page } = data;

    const response = await getInfluencers({ ...data, page, page_size: 9 });

    const count = response.data.count;
    const totalPages = response.data.pages;

    let influencers = [];

    if (response.data && response.data.items) {
        response.data.items.forEach((inf) => {
            let influencer = new Influencer();
            influencers.push(influencer.tikTokerFromJson(inf));
        });
    }

    return {
        page,
        count,
        influencers,
        totalPages,
    };
});

export const fetchPosts = createAsyncThunk("tiktok-discovery/influencer-posts", async (cursorValue, thunkAPI) => {
    const { tiktokDiscovery } = thunkAPI.getState();

    const data = {
        username: tiktokDiscovery.influencerSelected,
        cursor: cursorValue || tiktokDiscovery.cursor,
    };

    const response = await getPosts(data);

    let posts = [];

    if (response.data && response.data.posts) {
        response.data.posts.forEach((post) => {
            const model = new Video();
            posts.push(model.fromJson(post));
        });
    }

    return {
        posts,
        cursor: response.data.page_info.cursor,
        hasMore: response.data.page_info.has_more,
    };
});

export const fetchPossibleCampaigns = createAsyncThunk("tiktok-discovery/influencer-campaigns", async (data) => {
    const response = await getPossibleCampaigns(data);

    let campaigns = [];

    if (response.data && response.data.items) {
        const coins = [
            { alias: "BRL", label: "Real brasileiro", symbol: "R$" },
            { alias: "USD", label: "Dólar estadunidense", symbol: "$" },
            { alias: "EUR", label: "Euro", symbol: "€" },
        ];

        response.data.items.forEach((campaign) => {
            const coin = coins.find((c) => c.alias === campaign.currency);

            const obj = {
                id: campaign.id,
                name: campaign.name,
            };

            obj["coin"] = coin ? coin.symbol : "$";

            campaigns.push(obj);
        });
    }

    return campaigns;
});

export const addInfluencerInCampaign = createAsyncThunk("tiktok-discovery/add-influencer", async (data) => {
    const object = {
        profile_url: data["url"],
        videos_count: data["quant_video"],
        videos_value: data["investment_video"],
    };

    const result = await addInfluencer(data.id, object);

    return result;
});

export const tiktokDiscovery = createSlice({
    name: "tiktokDiscovery",
    initialState: initialState,
    reducers: {
        clearAddInfluencerInCampaign: (state) => {
            state.statusAddInfluencerInCampaign = StateStatus.idle;
        },
        updatePage: (state, action) => {
            state.page = action.payload;
        },
        updateFilters: (state, action) => {
            state.filters = action.payload;
        },
        updateFiltersText: (state, action) => {
            state.filtersText = action.payload;
        },
        updateInfluencerSelected: (state, action) => {
            state.influencerSelected = action.payload;
        },
        updateInfluencer: (state, action) => {
            state.influencer = action.payload;
        },
        clear: (state) => {
            state.page = 0;
            state.influencers = [];
            state.totalPages = 0;
            state.status = StateStatus.idle;
        },
    },
    extraReducers: {
        [fetchInfluencers.pending]: (state) => {
            state.status = StateStatus.loading;
        },
        [fetchInfluencers.fulfilled]: (state, action) => {
            state.count = action.payload.count;
            state.influencers = action.payload.influencers;
            state.totalPages = action.payload.totalPages;
            state.page = action.payload.page ? action.payload.page : state.page;
            //
            state.posts = [];
            state.cursor = null;
            state.hasMore = false;
            //
            state.status = StateStatus.succeeded;
        },
        [fetchInfluencers.rejected]: (state) => {
            state.page = 1;
            state.count = 0;
            state.influencers = [];
            state.totalPages = 1;
            //
            state.status = StateStatus.failed;
        },
        // --------------------------------------
        [fetchPosts.pending]: (state) => {
            state.statusPosts = StateStatus.loading;
        },
        [fetchPosts.fulfilled]: (state, action) => {
            state.cursor = action.payload.cursor;
            state.hasMore = action.payload.hasMore;
            //
            if (action.payload.posts) {
                action.payload.posts.map((post) => {
                    if (!state.posts.some((item) => item.id === post.id)) {
                        state.posts = [...state.posts, post];
                    }
                });
            }
            //
            state.statusPosts = StateStatus.succeeded;
        },
        [fetchPosts.rejected]: (state) => {
            state.posts = [];
            state.cursor = 0;
            //
            state.statusPosts = StateStatus.failed;
        },
        // --------------------------------------
        [fetchPossibleCampaigns.pending]: (state) => {
            state.statusPossibleCampaigns = StateStatus.loading;
        },
        [fetchPossibleCampaigns.fulfilled]: (state, action) => {
            state.campaigns = action.payload;
            //
            state.statusPossibleCampaigns = StateStatus.succeeded;
        },
        [fetchPossibleCampaigns.rejected]: (state) => {
            state.campaigns = [];
            state.statusPossibleCampaigns = StateStatus.failed;
        },
        // --------------------------------------
        [addInfluencerInCampaign.pending]: (state) => {
            state.statusAddInfluencerInCampaign = StateStatus.loading;
        },
        [addInfluencerInCampaign.fulfilled]: (state) => {
            state.statusAddInfluencerInCampaign = StateStatus.succeeded;
        },
        [addInfluencerInCampaign.rejected]: (state) => {
            state.statusAddInfluencerInCampaign = StateStatus.failed;
        },
    },
});

export const {
    clearAddInfluencerInCampaign,
    updatePage,
    updateFilters,
    updateFiltersText,
    updateInfluencer,
    updateInfluencerSelected,
    clear,
} = tiktokDiscovery.actions;

export const selectTiktokDiscovery = (state) => state.tiktokDiscovery;

export default tiktokDiscovery.reducer;
