import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//
import Tooltip from "../Tooltip/Tooltip";
//
import PropTypes from "prop-types";
import iconPointer from "../../assets/icons/pointer.svg";
// import { Tooltip } from 'antd';
//
import "./PerformanceBar.scss";

const Performance = ({ performance }) => {
    const { t } = useTranslation();
    const [value, setValue] = useState(0);

    useEffect(() => {
        const getPerformance = () => {
            setValue(performance >= 100 ? 100 : performance <= 0 ? 0 : performance);
        };
        getPerformance();
    });

    return (
        <div className="performance-content">
            <span className="performance-text">{t("Performance da campanha")}</span>

            <Tooltip
                placement="topRight"
                child={t(
                    "É o desempenho dos posts da campanha em relação ao desempenho médio das postagens do influenciador antes do início da campanha."
                )}
            />

            <div className="performance-bar">
                <div className="bar-labels">
                    <p className="bar-label">{t("Ruim")}</p>
                    <p className="bar-label">{t("Médio")}</p>
                    <p className="bar-label">{t("Bom")}</p>
                </div>

                <div className="bar">
                    <div className="bar-pointer">
                        <img
                            src={iconPointer}
                            className="pointer"
                            alt="icon pointer"
                            style={{
                                left: `${value < 33 ? "13.5%" : value < 66 ? "46.5%" : "80.5%"}`,
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

Performance.propTypes = {
    performance: PropTypes.number,
};

export default Performance;
