import React from 'react';
import PropTypes from  'prop-types'
// 
import './TagMentions.scss';

const TagMentions = ({ text, deleteMention }) => {
    return <div className={`tag-mention mention-${text[0] == "#" ? "hash" : "user"}`}>
        <span className="name-mention">{text}</span>
        {
            deleteMention != undefined &&
                <i className="icon icon-close" onClick={() => { deleteMention(); }} />
        }
    </div>
}

TagMentions.propTypes = {
    text: PropTypes.string,
    deleteMention: PropTypes.func
}

export default TagMentions;