import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
    fetchCampaign,
    fetchInfluencers,
    selectCampaign,
    filterDate,
    clearStatusActionCD,
    archiveCampaign,
    exportCampaign,
    duplicateCampaign,
} from "../../CampaignDetailsSlice";
//
import DropDown from "../../../../common/components/DropDown/DropDown";
import Card from "../../../../common/components/Card/Card";
import Header from "../../../../common/components/Header/Header";
import Loading, { SizedLoading } from "../../../../common/components/Loading/Loading";
import InfluencerDetailsTable from "../../../../common/components/InfluencerTable/InfluencerDetailsTable";
import InfluencerDetailsTableEC from "../../../../common/components/InfluencerTable/InfluencerDetailsTableEC";
//
import StateStatus from "../../../../utils/status";
import Select from "../../../../common/components/Select/Select";
import { changeObjAlert, showAlert } from "../../../../common/components/Alert/alertSlice";
//
import { AirUtils } from "../../../../utils/utils";
import "./ComparativeTable.scss";
import ConnectAndLinks from "../../../../common/components/InfluencerTable/ConnectAndLinks";

const ComparativeTable = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const airUtils = new AirUtils();

    const { id } = useParams();
    const dispatch = useDispatch();

    const objCampaign = useSelector(selectCampaign);

    const campaign = objCampaign.campaign;
    //
    const [loadingInflu, setLoadingInflu] = useState(true);
    //
    const [filter, setFilter] = useState("social");
    const [indexFilter, setIndexFilter] = useState(0);
    const [openActions, setOpenActions] = useState(false);
    const [filterNwTable, setFilterNwTable] = useState("all");

    let actions = [
        { icon: "edit", key: "edit", value: t("Editar campanha") },
        { icon: "duplicate", key: "duplicate", value: t("Duplicar campanha") },
        { icon: "export", key: "export", value: t("Exportar campanha") },
        { icon: "archieve", key: "filed", value: t("Arquivar campanha") },
    ];

    if (campaign?.type == "hashtag") actions = actions.filter((item) => item.key != "export");

    const [filters, setFilters] = useState([{ key: "social", text: t("Redes sociais") }]);

    const alert = (type, title, text, question, cancelText, okText, onOk) => {
        dispatch(
            changeObjAlert({
                show: true,
                type: type,
                title: title,
                text: text,
                okText: okText,
                question: question,
                cancelText: cancelText,
                onCancel: () => {
                    dispatch(showAlert(false));
                },
                onOk: () => {
                    onOk;
                },
            })
        );
    };

    useEffect(async () => {
        await dispatch(filterDate({ startDate: null, endDate: null }));

        if (!campaign) {
            await dispatch(fetchCampaign(id));
        }
    }, []);

    useEffect(async () => {
        setLoadingInflu(true);
        await dispatch(fetchInfluencers({ id, network: filterNwTable == "all" ? null : filterNwTable }));
        setLoadingInflu(false);
    }, [filterNwTable, objCampaign.filterEndDate, objCampaign.filterStartDate]);

    useEffect(() => {
        if (campaign && campaign.objectives.includes("site")) {
            setFilters([...filters, { key: "e-commerce", text: t("Site / loja") }]);
        }
    }, [campaign]);

    useEffect(() => {
        if (objCampaign.statusActionCD === StateStatus.succeeded) {
            alert("success", t("sucesso"), t(objCampaign.msg));
        } else if (objCampaign.statusActionCD === StateStatus.failed) {
            alert("error", t("erro"), t(objCampaign.msg));
        }

        dispatch(clearStatusActionCD());
    }, [objCampaign.statusActionCD]);

    return (
        <div className="campaign-table background-page">
            {objCampaign.status == StateStatus.succeeded && campaign && (
                <Header
                    back={true}
                    className="home-header"
                    limitEndDate={campaign.endDate}
                    limitStartDate={campaign.startDate}
                    subHeader={
                        <div className="subheader-left">
                            <div className="subheader-title">
                                <span className="span-name">{campaign.name}</span>

                                {objCampaign.statusActionCD === StateStatus.loading && (
                                    <SizedLoading size={24} withContainer={false} />
                                )}

                                {objCampaign.statusActionCD === StateStatus.succeeded && (
                                    <DropDown
                                        options={actions}
                                        optionsWithIcons={true}
                                        onChange={() => setOpenActions(!openActions)}
                                        onClick={(value) => {
                                            if (value.key === "edit") {
                                                history.push(`/edit-campaign/${campaign.id}/type`);
                                            } else if (value.key == "duplicate") {
                                                dispatch(duplicateCampaign(campaign.id));
                                            } else if (value.key == "export") {
                                                dispatch(exportCampaign(campaign.id));
                                            } else if (value.key == "filed") {
                                                dispatch(
                                                    changeObjAlert({
                                                        show: true,
                                                        type: "warning",
                                                        title: t("atenção"),
                                                        question: t(
                                                            "Deseja realmente arquivar essa campanha? (Essa ação não pode ser desfeita)"
                                                        ),
                                                        okText: t("Sim, arquivar campanha"),
                                                        cancelText: t("Não, manter campanha"),
                                                        onCancel: () => {
                                                            dispatch(showAlert(false));
                                                        },
                                                        onOk: () => {
                                                            dispatch(archiveCampaign(campaign.id));
                                                        },
                                                    })
                                                );
                                            }

                                            setOpenActions(false);
                                        }}
                                        child={
                                            <i className={`icon-more icon-more-${openActions ? "open" : "close"}`} />
                                        }
                                    />
                                )}
                            </div>

                            <span className="text">
                                {t("Campanha") + ": " + t("período de")}{" "}
                                {airUtils.buildValue("date", new Date(campaign.startDate))} -{" "}
                                {airUtils.buildValue("date", new Date(campaign.endDate))} | {t("tipo")}:{" "}
                                {campaign.type == "influencers" ? t("influenciadores") : t("hashtag")}
                                {campaign.objectives.length > 0 &&
                                    campaign.type == "influencers" &&
                                    ` | ${t("objetivo")}: ${campaign.objectives.includes("site") ? t("vendas") : ""} ${
                                        campaign.objectives.length > 1 ? ` ${t("e")} ` : ""
                                    } ${campaign.objectives.includes("social_media") ? t("redes socias") : ""}`}
                            </span>
                        </div>
                    }
                    funcDate={(date) => {
                        if (date) {
                            dispatch(filterDate({ startDate: date[0], endDate: date[1] }));
                        } else {
                            dispatch(filterDate({ startDate: null, endDate: null }));
                        }
                    }}
                />
            )}

            <div className="scroll-content">
                <div className="campaign-table-content">
                    {objCampaign.status == StateStatus.succeeded && campaign && (
                        <Card
                            child={
                                <>
                                    <div className="card-header">
                                        <h3 className="campaign-title-data">
                                            {t("Tabela comparativa de influenciadores")}
                                        </h3>

                                        {campaign.networks.length > 1 && (
                                            <Select
                                                defaultValue={
                                                    !filterNwTable || filterNwTable == "all"
                                                        ? t("geral")
                                                        : filterNwTable
                                                }
                                                onChange={(nw) => {
                                                    setFilterNwTable(nw.key == t("geral") ? "all" : nw.key);
                                                }}
                                                options={[t("geral"), ...Object.keys(campaign.networks)].map((nw) => {
                                                    let newObj;

                                                    if (nw == t("geral") || campaign.networks[nw] != 0)
                                                        newObj = { value: nw, key: nw, icon: `icon-${nw}-circle` };

                                                    return newObj;
                                                })}
                                            />
                                        )}
                                    </div>

                                    {/* <div className={`segment-filter-table segment-${campaign.socialCampaign}`}> */}
                                    <div className="segment-filter-table">
                                        {filters.map((type, key) => {
                                            return (
                                                <div
                                                    key={key}
                                                    className={`option-table option-${type.key} ${
                                                        filter == type.key ? `active` : ""
                                                    }`}
                                                    onClick={() => {
                                                        setFilter(type.key);
                                                        setIndexFilter(key);
                                                    }}
                                                >
                                                    <i className={`icon ${type.icon}`} />
                                                    <span className="text-option">{type.text}</span>
                                                </div>
                                            );
                                        })}
                                        <div
                                            style={{ transform: `translate(${indexFilter * 100}%)` }}
                                            className={`segment-underline position-${indexFilter}`}
                                        ></div>
                                    </div>

                                    {loadingInflu ? (
                                        <div className="card-loading">
                                            {" "}
                                            <Loading />{" "}
                                        </div>
                                    ) : indexFilter == 0 ? (
                                        <InfluencerDetailsTable
                                            symbolCoin={campaign.currency}
                                            campaignId={campaign.id}
                                            influencers={objCampaign.influencers}
                                            withTracking={campaign.objectives.includes("site")}
                                        />
                                    ) : (
                                        campaign.objectives.includes("site") && (
                                            <InfluencerDetailsTableEC
                                                symbolCoin={campaign.currency}
                                                campaignId={campaign.id}
                                                influencers={objCampaign.influencers}
                                            />
                                        )
                                    )}
                                </>
                            }
                        />
                    )}

                    {objCampaign.status == StateStatus.loading && <Loading />}
                    {objCampaign.status == StateStatus.failed && <p>{t("ERRO")}</p>}

                    <Card
                        child={
                            <>
                                <div className="card-header">
                                    <h3 className="campaign-title-data">{t("Conectes e Links")}</h3>
                                </div>
                                <div className="table-card">
                                    {objCampaign.statusInfluencerMetrics == StateStatus.loading ? (
                                        <div className="card-loading">
                                            {" "}
                                            <Loading />{" "}
                                        </div>
                                    ) : objCampaign.influencers.length > 0 ? (
                                        <ConnectAndLinks
                                            campaignId={campaign.id}
                                            influencers={objCampaign.influencers}
                                            withTracking={campaign.objectives.includes("site")}
                                            withTCMCampaignInvitation={
                                                objCampaign.networks && objCampaign.networks.includes("tiktok")
                                            }
                                        />
                                    ) : (
                                        <p className="empty-value">{t("A campanha não possui influenciadores")}</p>
                                    )}
                                </div>
                            </>
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default ComparativeTable;
