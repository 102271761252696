import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
// 
import { Button, Dropdown as AntDropDown } from 'antd';
import { Menu } from 'antd';
// 
import './DropDown.scss';

const DropDown = ({
    onClick,
    options,
    optionsWithIcons,
    onChange,
    style,
    backgroundColor,
    color,
    child,
}) => {
    const [ddOptions, setDdOptions] = useState();
    
    useEffect(() => {
        const menu = <Menu>
            {options && options.map((option, key) => {
                return <Menu.Item onClick={() => {onClick && onClick(option)}}  key={key}>   
                    {optionsWithIcons ?  <div className="option-with-icon">  
                            <i className={`icon icon-${option.icon}`} />
                            {option.value}  
                        </div>  : 
                        option.value    
                    }
                </Menu.Item>
            })}
        </Menu>

        setDdOptions(menu)
    }, [options])

    return <AntDropDown 
        className="drop-down" 
        overlay={ddOptions}
        trigger="click"
        onVisibleChange={onChange}
    >
        <Button 
            style={style || { 
                backgroundColor: backgroundColor || "transparent",
                color: color || "#414141",
            }}>
            {child || "Dropdown"}
        </Button>
    </AntDropDown>
}

DropDown.propTypes = {
    style: PropTypes.object,
    options: PropTypes.arrayOf(PropTypes.object),
    onClick: PropTypes.func, 
    onChange: PropTypes.func,
    color: PropTypes.string,
    backgroundColor: PropTypes.string,
    optionsWithIcons: PropTypes.bool,
    child: PropTypes.element
}

export default DropDown