import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    pages: []
}

export const routes = createSlice({
    name: 'routes',
    initialState: initialState,
    reducers: {
        updatePages: (state, action) => {
            if(!state.pages.includes(action.payload)) {
                state.pages = [...state.pages, action.payload]
            }
        },
    },
})

export const { updatePages } = routes.actions

export const selectRoutes = (state) => state.routes

export default routes.reducer
