import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
// 
import Button from "../../../Button/Button"
import Avatar from "../../../Avatar/Avatar"
import { Tooltip } from "antd";
// 
import { humanize } from "../../../../../utils/formatters";
import "./CardTiktoker.scss";

const CardTiktoker = ({ influencer, onOpen, onAdd }) => {
    const { t } = useTranslation()

    const interactions = [
        { key: "followers", toooltip: t("Seguidores"), icon: "group", value: influencer.followers || 0 },
        // { key: "views", toooltip: t("Visualizações"), icon: "eye-essential", value: influencer.views || 0 },
        // { key: "engagement", toooltip: t("Enjagamento"), icon: "products", value: influencer.egagement || 0 },
        { key: "likes", toooltip: t("Média de curtidas"), icon: "likes", value: influencer.likesCount || 0 },
        { key: "videos", toooltip: t("Vídeos"), icon: "play", value: influencer.videosCount || 0 },
    ]

    return <div className="card-tiktoker" onClick={() => onOpen()}>
        <div className="avatar-name">
            <div className="avatar">
                <Avatar src={influencer.avatar} />
                {/* {influencer.growing && <i className="icon icon-rocket"/>} */}
            </div>

            <div className="name-location">
                <span className="name">{influencer.name}</span>
                {/* <span className="location">Porto Real</span> */}
            </div>
        </div>

        {/* <p className="bio">
            <Tooltip title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ultrices mauris at purus venenatis, sit amet bibendum libero bibendum. Nulla pretium porttitor elit, vitae condimentum augue varius in. In hac habitasse platea dictumst. Cras tristique auctor felis, at dictum nibh molestie in. Phasellus sit amet rutrum sem, sit amet euismod est. Fusce vitae dictum urna, sed rhoncus neque. Nam dictum fermentum quam id varius. Vestibulum eu vehicula felis. Nunc pellentesque ligula ut arcu accumsan maximus sed et quam. Morbi eu aliquet tellus. Aliquam semper porttitor lectus, eget accumsan mauris bibendum sed. Mauris fermentum, leo id laoreet euismod, metus tellus dignissim arcu, eu ornare orci metus non odio. Quisque tristique volutpat diam, lacinia tincidunt nisl congue et. Phasellus rhoncus ultricies mi vitae mollis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.">
                {influencer.bio || t("Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ultrices mauris at purus venenatis, sit amet bibendum libero bibendum. Nulla pretium porttitor elit, vitae condimentum augue varius in. In hac habitasse platea dictumst. Cras tristique auctor felis, at dictum nibh molestie in. Phasellus sit amet rutrum sem, sit amet euismod est. Fusce vitae dictum urna, sed rhoncus neque. Nam dictum fermentum quam id varius. Vestibulum eu vehicula felis. Nunc pellentesque ligula ut arcu accumsan maximus sed et quam. Morbi eu aliquet tellus. Aliquam semper porttitor lectus, eget accumsan mauris bibendum sed. Mauris fermentum, leo id laoreet euismod, metus tellus dignissim arcu, eu ornare orci metus non odio. Quisque tristique volutpat diam, lacinia tincidunt nisl congue et. Phasellus rhoncus ultricies mi vitae mollis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.")}
            </Tooltip>
        </p> */}

        <div className="interactions">
            {interactions.map(interaction => {
                return <div key={interaction.key} className="interaction">
                    <Tooltip title={interaction.toooltip}>
                        <i className={`icon icon-${interaction.icon}`}/>
                        
                        {humanize(interaction.value)}
                    </Tooltip>
                </div>
            })}
        </div>

        <Button
            color="secondary"
            outline={true}
            text={t("Adicionar a campanha")}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                onAdd(influencer.username);
            }}
        />
    </div>
}

CardTiktoker.propTypes = {
    influencer: PropTypes.object,
    onOpen: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
}

export default CardTiktoker