import {
    format,
    parse
} from "date-fns"
import {
    PhoneNumberUtil
} from "google-libphonenumber"
import {
    ptBR,
    enUS,
    es
} from "date-fns/esm/locale"

const phoneUtil = PhoneNumberUtil.getInstance()

export const numberToReal = (number) =>
    number.toLocaleString("pt-RF", {
        style: "currency",
        currency: "BRL"
    })

export const parseNumber = (numberStr) => parseInt(numberStr.replace(/\D/g, ""))

export const cpfMask = (value) => {
    return value
        .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{3})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1") // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const cnpjMask = (value) => {
    return value
        .replace(/\D/g, "") // substitui qualquer caracter que nao seja numero por nada
        .replace(/(\d{2})(\d)/, "$1.$2") // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})/, "$1/$2")
        .replace(/(\d{4})(\d{1,2})/, "$1-$2")
        .replace(/(-\d{2})\d+?$/, "$1") // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
}

export const beautifyCpfCnpj = (value) => {
    if (!value) return ""

    const final = value.replace(/[.\-/]/g, "")

    if (final.length < 12) {
        return cpfMask(value)
    } else {
        return cnpjMask(value)
    }
}

export const parseDate = (dateStr, fmt) => {
    const lang = (window.navigator.userLanguage || window.navigator.language);

    return parse(dateStr, (fmt || (lang === "pt" || lang === "es" ? "dd/MM/yyyy" : "MM/dd/yyyy")), new Date())
}

export const formatDate = (date, fmt = "dd/MM/yyyy") => {
    const lang = (window.navigator.userLanguage || window.navigator.language);

    return format(date, fmt, {
        locale: (lang === "pt" ? ptBR : (lang === es ? lang : enUS))
    })
}

export const parsePhone = (phoneStr) => {
    try {
        const result = phoneUtil.parse(phoneStr)
        const valid = phoneUtil.isValidNumber(result)

        const code = result.getCountryCode()
        const number = result.getNationalNumber()
        const country = phoneUtil.getRegionCodeForNumber(result)

        const phone = {
            country: country,
            code: code,
            number: number,
            phone: `+${code}-${number}`,
            isValid: valid,
        }

        return phone
    } catch (err) {
        return {
            isValid: false
        }
    }
}

export const truncadeLabel = (label, max) => {
    if (!label || label.length <= max) return label
    else return label.substring(0, max) + "..."
}

export const humanize = (val) => {
    const ret = String(parseInt(val, 10));
    switch (ret.length) {
        case 4:
            return ret.slice(0, 1) + ((parseInt(ret.slice(1, 2), 10) === 0) ? '' : '.' + ret.slice(1, 2)) + 'k';
        case 5:
            return ret.slice(0, 2) + 'K';
        case 6:
            return ret.slice(0, 3) + 'K';
        case 7:
            return ret.slice(0, 1) + ((parseInt(ret.slice(1, 2), 10) === 0) ? '' : '.' + ret.slice(1, 2)) + 'M';
        case 8:
            return ret.slice(0, 2) + 'M';
        case 9:
            return ret.slice(0, 3) + 'M';
        case 10:
            return ret.slice(0, 1) + ((parseInt(ret.slice(1, 2), 10) === 0) ? '' : '.' + ret.slice(1, 2)) + 'B';
        case 11:
            return ret.slice(0, 2) + 'B';
        case 12:
            return ret.slice(0, 3) + 'B';
    }
    return ret;
}

export const addPoints = (num) => {
    return `${num ? num : ""}`.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}
