import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
//
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { changeObjAlert, showAlert } from "../../../../../common/components/Alert/alertSlice";
import { selectCampaign, updateInfluencer, updateInfluencerLinkTracking } from "../../../createCampaignSlice";
//
import Input from "../../../../../common/components/Input/Input";
import Avatar from "../../../../../common/components/Avatar/Avatar";
//
// import { selectCampaign } from '../../../createCampaignSlice'
// import { influencerLink } from '../../../CreateCampaignService'
import "./TrackingInfluencerLink.scss";
import Button from "../../../../../common/components/Button/Button";
import iconSendEmail from "../../../../../common/assets/icons/send_email.svg";
import { influencerLink } from "../../../CreateCampaignService";

const TrackingInfluencerLink = ({ influencer, index }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const objCampaign = useSelector(selectCampaign);

    const [url, setUrl] = useState("");
    const [error, setError] = useState(-1);
    const [email, setEmail] = useState("");
    const [amountShipments, setAmountShipments] = useState(0);
    const [loading, setLoading] = useState(false);

    const alert = (type, title, text, question, cancelText, okText, onOk) => {
        dispatch(
            changeObjAlert({
                show: true,
                type: type,
                title: title,
                text: text,
                okText: okText,
                question: question,
                cancelText: cancelText,
                onCancel: () => {
                    dispatch(showAlert(false));
                },
                onOk: () => {
                    onOk;
                },
            })
        );
    };

    const sendLink = (influencer, index) => {
        setLoading(true);

        dispatch(updateInfluencer({ influencer: influencer, index: index }));

        influencerLink(objCampaign.campaign.id, influencer.contractId, {
            link: influencer.link,
            email: influencer.email,
        })
            .then(() => {
                setAmountShipments(influencer.amountShipments);
                alert(
                    "success",
                    t("sucesso"),
                    `${t("O e-mail foi enviado para o influenciador")} (${influencer.name})`
                );

                setLoading(false);
            })
            .catch((err) => {
                alert("error", t("erro"), t(err.message || "Erro ao enviar e-mail"));
                setLoading(false);
            });
    };

    const sendEmail = () => {
        if (error === -1) {
            influencer.email = email;
            influencer.link = url;
            influencer.amountShipments = amountShipments + 1;

            sendLink(influencer, index);
        }
    };

    useEffect(() => {
        if (influencer.email) {
            setEmail(influencer.email);
        }
        if (influencer.originalLink) {
            setUrl(influencer.originalLink);
        }
    }, []);

    useEffect(() => {
        influencer.link = url;
        influencer.originalLink = url;
        influencer.email = email;
        influencer.linkTrackingError = error;
        validateInfluencerData();
        dispatch(updateInfluencerLinkTracking({ link: url, email, linkTrackingError: error, index }));
    }, [url, email, error]);

    const validateInfluencerData = () => {
        const urlRegex = new RegExp(
            "^(https?:\\/\\/)?" + // validate protocol
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
                "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
                "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
                "(\\#[-a-z\\d_]*)?$",
            "i"
        ); // validate fragment locator
        var emailRegex =
            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi;

        if (url && !urlRegex.test(url)) {
            setError(0);
        } else if (email && email.length && !emailRegex.test(email)) {
            setError(1);
        } else {
            setError(-1);
        }
    };

    return (
        <div className="link-influencer">
            <p className="influencer-profile">
                <Avatar size="51px" src={influencer.avatar} network={influencer.network} />
                <span className="influencer-name">{influencer.name}</span>
            </p>

            <div className="link-inputs">
                <div className="input-junction">
                    <span className="label-input">{t("Rastreamento de link")}</span>
                    <Input
                        width="100%"
                        error={error == 0}
                        textError={t("Informe uma url de domínio válida!")}
                        value={url}
                        placeholder={t("Insira o link")}
                        onChange={(text) => {
                            setUrl(text);
                            if (error == 0) setError(-1);
                        }}
                        onBlur={(text) => {
                            if (text.includes("utm_")) {
                                dispatch(
                                    changeObjAlert({
                                        show: true,
                                        type: "warning",
                                        title: t("atenção"),
                                        text: t(
                                            "Notamos que a URL passada está usando UTM SOURCE. É de extrema importâcia que exista um link para cada influenciador, pois os tráfegos que não pertencem a eles serão contabilizados como pertencentes."
                                        ),
                                        question: t("Deseja continuar usando esse link?"),
                                        okText: t("Sim"),
                                        cancelText: t("Não, enviar sem UTM SOURCE"),
                                        onCancel: () => {
                                            const newUrl = url.replace(
                                                /(\?)utm[^&]*(?:&utm[^&]*)*&(?=(?!utm[^\s&=]*=)[^\s&=]+=)|\?utm[^&]*(?:&utm[^&]*)*$|&utm[^&]*/gi,
                                                "$1"
                                            );

                                            setUrl(newUrl);
                                            influencer.link = newUrl;

                                            dispatch(updateInfluencer({ influencer: influencer }));
                                        },
                                        onOk: () => {
                                            influencer.link = url;

                                            dispatch(updateInfluencer({ influencer: influencer }));
                                        },
                                    })
                                );
                            }
                        }}
                    />
                </div>
                <div className="input-junction">
                    <span className="label-input">{t("Insira o e-mail")}</span>
                    <Input
                        width="100%"
                        error={error == 1}
                        placeholder={t("Insira o e-mail")}
                        textError={t("Informe um e-mail válido!")}
                        value={email}
                        onChange={(text) => {
                            setEmail(text);
                            if (error == 1) setError(-1);
                        }}
                        onBlur={(text) => {
                            influencer.email = text;
                            dispatch(updateInfluencer({ influencer: influencer }));
                        }}
                    />
                </div>
                <Button
                    disabled={error != -1 || !email || email.length == 0}
                    onClick={() => {
                        sendEmail();
                    }}
                    loading={loading}
                    text={
                        <>
                            {t("Enviar link")}
                            <img className="send-email-icon" alt="send email" src={iconSendEmail} />
                        </>
                    }
                />
            </div>
        </div>
    );
};

TrackingInfluencerLink.propTypes = {
    influencer: PropTypes.any,
    index: PropTypes.number,
};

export default TrackingInfluencerLink;
