import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
//
import { Spin, Switch } from "antd";
import { Tooltip } from "antd";
import Avatar from "../Avatar/Avatar";
import GrowOrDecrease from "../GrowOrDecrease/GrowOrDecrease";
//
import { AirUtils } from "../../../utils/utils";
import { humanize } from "../../../utils/formatters";
import { useDispatch, useSelector } from "react-redux";
// import ReactPlayer from "react-player";
import {
    AttachPostToCampaign,
    DetachPostToCampaign,
    selectCampaign,
} from "../../../features/CampaignDetails/CampaignDetailsSlice";
//
import StateStatus from "../../../utils/stateStatus";
import "./CardFeed.scss";
import iconTikTok from "../../assets/icons/tiktok.svg";
import iconLike from "../../assets/icons/like.svg";
import iconComment from "../../assets/icons/comment.svg";
import iconReach from "../../assets/icons/reach.svg";
import iconSave from "../../assets/icons/save.svg";
import iconShare from "../../assets/icons/share.svg";
import SocialNetworkIcon from "../SocialNetworkIcon/SocialNetworkIcon";

const CardFeed = ({ post, notUser, campaignId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const airUtils = new AirUtils();
    const history = useHistory();

    const objCampaign = useSelector(selectCampaign);

    const [date, setDate] = useState();
    const [inCampaign, setInCampaign] = useState();
    const [loading, setLoading] = useState(false);
    //
    // const [playing, setPlaying] = useState(false);
    const [play] = useState(false);
    // const playerRef = useRef();
    // const [, setVideoError] = useState(false);

    const iconMap = {
        likes: iconLike,
        comments: iconComment,
        reach: iconReach,
        saved: iconSave,
        shares: iconShare,
    };

    const togglePost = async () => {
        setLoading(true);

        if (inCampaign) {
            await dispatch(DetachPostToCampaign({ campaignId, postId: post.id }));
        } else {
            await dispatch(AttachPostToCampaign({ campaignId, postId: post.id }));
        }

        setLoading(false);
    };

    useEffect(() => {
        if (objCampaign.statusPostCampaign == StateStatus.succeeded) setInCampaign(!inCampaign);
    }, [loading]);

    useEffect(() => {
        if (post) {
            const fDate = new Date(post.date);

            setDate(fDate);
            setInCampaign(post.inCampaign);
        }
    }, []);

    return (
        <div className="card-feed">
            {post && (
                <>
                    <div className={`post-header ${notUser ? "header-right" : ""}`}>
                        {!notUser && (
                            <Tooltip title={post.owner.name}>
                                <div className="influencer">
                                    {post.owner.avatar != "" && (
                                        <Avatar
                                            src={post.owner.avatar}
                                            network={post.network}
                                            size="28px"
                                            onClick={() => {
                                                history.push(
                                                    `/campaign/${campaignId}/influencer/${post.owner.airId}/feeds`
                                                );
                                            }}
                                        />
                                    )}
                                    <span className="name">{post.owner.name}</span>
                                </div>
                            </Tooltip>
                        )}

                        <Tooltip title={t("Adicionar ou remover post da campanha")}>
                            <div className="in-campaign">
                                {loading ? (
                                    <Spin />
                                ) : (
                                    <Switch size="small" checked={inCampaign} onChange={() => togglePost()} />
                                )}
                            </div>
                        </Tooltip>
                        {objCampaign.campaign.type !== "hashtag" && (
                            <GrowOrDecrease
                                tooltip={true}
                                changePercent={post.performance / 100}
                                className="grow-or-decrease"
                            />
                        )}
                    </div>

                    <div
                        className="post-content"
                        onClick={() => {
                            window.open(post.link, "_blank");
                        }}
                    >
                        <div className="nw-date">
                            <SocialNetworkIcon network={post.network} showLabel={false} />
                            <span className="post-date">{airUtils.buildValue("date", date)}</span>
                        </div>

                        <div className="container-post">
                            {/* {post.url ? (
                                <ReactPlayer
                                    style={{ cursor: playing ? "pointer" : "default" }}
                                    className="post-video"
                                    width="100%"
                                    height="100%"
                                    url={post.url}
                                    playing={playing}
                                    ref={playerRef}
                                    onClick={(e) => {
                                        setPlaying((state) => !state);
                                        if (e.target && e.target.nodeName == "VIDEO") {
                                            if (!e.target.paused) {
                                                e.target.pause();
                                                e.target.currentTime = 0;
                                            } else {
                                                e.target.play();
                                            }
                                        }
                                    }}
                                    // onEnded={() => setPlay(false)}
                                    onError={() => {
                                        setVideoError(true);
                                    }}
                                />
                            ) : (
                                <div
                                    className="post-img"
                                    style={{ backgroundImage: `url(${airUtils.buildImage(post.image)})` }}
                                >
                                    {post.type !== "video" && (
                                        <i
                                            onClick={() => {
                                                window.open(post.link, "_blank");
                                            }}
                                            className="icon icon-link-external"
                                        />
                                    )}
                                </div>
                            )} */}
                            <div
                                className="post-img"
                                style={{ backgroundImage: `url(${airUtils.buildImage(post.image)})` }}
                            >
                                {post.type !== "video" && (
                                    <i
                                        onClick={() => {
                                            window.open(post.link, "_blank");
                                        }}
                                        className="icon icon-link-external"
                                    />
                                )}
                            </div>
                            {/* {post.type === "video" && !playing && (
                                <div
                                    className="icon-play"
                                    onClick={() => {
                                        setPlaying(true);
                                    }}
                                />
                            )} */}

                            {!play && (
                                <div className={`content-icon-background background-${post.network}`}>
                                    {post.network === "tiktok" ? (
                                        <img src={iconTikTok} className="content-tiktok-icon" alt="tiktok icon" />
                                    ) : (
                                        <i className={`content-icon icon-${post.network}`} />
                                    )}
                                </div>
                            )}
                        </div>

                        <div className="interactions">
                            {post.interactions &&
                                post.interactions.map((interaction, i) => {
                                    return (
                                        <Tooltip key={i} title={t(interaction.key)}>
                                            <div className="interaction">
                                                <img
                                                    className={`icon icon-${interaction.key}`}
                                                    src={iconMap[interaction.key] || ""}
                                                />{" "}
                                                {t(humanize(interaction.value))}
                                            </div>
                                        </Tooltip>
                                    );
                                })}
                        </div>

                        <p className="description">
                            <Tooltip title={post.description}>{post.description}</Tooltip>
                        </p>
                    </div>
                </>
            )}
        </div>
    );
};

CardFeed.propTypes = {
    post: PropTypes.any,
    influencer: PropTypes.any,
    campaignId: PropTypes.any,
    notUser: PropTypes.bool,
};

export default CardFeed;
