class Video {
    id;
    url;
    link;
    type;
    date;
    image;
    owner;
    description;
    performance;
    inCampaign;
    network;
    interactions = [];
    orderId;
    sparkState;
    reach;
    views;

    fromJson(data) {
        this.link = data["embed_url"];
        this.image = data["thumbnail_url"];
        this.id = data["video_id"];
        this.description = data["caption"];
        this.date = data["create_time"];
        this.owner = data["display_name"];

        this.interactions = [
            { key: "likes", value: data["likes"], label: "Curtidas" },
            { key: "eye-essential", value: data["video_views"], label: "Visualizações" },
            { key: "share-fb", value: data["shares"], label: "Compartilhamentos" },
            { key: "comment", value: data["comments"], label: "Comentários" },
        ];

        return this;
    }

    detailsFromJson(data) {
        this.owner = {
            name: data.author.name,
            username: data.author.username,
            profileUrl: data.author.profile_url,
            avatar: data.author.profile_picture_url,
            airId: data.author.air_id,
        };

        this.id = data.post_id;
        this.description = data.caption;
        this.interactions = data.interactions;
        this.inCampaign = data.is_in_campaign;
        this.link = data.permalink;

        this.type = data.type;
        this.url = data.medias[0].media_url;
        this.image = data.medias[0].thumbnail_url;

        this.performance = data.performance;
        this.network = data.social_network;
        this.date = new Date(data.timestamp * 1000);

        this.orderId = data.tiktok?.order_id;
        this.sparkState = data.tiktok?.spark_state;

        this.interactions.forEach((i) => {
            if (i.key == "views") this.views = i.value;
            if (i.key == "reach") this.reach = i.value;
        });

        return this;
    }
}

export default Video;
