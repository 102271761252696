import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
//
import { useDispatch, useSelector } from "react-redux";
import {
    fetchAllInfluencersPosts,
    fetchAllInfluencersStories,
    fetchCampaign,
    fetchInfluencerMetrics,
    fetchInfluencerProfile,
    selectCampaign,
    clearInfluencerProfile,
    fetchAllInfluencersVideos,
    fetchContractors,
    filterDate,
} from "../../CampaignDetailsSlice";
//
import { ChartHeaderOnly } from "../../components/Charts/ChartNW";
import Avatar from "../../../../common/components/Avatar/Avatar";
import Card from "../../../../common/components/Card/Card";
import CardFeed from "../../../../common/components/CardFeed/CardFeed";
import CardStories from "../../../../common/components/CardStories/CardStories";
// import CardVideo from "../../../../common/components/CardVideo/CardVideo";
import Header from "../../../../common/components/Header/Header";
import Loading from "../../../../common/components/Loading/Loading";
import Select from "../../../../common/components/Select/Select";
import PostsModal from "../../components/PostsModal/PostsModal";
import Pagination from "../../../../common/components/Pagination/Pagination";
//
//
import StateStatus from "../../../../utils/status";
import moment from "moment";
import "./InfluencerDetails.scss";
import { generateProfileUrl } from "../../../../utils/utils";

const InfluencerDetails = () => {
    const isContractor = window.location.href.includes("/contractor/")
    // 
    const dispatch = useDispatch();
    const history = useHistory();
    const objCampaign = useSelector(selectCampaign);
    //
    const campaign = objCampaign.campaign;
    //
    const { campaignId, airId, postType } = useParams();
    const { t } = useTranslation();
    //
    const [visible, setVisible] = useState(false); // modal
    const [idxInfluencer, setIndexInfluencer] = useState(); // modal
    const [metricsFilter, updateMetricsFilter] = useState("general");
    //
    const [page, setPage] = useState(1);
    //
    const [indexFilter, setIndexFilter] = useState(0);
    const [filter, setFilter] = useState("last");
    const [filterPost, setFilterPost] = useState(postType || "feeds");

    const [influencerProfile, setInfluencerProfile] = useState();
    //
    const pagination =
        filterPost === "feeds"
            ? objCampaign.feedsPagination
            : filterPost === "stories"
            ? objCampaign.storiesPagination
            : objCampaign.videosPagination;
    //
    const filterFeedOrStories = [
        { value: "feeds", key: "Feed" },
        { value: "stories", key: "Stories" },
        { value: "videos", key: "Reels/Videos" },
    ];
    const filters = [
        { key: "last", text: t("Últimas postagens") },
        { key: "higher_performance", text: t("Maior desempenho") },
        { key: "lower_performance", text: t("Menor desempenho") },
        { key: "higher_interaction", text: t("Maior interação") },
        { key: "lower_interaction", text: t("Menor interação") },
    ];

    const closeEditModal = () => {
        setIndexInfluencer(null);
        setVisible(false);
    };

    const updatePath = (type) => {
        const types = ["feeds", "stories", "videos"];
        const typeIndex = types.indexOf(type);
        if (typeIndex == -1) return;

        const currentPath = history.location.pathname;
        let newPath = currentPath;

        types.forEach((item, index) => {
            if (index != typeIndex) {
                newPath = newPath.replace(item, type);
            }
        });

        history.replace(newPath);
    };

    useEffect(() => {
        dispatch(clearInfluencerProfile());
        dispatch(fetchCampaign(campaignId));

        if(isContractor) dispatch(fetchContractors({ id: campaignId, network: null }));
    }, []);

    useEffect(() => {
        if (objCampaign.statusContractor === StateStatus.succeeded) {
            setInfluencerProfile(objCampaign.contractors[0])
        }
    }, [objCampaign.statusContractor]);

    useEffect(() => {
        if (objCampaign.status === StateStatus.succeeded) {
            const startDate = moment(objCampaign.filterStartDate || objCampaign.campaign.startDate);
            const endDate = moment(objCampaign.filterEndDate || objCampaign.campaign.endDate);

            dispatch(
                fetchInfluencerProfile({
                    campaignId,
                    airId,
                    body: {
                        start_date: startDate.format("YYYY-MM-DD"),
                        end_date: endDate.format("YYYY-MM-DD"),
                    },
                })
            );
        }
    }, [objCampaign.status]);

    useEffect(() => {
        const valuesMapper = {
            feeds: "posts",
            stories: "stories",
            videos: "videos",
            general: null,
        };
        if (objCampaign.status === StateStatus.succeeded) {
            const startDate = moment(objCampaign.filterStartDate || objCampaign.campaign.startDate);
            const endDate = moment(objCampaign.filterEndDate || objCampaign.campaign.endDate);

            dispatch(
                fetchInfluencerMetrics({
                    campaignId,
                    airId,
                    body: {
                        start_date: startDate.format("YYYY-MM-DD"),
                        end_date: endDate.format("YYYY-MM-DD"),
                        post_type: valuesMapper[metricsFilter],
                    },
                })
            );

            if(!isContractor && objCampaign.influencerProfile && objCampaign.influencerProfile.name) {
                setInfluencerProfile(objCampaign.influencerProfile)
            } 
        }
    }, [objCampaign.status, objCampaign.filterStartDate, metricsFilter]);

    useEffect(() => {
        if (metricsFilter !== "general") {
            setFilterPost(metricsFilter);
        }
    }, [metricsFilter]);

    useEffect(() => {
        updatePath(filterPost);

        if (filterPost === "feeds") {
            dispatch(fetchAllInfluencersPosts({ campaignId, airId, page: page - 1, filter }));
        } else if (filterPost === "videos") {
            dispatch(fetchAllInfluencersVideos({ campaignId, airId, page: page - 1, filter }));
        } else {
            dispatch(fetchAllInfluencersStories({ campaignId, airId, page: page - 1, filter }));
        }
    }, [filterPost, page, filter, objCampaign.filterEndDate, objCampaign.filterStartDate]);

    return (
        <div className="campaign-influencer background-page">
            <PostsModal
                visible={visible}
                index={idxInfluencer}
                onOk={() => closeEditModal()}
                onCancel={() => closeEditModal()}
                posts={filterPost === "stories" ? objCampaign.stories : objCampaign.videos}
            />

            {objCampaign.status == StateStatus.succeeded && campaign && influencerProfile && (
                <Header
                    back={true}
                    className="influencer-page-header"
                    limitEndDate={campaign.endDate}
                    limitStartDate={campaign.startDate}
                    subHeader={
                        <div className="subheader-left">
                            <Avatar
                                size={"65px"}
                                src={influencerProfile.avatar}
                                network={influencerProfile.network}
                            />
                            <div className="subheader-junction">
                                <div className="subheader-inf">
                                    <span className="influencer-name">
                                        {influencerProfile.name}{" "}
                                    </span> 
                                    
                                    {influencerProfile.username && (
                                        <i
                                            onClick={() =>
                                                window
                                                    .open(
                                                        generateProfileUrl(
                                                            influencerProfile.network,
                                                            influencerProfile.username
                                                        )
                                                    )
                                                    .focus()
                                            }
                                            className="icon icon-link-external"
                                        />
                                    )}
                                </div>
                                <div className="subheader-cmp">
                                    <span className="campaign-name">
                                        {objCampaign.campaign ? objCampaign.campaign.name : null}{" "}
                                    </span>
                                </div>
                            </div>
                        </div>
                    }
                    funcDate={(date) => {
                        if (date) {
                            dispatch(filterDate({ startDate: date[0], endDate: date[1] }));
                        } else {
                            dispatch(filterDate({ startDate: null, endDate: null }));
                        }
                    }}
                />
            )}

            <div className="scroll-content">
                <div className="campaign-influencer-content">
                    {objCampaign.status == StateStatus.succeeded ? (
                        <>
                            {/* CONTENT */}

                            {!isContractor && 
                                <ChartHeaderOnly 
                                    title={t("Performance do influenciador na campanha")}
                                    subtitle={t("Apenas as publicações relacionadas à campanha são contabilizadas")}
                                    network={objCampaign.influencerProfile.network}
                                    currencySymbol={objCampaign.campaign.currency}
                                    isLoading={objCampaign.statusInfluencerMetrics === StateStatus.loading}
                                    isSingleProfile={true}
                                    counters={objCampaign.influencerMetrics}
                                    onChange={(key) => {
                                        if (key === "feed") {
                                            updateMetricsFilter("feeds");
                                            setFilterPost("feeds");
                                            return;
                                        }
                                        updateMetricsFilter(key);
                                        if (key !== "general") {
                                            setFilterPost(key);
                                        }
                                    }}
                                />
                            }

                            <Card
                                child={
                                    <>
                                        <div className="card-header">
                                            <div>
                                                <h3 className="campaign-title-data">{t(isContractor ? "Posts do contratante" : "Posts do influenciador")}</h3>

                                                <h3 className="campaign-subtitle-data">{t("Visualização de todas as publicações realizadas no perfil após conecte")}</h3>
                                            </div>

                                            <Select
                                                options={filterFeedOrStories}
                                                defaultValue={filterPost}
                                                onChange={(option) => {
                                                    setFilterPost(option.value);
                                                    setPage(1);
                                                }}
                                            />
                                        </div>

                                        <div className="segment-filter-posts">
                                            {filters.map((type, key) => {
                                                return (
                                                    <div
                                                        key={key}
                                                        className={`option-post ${
                                                            filter == type.key
                                                                ? `active color-${influencerProfile ? influencerProfile.network : "primary"}`
                                                                : ""
                                                        }`}
                                                        onClick={() => {
                                                            setPage(1);
                                                            setFilter(type.key);
                                                            setIndexFilter(key);
                                                        }}
                                                    >
                                                        <i className={`icon ${type.icon}`} />
                                                        <span className="text-option">{type.text}</span>
                                                    </div>
                                                );
                                            })}
                                            <div
                                                style={{ transform: `translate(${indexFilter * 100}%)` }}
                                                className={`segment-underline background-${influencerProfile ? influencerProfile.network : "primary"}`}
                                            ></div>
                                        </div>

                                        {objCampaign.statusPosts == StateStatus.loading && <Loading />}

                                        {objCampaign.statusPosts == StateStatus.succeeded && (
                                            <div className="container-posts">
                                                {filterPost == "feeds" &&
                                                    (objCampaign.feeds && objCampaign.feeds.length > 0 ? (
                                                        objCampaign.feeds.map((post, key) => (
                                                            <CardFeed
                                                                campaignId={objCampaign.campaign.id}
                                                                post={post}
                                                                key={key}
                                                            />
                                                        ))
                                                    ) : (
                                                        <p className="empty-value">                                                            
                                                            {t(isContractor ? "Nenhum post foi feito pelo contratante" : "Nenhum post foi feito pelo influenciador")}
                                                        </p>
                                                    ))}

                                                {filterPost == "stories" &&
                                                    (objCampaign.stories && objCampaign.stories.length > 0 ? (
                                                        objCampaign.stories.map((storie, key) => (
                                                            <CardStories
                                                                campaignId={objCampaign.campaign.id}
                                                                post={storie}
                                                                key={key}
                                                                onClick={() => {
                                                                    setVisible(true);
                                                                    setIndexInfluencer(key);
                                                                }}
                                                            />
                                                        ))
                                                    ) : (
                                                        <p className="empty-value">
                                                            {t(isContractor ? "Nenhum story foi feito pelo contratante" : "Nenhum story foi feito pelo influenciador")}
                                                        </p>
                                                    ))}

                                                {filterPost == "videos" &&
                                                    (objCampaign.videos && objCampaign.videos.length > 0 ? (
                                                        objCampaign.videos.map((video, key) => (
                                                            <CardStories
                                                                key={key}
                                                                post={video}
                                                                campaignId={objCampaign.campaign.id}
                                                                onClick={() => {
                                                                    setVisible(true);
                                                                    setIndexInfluencer(key);
                                                                }}
                                                            />
                                                        ))
                                                    ) : (
                                                        <p className="empty-value">
                                                            {t(isContractor ? "Nenhum video foi feito pelo contratante" : "Nenhum video foi feito pelo influenciador")}
                                                        </p>
                                                    ))}
                                            </div>
                                        )}
                                    </>
                                }
                            />

                            {pagination.total > 0 && (
                                <Pagination
                                    current={page}
                                    total={pagination.total}
                                    pageSize={pagination.itemsPerPage}
                                    isDisabled={objCampaign.status == StateStatus.loading}
                                    onChange={(page) => setPage(page)}
                                />
                            )}

                            {/* END CONTENT */}
                        </>
                    ) : objCampaign.status === StateStatus.failed ? (
                        <p>ERRO</p>
                    ) : (
                        <Loading />
                    )}
                </div>
            </div>
        </div>
    );
};

export default InfluencerDetails;
