import React from "react"
// 
import InputLabel from "../InputLabel/InputLabel"
// 
import "./InputPassword.scss"

const InputPassword = (props) => {

  return <div className="input-container input-container-password">
    <InputLabel type="password" isPassword {...props} />
  </div>
}
export default InputPassword
