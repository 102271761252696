import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
// 
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
// 
import { AirUtils } from "../../../../utils/utils";
import ChartColors from "../ChartColors";
// 
import "./DoubleBarChart.scss";
import "../Charts.scss";

const DoubleBarChart = ({ data, formatter = null }) => {
  const { t } = useTranslation();
  
  const airUtils = new AirUtils();
  const cColors = new ChartColors();
  const [series, setSeries] = useState([]);
  
  useEffect(() => {
    let newSeries = [];
    
    const users = [];

    data.forEach(inf => {
      users.push({ name: `${inf.label} (${t("contratado")})`, value: inf.total })
      users.push({ name: `${inf.label} (${t("entregue")})`, value: inf.value })
    })

    for (let index = 0; index < users.length; index++) {
      const user = users[index];

      let values = [];

      for (let i = 0; i < index; i++) {
        if(i % 2 == 1 && i > 0) {
          values.push(0);
        } 
      }
      values.push(user.value);

      newSeries.push({
        name: user.name,
        color: index % 2 == 0 ? cColors.getColor(index, .55) : cColors.getColor(index - 1, 1),
        pointPadding: index % 2 == 0 ? 0 : 0.15,
        data: values,
        selected: true
      })
    }


    setSeries(newSeries);
  }, [data])

  const options = {
    chart: {
      type: "column"
    },
    title: {
      text: ""
    },
    yAxis: {
      title: ""
    },
    xAxis: {
      visible: false
    },
    legend: {
      shadow: false
    },
    tooltip: {
      formatter: function () {
        const value = this.series.userOptions.data[this.series.userOptions.data.length - 1]
        let formattedValue = `<b>${airUtils.buildValue("number", value)}</b>`

        if (formatter){
          formattedValue = formatter(value)
        }

        return `<div class="high-tooltip">
          ${this.series.userOptions.name}: ${formattedValue}
        </div>` 
      }
    },
    plotOptions: {
      column: {
        grouping: false,
        shadow: false,
        borderWidth: 0
      },

      series: {
        showCheckbox: true,
        dataLabels: {
            enabled: false,
        },
        events: {
            checkboxClick: function () {
                if(this.visible) {
                    this.hide();
                } else {
                    this.show();
                }
            },
            legendItemClick: function () {
                if (this.selected) {
                  this.selected = false;
                } else {
                  this.selected = true;
                }
            
                if (this.visible) {
                  this.checkbox.checked = false;
                } else {
                  this.checkbox.checked = true;
                }
            }
        }
      }
    },
    series: series
  }

  return series && <div className="double-bar">
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
    />
  </div>
}

DoubleBarChart.propTypes = {
  data: PropTypes.any,
  formatter: PropTypes.func,
}

export default DoubleBarChart;