import PropTypes from "prop-types";
import { Tooltip } from 'antd';
import React, { useEffect, useState } from "react";
// 
import { useTranslation } from "react-i18next";
// import { useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
// import { changeObjAlert, showAlert } from "../Alert/alertSlice";
// 
import Avatar from "../Avatar/Avatar";
import { AirUtils } from "../../../utils/utils";
// 
import iconTikTok from "../../assets/icons/tiktok.svg"
import "./InfluencerTable.scss";

const InfluencerDetailsTable = ({ influencers = [], campaignId, symbolCoin, withTracking = false}) => {
    const { t } = useTranslation();
    // const dispatch = useDispatch();
    const history = useHistory();

    const airUtils = new AirUtils();

    const [sorts, setSorts] = useState(["name"]);
    const [boolBigger, setBoolBigger] = useState(false);
    const [arrowLocation, setArrowLocation] = useState(2);
    const [orderInfluencers, setOrderInfluencers] = useState([]);

    const [bigger] = useState({
        airscore: null,
        base: null,
        interactions: null,
        videos: null,
        posts: null,
        stories: null,
        performance: null,
        txe: null,
    });

    const [minors] = useState({
        airscore: null,
        base: null,
        interactions: null,
        videos: null,
        posts: null,
        stories: null,
        performance: null,
        txe: null,
    });

    const columns = [
        { alias: " ", title: " ", span: 1 },
        { alias: "network", title: t("rede"), span: 2 },
        { alias: "name", title: t("influenciador"), span: 2 },
        { alias: "base", title: t("seguidores"), span: 1 },
        { alias: "cpi", title: t("cpi"), span: 2 },
        { alias: "interactionsTotal", title: t("interações"), span: 1 },
        { alias: "videos", title: "videos / reels", span: 1 },
        { alias: "posts", title: "posts", span: 1 },
        { alias: "stories", title: "stories", span: 1 },
        { alias: "txe", title: t("txe"), span: 1 },
    ];

    const orderBy = (obj = "perNumber", index) => {
        setArrowLocation(index);

        if (!sorts.includes(obj)) {
            const newOrder = [...orderInfluencers].sort((a, b) => {
                if (obj === "name" || obj === "network") {
                    return a[obj].toLowerCase() > b[obj].toLowerCase() ? -1 : 1;
                } else if (obj == "videos") {
                    return a.deliveredVideos > b.deliveredVideos ? -1 : 1;
                } else if (obj == "posts") {
                    return a.deliveredFeeds > b.deliveredFeeds ? -1 : 1;
                } else if (obj == "stories") {
                    return a.deliveredStories > b.deliveredStories ? -1 : 1;
                } else {
                    return a[obj] > b[obj] ? -1 : 1;
                }
            });
            setBoolBigger(true);
            setOrderInfluencers(newOrder);
            setSorts((oldArray) => [...oldArray, obj]);
        } else {
            const newOrder = [...orderInfluencers].sort((a, b) => {
                if (obj === "name" || obj === "network") {
                    return a[obj].toLowerCase() < b[obj].toLowerCase() ? -1 : 1
                } else if (obj == "videos") {
                    return a.deliveredVideos < b.deliveredVideos ? -1 : 1;
                } else if (obj == "posts") {
                    return a.deliveredFeeds < b.deliveredFeeds ? -1 : 1;
                } else if (obj == "stories") {
                    return a.deliveredStories < b.deliveredStories ? -1 : 1;
                } else {
                    return a[obj] < b[obj] ? -1 : 1;
                }
            });
            setBoolBigger(false);
            setOrderInfluencers(newOrder);
            setSorts(sorts.filter(item => item !== obj))
        }
    }

    useEffect(() => {
        if (influencers.length > 0) {
            setOrderInfluencers(influencers);

            influencers.map(inf => {
                if (inf.airscore > bigger.airscore || bigger.airscore == null) {
                    bigger.airscore = inf.airscore;
                }
                if (inf.base > bigger.base || bigger.base == null) {
                    bigger.base = inf.base;
                }
                if ((inf.cpi > bigger.cpi || bigger.cpi == null) && inf.cpi > 0) {
                    bigger.cpi = inf.cpi;
                }
                if (inf.interactionsTotal > bigger.interactions || bigger.interactions == null) {
                    bigger.interactions = inf.interactionsTotal;
                }
                if (inf.deliveredVideos > bigger.videos || bigger.videos == null) {
                    bigger.videos = inf.deliveredFeeds;
                }
                if (inf.deliveredFeeds > bigger.posts || bigger.posts == null) {
                    bigger.posts = inf.deliveredFeeds;
                }
                if (inf.deliveredStories > bigger.stories || bigger.stories == null) {
                    bigger.stories = inf.deliveredStories;
                }
                if (inf.txe > bigger.txe || bigger.txe == null) {
                    bigger.txe = inf.txe;
                }
                // 
                if (inf.airscore < minors.airscore || minors.airscore == null) {
                    minors.airscore = inf.airscore;
                }
                if (inf.base < minors.base || minors.base == null) {
                    minors.base = inf.base;
                }
                if ((inf.cpi < minors.cpi || minors.cpi == null) && inf.cpi > 0) {
                    minors.cpi = inf.cpi;
                }
                if (inf.interactionsTotal < minors.interactions || minors.interactions == null) {
                    minors.interactions = inf.interactionsTotal;
                }
                if (inf.deliveredVideos < minors.videos || minors.videos == null) {
                    minors.videos = inf.deliveredVideos;
                }
                if (inf.deliveredFeeds < minors.posts || minors.posts == null) {
                    minors.posts = inf.deliveredFeeds;
                }
                if (inf.deliveredStories < minors.stories || minors.stories == null) {
                    minors.stories = inf.deliveredStories;
                }
                if (inf.txe < minors.txe || minors.txe == null) {
                    minors.txe = inf.txe;
                }
            })
        }

    }, [influencers]);

    return <table className="table-influencer table-influencer-details">
        <thead>
            <tr className="table-c-titles">
                {columns.map((item, key) => {
                    const withOrder = (item.alias != " " && item.alias != "trackingLink");

                    return (item.alias === "trackingLink" && !withTracking) ? null : <th
                        key={key}
                        rowSpan={item.span}
                        className={`th-${item.alias} ${!withOrder ? "default" : ""}`}
                        onClick={() => (withOrder) && orderBy(item.alias, key, item.withTotal)}
                    >
                        <span className={`name-column ${key === arrowLocation ? "space" : ""}`}>{item.title}</span>
                        {(withOrder) && key === arrowLocation && <i className={`icon icon-down ${boolBigger ? "bigger" : "smaller"}`}/>}
                    </th>
                })}
            </tr>
        </thead>

        <tbody>
            {
                orderInfluencers.length > 0 && orderInfluencers.map((influencer, key) => {
                    return <tr key={key}>
                        <th className="th-position" width="5%">
                            {key + 1}º
                        </th>

                        <th className={`th-network color-${influencer.network}`}>
                            { influencer.network === "tiktok" ? 
                                <img className="tiktok-icon-circle" alt="tiktok icon" src={iconTikTok} /> : 
                                <i className={`icon icon-${influencer.network}-circle`}/>
                            }
                        </th>

                        <th className="th-profile">
                            <Avatar
                                size="24px"
                                network={influencer.network}
                                src={influencer.avatar}
                                onClick={() => { history.push(`/campaign/${campaignId}/influencer/${influencer.airId}/feeds`) }}
                            />
                            <Tooltip title={`@${influencer.username}`}>
                                {influencer.name || influencer.username}
                            </Tooltip>
                        </th>

                        <th className={`th-followers ${influencer.base == bigger.base && "bigger"} ${influencer.base == minors.base && "minor"}`}>
                            {airUtils.buildValue("humanize", influencer.base)}
                        </th>
                        
                        {/* Aqui se o CPI for maior, usa classe minor e vice-versa, pois é um indicativo que quanto MAIOR, pior. */}
                        <th className={`th-cpi ${influencer.cpi == bigger.cpi && "minor"} ${influencer.cpi == minors.cpi && "bigger"}`}>
                            {airUtils.buildValue("currency", influencer.cpi, symbolCoin)}
                        </th>

                        <th className={`th-interactions ${influencer.interactionsTotal == bigger.interactionsTotal && "bigger"} ${influencer.interactionsTotal == minors.interactionsTotal && "minor"}`}>
                            {airUtils.buildValue("integer", influencer.interactionsTotal)}
                        </th>

                        <th className={`th-posts ${influencer.deliveredVideos == bigger.videos && "bigger"} ${influencer.deliveredVideos == minors.videos && "minor"}`}>
                            {influencer.deliveredVideos} {t("de")} {influencer.contractVideos}
                        </th>

                        <th className={`th-posts ${influencer.deliveredFeeds == bigger.posts && "bigger"} ${influencer.deliveredFeeds == minors.posts && "minor"}`}>
                            {influencer.network === "tiktok"
                                ? "-"
                                : `${influencer.deliveredFeeds} ${t("de")} ${influencer.contractFeeds}`
                            }
                        </th>

                        <th className={`th-stories ${influencer.deliveredStories == bigger.stories && "bigger"} ${influencer.deliveredStories == minors.stories && "minor"}`}>
                            {influencer.network === "tiktok"
                                ? "-"
                                : `${influencer.deliveredStories} ${t("de")} ${influencer.contractStories}`
                            }
                        </th>


                        <th className={`th-txe ${influencer.txe == bigger.txe && "bigger"} ${influencer.txe == minors.txe && "minor"}`}>
                            {airUtils.buildValue("percentage", influencer.txe)}
                        </th>
                    </tr>
                })
            }
        </tbody>
    </table>
}

InfluencerDetailsTable.propTypes = {
    influencers: PropTypes.array,
    symbolCoin: PropTypes.string,
    withTracking: PropTypes.bool,
    campaignId: PropTypes.any
}

export default InfluencerDetailsTable;