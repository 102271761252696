import React, { useRef, useState } from 'react';
// 
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
// 
// import { changeObjAlert, showAlert } from '../../../../../common/components/Alert/alertSlice';
//
import Button from '../../../../../common/components/Button/Button';
import Input from '../../../../../common/components/Input/Input';
import { importInfluencers } from '../../../createCampaignSlice';
import './ImportInfluencers.scss';

const ImportInfluencers = () => {
    const { t } = useTranslation();
    
    const dispatch = useDispatch();
    
    const inputFile = useRef(null) 

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [archieve, setArchieve] = useState();
    
    const [influencersData, setInfluencersData] = useState([]);

    const downloadExample = () => {
        const url = process.env.REACT_APP_API_URL + 'campaign/file_example'
        location.href = url
		return;
	}

    const selectedFile = (event) => {
        if(event.target.files && event.target.files.length > 0) {
            if(event.target.files[0].name.includes(".xlsx")) {
                setArchieve(event.target.files[0].name);
                setError(false);

                let files = event.target.files; 
                let f = files[0];

                const dt = new FormData() 
                dt.append("file", f);
                setInfluencersData(dt);
            } else {
                setError(true);
                setArchieve("");
            }
        }
    }

    const buildInfluencers = async () => {
        setLoading(true);

        await dispatch(importInfluencers(influencersData))
            
        setArchieve(null);
        inputFile.current.value = "";
        
        setLoading(false);
    }

    return <div className="container-add-influencer">
        <p className="label-input">{t("Insira uma lista para inclusão dos influenciadores")}</p>
        <div className="junction-input-button">
            <Input 
                width="82%"
                error={error} 
                disabled={true}
                value={archieve}
                textError={t("Escolha um arquivo válido")} 
                placeholder={t("Escolha um arquivo")} 
            />
            {
                archieve ? 
                    <Button 
                        icon="save"
                        color="success"
                        loading={loading}
                        text={t("Enviar")}
                        onClick={() => {
                            buildInfluencers();
                        }}
                    />
                :    
                    <Button
                        icon="archieve"
                        color="primary"
                        text={t("Procurar")}
                        onClick={() => {
                            inputFile.current.click();
                        }}
                    />
            }

            {/* input file */}
            <input 
                type="file" 
                id="file" 
                accept="xlsx" 
                ref={inputFile} 
                style={{display: "none"}}
                onChange={selectedFile} 
            />
        </div>

        <p className="text-orientations">
            <span className="title-orientations">{t("Orientação importante")}</span>
            <span className="subtitle-orientations">{t("Para importar influenciadores siga os passos abaixo.")}</span>
            
            <span className="step">{t("1º passo - Baixar o nosso modelo em Excel.")}</span>
            <span className="step">{t("2º passo - Habilite a edição no Excel, preencha as informações corretamente e salve o arquivo.")}</span>
            <span className="step">{t("3º passo - Selecione o arquivo clicando em “procurar”.")}</span>
            <span className="step">{t("4º passo - Importe para a campanha clicando em “enviar”.")}</span>
        </p>


        <Button
            href=""
            width="280px"
            icon="import"
            outline={true}
            text={t("Baixar modelo")}
            onClick={() => { downloadExample(); }}
        />
    </div>
}

export default ImportInfluencers;