//
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
//
import { useDispatch, useSelector } from "react-redux";
import { changeObjAlert, showAlert } from "../../../../common/components/Alert/alertSlice";
import CardIntegrationProfile from "../../components/CardIntegrationProfile/CardIntegrationProfile";
import { updIntegration } from "../../CreateCampaignService";
import { selectCampaign, updateIntegrations, removeIntegration } from "../../createCampaignSlice";
//
import "./StepIntegrationGO.scss";

import GoogleLogin from "../../../../common/components/GoogleLogin/GoogleLogin";
import { GoogleOAuthProvider } from "@react-oauth/google";

const requiredPermissions = [
    "email",
    "profile",
    // "https://www.googleapis.com/auth/analytics.manage.users.readonly",
    "https://www.googleapis.com/auth/analytics.readonly",
    // "https://www.googleapis.com/auth/analytics",
    "openid",
    "https://www.googleapis.com/auth/userinfo.profile",
    "https://www.googleapis.com/auth/userinfo.email",
];

const StepIntegrationGO = ({ connectLink, campaignId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const objCampaign = useSelector(selectCampaign);

    const [error, setError] = useState(false);
    const [account, setAccount] = useState(false);
    const [googleInitialId, setGoogleInitialId] = useState();

    useEffect(() => {
        if (Object.keys(objCampaign.campaign.integrations).includes("google")) {
            setGoogleInitialId(objCampaign.campaign.integrations.google?.id);
            setAccount(true);
        }
    }, []);

    const logout = () => {
        setAccount(false);
        dispatch(removeIntegration({ provider: "google" }));
    };

    const checkScopePermissions = (scope) => {
        let isValid = true;

        if (scope) {
            requiredPermissions.forEach((item) => {
                if (!scope.includes(item) && isValid) {
                    isValid = false;
                }
            });
        }

        if (!isValid) {
            alert(
                "error",
                t("erro"),
                t("Todas as permissões são necessárias, por favor verifique e marque todas as caixas de seleção")
            );
        }
        return isValid;
    };

    const responseGoogle = async (response) => {
        setError(false);
        let scope = response.scope;
        console.log(response, "error");
        const isScopeValid = checkScopePermissions(scope);

        console.log(objCampaign.campaign.id);
        if (isScopeValid) {
            if (response.code) {
                await updIntegration(campaignId || objCampaign.campaign.id, {
                    provider: "google",
                    authorization_code: response.code,
                    redirect_url: process.env.REACT_APP_URL,
                    external_id: googleInitialId,
                })
                    .then((res) => {
                        if (res.data.meta) {
                            const profile = res.data.meta;
                            dispatch(
                                updateIntegrations({
                                    provider: "google",
                                    data: {
                                        network: "google",
                                        id: profile.id,
                                        name: profile.name,
                                        email: profile.email,
                                        avatar: profile.picture,
                                    },
                                })
                            );
                        }

                        alert("success", t("sucesso"), t("Sua conta do Google foi conectada com sucesso!"));
                        setAccount(true);
                    })
                    .catch((err) => {
                        console.log(err, err.data, err.data?.error);
                        if (err.data && ["SERVICE_FORBIDDEN", "SERVICE_NOT_AUTHORIZED"].includes(err.data.error)) {
                            alert(
                                "error",
                                t("Crie sua conta no google analytics"),
                                t(
                                    "Parece que você não tem uma conta google analytics, por favor verifique sua conta ou crie uma conta no google analytics acessando https://analytics.google.com e tente novamente!"
                                )
                            );
                        } else {
                            alert("error", t("erro"), t("Erro ao fazer login com sua conta do google!"));
                        }
                    });
            }
        }
    };

    const alert = (type, title, text, question, cancelText, okText, onOk) => {
        dispatch(
            changeObjAlert({
                show: true,
                type: type,
                title: title,
                text: text,
                okText: okText,
                question: question,
                cancelText: cancelText,
                onCancel: () => {
                    dispatch(showAlert(false));
                },
                onOk: () => {
                    onOk();
                },
            })
        );
    };

    return (
        <>
            {objCampaign.campaign && !account ? (
                <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                    <GoogleLogin
                        onSuccess={responseGoogle}
                        onError={responseGoogle}
                        connectLink={connectLink || objCampaign.campaign.analyticsConnectUrl}
                    />
                </GoogleOAuthProvider>
            ) : (
                <div className="card-user-go">
                    <CardIntegrationProfile
                        profile={
                            objCampaign.campaign.integrations["google"]
                                ? objCampaign.campaign.integrations["google"]
                                : {}
                        }
                        showEmail={true}
                    />
                </div>
            )}

            {objCampaign.campaign && account && (
                <p onClick={() => logout()} className="p-change-account">
                    {t("Trocar conta")}
                </p>
            )}

            {error && <p className="text-empty">{t("Conecte sua conta Google Analytics para poder prosseguir!")}</p>}
        </>
    );
};

StepIntegrationGO.propTypes = {
    connectLink: PropTypes.string,
    campaignId: PropTypes.string,
};
export default StepIntegrationGO;
