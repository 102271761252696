import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchChartAudience, fetchChartNw, selectCharts } from "./ChartsSlice";
//
import Card from "../../../../common/components/Card/Card";
import BarChart from "../../../../common/components/Charts/BarChart/BarChart";
import DoubleBarChart from "../../../../common/components/Charts/DoubleBarChart/DoubleBarChart";
import ThreeCharts from "../../../../common/components/Charts/ThreeCharts/ThreeCharts";
import DonutsChart from "../../../../common/components/Charts/DonutsChart/DonutsChart";
import Loading, { SizedLoading } from "../../../../common/components/Loading/Loading";
import Select from "../../../../common/components/Select/Select";
//
import { AirUtils } from "../../../../utils/utils";
import "./Chart.scss";
import ColumnChart from "../../../../common/components/Charts/ColumnChart/ColumnChart";

const ChartNW = ({
    networks = [],
    title,
    subtitle,
    type,
    showFilterNw = true,
    network,
    currencySymbol,
    hiredPostTypes,
    onChange,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const airUtils = new AirUtils();

    const { nw, id } = useParams();

    // selectors
    const objCharts = useSelector(selectCharts);

    // filters
    const [filterNw, setFilterNw] = useState(nw ? nw : "all");
    const [typePost, setTypePost] = useState("general"); // general || feed || stories
    const [filterType, setFilterType] = useState(type == "hash" ? "per_hashtag" : "per_profile"); // inf: per_profile || per_interaction - hash: per_hashtag

    // charts
    const [counters, setCounters] = useState([]);
    const [allCharts, setCharts] = useState(); // chart = {}
    const [selectedChart, setSelectedChart] = useState(); // chart = {}

    // aux
    const [indexTypePost, setIndexTypePost] = useState(0);
    const [indexSelectedChart, setIndexSelectedChart] = useState(0);
    const [loading, setLoading] = useState(false);

    const [typePosts, setTypePosts] = useState([]);
    const types = [
        { value: "per_profile", key: t("Por perfil") },
        { value: "per_interaction", key: t("Por interação") },
    ];

    useEffect(() => {
        let types = [{ icon: "icon-general-posts2", key: "general", text: t("Geral") }];

        if (type !== "hash") types.push({ icon: "icon-group", key: "audience", text: t("Audiência") });

        if (filterNw === "instagram") {
            if (!hiredPostTypes || hiredPostTypes.includes("posts"))
                types.push({ icon: "icon-feed2", key: "feed", text: t("Posts") });
            if (!hiredPostTypes || hiredPostTypes.includes("videos"))
                types.push({ icon: "icon-stories2", key: "videos", text: t("Videos") });
            if (!hiredPostTypes || hiredPostTypes.includes("stories"))
                types.push({ icon: "icon-stories2", key: "stories", text: t("Stories") });
        }

        setTypePosts(types);
    }, [filterNw, hiredPostTypes, type]);

    const buildWidth = () => {
        if (type.includes("influ") && filterNw == "instagram" && typePost == "stories") {
            return Object.keys(allCharts).length - 2;
        } else {
            return Object.keys(allCharts).length;
        }
    };

    const overwriteWidth = (label, def) => {
        if (label === "investment") return "16";
        return def;
    };

    const buildChart = () => {
        const chartName = Object.keys(allCharts)[indexSelectedChart];

        if (
            type.includes("influ") &&
            filterNw == "instagram" &&
            chartName == "total_interactions" &&
            typePost == "stories"
        ) {
            let dataCharts = {};
            let nameCharts = ["total_interactions", "exit_rate", "retention_rate"];

            Object.keys(allCharts).map((nameChart) => {
                if (nameCharts.includes(nameChart)) {
                    dataCharts[nameChart] = allCharts[nameChart];
                }
            });

            return <ThreeCharts datas={dataCharts} names={nameCharts} filter={filterType} />;
        } else if (chartName == "investment") {
            return (
                <DoubleBarChart
                    data={selectedChart}
                    formatter={(value) => airUtils.buildValue("investment", value, currencySymbol, 2)}
                />
            );
        } else if (chartName == "publications") {
            return <DoubleBarChart data={selectedChart} />;
        } else if (chartName == "gender" || chartName == "device") {
            return <DonutsChart data={selectedChart} />;
        } else if (chartName == "age" || chartName == "country") {
            return <BarChart data={selectedChart} nameFormat="is_percent" />;
        } else if (chartName == "video_completion_rate") {
            return <ColumnChart data={selectedChart} />;
        }

        return (
            <BarChart
                nameFormat={chartName}
                data={selectedChart}
                formatter={(value) => airUtils.buildValue(selectedChart, value)}
            />
        );
    };

    // get data
    useEffect(async () => {
        setLoading(true);
        setIndexSelectedChart(0);

        if (typePost === "audience") {
            await dispatch(fetchChartAudience({ id }));
        } else {
            await dispatch(
                fetchChartNw({
                    id: id,
                    network: filterNw == "all" ? null : filterNw,
                    postType: typePost == "general" ? null : typePost == "feed" ? "posts" : typePost,
                })
            );
        }

        setLoading(false);

        // per influencer chart
        if (network) {
            setFilterNw(network);
        }
    }, [filterNw, typePost]);

    useEffect(() => {
        if (onChange && typePost !== "general") onChange(typePost);
    }, [typePost]);

    // get chart
    useEffect(() => {
        if (objCharts.network) {
            setCounters(objCharts.network.counters);
            setCharts(objCharts.network.charts);
            setSelectedChart(objCharts.network.charts[Object.keys(objCharts.network.charts)[0]][filterType]);
        }
    }, [objCharts.network]);

    useEffect(() => {
        if (selectedChart) {
            setSelectedChart(allCharts[Object.keys(allCharts)[indexSelectedChart]][filterType]);
        }
    }, [filterType, indexSelectedChart]);

    return (
        <>
            {(allCharts != null || type === "hashtag") && (
                <Card
                    className={`chart-card chart-${filterNw} border-color-${filterNw}`}
                    child={
                        <>
                            <div className="chart-header">
                                <div className="chart-titles">
                                    <p className="chart-title campaign-title-data">{title}</p>
                                    
                                    <p className="chart-subtitle campaign-subtitle-data">{subtitle}</p>
                                </div>

                                {networks.length > 1 && showFilterNw && (
                                    <Select
                                        defaultValue={filterNw == "all" ? t("geral") : filterNw}
                                        onChange={(nw) => {
                                            setIndexTypePost(0);
                                            setFilterType(type == "hash" ? "per_hashtag" : "per_profile");
                                            //
                                            setTypePost("general");
                                            setFilterNw(nw.key == t("geral") ? "all" : nw.key);
                                        }}
                                        options={[t("geral"), ...networks].map((nw) => {
                                            return { value: nw, key: nw, icon: `icon-${nw}-circle` };
                                        })}
                                    />
                                )}
                            </div>

                            <div className="segment-type-posts">
                                {typePosts.map((type, key) => {
                                    return (
                                        <div
                                            key={key}
                                            className={`option-post ${
                                                typePost == type.key ? `active color-${filterNw}` : ""
                                            }`}
                                            onClick={() => {
                                                setFilterType(type == "hash" ? "per_hashtag" : "per_profile");
                                                setIndexTypePost(key);

                                                setTypePost(type.key);
                                            }}
                                        >
                                            <i className={`icon ${type.icon}`} />
                                            <span className="text-option">{type.text}</span>
                                        </div>
                                    );
                                })}
                                <div
                                    style={{ transform: `translate(${indexTypePost * 100}%)` }}
                                    className={`segment-underline background-${filterNw}`}
                                ></div>
                            </div>

                            {loading && (
                                <div className="container-loading">
                                    {" "}
                                    <Loading />{" "}
                                </div>
                            )}

                            {!loading && (
                                <>
                                    <div className="container-segment-chart">
                                        <div className={`segment-chart chart-${type}`}>
                                            {counters != null && (
                                                <>
                                                    {counters.map((item, key) => {
                                                        const { label } = item;
                                                        return (
                                                            !["airscore", "exit_rate", "retention_rate"].includes(
                                                                item.label
                                                            ) && (
                                                                <div
                                                                    key={key}
                                                                    className={`chart-option ${
                                                                        indexSelectedChart == key &&
                                                                        `active color-${filterNw}`
                                                                    }`}
                                                                    style={{
                                                                        width: `${overwriteWidth(
                                                                            label,
                                                                            100 / buildWidth()
                                                                        )}%`,
                                                                        color: `color-${filterNw}`,
                                                                    }}
                                                                    onClick={() => {
                                                                        setFilterType(
                                                                            type == "hash"
                                                                                ? "per_hashtag"
                                                                                : "per_profile"
                                                                        );
                                                                        setIndexSelectedChart(key);
                                                                    }}
                                                                >
                                                                    <span className="text-option">{t(item.label)}</span>

                                                                    <span className="text-value">
                                                                        {airUtils.buildValue(
                                                                            item.label,
                                                                            item.value,
                                                                            item.total === null ||
                                                                                item.total === undefined
                                                                                ? currencySymbol
                                                                                : item.total
                                                                        )}
                                                                    </span>
                                                                </div>
                                                            )
                                                        );
                                                    })}

                                                    <div
                                                        style={{
                                                            width: `${100 / buildWidth()}%`,
                                                            transform: `translate(${indexSelectedChart * 100}%)`,
                                                        }}
                                                        className={`segment-underline background-${filterNw}`}
                                                    ></div>
                                                </>
                                            )}
                                        </div>
                                    </div>

                                    {type.includes("influ") &&
                                    allCharts[Object.keys(allCharts)[indexSelectedChart]]["per_interaction"] ? (
                                        <Select
                                            width="156px"
                                            className="chart-select-filter"
                                            defaultValue={
                                                filterType == "per_profile" ? t("Por perfil") : t("Por interação")
                                            }
                                            onChange={(type) => {
                                                setFilterType(type.value);
                                            }}
                                            options={types}
                                        />
                                    ) : (
                                        <div className="chart-select-filter"></div>
                                    )}

                                    <div className="chart-container">{selectedChart && buildChart()}</div>
                                </>
                            )}
                        </>
                    }
                />
            )}
        </>
    );
};

ChartNW.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    network: PropTypes.string,
    currencySymbol: PropTypes.string,
    networks: PropTypes.any,
    showFilterNw: PropTypes.bool,
    hiredPostTypes: PropTypes.array,
    onChange: PropTypes.func,
};

const ChartHeaderOnly = ({
    title,
    subtitle,
    network,
    counters = [],
    currencySymbol,
    isLoading = false,
    isSingleProfile = false,
    onChange = () => {},
}) => {
    const { t } = useTranslation();
    const [indexTypePost, setIndexTypePost] = useState(0);
    const airUtils = new AirUtils();

    const typePosts = [
        { icon: "icon-general-posts2", key: "general", text: t("Geral") },
        { icon: "icon-feed2", key: "feed", text: t("Feed") },
        { icon: "icon-stories2", key: "videos", text: t("Videos") },
        { icon: "icon-stories2", key: "stories", text: t("Stories") },
    ].filter((item) => {
        if (network == "instagram") {
            return item;
        } else if (!["feed", "stories"].includes(item.key)) {
            return item;
        }
    });

    return (
        <Card
            className={`chart-card chart-${network} border-color-${network}`}
            child={
                <>
                    <div className="chart-header">
                        <div className="chart-titles">
                            <span className="chart-title campaign-title-data">{title}</span>
                            <span className="chart-subtitle campaign-subtitle-data">{subtitle}</span>
                        </div>
                    </div>

                    <div className="segment-type-posts">
                        {typePosts.map((type, key) => {
                            return (
                                <div
                                    key={key}
                                    className={`option-post ${
                                        typePosts[indexTypePost].key == type.key ? `active color-${network}` : ""
                                    }`}
                                    onClick={() => {
                                        setIndexTypePost(key);

                                        onChange(type.key);
                                    }}
                                >
                                    <i className={`icon ${type.icon}`} />
                                    <span className="text-option">{type.text}</span>
                                </div>
                            );
                        })}
                        <div
                            style={{ transform: `translate(${indexTypePost * 100}%)` }}
                            className={`segment-underline background-${network}`}
                        ></div>
                    </div>

                    {!isLoading ? (
                        <div className="counter-basic-container">
                            {counters != null && (
                                <>
                                    {counters.map((item, key) => {
                                        return (
                                            !["airscore", "exit_rate", "retention_rate"].includes(item.label) && (
                                                <div key={key} className="counter-basic">
                                                    <div className="counter-basic-title">
                                                        {isSingleProfile && item.label === "base"
                                                            ? t("single_base")
                                                            : t(item.label)}
                                                    </div>
                                                    <span className="counter-basic-value">
                                                        {airUtils.buildValue(
                                                            item.label,
                                                            item.value,
                                                            item.total === null || item.total === undefined
                                                                ? currencySymbol
                                                                : item.total
                                                        )}
                                                    </span>
                                                </div>
                                            )
                                        );
                                    })}
                                </>
                            )}
                        </div>
                    ) : (
                        <SizedLoading size={50} />
                    )}
                </>
            }
        />
    );
};

ChartHeaderOnly.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    network: PropTypes.string,
    typePost: PropTypes.string,
    currencySymbol: PropTypes.string,
    counters: PropTypes.array,
    isLoading: PropTypes.bool,
    isSingleProfile: PropTypes.bool,
    onChange: PropTypes.func,
};

export { ChartNW, ChartHeaderOnly };
