import PropTypes from "prop-types";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
//
import { changeObjAlert, showAlert } from "../../../../common/components/Alert/alertSlice";
import {
    archiveCampaign,
    duplicateCampaign,
    excludeCampaign,
    exportCampaign,
    updateCampaignIdAction,
} from "../../HomeSlice";
//
import Avatar from "../../../../common/components/Avatar/Avatar";
import Button from "../../../../common/components/Button/Button";
import Card from "../../../../common/components/Card/Card";
import CardData from "../../../../common/components/CardData/CardData";
import DropDown from "../../../../common/components/DropDown/DropDown";
import { SizedLoading } from "../../../../common/components/Loading/Loading";
import Performance from "../../../../common/components/PerformanceBar/PerformanceBar";
import { humanize } from "../../../../utils/formatters";
import StateStatus from "../../../../utils/stateStatus";
//
import { AirUtils } from "../../../../utils/utils";
import "./CampaignPreview.scss";

const CampaignPreview = ({ campaign, actionId, statusAction }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const airUtils = new AirUtils();

    const [openActions, setOpenActions] = useState(false);

    const status = { active: t("ativa"), filed: t("arquivada"), inactive: t("inativa") };

    let actions = [
        { icon: "edit", key: "edit", value: t("Editar campanha") },
        { icon: "duplicate", key: "duplicate", value: t("Duplicar campanha") },
        { icon: "export", key: "export", value: t("Exportar campanha") },
        { icon: "archieve", key: "filed", value: t("Arquivar campanha") },
    ].filter((item) => {
        if (campaign.status != "inactive") {
            return item;
        } else if (!["edit", "filed", "duplicate"].includes(item.key)) {
            return item;
        }
    });

    if (campaign.type == "hashtag") actions = actions.filter((item) => item.key != "export");

    const draftActions = [
        { icon: "edit", key: "edit", value: t("Editar campanha") },
        { icon: "archieve", key: "exclude", value: t("Apagar rascunho") },
    ];

    const formatValue = (type, value, currency = "USD") => {
        if (type == "base" || type == "total_post_hashtags" || type == "interactions_total") {
            return humanize(value);
        } else if (type == "investment") {
            return new AirUtils().buildValue(type, value, 0, 2, currency);
        } else if (type == "engagement_rate") {
            return `${value}%`;
        }
        return value;
    };

    return (
        <Card
            className={`campaign-card campaign-${campaign.type} ${campaign.draft ? "campaign-draft" : ""}`}
            child={
                <>
                    <div className="card-header">
                        <div className="name-status">
                            <span className="type-objective">
                                {t("Tipo")}: {campaign.type == "influencers" ? t("influenciadores") : t("hashtag")}
                                {campaign.objectives.length > 0 &&
                                    campaign.type == "influencers" &&
                                    ` | ${t("Objetivo")}: ${
                                        campaign.objectives.includes("site") ? t("site/loja virtual") : ""
                                    } ${campaign.objectives.length > 1 ? ` ${t("e")} ` : ""} ${
                                        campaign.objectives.includes("social_media") ? t("redes socias") : ""
                                    }`}
                            </span>

                            <span className={`${t("status")} ${campaign.status}`}>
                                {t("campanha")} {campaign.draft ? t("em criação") : status[campaign.status]}
                                {!campaign.draft && <div className="pointer"></div>}
                            </span>

                            <div className="name">
                                <span className="span-name">{campaign.name}</span>

                                {statusAction == StateStatus.loading ? (
                                    actionId == campaign.id &&
                                    statusAction === StateStatus.loading && (
                                        <SizedLoading size={24} withContainer={false} />
                                    )
                                ) : (
                                    <DropDown
                                        options={campaign.draft ? draftActions : actions}
                                        optionsWithIcons={true}
                                        onChange={() => {
                                            setOpenActions(!openActions);
                                        }}
                                        onClick={(value) => {
                                            dispatch(updateCampaignIdAction(campaign.id));

                                            if (value.key === "edit") {
                                                history.push(`/edit-campaign/${campaign.id}/type`);
                                            } else if (value.key == "duplicate") {
                                                dispatch(duplicateCampaign(campaign.id));
                                            } else if (value.key == "export") {
                                                dispatch(exportCampaign(campaign.id));
                                            } else if (value.key == "exclude") {
                                                dispatch(
                                                    changeObjAlert({
                                                        show: true,
                                                        type: "warning",
                                                        title: t("atenção"),
                                                        question: t(
                                                            "Deseja realmente apagar este rascunho? (Essa ação não pode ser desfeita)"
                                                        ),
                                                        okText: t("Sim, apagar rascunho"),
                                                        cancelText: t("Não, manter rascunho"),
                                                        onCancel: () => {
                                                            dispatch(showAlert(false));
                                                        },
                                                        onOk: () => {
                                                            dispatch(excludeCampaign(campaign.id));
                                                        },
                                                    })
                                                );
                                            } else if (value.key == "filed") {
                                                dispatch(
                                                    changeObjAlert({
                                                        show: true,
                                                        type: "warning",
                                                        title: t("atenção"),
                                                        question: t(
                                                            "Deseja realmente arquivar essa campanha? (Essa ação não pode ser desfeita)"
                                                        ),
                                                        okText: t("Sim, arquivar campanha"),
                                                        cancelText: t("Não, manter campanha"),
                                                        onCancel: () => {
                                                            dispatch(showAlert(false));
                                                        },
                                                        onOk: () => {
                                                            dispatch(archiveCampaign(campaign.id));
                                                        },
                                                    })
                                                );
                                            }

                                            setOpenActions(false);
                                        }}
                                        child={
                                            <i className={`icon-more icon-more-${openActions ? "open" : "close"}`} />
                                        }
                                    />
                                )}
                            </div>
                        </div>
                        <div className="date-performance">
                            <span className="date">
                                {t("Período")}: {airUtils.buildValue("date", new Date(campaign.startDate))} -{" "}
                                {airUtils.buildValue("date", new Date(campaign.endDate))}
                            </span>
                            {!campaign.draft && campaign.type !== "hashtag" && (
                                <Performance performance={campaign.performance} />
                            )}
                        </div>
                    </div>

                    <div className="card-body">
                        <div
                            className={`card-metrics card-${campaign.objectives.toString()} card-${
                                campaign.metrics.length
                            }`}
                        >
                            {campaign.metrics.map((metric, key) => {
                                return (
                                    <CardData
                                        key={key}
                                        tooltip={
                                            metric.alias != "best_performance" ? t(`tooltip_${metric.alias}`) : null
                                        }
                                        className={`metric metric-${metric.alias}`}
                                        title={<span>{t(metric.alias)}</span>}
                                        child={
                                            typeof metric.value != "object" ? (
                                                <span>
                                                    {formatValue(metric.alias, metric.value, campaign.currency)}
                                                </span>
                                            ) : (
                                                <div className="card-value-influencers">
                                                    {" "}
                                                    {metric.value.map((inf, key) => {
                                                        return key <= 4 ? (
                                                            <Avatar
                                                                tooltip={inf.name}
                                                                size="25px"
                                                                key={key}
                                                                src={inf.avatar}
                                                                network={inf.network}
                                                                onClick={() => {
                                                                    history.push(
                                                                        `/campaign/${campaign.id}/influencer/${inf.air_id}/feeds`
                                                                    );
                                                                }}
                                                            />
                                                        ) : null;
                                                    })}{" "}
                                                </div>
                                            )
                                        }
                                    />
                                );
                            })}
                        </div>
                        <div className="card-buttons">
                            {(campaign.influencers.length > 0 || !campaign.draft) && (
                                <div className="buttons-nw">
                                    {Object.keys(campaign.networks).map((nw, key) => {
                                        return campaign.networks[nw] != 0 ? (
                                            <Button
                                                className={`background-${nw}-opacity border-color-${nw}`}
                                                icon={nw}
                                                key={key}
                                                text={`${campaign.networks[nw]}`}
                                                onClick={() => {
                                                    history.push(`/campaign/${campaign.id}/${nw}`);
                                                }}
                                            />
                                        ) : null;
                                    })}
                                </div>
                            )}

                            {campaign.status != "inactive" ? (
                                campaign.draft ? (
                                    <>
                                        <p className="text-draft">{t("rascunho")}</p>
                                        <p
                                            className="p-click"
                                            onClick={() => {
                                                history.push(`/edit-campaign/${campaign.id}/type`);
                                            }}
                                        >
                                            {t("Continuar criação")}
                                            <i className="icon icon-advance" />
                                        </p>
                                    </>
                                ) : (
                                    <p
                                        className="p-click"
                                        onClick={() => {
                                            history.push(`/campaign/${campaign.id}`);
                                        }}
                                    >
                                        {t("Analisar campanha")}
                                        <i className="icon icon-advance" />
                                    </p>
                                )
                            ) : (
                                <p
                                    className="p-click"
                                    onClick={() => {
                                        console.log("adicionar o exportar");
                                    }}
                                >
                                    {t("Exportar campanha")}
                                    <i className="icon icon-advance" />
                                </p>
                            )}
                        </div>
                    </div>
                </>
            }
        />
    );
};

CampaignPreview.propTypes = {
    statusAction: PropTypes.string,
    campaign: PropTypes.any,
    actionId: PropTypes.any,
};

export default CampaignPreview;
