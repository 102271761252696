const interests = [
    "fashion_accessories",
    "physical_activities_health",
    "animals",
    "beauty_style",
    "comedy",
    "food",
    "sports",
    "do_it_yourself",
    "motivation_guidance",
    "talents",
]

export default interests