const categories = [
    "arts_crafts",
    "automobiles_transport",
    "drink",
    "beauty",
    "clothing_accessories",
    "education_training",
    "electronics",
    "finance_investments",
    "food_drinks",
    "games",
    "health_wellness",
]

export default categories
