import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile, selectCampaign, updateInfluencer, updateInfluencerDefault } from "../../../createCampaignSlice";
//
import { updInfluencer } from "../../../CreateCampaignService";
import Button from "../../../../../common/components/Button/Button";
import Input from "../../../../../common/components/Input/Input";
import Tooltip from "../../../../../common/components/Tooltip/Tooltip";
//
import CurrencyFormat from "react-currency-format";
import checkUrl from "../../../../../utils/verifications";
//
import "./AddTiktokInfluencer.scss";
import StateStatus from "../../../../../utils/status";
import TikTokDiscovery from "../../../../../common/components/TikTokDiscovery/TikTokDiscovery";

const AddTiktokInfluencer = ({ influencer, closeModal, isEdit }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    //
    const objCampaign = useSelector(selectCampaign);
    //
    const campaign = objCampaign.campaign;
    //
    const [url, setUrl] = useState("");
    const [quantVideos, setQuantVideos] = useState("");
    const [investmentVideos, setInvestmentVideos] = useState("");
    //
    const [error, setError] = useState(-1);
    const [openSearch, setOpenSearch] = useState(false);

    const sendInfluencer = async (edit = false) => {
        if (!checkUrl(url, !influencer ? campaign.influencers : null)) {
            setError(0); // error on input url
            return;
        }
        // if(!quantVideos || parseInt(quantVideos) <= 0) {
        //     setError(1); // error quant posts
        //     return;
        // }

        setError(-1);

        if (!edit) {
            // create
            const data = {
                quant_videos: parseInt(quantVideos) || 0,
                investment_videos: parseFloat(investmentVideos) || 0,
            };

            dispatch(fetchProfile({ url: url, type: "influencer", additionalData: data }));

            setUrl("");
            setQuantVideos("");
            setInvestmentVideos("");
        } else {
            // update
            const quantVideosValue = parseInt(quantVideos) || 0;
            const investmentVideosValue = parseFloat(investmentVideos) || 0;
            await updInfluencer(campaign.id, influencer.id, {
                videos_count: quantVideosValue,
                videos_value: investmentVideosValue,
            }).then(() => {
                influencer.contractVideos = quantVideosValue;
                influencer.investmentVideos = investmentVideosValue;

                influencer.investmentTotal = investmentVideos;
                dispatch(updateInfluencer({ influencer: influencer }));
            });
        }

        if (closeModal) closeModal();
    };

    const calcTotal = () => {
        let value = 0;

        if (investmentVideos && investmentVideos.length > 0) value += parseFloat(investmentVideos);

        return !value || value === "" || value == 0 ? "0.00" : value;
    };

    useEffect(() => {
        if (influencer) {
            // clear form
            setUrl("");
            setQuantVideos("");
            setInvestmentVideos("");

            // set data
            setUrl(influencer.profileUrl);
            setQuantVideos(influencer.contractVideos == 0 ? "" : `${influencer.contractVideos}`);
            setInvestmentVideos(influencer.investmentVideos == 0 ? "" : `${influencer.investmentVideos}`);
        }
    }, [influencer]);

    useEffect(() => {
        if (objCampaign.influencerDefault) {
            setUrl(objCampaign.influencerDefault);

            dispatch(updateInfluencerDefault(null));
        }
    }, [objCampaign.influencerDefault]);

    return (
        <div className="container-add-tiktok-influencer">
            {openSearch && (
                <TikTokDiscovery
                    onBack={() => setOpenSearch(false)}
                    onAdd={(username) => {
                        setOpenSearch(false);
                        setUrl(`https://www.tiktok.com/@${username ? username : ""}`);
                    }}
                />
            )}

            {!openSearch && (
                <>
                    <p className="label-input">{t("Insira a URL completa do perfil público do influenciador")}</p>

                    {/* URL */}
                    <Input
                        width="100%"
                        value={url}
                        error={error == 0}
                        disabled={!!influencer}
                        textError={t(
                            "URL inválida ou perfil/fanpage não é pública. Verifique se você já adicionou essa url"
                        )}
                        placeholder="URL"
                        onChange={(value) => {
                            setUrl(value);
                            if (error == 0) setError(-1);
                        }}
                    />

                    {/* POSTSTORIES */}
                    <div className="container-data-posts">
                        {/* posts */}
                        <div className="card-post-data">
                            <div className="card-header">
                                <p className="card-header-title">{t("Videos")}</p>
                                <Tooltip
                                    child={t(
                                        "Quantidade de post que o influenciador foi contratado para fazer na campanha."
                                    )}
                                />
                            </div>

                            <div className="card-body">
                                <Input
                                    error={error == 1}
                                    textError={t("Informe a quantia")}
                                    onlyNum={true}
                                    value={quantVideos}
                                    width="48%"
                                    placeholder={t("Quantidade contratada")}
                                    onChange={(value) => {
                                        setQuantVideos(value);
                                        if (error == 1) setError(-1);
                                    }}
                                />

                                <CurrencyFormat
                                    value={investmentVideos}
                                    className={`ant-input input-currency ${error == 2 ? "input-error" : ""}`}
                                    decimalSeparator={"."}
                                    thousandSeparator={","}
                                    prefix={`${campaign.coin.symbol} `}
                                    placeholder={t("Investimento")}
                                    onValueChange={(value) => {
                                        setInvestmentVideos(value.value);
                                        if (error == 2) setError(-1);
                                    }}
                                />
                                {error == 2 && <span className="span-error">{t("Informe o investimento")}</span>}
                            </div>
                        </div>

                        {/* total */}
                        <div className={`card-post-data card-total ${url.includes("instagram.com") ? "" : "blocked"}`}>
                            <div className="card-header">
                                <p className="card-header-title">{t("Total")}</p>
                                <Tooltip
                                    child={t("Valor total pago para o influenciador fazer as publicações da campanha.")}
                                />
                            </div>
                            <div className="card-body">
                                <CurrencyFormat
                                    disabled={true}
                                    value={calcTotal()}
                                    // placeholder={calcTotal()}
                                    decimalSeparator={"."}
                                    thousandSeparator={","}
                                    prefix={`${campaign.coin.symbol} `}
                                    className="ant-input input-currency"
                                    style={{ width: "100%" }}
                                />
                            </div>
                        </div>
                    </div>

                    {/* ADD */}
                    <div className="junction-type-save">
                        {!influencer && (
                            <>
                                {/* <Button
                        icon="search"
                        color="success"
                        outline={true}
                        text={t("Buscar influenciador")}
                        onClick={() => setOpenSearch(true)}
                        loading={objCampaign.status === StateStatus.loading}
                    /> */}
                                <div></div>

                                <Button
                                    icon="plus"
                                    color="success"
                                    text={t("Adicionar perfil")}
                                    onClick={() => sendInfluencer()}
                                    loading={objCampaign.status === StateStatus.loading}
                                />
                            </>
                        )}

                        {influencer && (
                            <Button
                                icon="save"
                                color="success"
                                text={t("Salvar")}
                                onClick={() => sendInfluencer(isEdit)}
                                loading={objCampaign.status === StateStatus.loading}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

AddTiktokInfluencer.defaultProps = {
    isEdit: true,
};

AddTiktokInfluencer.propTypes = {
    index: PropTypes.number,
    influencer: PropTypes.any,
    closeModal: PropTypes.func,
    isEdit: PropTypes.bool,
};

export default AddTiktokInfluencer;
