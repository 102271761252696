import React, { useState } from "react";
import { useTranslation } from "react-i18next";
//
import { useDispatch, useSelector } from "react-redux";
import {
    changeStep,
    selectCampaign,
    fetchProfile,
    removeContractor,
    updateBrandName,
    updateBrandEmail,
    updateBrandContactName,
    updateBrandPhone,
    saveStep,
} from "../../createCampaignSlice";
import { rmvContractor } from "../../CreateCampaignService";
//
import Button from "../../../../common/components/Button/Button";
import Card from "../../../../common/components/Card/Card";
import ContractorTable from "../../../../common/components/ContractorTable/ContractorTable";
import Input from "../../../../common/components/Input/Input";
//
import checkUrl from "../../../../utils/verifications";
import CardFooter from "../../components/CardFooter/CardFooter";
import "./StepContractors.scss";

const StepContractors = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    //
    const objCampaign = useSelector(selectCampaign);
    const campaign = objCampaign.campaign;
    //
    const [error, setError] = useState(-1);
    const [buttonClicked, setButtonClicked] = useState(-1);
    //
    const [urlContractor, setUrlContractor] = useState();

    const isValid = () => {
        const emailRegex =
            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi;

        if (!campaign.brandName || campaign.brandName.length < 3) {
            setError(0);
        } else if (
            objCampaign.campaign.type === "influencers" &&
            (!campaign.brandEmail || campaign.brandEmail.length < 3 || !emailRegex.test(campaign.brandEmail))
        ) {
            setError(1);
        } else if (
            objCampaign.campaign.type === "influencers" &&
            campaign.brandPhone &&
            campaign.brandPhone.length < 10
        ) {
            setError(3);
        } else if (campaign.contractors.length < 1) {
            setError(5);
        } else {
            dispatch(saveStep({ step: "brand", index: objCampaign.index + 1 }));
        }
    };

    const addContractor = () => {
        if (!urlContractor || !checkUrl(urlContractor, campaign.contractors)) {
            setError(4);
        } else {
            setError(-1);

            let buildUrl = urlContractor;

            if (!urlContractor.includes("www.")) {
                buildUrl = `www.${buildUrl}`;
            }
            if (!urlContractor.includes("https://")) {
                buildUrl = `https://${buildUrl}`;
            }

            dispatch(fetchProfile({ url: buildUrl, type: "contractor" }));

            setUrlContractor("");
        }
    };

    return (
        <Card
            child={
                <>
                    <h2 className="step-name">{t("Adicione o perfil social do contratante da campanha")}</h2>

                    <div className="inputs inputs-brands">
                        <div className="junction-input junction-name">
                            <span className="label-input">{t("Nome da marca")}</span>
                            <Input
                                maxLength={30}
                                error={error === 0}
                                value={campaign.brandName}
                                textError={t("Informe o nome")}
                                placeholder={t("Insira o nome da marca")}
                                onChange={(value) => {
                                    if (error === 0) setError(-1);
                                    dispatch(updateBrandName(value));
                                }}
                            />
                        </div>

                        {objCampaign.campaign.type === "influencers" && (
                            <>
                                <div className="row-email-name">
                                    <div className="junction-input junction-email">
                                        <span className="label-input">{t("Email de contato")}</span>
                                        <Input
                                            error={error === 1}
                                            value={campaign.brandEmail}
                                            textError={t("Informe o e-mail")}
                                            placeholder={t("Insira o e-mail do contato")}
                                            onChange={(value) => {
                                                if (error === 1) setError(-1);
                                                dispatch(updateBrandEmail(value));
                                            }}
                                        />
                                    </div>

                                    <div className="junction-input junction-brand-contact">
                                        <span className="label-input">{t("Nome de contato (Opcional)")}</span>
                                        <Input
                                            error={error === 2}
                                            value={campaign.brandContactName}
                                            textError={t("Informe o contato")}
                                            placeholder={t("Insira nome para contato")}
                                            onChange={(value) => {
                                                if (error === 2) setError(-1);
                                                dispatch(updateBrandContactName(value));
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="junction-input junction-brand-contact">
                                    <span className="label-input">{t("Número do contato (Opcional)")}</span>
                                    <Input
                                        error={error === 3}
                                        value={campaign.brandPhone}
                                        textError={t("Informe o telefone")}
                                        placeholder={t("Insira número de telefone")}
                                        onChange={(value) => {
                                            if (error === 3) setError(-1);
                                            dispatch(updateBrandPhone(value));
                                        }}
                                    />
                                </div>{" "}
                            </>
                        )}
                    </div>

                    <div className="inputs inputs-contractors">
                        <div className="junction-input">
                            <span className="label-input">
                                {t("Insira a URL completa do perfil do contratante (público)")}
                            </span>

                            <Input
                                width="70%"
                                error={error == 4}
                                value={urlContractor}
                                textError={t(
                                    "URL inválida ou perfil/fanpage não é pública. Verifique se você já adicionou essa url"
                                )}
                                placeholder={t("URL rede social")}
                                onChange={(value) => {
                                    if (error === 4) setError(-1);
                                    setUrlContractor(value);
                                }}
                            />
                        </div>

                        <Button
                            icon="plus"
                            color="success"
                            text={t("Adicionar perfil")}
                            onClick={() => {
                                setButtonClicked(0);
                                addContractor();
                            }}
                            loading={buttonClicked == 0 && objCampaign.status == "loading"}
                        />
                    </div>

                    {/* error empty */}
                    {error == 5 && (
                        <p className="text-empty">{t("Adicione ao menos um contratante para poder prosseguir")}</p>
                    )}

                    {campaign.contractors.length > 0 && (
                        <div className="container-table">
                            <ContractorTable
                                contractors={campaign.contractors}
                                deleteContractor={async (id) => {
                                    rmvContractor(campaign.id, id).then(() => {
                                        dispatch(removeContractor(id));
                                    });
                                }}
                            />
                        </div>
                    )}
                </>
            }
            footer={
                <CardFooter
                    loading={objCampaign.status == "loading"}
                    onBack={() => dispatch(changeStep(objCampaign.index - 1))}
                    onNext={() => isValid()}
                />
            }
        />
    );
};

export default StepContractors;
