import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch, useHistory, useRouteMatch, useLocation } from "react-router-dom";
//
import { useDispatch, useSelector } from "react-redux";
import { clearStatusRegistration, clearsSatusRegisterProvider, fetchPlanDetails, getContractedPlan, profile, registerProvider, selectRegister, setPlanId, updStep } from "./RegisterSlice";
import { changeObjAlert, showAlert } from "../../common/components/Alert/alertSlice";
import StateStatus from "../../utils/stateStatus";
//
// import Billing from "./steps/Billing/Billing";
import Payment from "./steps/Payment/Payment";
import Registration from "./steps/Registration/Registration";
import ButtonAuthGoogle from "../../common/components/ButtonAuthGoogle/ButtonAuthGoogle";
//
import logo from "../../common/assets/icons/HypoLake.svg";
import purpleSmoke from "../../common/assets/images/purple-smoke.svg";
import greenSmoke from "../../common/assets/images/green-smoke.svg";
//
import { AirUtils } from "../../utils/utils";
import "./Register.scss";
import Loading from "../../common/components/Loading/Loading";
import { LANGUAGES } from "../Login/Login";
import i18n from "../../languages/i18n";

const Register = () => {
    const { t } = useTranslation();
    const { path } = useRouteMatch();
    const { search } = useLocation();
    //
    const query = new URLSearchParams(search);
    const utils = new AirUtils();
    //
    const history = useHistory();
    const dispatch = useDispatch();
    //
    const token = window.localStorage.getItem("access_token");
    //
    const registerState = useSelector(selectRegister);
    // 
    const statusRegistration = registerState.statusRegistration;
    //
    const steps = [
        { name: t("cadastro"), path: "" },
        // { name: t("endereço"), path: "billing" },
        { name: t("pagamento"), path: "payment" },
    ];

    const goTo = (index, route) => {
        if (index !== registerState.stepActive && ((index > registerState.stepActive && registerState.maxStep > index) || index < registerState.stepActive)) {
            dispatch(updStep(index));
            history.push(`${path}/${route}`);
        }
    };

    const verifyStep = () => {
        // if (window.location.href.indexOf("/billing") !== -1) {
        //     dispatch(updStep(1));
        // } else
        if (window.location.href.indexOf("/payment") !== -1) {
            dispatch(updStep(1));
        } else {
            dispatch(updStep(0));
        }
    };

    const alert = (type, title, text, question, cancelText, okText, onOk) => {
        dispatch(
            changeObjAlert({
                show: true,
                type: type,
                title: title,
                text: text,
                okText: okText,
                question: question,
                cancelText: cancelText,
                onCancel: () => {
                    dispatch(showAlert(false));
                },
                onOk: () => {
                    onOk;
                },
            })
        );
    };

    useEffect(() => {
        verifyStep();
    }, []);

    useEffect(() => {
        if (registerState.statusPlanDetails === StateStatus.idle) {
            let planId = query.get("plan_id") || localStorage.getItem("plan_id");

            if (planId) {
                localStorage.setItem("plan_id", planId);
                dispatch(setPlanId(planId));
                dispatch(fetchPlanDetails(planId));
                return;
            }

            const planUrl = LANGUAGES[i18n.language] || "https://hypolake.com/plan/";
            window.href = planUrl;
        }
    }, []);

    useEffect(() => {
        if (token) {
            dispatch(profile());
            dispatch(getContractedPlan());
            return;
        }
    }, [token]);

    useEffect(() => {
        const token = localStorage.getItem("access_token");

        if (token) {
            if (statusRegistration === StateStatus.succeeded && registerState.data.id && !registerState.data.paymentAuthorized) {
                dispatch(clearStatusRegistration())

                history.replace("/register/payment");
            } else if (registerState.data.paymentAuthorized) {
                history.replace("/");
            }
        }
    }, [statusRegistration]);

    useEffect(() => {
        if (registerState.statusRegisterProvider === StateStatus.failed) {
            alert("error", t("erro"), t("A conta já está cadastrada ou houve um erro em sua geração"));
            dispatch(clearsSatusRegisterProvider());
        }
    }, [registerState.statusRegisterProvider]);

    history.listen(() => {
        verifyStep();
    });

    return (
        registerState && (
            <>
                <div className="register-page">
                    <div className="register-aside">
                        <header>
                            <img
                                src={logo}
                                className="logo"
                                onClick={() => {
                                    window.localStorage.removeItem("access_token");
                                    window.localStorage.removeItem("refresh_token");
                                    history.replace("/login");
                                }}
                            />
                        </header>

                        <img src={greenSmoke} className="smoke-img green-smoke" alt="green smoke" />

                        <img src={purpleSmoke} className="smoke-img purple-smoke" alt="purple smoke" />

                        <section className="hypo-text-about">
                            <p className="title">{t("Mais comodidade e profundidade sobre as suas análises")}</p>

                            <p className="text">
                                {t(
                                    "A única plataforma do mercado que possibilita a integração do Google Analytics. Com isso você consegue obter dados e insights da performance dos influenciadores em sua campanha e o retorno em suas vendas."
                                )}
                            </p>
                        </section>
                    </div>

                    <div className="register-content">
                        <h1 className="register-title">{t("Dados de Cadastro")}</h1>
                        <p className="register-subtitle">{t("Preencha os dados abaixo para realizar o cadastro")}</p>

                        <div className="container-steps">
                            <div className="line">
                                <div className={`line-colored w-${registerState.stepActive}`}></div>
                            </div>

                            <div className="steps">
                                {steps.map((item, i) => (
                                    <div
                                        key={item.path}
                                        role="presentation"
                                        onClick={() => {
                                            goTo(i, item.path);
                                        }}
                                        className={`step ${registerState.stepActive === i ? "atual" : ""} ${registerState.stepActive >= i ? "complet" : ""} ${
                                            registerState.stepActive !== i && registerState.maxStep > i ? "cursor-pointer" : ""
                                        }`}
                                    >
                                        {registerState.stepActive > i ? <i className="icon icon-checkmark step-num" /> : <div className="step-num">{i + 1}</div>}
                                        <span className={`step-title ${registerState.stepActive === i ? "atual" : ""}`}>{item.name}</span>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {registerState.planDetails && Object.keys(registerState.planDetails).includes("currency") && (
                            <div className="plan-price">
                                <span className="text-plan">{registerState.planDetails ? registerState.planDetails.label : "-"}</span>
                                <span className="text-price">
                                    ({registerState.planDetails.currency}){" "}
                                    {utils.buildValue("currency", registerState.planDetails ? registerState.planDetails.value : 0, 0, 2, registerState.planDetails.currency)}
                                </span>
                            </div>
                        )}

                        {registerState.statusProfile === StateStatus.loading && (
                            <div className="loading-profile">
                                <Loading />
                            </div>
                        )}

                        {(registerState.statusProfile === StateStatus.succeeded || registerState.statusProfile === StateStatus.idle) && (
                            <>
                                {/* google auth */}
                                {!registerState.data.id && registerState.stepActive < 1 && (
                                    <ButtonAuthGoogle
                                        onAlert={alert}
                                        text="Cadastrar com o google"
                                        username={registerState.data.name + " " + registerState.data.surname}
                                        isConcluded={registerState.statusRegisterProvider === StateStatus.succeeded}
                                        isLoading={registerState.statusRegisterProvider === StateStatus.loading}
                                        onSend={(data) => dispatch(registerProvider(data))}
                                    />
                                )}

                                <Switch>
                                    <Route exact path={path} component={Registration}></Route>
                                    {/* <Route path={`${path}/billing`} component={Billing}></Route> */}
                                    <Route path={`${path}/payment`} component={Payment}></Route>
                                </Switch>
                            </>
                        )}
                    </div>
                </div>
            </>
        )
    );
};

export default Register;
