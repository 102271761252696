const pt = {
    // MULTIPLES
    tiktok: "TikTok",
    Tiktok: "TikTok",

    // LABELS - CHARTS
    total_posts: "Total de posts",
    total_posts_per_hashtags: "Total de posts por hashtags",
    total_interactions: "Total de interações",
    interactions: "Total de interações",
    engagement_rate: "Taxa de engajamento",
    investment: "Investimento",
    base: "Soma das bases",
    single_base: "Base",
    sum_of_bases: "Soma das bases",
    cost_per_interaction: "Custo por interação",
    airscore: "Airscore",
    publications: "Publicações",
    reach_rate: "Taxa de alcance",
    retention_rate: "Taxa de retenção",
    exit_rate: "Taxa de saída",
    cost_per_interaaction: "Custo por interação",
    clicks: "Nº de cliques",
    number_clicks: "Nº de cliques",
    cost_per_click: "Custo por clique",
    conversion_rate: "Taxa de conversão",
    conversions: "Conversões",
    cost_per_conversion: "Custo por conversão",
    roi: "ROI",
    videos_hired: "Vídeos contratados",
    feed_hired: "Feed contratados",
    stories_hired: "Stories contratados",
    best_performance: "Melhor desempenho",
    total_post_hashtags: "Total de post por hashtags",
    interactions_total: "Total de interações",
    gender: "Gênero",
    device: "Dispositivo",
    top_age: "Faixa etária",
    top_country: "Páis",
    male: "Homem",
    female: "Mulher",
    android: "Android",
    ios: "Ios",
    average_video_completion_rate: "Taxa de conclusão do vídeo",
    video_completion_rate: "Taxa de conclusão de vídeo",

    // LABELS - POSTS
    views: "Visualizações",
    likes: "Curtidas",
    dislikes: "Descurtidas",
    "like-heart1": "Curtidas",
    comments: "Comentários",
    shares: "Compartilhamentos",
    pins: "Pins",

    // LABELS - SUMMARY
    active_campaigns: "Total de campanhas ativas",
    total_investment: "Total de investimento",
    mean_investment: "Média de Investimento",
    influencers: "Total de influenciadores",
    mean_influencers: "Média de influenciadores",
    total_conversions: "Total de conversões",
    posts: "Total de publicações",
    stories: "Stories",
    mean_impressions: "Média de impressões",
    average_airscore: "Airscore médio",
    mean_engagement: "Média de engajamento",
    mean_cpi: "Média de CPI",
    connected_profiles: "Total de perfis conectados",
    //
    page_tracks: "Rastreamento de páginas",
    tracks: "Rastreamento de influenciadores",
    ga_traffic: "Total tráfego GA",
    mean_traffic: "Média tráfego campanha",
    total_clicks: "Total de cliques",
    cost_per_traffic: "Custo por tráfego",
    //
    reach_potential: "Potencial de alcance",
    impressions: "Total de impressões",
    reactions: "Reações (engajamento)",
    traffic_link: "Tráfego bruto + link encurtado",
    content_estimate: "Estimativa de conteúdo",
    average_views: "Média de visualizações",
    reach: "Alcance",

    // SUMMARY - CAMPAIGN PREVIEW
    tooltip_total_post_hashtags: "Quantidade total de posts em relação as hashtags da  campanha.",
    tooltip_interactions_total: "Total de interações geradas nos posts da campanha publicados pelos Influenciadores.",
    tooltip_engagement_rate: "Porcentagem média em relação a todas as hashtags adicionadas na campanha",
    tooltip_investment:
        "Soma do investimento que você adicionou para cada Influenciador.ma do investimento que você adicionou para cada Influenciador.",
    tooltip_base:
        "Soma da audiência de todos os Influenciadores. Sabemos que dificilmente isso será alcançado sem investimento em média",
    tooltip_feed_hired: "Quantidade de publicações contratadas para o feed do influenciador",
    tooltip_stories_hired: "Quantidade de publicações contratadas de stories do influenciador",
    tooltip_clicks: "É a soma de todos os cliques nos links relacionados a  campanha.",
    tooltip_cost_per_click: "É o custo por visita de cada perfil contratado na campanha",
    tooltip_conversion_rate: "Porcentagem total de vendas geradas pela campanha.",
    tooltip_roi: "É o retorno de investimento total que a campanha trouxe.",

    // SUMMARY - MY CAMPAIGNS
    tooltip_active_campaigns: "Número total de campanhas em andamento.",
    tooltip_total_investment: "Soma de investimento de todas as campanhas.",
    tooltip_mean_investment: "Investimento total de todas as campanha ativas.",
    tooltip_influencers: "Soma de todos os perfis nas campanhas.",
    tooltip_mean_influencers: "Total de perfis dividido por campanha.",
    tooltip_impressions: "Soma de impressão dos posts + stories.",
    tooltip_mean_impressions:
        "Impressão dividido por alcance (post e Stories), depois faremos a média total.  Aqui temos que fazer uma taxa por influenciador e depois fazemos uma média total.",
    tooltip_mean_engagement: "É a média de interações das publicações dos perfis.",
    tooltip_mean_cpi: "Custo Por Interação: Média por interação com as publicações dos perfis das campanhas.",
    tooltip_total_conversions: "Total de vendas geradas pela campanha.",
    tooltip_connected_profiles: "Quantidade de perfis conectados (liberou o connect) nas campanhas.",

    // SUMMARY - TRAKING LINKS
    tooltip_page_tracks: "Quantidade de página linkadas com o Google analytics.",
    tooltip_tracks: "Quantidade de influenciadores com rastreamento de link ativo pela ferramenta.",
    tooltip_ga_traffic: "A soma de todos os tráfegos gerada pela link de Google Analytcs.",
    tooltip_mean_traffic: "Soma de todos os tráfegos diários pelo total de campanha.",
    tooltip_total_clicks: "Soma de todos os clicks no encurtador dos perfis.",
    tooltip_cost_per_traffic: "É o custo de todos os tráfegos gerada pela link de Google Analytcs.",

    // CREATE CAMPAIGN - INFLUENCERS
    "Creator must have a minimum of 10000 followers": "O criador deve ter no mínimo 10.000 seguidores",
    "Creator must have at least 1000 video views in last 30 days": "O criador deve ter pelo menos 1.000 visualizações de vídeo nos últimos 30 dias",
    "Creator must have at least 3 posts in last 30 days": "O criador deve ter pelo menos três postagens nos últimos 30 dias",
    "Creator must be setup as a Personal Account (not Business Account)": "A conta do criador está configurada como uma conta pessoal (não como conta comercial)",


    // CREATE CAMPAIGN TIKTOK - AUDIENCE
    Audiência: "Audiência",
    country: "País",
    age: "Idade",
    undefined: "Indefinido",
    //
    arts_crafts: "Artes e ofícios",
    automobiles_transport: "Automóveis e transporte",
    drink: "Bebê",
    beauty: "Beleza",
    clothing_accessories: "Roupas e acessórios",
    education_training: "Educação e formação",
    electronics: "Eletrônicos",
    finance_investments: "Finanças e Investimentos",
    food_drinks: "Comidas e bebidas",
    games: "Jogos",
    health_wellness: "Saúde e bem-estar",
    //
    fashion_accessories: "Acessórios da moda",
    physical_activities_health: "Atividades físicas e saúde",
    animals: "Animais",
    beauty_style: "Beleza e estilo",
    comedy: "Comédia",
    food: "Comida",
    sports: "Esportes",
    do_it_yourself: "Faça você mesmo e truques para o cotidiano",
    motivation_guidance: "Motivação e orientação",
    talents: "Talentos",

    // CREATE CAMPAIGN TIKTOK - INFLUENCER

    // CREATE CAMPAIGN TIKTOK - SAVE
    range: "Alcance",
    video_views: "Vizualizações de vídeo",

    // API RETURNS
    "E-mail is already registered": "E-mail já registrado!",
    "The influencer is not connected to your account": "O influenciador ainda não deu o conect em sua conta!",
    "Invalid password": "Senha inválida!",
    "To receive site/store metrics, is necessary to select at least one goal":
        "Para receber as métricas do site/loja, é necessário selecionar pelo menos uma meta!",
    connect: "Conecte",
    actions: "Ações",
};

export default pt;
