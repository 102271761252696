const en = {
    // MULTIPLES
    Desempenho: "Performance",
    Ruim: "Bad",
    Médio: "Medium",
    Bom: "Good",
    Voltar: "Back",
    Próximo: "Next",
    de: "-",
    e: "and",
    "Solicitar conecte": "Request connect",
    "Conecte autorizado": "Connect authorized",
    Campanhas: "Campaigns",
    rede: "network",
    influenciadores: "influencers",
    "influenciadores encontrados": "influencers found",
    "redes socias": "social networks",
    Alcance: "Reach",
    "DD/MM/YYYY": "MM/DD/YYYY",
    tiktok: "TikTok",
    Tiktok: "TikTok",

    // LOGIN
    "Não foi possível fazer o login. Favor usar uma conta existente!":
        "Unable to login. Please use an existing account!",
    "E-mail": "Email",
    "E-mail inválido": "Invalid email",
    "A senha precisa ter no mínimo 8 caracteres contendo":
        "The password must be at least 8 characters long and contain",
    "letra maiúscula, letra minúscula, caracter especial e número":
        "uppercase letter, lowercase letter, special character and number",
    Senha: "Password",
    "Informe sua senha": "Inform your password",
    "Esqueci a senha": "Forgot the password",
    Entrar: "Log in",
    "Cadastrar-se": "Sign up",
    "Fazer login com o google": "Sign in with google",
    "Por que conectar com Google Analytics?": "Why connect with Google Analytics?",
    "USAR GOOGLE": "USE GOOGLE",

    // REGISTER
    cadastro: "register",
    endereço: "address",
    pagamento: "payment",
    "Dados de Cadastro": "Registration data",
    "Preencha os dados abaixo para realizar o cadastro": "Fill in the details below to register",
    Plano: "Plan",
    "A única plataforma do mercado que possibilita a integração do Google Analytics. Com isso você consegue obter dados e insights da performance dos influenciadores em sua campanha e o retorno em suas vendas.":
        "The only platform on the market that allows the integration of Google Analytics. With this you can obtain data and insights on the performance of influencers in your campaign and the return on your sales.",
    "Mais comodidade e profundidade sobre as suas análises": "More convenience and depth about your analytics",
    "Clicando no botão acima você concorda com nossos": "By clicking the button above you agree to our",
    "Fazer login": "Login",
    "E-mail de recuperação": "Recovery email",
    "Cadastrar com o google": "Register with google",

    // "$": "$",

    // REGISTER - CONTRACT
    Contrato: "Contract",
    "Declaro que li e concordo com os termos e condições de uso":
        "I declare that I have read and agree to the terms and conditions of use",

    // REGISTER - BILLING
    CEP: "Zip code",
    "CEP obrigatório!": "Mandatory zip code",
    "Informe seu CEP": "Inform your postal code",
    Cidade: "City",
    "Cidade Obrigatória!": "Mandatory city",
    "Informe sua cidade": "Inform your city",
    País: "Country",
    "País Obrigatório!": "Mandatory country",
    "Informe seu país": "Inform your country",
    Endereço: "Address",
    "Endereço Obrigatório!": "Address Required!",
    "Informe seu endereço": "Inform your address",
    "Continuar compra": "Continue purchase",

    // REGISTER - PAYMENT
    "Cupom de desconto": "Discount coupon",
    "Informe o cupom de desconto": "Enter the discount coupon",
    "Clicando no botão abaixo você concorda com nossos": "By clicking the button above you agree to our",
    "termos de uso e política de privacidade": "terms of use and privacy policy",
    "Receba dicas e novidades da HypoLake": "Get tips and news from HypoLake",
    "Aguarde, processando pagamento.": "Wait, processing payment.",
    "Cupom aplicado com sucesso": "Coupon applied successfully",
    "Cupom inválido": "Invalid coupon",
    "Valor do desconto": "Discount amount",
    "Valor total": "Total value",

    // REGISTER - REGISTRATION
    "Minimo 6  caracteres;": "Minimum 6 characters;",
    "Letras maiúscula;": "Capital letters;",
    "Letras minúsculas;": "Small letters;",
    "Pelo menos um numeral;": "At least one numeral;",
    "Pelo menos um caractere especial (@, #, $ e etc);": "At least one special character (@, #, $ and etc);",
    Nome: "Name",
    "Nome inválido!": "Invalid name!",
    "Informe seu nome": "Enter your name",
    Sobrenome: "Surname",
    "Sobrenome inválido!": "Invalid last name!",
    "Informe seu sobrenome": "Enter your last name",
    "Nome da empresa": "Company Name",
    "Nome da empresa inválido!": "Invalid company name!",
    "Informe o nome da empresa": "Provide the company name",
    // "E-mail": "Email",
    "E-mail inválido!": "Invalid email!",
    "Informe seu e-mail": "Your email",
    Telefone: "Phone",
    "Informe seu número de telefone": "Provide your phone number",
    "Telefone inválido!": "Invalid phone!",
    "Configure uma senha": "Choose a password",
    "Senha inválida": "Invalid password",
    "Informe a senha": "Enter the password",
    "Confirmar senha": "Confirm password",
    // "Senha inválida": "Invalid password",
    "As senhas não coincidem": "Passwords don't match",
    "Confirme a senha": "Confirm the Password",
    // "Receba dicas e novidades da HypoLake": "Get tips and news from HypoLake",
    "Sua senha tem que possuir": "Your password must have",

    // HEADER
    "Adicionar marcas e temas": "Add tags and themes",
    "Configurar conta": "Settings",
    Idioma: "Language",
    Sair: "Logout",
    Selecionar: "Select",
    período: "time course",
    "Selecionar período": "Select period",

    // MENU
    "Resumo Campanha": "Campaign Summary",
    "Criar Campanha": "Create Campaign",
    "Links rastreamento": "Tracking links",

    // TRANSLATE
    Português: "Portuguese",
    Inglês: "English",
    Espanhol: "Spanish",
    "Selecione o idioma desejado": "Select the desired language",

    // LABELS

    // LABELS - CHARTS
    total_posts: "Total posts",
    total_posts_per_hashtags: "Total posts by hashtags",
    total_interactions: "Total interactions",
    interactions: "Interactions",
    engagement_rate: "Engagement Rate",
    average_views: "Average Views",
    reach: "Reach",
    investment: "Investment",
    base: "Sum of bases",
    single_base: "Base",
    sum_of_bases: "Sum of bases",
    cost_per_interaction: "Cost per interaction",
    airscore: "airscore",
    publications: "Publications",
    reach_rate: "Reach rate",
    retention_rate: "Retention rate",
    exit_rate: "Exit rate",
    // "cost_per_interaction": "Cost per interaction",
    clicks: "Number of clicks",
    number_clicks: "Number of clicks",
    cost_per_click: "Cost per click",
    conversion_rate: "Conversion rate",
    conversions: "Conversions",
    cost_per_conversion: "Cost per conversion",
    roi: "ROI",
    videos_hired: "Videos Hired",
    feed_hired: "Feed Hired",
    stories_hired: "Stories Hired",
    best_performance: "Best performance",
    total_post_hashtags: "Total post by hashtags",
    interactions_total: "Total interactions",
    gender: "Gender",
    device: "Device",
    top_age: "Age",
    top_country: "Country",
    male: "Male",
    female: "Female",
    android: "Android",
    ios: "Ios",
    video_completion_rate: "Video completion rate",
    country: "Country",
    age: "Age",
    undefined: "Undefined",
    // LABELS - POSTS
    views: "Views",
    page_views: "Page Views",
    likes: "Likes",
    dislikes: "Dislikes",
    "like-heart1": "Likes",
    comments: "Comments",
    shares: "Shares",
    saved: "Saved",
    pins: "pins",
    "Adicionar ou remover post da campanha": "Add or remove post from campaign",

    // LABELS - SUMMARY
    "Resumo das campanhas": "Campaign Summary",
    active_campaigns: "Total active campaigns",
    total_investment: "Total investment",
    mean_investment: "Average investment",
    influencers: "Total influencers",
    mean_influencers: "Average influencers",
    total_conversions: "Total conversion",
    posts: "Total posts",
    stories: "Total stories",
    mean_impressions: "Average impressions",
    average_airscore: "Average airscore",
    mean_engagement: "Average engagement",
    mean_cpi: "Average CPI",
    connected_profiles: "Total connected profiles",
    //
    page_tracks: "Page tracking",
    tracks: "Influencer Tracks",
    ga_traffic: "Total GA traffic",
    mean_traffic: "Average campaign traffic",
    total_clicks: "Total clicks",
    cost_per_traffic: "Cost per traffic",
    //
    reach_potential: "Reach potential",
    impressions: "Total impressions",
    reactions: "Reactions (engagement)",
    traffic_link: "Traffic",
    content_estimate: "Content Estimate",

    // HOME
    Filtrar: "Filter",
    Pesquisar: "Search",
    //
    "Todas as campanhas": "All campaigns",
    "Campanhas ativas": "Active campaigns",
    "Campanhas arquivadas": "Archived campaigns",
    "Data - menor para maior": "Date: smallest to largest",
    "Data - maior para menor": "Date: highest to lowest",
    "Desempenho - melhor para pior": "Performance: better for worse",
    "Desempenho - pior para melhor": "Performance: worse for better",

    // HOME - CAMPAIGN PREVIEW
    "campanha em criação": "campaign in creation",
    Tipo: "Type",
    Objetivo: "Objective",
    "Informações do contratante": "Contractor's information",
    Marca: "Brand",
    "E-mail de contato": "Contractor’s email",
    Contato: "Contact",
    "Nome da marca": "Brand's name",
    "site/loja virtual": "website/virtual store",
    "Você ainda não possui campanhas": "You don't have campaigns yet",
    "A pesquisa por": "The search for",
    "não retornou resultados.": "did not return results.",
    ativa: "active",
    arquivada: "filed",
    inativa: "inactive",
    "Editar campanha": "Edit campaign",
    "Duplicar campanha": "Duplicate campaign",
    "Exportar campanha": "Export campaign",
    "Arquivar campanha": "Archive campaign",
    "Apagar rascunho": "Exclude draft",
    "Deseja realmente apagar este rascunho? (Essa ação não pode ser desfeita)":
        "Do you really want to exclude this draft? (This action cannot be undone",
    "Sim, apagar rascunho": "Yes, exclude draft",
    "Não, manter rascunho": "No, keep draft",
    status: "status",
    campanha: "campaign",
    "em criação": "in creation",
    Período: "Time course",
    rascunho: "draft",
    "Continuar criação": "Continue creation",
    "Analisar campanha": "Analyze campaign",
    // "Exportar campanha": "Export campaign",
    "Campanha arquivada com sucesso!": "Campaign successfully archived!",
    "Campanha exportada com sucesso!": "Campaign exported successfully",
    "Rascunho apagado com sucesso!": "Draft excluded successfully",
    "Deseja realmente arquivar essa campanha? (Essa ação não pode ser desfeita)":
        "Do you really want to archive this campaign? (This action cannot be undone",
    "Sim, arquivar campanha": "Yes, archive campaign",
    "Não, manter campanha": "No, keep campaign",
    "Descrição da campanha": "Campaign Description",
    "Descrição da campanha (visível para o influenciador)": "Campaign description (visible for influencers)",

    // TRANCKING LINK
    "Links de rastreamento": "Tracking links",

    // DETAILS
    // "Editar campanha": "Edit campaign",
    // "Duplicar campanha": "Duplicate campaign",
    // "Exportar campanha": "Export campaign",
    // "Arquivar campanha": "Archive campaign",
    Campanha: "Campaign",
    "período de": "period of",
    tipo: "type",
    objetivo: "objective",
    vendas: "sales",
    "Filtre a campanha por um período específico.": "Filter the campaign by a specific period.",
    //
    "Últimas postagens": "Latest posts",
    "Maior desempenho": "Higher performance",
    "Menor desempenho": "Lower performance",
    "Maior interação": "Greater interaction",
    "Menor interação": "Less interaction",
    //
    "Hashtags oficiais da campanha": "Official campaign hashtags",
    "Sem hashtahgs ou perfil na campanha": "No hashtahgs or profile in the campaign",
    "Performance da campanha em seu site/loja virtual": "Campaign performance on your website/virtual store",
    "Publicações da campanha": "Campaign publications",
    "Nenhum post foi feito pelos influenciadores": "No posts were made by influencers",
    "Nenhum story foi feito pelos influenciadores": "No stories were made by influencers",
    "Nenhum post pendente": "No pending publications",
    "Tabela comparativa de influenciadores": "Comparative table of influencers",
    geral: "general",
    "A campanha não possui influenciadores": "The campaign has no influencers",
    "Ver tabela comparativa": "See comparative table",
    "Performance do contratante no período da campanha": "Contractor performance during the campaign period",
    "Ver todas as publicações do contratante": "View all contractor publications",
    "Histórico de base do contratante no período da campanha": "Contractor base history during the campaign period",
    Primeira: "First",
    Última: "Last",
    "Video aprovado com sucesso": "Video approved successfully",
    "Em instantes a publicação será publicada na rede do influenciador.":
        "In a moment, the publication will be published on the influencer's network.",
    "Comentário enviado com sucesso": "Feedback sent successfully",
    "Agora que você mandou um feedback o perfil poderá aplicar alguns ajustes e enviar o conteúdo novamente.":
        "Now that you've sent feedback, the profile can apply some tweaks and send the content again.",

    // DETAILS - CONTRACTOR TABLE
    contratante: "contractor",
    "conecte contratante": "connect contractor",

    // DETAILS - INFLUENCERS TABLE
    // "rede": "network", -> already exists
    influenciador: "influencer",
    seguidores: "followers",
    cpi: "cpi",
    interações: "interactions",
    "Performance da campanha": "Campaign performance",
    txe: "engagement rate",
    conecte: "plug it in",
    "Nº cliques": "number of clicks",
    "custo cliques": "cost per click",
    "taxa conversão": "conversion rate",
    conversões: "conversions",
    "custo conversão": "conversion cost",
    investimento: "investment",
    "conecte influenciador": "connect influencer",
    "Ver conectes e links": "See connects and links",

    // DETAILS - CARD STORIES
    Data: "Date",
    Visualizações: "Views",
    "Ver todos os dados do story": "See all story data",
    "Ver dados": "View data",
    "Adicionar ou remover story da campanha": "Add or remove story from campaign",

    // DETAILS - CHART
    contratado: "hired",
    entregue: "delivered",
    "Base de seguidores no início da campanha": "Follower base at the beginning of the campaign",
    "Crescimento de seguidores": "Follower growth",
    "Por data": "By date",
    "Por perfil": "By profile",
    "A campanha ainda não possui dados para esse gráfico": "The campaign does not yet have data for this chart",
    "Por interação": "By interaction",
    Geral: "General",
    Feed: "Feed",
    Stories: "Stories",
    "Desempenho das hashtags na campanha": "Campaign hashtag performance",
    "Desempenho dos influenciadores na campanha": "Influencer performance in the campaign",

    // DETAILS - STORIES MODAL
    Engajamento: "Engagement",
    // "Alcance": "Reach",  -> already exists
    Interações: "Interactions",
    Curtidas: "Likes",
    Salvos: "Saved",
    Comentários: "Comments",
    Compartilhamentos: "Shares",
    Impressões: "Impressions",
    Respostas: "Answers",
    Próxima: "Next",
    Reações: "Reactions",
    Saída: "Exit",
    "Taxa de engajamento (TXE)": "Engagement rate (TXE)",
    "Taxa de alcance (TXE)": "Range Rate (TXE)",

    // DETAILS - COMPARATIVE TABLE
    // all -> already exists
    "Perfil conectado": "Connected profile",
    "Gerar link para conexão": "Generate connection link",
    "Copiar link de rastreamento": "Copy tracking link",
    "Gerar link rastreado": "Generate tracked link",
    "Meu link rastreado": "My own tracked link",
    "URL de rastreamento": "Tracking URL",
    "Enviar link para o e-mail do influenciador": "Send link to influencer email",
    "Copiar link do influenciador": "Copy influencer link",
    "Link copiado com sucesso": "Link copied successfully",
    "Convite da Campanha": "TTCM Campaign invitation",
    pending: "Pending",
    active: "Active",
    declined: "Declined",

    // DETAILS - CONTRACTOR POSTS
    "Publicações do contratante no período da campanha": "Contractor's publications during the campaign period",
    "O contratante não fez posts durante o período da campanha":
        "The contractor did not post during the campaign period",

    // DETAILS - INFLUENCERS DETAILS
    "Performance do influenciador na campanha": "Influencer performance in the campaign",
    "Apenas as publicações relacionadas à campanha são contabilizadas": "Only posts related to the campaign are counted",
    "Posts do influenciador": "Influencer posts",
    "Nenhum post foi feito pelo influenciador": "No posts were made by the influencer",
    "Nenhum story foi feito pelo influenciador": "No stories were made by the influencer",

    // DETAILS - CAMPAIGN POSTS
    // "Publicações da campanha": "Campaign publications",
    "Ver todas as publicações da campanha": "See all publications",

    // CONFIGURATION
    Configurações: "Settings",
    Perfil: "Profile",
    Cobrança: "Invoice",
    Pagamentos: "Payments",
    "Gerenciador de perfis": "Profile manager",

    // CONFIGURATION - BILLING
    Fatura: "Invoice",
    "Histórico de pagamento": "Payment history",
    "Veja seu histórico de pagamento e gerencie suas faturas": "View your payment history and manage your invoices",
    "Aguarde o download que está em adamento acabar": "Wait for the download that is in adamento to finish",
    "Sem faturas": "No invoices",
    "Número Fatura": "Invoice Number",
    Valor: "Value",
    "Baixar Fatura": "Download Invoice",

    // CONFIGURATION - PAYMENT
    Pagamento: "Payment",
    mensal: "monthly",
    "plano válido até": "Plan valid until",
    $: "$",
    "Plano contratado": "Current plan",
    "Cancelar plano": "Cancel plan",
    "Cancelamento de plano": "Plan cancellation",
    "Antes de cancelar o plano, leia e selecione os itens abaixo":
        "Before canceling the plan, please read and select the items below",
    "O cancelamento ocorrerá no final do seu ciclo atual de pagamento.":
        "Cancellation will occur at the end of your current payment cycle.",
    "Você perderá o acesso à nossa plataforma, incluindo todos os dados cadastrados.":
        "You will lose access to our platform, including all registered data.",

    // CONFIGURATION - PROFILE
    Cancelar: "Cancel",
    Salvar: "Save",
    Editar: "Edit",
    "Adicione ou edite um perfil": "Add or edit a profile",
    "Informe um nome válido": "Please enter a valid name",
    // "Nome": "Name",
    // "Sobrenome": "Surname",
    "Informe um sobrenome válido": "Please provide a valid last name",
    "Informe um e-mail válido": "Please provide a valid email",
    "Erro ao atualizar e-mail": "Error updating email",
    "Um e-mail de confirmação foi enviado para seu e-mail": "A confirmation email has been sent to your email",
    // "E-mail": "Email",
    "Erro ao atualizar senha": "Error updating password",
    "Senha atualizada com sucesso": "Password updated successfully",
    // "Senha": "Password",
    "Senha atual": "Current password",
    "Informe a senha atual": "Enter current password",
    "Nova senha": "New Password",
    "Informe a nova senha": "Enter the new password",
    "Confirme sua nova senha": "Confirm your new password",
    // "As senhas não coincidem": "Passwords don't match",
    // "Senha inválida": "Invalid password",
    "Criar perfil": "Create profile",
    "Plano cancelado": "Canceled plan",

    // CONFIGURATION - MANAGE PROFILES
    "Adicionar novo perfil": "Add new profile",
    "Perfil excluído com sucesso": "Profile deleted successfully",
    "Erro ao remover perfil. Tente novamente mais tarde": "Error removing profile. Please try again later",
    "Essa ação não pode ser desfeita": "This action cannot be undone",
    "Você realmente deseja excluir o perfil": "Do you really want to delete the profile",

    // CAMPAIGN SUMMARY
    "Total da campanha": "Campaign total",
    "Por campanha": "Per campaign",
    "Últimos 7 dias": "Last 7 days",
    "Últimos 14 dias": "Last 14 days",
    "Últimos 21 dias": "Last 21 days",
    "Últimos 29 dias": "Last 29 days",
    "minhas campanhas": "my campaigns",
    "rastreamento de link": "link tracking",
    "Total de rastreamentos": "link tracking",
    dados: "data",
    "Conectes e Links": "Connects and links",
    // CREATE CAMPAIGN TIKTOK
    Dados: "Data",
    Resumo: "Save",

    // CREATE CAMPAIGN TIKTOK - INTEGRATION
    "Integração com o TikTok": "TikTok Integration",
    "Agora você precisa fazer o Connect com o TikTok": "Now connect your TikTok account.",
    "Para que você consiga criar uma campanha e verificar o desempenho dos perfis, precisamos que faça login com sua conta business do TikTok.":
        "In order for you to be able to create a campaign and check the performance of the profiles, we need you to log in with your TikTok business account.",
    "Ao clicar no botão de login com o TikTok, você será redirecionado para a página e efetuar o login.":
        "By clicking the login button with TikTok, you will be redirected to the page and log in.",
    "Entrar com o TikTok": "Sign in with TikTok",
    "Conecte sua conta do TikTok para poder prosseguir!": "Connect your TikTok account to use!",
    "Solicitar SparkAd": "Request SparkAd",
    "Requisitar autorização para Spark Ad": "Request Spark Ad Authorization",
    "Impulsionamento em andamento": "Applying SparkAd",
    "Aguardando confirmação": "Waiting Approval",
    "Aguardando connect": "Waiting Connect",
    "Impusionamento realizado": "SparkAd Applied",
    "Impusionamento recusado": "SparkAd Refused",
    "Solicitar Autorização": "Request Authorization",
    "Escolha por quanto tempo deseja ter autorização para impulsionar esta video:":
        "Choose how much time to boost this video:",
    "7 dias": "7 days",
    "30 dias": "30 days",
    "60 dias": "60 days",
    "360 dias": "360 days",
    "Publicações pendentes": "Pending publications",
    Rejeitar: "Reject",
    Aprovar: "Approve",
    "Envie comentários informando o motivo da publicação ser rejeitada.":
        "Send comments about why the publication was rejected.",
    "Insira seu comentário": "Insert a comment",
    "Deseja aceitar essa publicação": "Do you accept the publication",
    Não: "No",
    "Sim, aceitar publicação": "Yes, accept the publication",

    // CREATE CAMPAIGN TIKTOK - INFORMATION
    // "Informações da campanha": "Campaign Information",
    // "Nome da campanha": "Campaign name",
    // "Informe o nome da campanha!": "Enter the name of the campaign!",
    // "Campanha x": "Campaign x",
    // "Data de início": "Start date",
    // "Data de término": "End date",
    // "Informe o período da campanha!": "Enter the campaign period!",
    Orçamento: "Budget",
    "Escolher tipo de orçamento": "Choose budget type",
    "Selecionar tipo": "Select type",
    Diário: "Diary",
    Estático: "Static",
    Flexível: "Flexible",
    Contínuo: "Continuous",
    Matricial: "Matrix",
    //
    "Insira o valor": "Enter value",
    // "Informe o investimento": "Report investment",
    Revisado: "Revised",
    Tráfego: "Traffic",
    Conversão: "Conversion",
    // "Alcance": "Range",
    // "Visualizações": "Views",
    // "Engajamento": "Engagement",
    "Geração de Lead": "Lead Generation",
    "Catálogo de vendas": "Sales Catalog",
    "Objetivo da campanha": "Campaign objective",
    "Selecione o objetivo que deseja": "Select the objective you want",
    "Selecione o objetivo": "Select objective",
    "Selecionar objetivo": "Select objective",

    // CREATE CAMPAIGN TIKTOK - DATA
    "Vizualizações de vídeo": "Video views",
    Total: "Total",
    Ilimitado: "Unlimited",
    "Informe o valor": "Inform the value",
    "Selecione o tipo de orçamento": "Select budget type",

    // CREATE CAMPAIGN TIKTOK - INFLUENCERS
    "Insira a URL/ID do perfil público do influenciador": "Enter the influencer's public profile URL/ID",
    "URL inválida. Verifique se você já adicionou essa url":
        "Invalid URL. Please check if you have already added this url",
    "Adicione ao menos um influenciador para poder prosseguir": "Add at least one influencer to proceed",

    // CREATE CAMPAIGN TIKTOK - AUDIENCE
    Audiência: "Audience",

    "Tipo de orçamento": "Budget type",
    "Localização - País": "Location - Country",
    "Selecione algum local": "Enter a location",
    "Selecionar locais": "Select locations",
    "Agendamento de frequência": "Frequency Scheduling",
    "Selecionar agendamento": "Select schedule",
    Frequência: "Frequency",
    "Selecionar frequência": "Select frequency",
    "Não mostrar mais que - vezes para o mesmo usuário em - dias.":
        "Do not show more than - times for the same user in - days.",
    Categorias: "Categories",
    "Selecionar categoria": "Select Category",
    Interesses: "Interests",
    "Selecionar interesse": "Select interest",
    "Informe o bid": "Enter the bid",
    "Selecione o agendamento": "Select schedule",
    "Selecione a frequência": "Select frequency",
    "Informe alguma categoria": "Please enter a category",
    "Selecione um parâmetro": "Select a parameter",
    "Informe algum interesse": "Report any interest",
    "Você pode configurar a sua campanha sem uma data de término, mas ela continuará ativa até você pausar a mesma. Ao adicionar uma data de término para sua campanha, ela irá encerrar automaticamente.":
        "You can configure your campaign without an end date, but it will remain active until you pause it. When you add an end date to your campaign, it will automatically end.",
    "Não mostrar mais que": "Not show more than",
    "vezes para o mesmo usuário em": "times for the same user in",
    dias: "days",

    arts_crafts: "Arts and crafts",
    automobiles_transport: "Automobiles and transport",
    drink: "Drink",
    beauty: "Beauty",
    clothing_accessories: "Clothing and accessories",
    education_training: "Education and training",
    electronics: "Electronics",
    finance_investments: "Finance and Investments",
    food_drinks: "Food and drinks",
    games: "Games",
    health_wellness: "Health and wellness",
    //
    fashion_accessories: "Fashion accessories",
    physical_activities_health: "Physical activities and health",
    animals: "Animals",
    beauty_style: "Beauty and style",
    comedy: "Comedy",
    food: "Food",
    sports: "Sports",
    do_it_yourself: "Do it yourself and life hacks",
    motivation_guidance: "Motivation and guidance",
    talents: "Talents",

    // CREATE CAMPAIGN TIKTOK - INFLUENCER
    "Vídeos contratados do influenciador": "Influencer hired videos",
    vídeos: "videos",
    por: "per",
    "Ativo no marketplace": "Active in the marketplace",
    "Inativo no marketplace	": "Inactive in the marketplace ",

    // CREATE CAMPAIGN TIKTOK - SAVE
    range: "Range",
    video_views: "Video Views",
    Localização: "Localization",
    "Bid (valor monetário)": "Bid (monetary value)",

    // --------------------------------------------------------------------

    // CREATE CAMPAIGN
    // "Nome": "Name",
    // "Tipo": "Type",
    "Selecione a rede social que deseja criar uma campanha": "Select the social network you want to create a campaign",
    "Criar campanha de Instagram": "Create Instagram Campaign",
    "Criar campanha de TikTok": "Create TikTok Campaign",

    Contratante: "Contractor",
    "Hashtags e perfis": "Hashtags and profiles",
    Hashtags: "Hashtags",
    // "Objetivo": "Objective",

    "Real brasileiro": "Brazilian real",
    "Dólar estadunidense": "US Dollar",
    Euro: "Euro",
    Integração: "Integration",
    "Site / Loja virtual": "Website / Online Store",
    "Adicionar influenciadores": "Add influencers",
    "Rastrear links": "Track links",
    // "Salvar": "To save",
    "Preencha o passo": "Fill in the step",
    "para poder prosseguir": "to be able to proceed",
    "Erro na criação da campanha": "Campaign creation error",
    "Entre em contato conosco para resolvermos o problema.": "Please contact us to resolve the issue.",
    "Criar campanha": "Create campaign",
    // "Editar campanha": "Edit campaign",

    // CREATE CAMPAIGN - CONTRACTOR
    "Adicione o perfil social do contratante da campanha": "Add the social profile of the campaign contractor",
    "Insira a URL completa do perfil do contratante (público)": "Enter the full URL of the contractor profile (public)",
    "Informe o nome": "Provide brand's name",
    "Insira o nome da marca": "Fill with brand's name",
    "Email de contato": "Contact e-mail",
    "Informe o e-mail": "Provide a contact e-mail",
    "Insira o e-mail do contato": "Fill with a contact e-mail",
    "Nome de contato (Opcional)": "Contact's name (Optional)",
    "Informe o contato": "Provide the contact's name",
    "Insira nome para contato": "Fill with the contact's name",
    "Número do contato (Opcional)": "Contact's phone number (Optional)",
    "Informe o telefone": "Provide the contact's phone number",
    "Insira número de telefone": "Fill with the contact's phone number",
    "URL inválida ou perfil/fanpage não é pública. Verifique se você já adicionou essa url":
        "Invalid URL or profile/fanpage is not public. Check if you already added this url",
    "URL rede social": "Social network URL",
    "Adicionar perfil": "Add profile",
    "Buscar influenciador": "Search influencer",
    "Adicione ao menos um contratante para poder prosseguir": "Add at least one contractor to proceed",
    "Salvar e voltar": "Save and back",
    "Salvar e avançar": "Save and next",

    // CREATE CAMPAIGN - INFLUENCERS
    "Adicionar influenciador": "Add influencer",
    "Importar influenciadores": "Import influencers",
    "Baixar influenciadores": "Download influencers",
    "Adicione ao menos um influenciador para poder prosseguir!": "Add at least one influencer to proceed!",
    "Cachê ($)": "Cachet ($)",
    Permuta: "Exchange",
    "Insira a URL completa do perfil público do influenciador": "Enter the full URL of the influencer's public profile",
    // "URL inválida ou perfil/fanpage não é pública. Verifique se você já adicionou essa url": "Invalid URL or profile/fanpage is not public. Check if you have already added this url",
    "Posts contratados do influenciador": "Influencer hired posts",
    "Reels contratados do influenciador": "Influencer hired Reels/videos",
    "O criador deve ter no mínimo 10.000 seguidores": "Creator must have a minimum of 10000 followers",
    "O criador deve ter pelo menos 1.000 visualizações de vídeo nos últimos 30 dias": "Creator must have at least 1000 video views in last 30 days",
    "O criador deve ter pelo menos três postagens nos últimos 30 dias": "Creator must have at least 3 posts in last 30 days",
    "O criador deve ser configurado como uma conta pessoal (não como conta comercial)": "Creator must be setup as a Personal Account (not Business Account)",
    "Informe a quantia": "Inform the amount",
    "Quantidade contratada": "Contracted quantity",
    Investimento: "Investment",
    "Informe o investimento": "Report the investment",
    "Stories contratados do influenciador": "Hired Stories from the Influencer",
    "Valor total pago para o influenciador fazer as publicações da campanha.":
        "Total amount paid for the influencer to publish the campaign.",
    "Quantidade de post que o influenciador foi contratado para fazer na campanha.":
        "Number of posts that the influencer was hired to make in the campaign.",
    "Quantidade de stories que o influenciador foi contratado para fazer na campanha.":
        "Number of stories that the influencer was hired to do in the campaign.",
    // "Informe a quantia": "Inform the amount",
    // "Quantidade contratada": "Contracted quantity",
    "Investimento total": "Total investment",
    "Tipo de contrato": "Contact type",

    "Insira uma lista para inclusão dos influenciadores": "Enter a list for inclusion of influencers",
    "Escolha um arquivo válido": "Choose a valid file",
    "Escolha um arquivo": "choose a file",
    // "Salvar": "To save",
    Procurar: "Find",
    "Orientação importante": "important guidance",
    "Para importar influenciadores siga os passos abaixo.": "To import influencers follow the steps below.",
    "1º passo - Baixar o nosso modelo em Excel.": "1st - Download our template in Excel.",
    "2º passo - Habilite a edição no Excel, preencha as informações corretamente e salve o arquivo.":
        "2nd step - Enable editing in Excel, fill in the information correctly and save the file.",
    "3º passo - Selecione o arquivo clicando em “procurar”.": "3rd step - Select the file by clicking on “browse”.",
    "4º passo - Importe para a campanha clicando em “enviar”.":
        "4th step - Import to the campaign by clicking on “send”.",
    "Baixar modelo": "Download template",
    "Influenciadores adicionados": "Added influencers",
    Enviar: "Send",
    Conectado: "Connected",
    "Aguardando entrada no TTCM": "Waiting TTCM invite accept",
    "Solicitar Conecte": "Request connect",
    Conecte: "Connect",
    "Link de rastreiamento": "Tracking Link",

    // CREATE CAMPAIGN - INTEGRATION GOOGLE
    "Sua conta do Google foi conectada com sucesso!": "Your Google account has been successfully connected!",
    "Erro ao fazer login com sua conta do google!": "Error logging in with your google account!",
    "Integração com o Google Analytics": "Integration with Google Analytics",
    "Todas as permissões são necessárias, por favor verifique e marque todas as caixas de seleção":
        "All permissions required. Please verify and check all boxes.",
    "Para que você consiga mensurar as vendas e conversões geradas por seus influenciadores, precisamos que faça login com sua conta do Google Analytics.":
        "In order for you to be able to measure the sales and conversions generated by your influencers, we need you to log in with your Google Analytics account.",
    "Ao clicar no botão de login com o Google, você será redirecionado para a página da Web onde você precisa selecionar sua conta do Google Analytics. Selecione a conta necessária e dê acesso ao HypoLake":
        "By clicking on the login with Google button, you will be redirected to the webpage where you need to select your Google Analytics account. Select the required account and give access to HypoLake",
    "Fazer login com o Google": "Sign in with Google",
    "Trocar conta": "Switch account",
    "Conecte sua conta Google Analytics para poder prosseguir!": "Connect your Google Analytics account to proceed!",
    "Por que conectar minha conta?": "Why connect my account?",

    // CREATE CAMPAIGN - INTEGRATION NETWORKS
    "Entrar com o Facebook": "Login with Facebook",
    "Entrar com o Instagram": "Login with Instagram",
    "Entrar com o Twitter": "Login with Twitter",
    "Entrar com o Youtube": "Login with Youtube",
    "Integração com as redes sociais": "Integration with social networks",
    "Selecione as redes que deseja adicionar e conecte-se.": "Select the networks you want to add and connect.",
    "Sua conta do": "Your account from",
    "foi conectada com sucesso": "has been successfully connected",
    "Conta conectada com sucesso": "Account successfully connected",
    "Integre ao menos uma rede para porder prosseguir": "Integrate at least one network to be able to proceed",

    // CREATE CAMPAIGN - MENTIONS
    "Hashtags citadas/marcadas nos posts da campanha": "Hashtags cited/tagged in campaign posts",
    "Hashtags e perfis citados/marcados nos posts da campanha": "Hashtags and profiles cited/tagged in campaign posts",
    "Insira abaixo as #hashtags que deseja monitorar no período da campanha":
        "Enter below the #hashtags you want to monitor during the campaign period",
    "Adicione menções para poder prosseguir": "Add mentions so you can proceed",

    // CREATE CAMPAIGN - NAME
    "Informações da campanha": "Campaign information",
    "Nome da campanha interna": "Campaign name (internal control purpose)",
    "Nome da campanha visível para o influenciador": "Campaign name (visible for influencers)",
    "Informe o nome da campanha!": "Enter the name of the campaign!",
    "Campanha x": "Campaign x",
    "Data de início": "Start date",
    "Data de término": "End date",
    "Informe o período da campanha!": "Enter the campaign period!",
    Moeda: "Currency",
    "Selecione o tipo de moeda": "Select currency type",
    "Selecionar moeda": "Select currency",

    // CREATE CAMPAIGN - OBJECTIVE
    "Redes sociais / influenciador": "Social networks / influencer",
    "Selecione redes sociais / influenciador se o seu principal objetivo de campanha for engajamento e awareness em social.":
        "Select social media / influencer if your main campaign objective is social engagement and awareness.",
    "Site / loja virtual": "Website / virtual store",
    "Selecione site / loja virtual se o seu objetivo for tráfego e conversões em site ou loja virtual.":
        "Select Website / Online Store if your goal is website or online store traffic and conversions.",
    "Selecione o objetivo da campanha": "Select campaign objective",
    "Selecione uma opção para criar a campanha ou ambas para cruzar os dados.":
        "Select an option to campaign creation or both for joining data.",
    "Selecione ao menos uma das opções para porder prosseguir":
        "Select at least one of the options to be able to proceed",

    // CREATE CAMPAIGN - SITE
    "Selecionar tudo": "Select all",
    "Comécio eletrônico": "E-commerce",
    Meta: "Goal",
    Metas: "Goals",
    "Informações site/loja virtual": "Information website/virtual store",
    "Insira a URL de domínio do contratante": "Enter the contractor's domain URL",
    "Informe a url de domínio": "Enter the domain url",
    "É o endereço de seu site": "It's your website address",
    "Insira a URL de destino": "Enter the destination URL",
    "Informe a url de destino": "Enter the destination url",
    "Especifique uma URL completa para determinar a página de destino à qual as pessoas serão direcionadas":
        "Specify a full URL to determine the landing page people will be taken to",
    "Integração Site / loja virtual com Google Analytics": "Website / Online Store Integration with Google Analytics",
    "Selecione uma das opções": "Select one of the options",
    "Caso você tenha um e-commerce e tenha o 'comércio eletrônico' devidamente configurado no Google Analytics, selecione Comércio Eletrônico. Caso você tenha como meta a geração de leads ou outros tipos de conversão configurados no Google Analytics, selecione a opção Meta":
        "If you have an e-commerce and have 'e-commerce' properly configured in Google Analytics, select E-commerce. If your goal is lead generation or other conversion types configured in Google Analytics, select the Goal option",
    // "Meta": "Goal",
    "Nome Metas": "Name Goals",
    // "Pesquisar": "To look for",
    "Sem metas": "No goals",
    "Seleciona ao menos uma meta para poder prosseguir": "Select at least one goal in order to proceed",

    // CREATE CAMPAIGN - TRACKING LINK
    "Rastreamento de link do influenciador": "Influencer link tracking",
    "O e-mail foi enviado para o influenciador": "The email was sent to the influencer",
    // "Insira a URL de domínio do contratante": "Enter the contractor's domain URL",
    "Informe uma url de domínio válida!": "Please provide a valid domain url!",
    "Insira o e-mail do influenciador": "Enter influencer email",
    "Informe um e-mail válido!": "Please provide a valid email!",
    "air@exemplo.com": "air@example.com",
    "Enviar URL Encurtada": "Send Shortened URL",
    "Reenviar Link": "Resend Link",
    "Enviar Links": "Send Links",
    "Enviar link": "Send link",
    "Insira o link": "Enter the link",
    "Insira o e-mail": "Enter the email",
    "Rastreamento de link": "Tracked link",
    "Enviar para todos": "Send link for all",

    // CREATE CAMPAIGN - TYPE
    "Criar campanha por hashtags": "Create campaign by hashtags",
    "Campanha contendo apenas as hashtags que deseja, sem precisar adicionar influenciador, valores entre outros.":
        "Campaign containing only the hashtags you want, without having to add influencers, values, among others.",
    "Criar campanha por influenciadores": "Create campaign by influencers",
    "Campanhas contendo informações como: influenciadores, valores, hashtags, analytics site entre outros.":
        "Campaigns containing information such as: influencers, values, hashtags, website analytics, among others.",
    "Selecione o tipo de campanha que deseja criar": "Select the type of campaign you want to create",

    // CREATE CAMPAIGN - SAVE
    "Salvar campanha": "Save campaign",
    "Site / loja": "Website / virtual store",
    "site / loja virtual": "website / virtual store",
    "Redes sociais": "Social networks",
    "redes sociais": "social networks",
    "Período da campanha": "Campaign period",
    "Campanha por influenciadores": "Campaign by influencers",
    "Campanha por hashtags": "Campaign by hashtags",

    "Perfil do contratante da campanha": "Campaign contractor profile",
    "Hashtags e perfis marcados nos posts da campanha": "Hashtags and profiles tagged in campaign posts",
    "Hashtags marcadas nos posts da campanha": "Hashtags tagged in campaign posts",
    Conta: "Account",
    "Informações site / loja virtual": "Information website / virtual store",
    "URL de domínio do contratante": "Contractor domain URL",
    "URL de destino do contratante": "Contractor destination URL",
    "Perfil dos influenciadores da campanha": "Campaign influencers profile",
    "Sua campanha foi criada com sucesso!": "Your campaign has been successfully created!",
    "Sua campanha foi atualizada com sucesso!": "Your campaign has been successfully updated!",
    "Erro ao criar campanha. Tente novamente mais tarde.": "Error creating campaign. Try again later.",

    // SUMMARY - CAMPAIGN PREVIEW
    tooltip_total_post_hashtags: "Total number of posts in relation to campaign hashtags.",
    tooltip_interactions_total: "Total interactions generated on campaign posts published by Influencers.",
    tooltip_engagement_rate: "Average percentage of all hashtags added in the campaign",
    tooltip_investment:
        "Sum of the investment you added for each Influencer.ma of the investment you added for each Influencer.",
    tooltip_base:
        "Sum of the audience of all Influencers. We know that this will hardly be achieved without an average investment",
    tooltip_feed_hired: "Number of posts hired for the influencer's feed",
    tooltip_stories_hired: "Number of contracted publications of influencer stories",
    tooltip_clicks: "This is the sum of all clicks on links related to the campaign.",
    tooltip_cost_per_click: "It is the cost per visit of each profile hired in the campaign",
    tooltip_conversion_rate: "Total percentage of sales generated by the campaign.",
    tooltip_roi: "It is the total return on investment that the campaign brought.",
    "É o desempenho dos posts da campanha em relação ao desempenho médio das postagens do influenciador antes do início da campanha.":
        "Is the performance of the campaign posts relative to the average performance of the influencer's posts before the campaign started.",

    // SUMMARY - MY CAMPAIGNS
    tooltip_active_campaigns: "Total number of campaigns in progress.",
    tooltip_total_investment: "Sum of investment of all campaigns.",
    tooltip_mean_investment: "Total investment of all active campaigns.",
    tooltip_influencers: "Sum of all profiles in campaigns.",
    tooltip_mean_influencers: "Total profiles divided by campaign.",
    tooltip_impressions: "Sum of impression of posts + stories.",
    tooltip_mean_impressions:
        "Impression divided by reach (post and Stories), then we will do the total average. Here we have to do a rate per influencer and then we do a total average.",
    tooltip_mean_engagement: "It's the average number of interactions of the profile posts.",
    tooltip_mean_cpi: "Cost Per Interaction: Average per interaction with campaign profile posts.",
    tooltip_total_conversions: "Total sales generated by the campaign.",
    tooltip_connected_profiles: "Number of connected profiles (connect enabled) in campaigns.",

    // SUMMARY - TRAKING LINKS
    tooltip_page_tracks: "Amount of pages linked with Google analytics.",
    tooltip_tracks: "Number of influencers with active tracked link by the tool.",
    tooltip_ga_traffic: "The sum of all traffic generated by the Google Analytcs link.",
    tooltip_mean_traffic: "Sum of all daily traffic by campaign total.",
    tooltip_total_clicks: "Sum of all profile shortener clicks.",
    tooltip_cost_per_traffic: "It is the cost of all traffic generated by the Google Analytics link.",

    // TIKTOK DISCOVERY
    "Criar nova campanha": "Create new campaign",
    "Adicionar a uma campanha existente": "Add to an existing campaign",
    "Perfil adicionado": "profile added",
    "Erro ao adicionar perfil": "Error adding profile",
    "Selecione uma opção": "Select an option",
    "Selecione a campanha": "Select the campaign",
    Confirmar: "Confirm",
    "Buscar no TikTok": "Search on TikTok",
    "Encontre perfis que combinem com a sua campanha.": "Find profiles that match your campaign.",

    // TIKTOK DISCOVERY - TIKTOKER DETAILS
    Seguidores: "followers",
    Vídeos: "videos",
    "Total de vídeos": "total videos",
    "Média de curtidas": "average likes",
    "Experiência com marcas": "experience with brands",
    Sistema: "System",
    "País da audiência": "audience country",
    "Gênero audiência": "audience genre",
    "Voltar a buscar no TikTok": "Return to search on TikTok",
    "Vídeos do perfil": "Profile videos",

    // TIKTOK DISCOVERY - SEARCH
    Todos: "All",
    Personalizar: "Personalize",
    Especialista: "Specialist",
    Emergente: "Emerging",
    Feminino: "Female",
    Masculino: "Male",
    "Voltar a Adiconar a Campanha": "Back to Add Campaign",
    Engajamentos: "Engagements",
    "País influenciador": "Influencer country",
    "País audiência": "Audience country",
    "Com crescimento acelerado": "With accelerated growth",
    "Perfil com crescimento acelerado": "Accelerated growth profile",
    "Limpar todos": "Clear all",
    Resultados: "Results",
    "Adicionar a campanha": "Add to the campaign",
    "Número de visualizações": "Number of views",
    "Número de seguidores": "Number of followers",
    "de ": "from ",
    "a ": "to",
    "Tente novamente mais tarde": "Try again later",
    "Ordenar por seguidores": "Order by followers",
    "Ordenar por visualizações": "Order by views",
    "Ordenar por engajamento": "Order by engagement",

    // ALERTS
    sucesso: "success",
    Sucesso: "Success",
    erro: "error",
    Erro: "Error",
    ERRO: "ERROR",
    alerta: "alert",
    Alerta: "Alert",
    atenção: "Alert",
    //
    "Link copiado! Através deste link, o contratante poderá se cadastrar no Airscore e assim você terá métricas mais completas sobre sua campanha.":
        "Link copied! Through this link, the contractor will be able to register on Airscore and so you will have more complete metrics about your campaign",
    "Link copiado! Envie este link para o influenciador. Através deste link, o influenciador poderá se cadastrar no Airscore e assim você terá métricas mais completas sobre sua campanha.":
        "Link copied! Send this link to the influencer. Through this link, the influencer will be able to register on Airscore and so you will have more complete metrics about your campaign.",
    "Link copiado! Envie este link para o influenciador. Através deste link, o influenciador poderá compartilhar seu link próprio para medirmos seu desempenho.":
        "Link copied! Send this link to the influencer. Through this link, the influencer will be able to share their own link so that we can measure their performance.",
    "Deseja excluir esse perfil da campanha": "Do you want to delete this profile from the campaign",

    "Notamos que a URL passada está usando UTM SOURCE. É de extrema importâcia que exista um link para cada influenciador, pois os tráfegos que não pertencem a eles serão contabilizados como pertencentes.":
        "We noticed that the URL passed is using UTM SOURCE. It is extremely important that there is a link for each influencer, as traffic that does not belong to them will be counted as belonging.",
    "Deseja continuar usando esse link?": "Do you want to continue using this link?",
    "Não, enviar sem UTM SOURCE": "No, send without UTM SOURCE",
    Sim: "Yes",

    "Sim, remover perfil": "Yes, remove profile",
    "Não, manter perfil": "No, keep profile",
    "Campanha duplicada com sucesso": "Successfully duplicated campaign",
    "E-mail atualizado com sucesso": "Email successfully updated",
    "Não foi possível atualizar seu e-mail. Tente novamente": "Your email could not be updated. Try again",
    "Erro ao arquivar campanha!": "Error on archiving campaign!",
    "Erro ao duplicar campanha!": "Error on duplicating campaign!",
    "Erro ao exportar campanha!": "Error on exporting campaign!",
    "Erro ao apagar rascunho!": "Error on exclude draft",
    "Erro ao criar perfil": "Error on create a new profile",
    "Perfil criado com sucesso": "Profile successfully created ",

    // Countries
    "Emirados Árabes Unidos": "United Arab Emirates",
    Austrália: "Australia",
    Brasil: "Brazil",
    Canadá: "Canada",
    China: "China",
    Alemanha: "Germany",
    Egito: "Egypt",
    Espanha: "Spain",
    França: "France",
    "Reino Unido": "United Kingdom",
    "Hong Kong": "Hong Kong",
    Indonésia: "Indonesia",
    Israel: "Israel",
    Índia: "India",
    Japão: "Japan",
    "Coréia do Norte": "North Korea",
    Malásia: "Malaysia",
    Filipinas: "Filipines",
    Rússia: "Russia",
    "Arábia Saudita": "Saudi Arabia",
    Singapura: "Singapore",
    Tailândia: "Tailand",
    Turquia: "Turkey",
    Taiwan: "Taiwan",
    Vietnã: "Vietnam",
    "Estados Unidos": "United States",
    "A conta do google não está cadastrada": "Google account not registered",
    "A conta do google ainda não está cadastrada na nossa plataforma, faça o registro para continuar.":
        "Your google account is not registered on our platform, sign-up to continue",
    "Deseja fazer o cadastro?": "Sign up?",
    Verificando: "Verifying",
    "Limite de campanhas atingido, atualize seu plano ou remova campanhas.":
        "Campaign limit reached, upgrade your plan or remove campaigns.",
    "Limite de perfis atingido, atualize seu plano.": "Profile limit reached, upgrade your plan.",
    "Expirado - Solicitar Conecte Novamente": "Expired - Request Connect Again",
    "Para acompanhar os dados da sua campanha faça o connect": "To follow your campaign data, connect your account",
    Ou: "Or",
    "copie o link do connect": "copy connect link",
    "e envie para outra pessoa": "and send to another person",
    "Link copiado!": "Link copied!",
    "Connect realizado com sucesso": "Campaign successfully connected",
    "Selecione suas metas para continuar": "Select goals to continue",
    "Selecionar metas": "Select goals",
    "Erro ao processar pagamento. Verifique as informações e tente novamente.":
        "Error while processing payment. Check the information and try again.",
    "Seu pagamento está sendo processado, aguarde...": "Your payment is being processed, please wait...",
    "Pagamento realizado com sucesso! Redirecionando...": "Payment successfully completed! Redirecting...",
    "Voce deve informar a quantidade de pelo menos post, stories ou reels.":
        "You must provide the quantity of at least post, stories or reels.",
    "Bem vindo ao Hypolake. Sua ferramenta de gestão de campanhas de digital influencers. Vamos começar?":
        "Welcome to Hypolake, your digital influencer campaign management tool. Let's start the tour?",
    "Clique no seu nome para ver as configurações.": "Click on your name to see the settings.",
    "Edite seu perfil, controle seus pagamentos, altere idioma ou saia clicando no seu nome e selecionando a melhor opção para você.":
        "Edit your profile, control your invoices, change languages and log out by clicking on your name and selecting the best option for you.",
    "Clique em criar campanha.": "Click in Create Campaigns.",
    "Crie a melhor campanha para você passando por todos os passos da página de criação de campanha.":
        'Create the best campaign for you by going through all the steps on the "Campaign Creation" page.',
    "Clique no menu Campanhas.": "Open the Campaigns",
    "Acompanhe todas suas campanhas e performance de cada uma na página de campanhas.":
        'Track all your campaigns and the performance of each one in the "Campaigns" page.',
    "Clique para abrir o Resumo das Campanhas": "Click to open the Campaign Summary.",
    'Acompanhe um resumo geral dos resultados das suas campanhas na página "Resumo Campanha"':
        'Track an overall summary of the results your campaigns are generating on the "Campaign Summary" page.',
    "Na busca do TikTok...": "In TikTok Search...",
    "Encontre digital influencers que se encaixem na sua campanha usando o filtro de busca para Tiktok. <br/><br/> Visualize dados como seguidores, média de likes e adicione diretamente perfis às suas campanhas":
        "Find digital influencers that match your campaign using the search filter for TikTok. <br/><br/>View data such as number of followers, average number of likes and add profiles to your campaigns",
    "1. Bem-vindo ao Hypolake": "1. Welcome to hypolake",
    "2. Como criar uma campanha?": "2. How to create a campaign?",
    "3. Criar uma campanha": "3. Create a campaign",
    "4. Como analisar uma campanha?": "4. How to analyze a campaign?",
    "Conheça o Hypolake": "Meeting hypolake",
    "Primeiros passos": "Getting started",
    Pular: "Skip",
    "Crie sua conta no google analytics": "Create your google analytics account",
    "Parece que você não tem uma conta google analytics, por favor verifique sua conta ou crie uma conta no google analytics acessando https://analytics.google.com e tente novamente!":
        "It seems that you don't have a google analytics account, please check your account or create a google analytics account by accessing https://analytics.google.com and try again!",
    "A busca retornará no máximo 30 resultados.": "Search will return a maximum of 30 results.",
    "Dados atualizados em ": "Data updated on ",
};

export default en;
