import React from 'react';
import PropTypes from 'prop-types'
// 
import { Button as AntButton } from 'antd';
// 
import iconTikTok from "../../../common/assets/icons/tiktok.svg"
import iconSendEmail from "../../../common/assets/icons/send_email.svg"
// 
import './Button.scss';
import '../../../common/styles/colors.scss';

const Button = ({
  color,
  width,
  height,
  className,
  icon,
  text,
  type,
  htmlType,
  onClick,
  style,
  styleButton,
  outline,
  loading,
  disabled,
}) => {

  const getIcon = (icon) => {
    if (icon === "tiktok") {
      return <img className="tiktok-icon" alt="tiktok icon" src={iconTikTok} />
    }
    else if (icon === "send-email") {
      return <img className="send-email-icon" alt="send email" src={iconSendEmail} />
    }
    return <i className={`icon icon-${icon}`} />
  }

  return <div style={{ width: width, height: height }}>
    <AntButton
      block={width}
      style={style}
      onClick={onClick}
      loading={loading}
      disabled={disabled}
      htmlType={htmlType}
      type={type ? type : outline ? "" : "primary"}
      className={`button ${(outline ? 'outline-' : 'pattern-') + color} ${!text ? "not-text" : ""} ${className}`}
      icon={icon ? getIcon(icon) : null}
    >
      <span style={styleButton} className="text-button">{text}</span>
    </AntButton>

  </div>
}

Button.defaultProps = {
  color: "primary"
}

Button.propTypes = {
  text: PropTypes.any,
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  icon: PropTypes.string,
  type: PropTypes.string,
  htmlType: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  styleButton: PropTypes.object,
  outline: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,

}

// ----------------

const OutlinedGreyButton = ({ children, onClick = () => { } }) => {
  return <button className='outlined-grey-button' onClick={onClick}>{children}</button>
}

OutlinedGreyButton.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func
}

// ----------------

const BlackButton = ({ children, onClick = () => { }, disabled = false }) => {
  return <button className='black-Button' onClick={onClick} disabled={disabled}>
    {children}
  </button>
}

const RejectButton = ({children, onClick, disabled}) => {
  return (<button disabled={disabled} onClick={onClick} className='reject-button'>{children}</button>)
}

const GreenButton = ({children, onClick, className, disabled}) => {
  return (<button disabled={disabled} onClick={onClick} className={`green-button ${className}`}>{children}</button>)
}

const OutlinedGreenButton = ({children, onClick, className, disabled}) => {
  return (<button disabled={disabled} onClick={onClick} className={`outlined-green-button ${className}`}>{children}</button>)
}

RejectButton.defaultProps = {
  onClick: () => {},
}

GreenButton.defaultProps = {
  onClick: () => {},
  className: ""
}

OutlinedGreenButton.defaultProps = {
  onClick: () => {},
  className: ""
}

RejectButton.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}

GreenButton.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool
}

OutlinedGreenButton.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool
}


BlackButton.propTypes = {
  children: PropTypes.any,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}

export { OutlinedGreyButton, BlackButton, RejectButton, GreenButton, OutlinedGreenButton }

export default Button