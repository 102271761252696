import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeObjAlert, showAlert } from "../../common/components/Alert/alertSlice";
import {
    updateInfluencerDefault,
    changeStep,
    clearCampaign,
    fetchCampaign,
    saveStep,
    updateStatus,
    selectCampaign,
    updateType,
} from "./createCampaignSlice";
//
import Header from "../../common/components/Header/Header";
import Loading from "../../common/components/Loading/Loading";
//
import StepContractors from "./steps/contractors/StepContractors";
import StepInfluencers from "./steps/influencers/StepInfluencers";
import StepMentions from "./steps/mentions/StepMentions";
import StepName from "./steps/name/StepName";
import StepSave from "./steps/save/StepSave";
import StepSite from "./steps/site/StepSite";
import StepTrackingLink from "./steps/trackingLink/StepTrackingLink";
import StepTypeObjective from "./steps/typeObjective/StepTypeObjective";
//
import StateStatus from "../../utils/stateStatus";
import "./CreateCampaign.scss";
import { AirUtils } from "../../utils/utils";

const CreateCampaign = () => {
    const { t } = useTranslation();
    const { id, page } = useParams();
    //
    const history = useHistory();
    const dispatch = useDispatch();
    const airUtils = new AirUtils();
    //
    const objCampaign = useSelector(selectCampaign);
    const current = objCampaign.index;
    const campaign = objCampaign.campaign;
    const status = objCampaign.statusGetCampaign;
    //
    const [firstPage, updateFirstPage] = useState(false);
    const [filteredSteps, setFilteredSteps] = useState([]);

    const steps = [
        campaign.type == "influencers"
            ? { alias: "type", name: t("Objetivo"), component: <StepTypeObjective /> }
            : false,

        { alias: "name", name: t("Nome"), component: <StepName /> },

        { alias: "contractors", name: t("Contratante"), component: <StepContractors /> },

        campaign.type == "influencers"
            ? { alias: "hashtags-profiles", name: t("Hashtags e perfis"), component: <StepMentions /> }
            : { alias: "hashtags", name: t("Hashtags"), component: <StepMentions onlyHash={true} /> },

        // only campaign influencer
        campaign.type == "influencers" &&
            campaign.objectives.includes("site") && {
                alias: "site",
                name: t("Site / Loja virtual"),
                component: <StepSite />,
            },

        // social in objectives
        campaign.type == "influencers" &&
            campaign.objectives.includes("social_media") && {
                alias: "influencers",
                name: t("Influenciadores"),
                component: <StepInfluencers />,
            },
        campaign.type == "influencers" &&
            campaign.objectives.includes("site") &&
            campaign.objectives.includes("social_media") && {
                alias: "link",
                name: t("Rastrear links"),
                component: <StepTrackingLink />,
            },

        { alias: "save", name: t("Salvar"), component: <StepSave /> },
    ].filter((item) => item != false);

    const goTo = async (index) => {
        if (id && !campaign.draft) {
            dispatch(changeStep(index));
        } else {
            if (current < index) {
                for (let i = current; i < index; i++) {
                    const step = filteredSteps[i];

                    // O await é necessário
                    if ((step.alias == "name" && !!campaign.name) || !!campaign.initalDate || !!campaign.initalDate) {
                        current == i && (await dispatch(saveStep({ step: "name", index: i + 1 })));
                    } else if (step.alias == "type") {
                        await dispatch(changeStep(i + 1));
                    } else if (step.alias == "contractors" && campaign.contractors.length > 0) {
                        await dispatch(changeStep(i + 1));
                    } else if (
                        (step.alias == "hashtags-profiles" || step.alias == "hashtags") &&
                        campaign.mentions.length > 0
                    ) {
                        await dispatch(saveStep({ step: "mentions", index: i + 1 }));
                    } else if (step.alias == "objective" && campaign.objectives.length > 0) {
                        current == i && (await dispatch(saveStep({ step: "objective", index: i + 1 })));
                    } else if (
                        (step.alias == "integrationGo" || step.alias == "integrationNw") &&
                        Object.keys(campaign.integrations).length > 0
                    ) {
                        await dispatch(changeStep(i + 1));
                    } else if (
                        step.alias == "site" &&
                        campaign.site.domain &&
                        campaign.site.domain.length > 5 &&
                        campaign.site.domain &&
                        campaign.site.domain.length > 5 &&
                        (campaign.site.type == "goal" ? campaign.site.goals.length > 0 : true)
                    ) {
                        current == i && (await dispatch(saveStep({ step: "site", index: i + 1 })));
                    } else if (step.alias == "influencers" && campaign.influencers.length > 0) {
                        await dispatch(changeStep(i + 1));
                    } else if (step.alias == "link") {
                        await dispatch(changeStep(i + 1));
                    } else {
                        dispatch(
                            changeObjAlert({
                                show: true,
                                type: "error",
                                title: t("erro"),
                                text: `${t("Preencha o passo")} [ ${step.name} ] ${t("para poder prosseguir")}`,
                                onCancel: () => {
                                    dispatch(showAlert(false));
                                },
                            })
                        );
                        return;
                    }
                }
            } else {
                dispatch(changeStep(index));
            }
        }
    };

    const alert = (type, title, text, question, cancelText, okText, onOk) => {
        dispatch(
            changeObjAlert({
                show: true,
                type: type,
                title: title,
                text: text,
                okText: okText,
                question: question,
                cancelText: cancelText,
                onCancel: () => {
                    dispatch(showAlert(false));
                },
                onOk: () => {
                    onOk;
                },
            })
        );
    };

    useEffect(async () => {
        await dispatch(clearCampaign());

        if (id) dispatch(fetchCampaign(id));
    }, [id]);

    useEffect(() => {
        setFilteredSteps(steps.filter((item) => item != false));
    }, [campaign.type, campaign.objectives]);

    useEffect(() => {
        if (firstPage) {
            const step = steps[current].alias;
            history.replace(id ? `/edit-campaign/${id}/${step}` : `/create-campaign/${step}`);
        }
    }, [current]);

    useEffect(() => {
        if (!firstPage && id && page) {
            const step = steps.findIndex((item) => item.alias === page);
            if (step !== -1) {
                goTo(parseInt(step));

                updateFirstPage(true);
            } else {
                goTo(parseInt(0));
            }
        } else {
            updateFirstPage(true);
        }
    }, [status]);

    useEffect(() => {
        if (objCampaign.status === StateStatus.failed) {
            const renderedComponent = (
                <>
                    <p>{objCampaign.errorMsg}</p>
                    {objCampaign.errorItems && (
                        <ul>
                            {objCampaign.errorItems.map((item, index) => {
                                return <li key={`i-${index}`}>{t(item)}</li>;
                            })}
                        </ul>
                    )}
                </>
            );
            alert(
                "error",
                t("erro"),
                objCampaign.errorMsg ? renderedComponent : t("Entre em contato conosco para resolvermos o problema.")
            );

            dispatch(updateStatus());
        }
    }, [objCampaign.status]);

    useEffect(() => {
        const influencerUrl = airUtils.getParameterByName("influencer");

        if (influencerUrl) {
            const url = window.atob(influencerUrl);
            dispatch(updateInfluencerDefault(url));

            setTimeout(() => {
                dispatch(updateType("influencers"));
            }, 300);
        }
    }, []);

    return (
        <div className="cc-page scroll-content background-page">
            <Header
                back={id != null}
                title={t(id ? "Editar campanha" : "Criar campanha")}
                className="home-header"
            ></Header>

            {status === StateStatus.failed && "ERRO"}

            {status === StateStatus.loading && <Loading />}

            {(status === StateStatus.idle || status === StateStatus.succeeded) && (
                <div className="cc-content">
                    <div className="cc-stepper">
                        <div className="stepper">
                            <div
                                className="stepper-line"
                                style={{
                                    width: `${
                                        ((100 * (((current + 1) * 2 - 1) / ((current + 1) * 2))) /
                                            filteredSteps.length) *
                                        (current + 1)
                                    }%`,
                                }}
                            />
                        </div>

                        {/* labels */}
                        <div className="cc-stepper-labels">
                            {filteredSteps.map(
                                (item, key) =>
                                    item && (
                                        <div
                                            key={key}
                                            onClick={() => goTo(key)}
                                            className="cc-stepper-label"
                                            style={{ width: `${100 / filteredSteps.length}%` }}
                                        >
                                            <div className={`check ${current > key ? "show-check" : ""}`} />

                                            <span className="deselect-text">
                                                {" "}
                                                {key + 1}. {item.name}{" "}
                                            </span>
                                        </div>
                                    )
                            )}
                        </div>
                    </div>

                    <div className="cc-steppers">
                        {filteredSteps.length > 0 && filteredSteps[current] && filteredSteps[current].component}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CreateCampaign;
