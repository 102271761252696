import React, { useEffect } from "react";
import { HashRouter, Route, Switch, useHistory } from "react-router-dom";
// 
import { selectRoutes, updatePages } from "./RoutesSlice";
import { useDispatch, useSelector } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
// 
import Home from "../features/Home/Home";
import Login from "../features/Login/Login";
import Register from "../features/Register/Register";
import CreateCampaign from "../features/CreateCampaign/CreateCampaign";
import CampaignSummary from "../features/CampaignSummary/CampaignSummary";
import TrackingLinks from "../features/TrackingLinks/TrackingLinks";
import CampaignDetails from "../features/CampaignDetails/CampaignDetails";
import CampaignPosts from "../features/CampaignDetails/pages/Posts/CampaignPosts";
import ComparativeTable from "../features/CampaignDetails/pages/ComparativeTable/ComparativeTable";
import InfluencerDetails from "../features/CampaignDetails/pages/InfluencerDetails/InfluencerDetails";
import Configuration from "../features/Configurations/Configuration";
import ConfirmEmail from "../features/ConfirmEmail/ConfirmEmail";
import RecoveryPassword from "../features/RecoveryPassword/RecoveryPassword";
import TikTok from "../features/TikTok/TikTok";
import GoogleLogin from "../features/GoogleLogin";

const Routes = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const pages = useSelector(selectRoutes).pages
  // 
  const pagesToGa = ["register", "login", "password/reset", "configuration", "email-update"];

  const buildGaTag = () => {
    const path = window.location.href
    const pageName = path.split("/#")[1].replace(/[^a-zA-Z]/g, " ");

    if (path.includes(pagesToGa) && !pages.includes(pageName)) {
      window.gtag("config", process.env.REACT_APP_GA_TRACKING_ID, {
        page_path: window.location.hash.replace("#", ""),
        page_title: pageName
      })

      dispatch(updatePages(pageName))
    }
  }

  history.listen(() => {
    buildGaTag();
  })

  useEffect(() => {
    buildGaTag();
  }, [])

  return <HashRouter>
    <Switch>
      {/* dashboard */}
      <Route exact path={["", "/", "/#", "/#/:psrefresh_token"]} component={Home} />
      <Route path={"/create-campaign/:page"} component={CreateCampaign} />
      <Route path={"/edit-campaign/:id/:page"} component={CreateCampaign} />
      <Route path="/campaign-summary" component={CampaignSummary} />
      <Route path="/tracking-links" component={TrackingLinks} />
      <Route path="/tiktok-discovery" component={TikTok} />
      {/* dashboard - details */}
      <Route path={["/campaign/:id/posts/:typePost", "/campaign/:id/posts"]} component={CampaignPosts} />
      {/* <Route path={"/campaign/:id/contractor-posts"} component={ContractorPosts} /> */}
      <Route path={"/campaign/:id/comparative-table"} component={ComparativeTable} />
      <Route path={[
          "/campaign/:campaignId/influencer/:airId/:postType", 
          "/campaign/:campaignId/influencer/:airId",
          "/campaign/:campaignId/contractor/:airId/:postType",
          "/campaign/:campaignId/contractor/:airId"
        ]} component={InfluencerDetails} />
      <Route path={["/campaign/:id/:nw", "/campaign/:id"]} component={CampaignDetails} />
      {/* config account */}
      <Route path="/configuration" component={Configuration} />
      <Route path={["/email-update", "/email-update/:token"]} component={ConfirmEmail} />
      {/* login - register- forgot */}
      <Route path={"/password/reset"} component={RecoveryPassword} />
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Route path="/login-google" component={GoogleLogin} />
        <Route path={"/login"} component={Login} />
        <Route path={"/register"} component={Register} />
      </GoogleOAuthProvider>
    </Switch>
  </HashRouter>
}

export default Routes;