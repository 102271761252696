import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
// 
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
// 
import { AirUtils } from "../../../../utils/utils";
// 
import "../Charts.scss";

const ColumnChart = ({ data, className, height, color, nameFormat }) => {
    const airUtils = new AirUtils();

    const [series, setSeries] = useState([]);

    useEffect(() => {
        const newSeries = [];

        data.forEach((data) => {
            const value = typeof data.value === "string" ? parseFloat(data.value) : data.value
            
            newSeries.push([data.label, value])
        })

        setSeries(newSeries);
    }, [data])

    const options = {
        chart: {
            type: "column",
            height: height,
            className: className,
            styles: {
                width: "100%"
            }
        },
        title: {
          text: ""
        },
        yAxis: {
          title: ""
        },
        xAxis: {
            type: 'category',
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            series: {
                color: color
            }
        },
        tooltip: {
            formatter: function () {
                const value = airUtils.buildValue(nameFormat, this.y)  
                return `<div class="high-tooltip">
                    ${this.key}: <b>${value}</b>
                </div>` 
            }
        },
        series: [{
            data: series,
        }],
    };

    return series && <HighchartsReact
        highcharts={Highcharts}
        options={options}
    />
}

ColumnChart.defaultProps = {
    color: "#3BB481", 
    nameFormat: "number", 
}

ColumnChart.propTypes = {
    data: PropTypes.any,
    color: PropTypes.string,
    nameFormat: PropTypes.string,
    height: PropTypes.string,
    className: PropTypes.string,
}

export default ColumnChart;