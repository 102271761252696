import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
//
import Button from "../../../../common/components/Button/Button";
import CheckBox from "../../../../common/components/CheckBox/CheckBox";
//
import purpleSmoke from "../../../../common/assets/images/purple-smoke.svg";
import greenSmoke from "../../../../common/assets/images/green-smoke.svg";
//
import "./Contract.scss";

const ContractEN = ({ onAccept, visible }) => {
    const [checked, updChecked] = useState(false);

    const { t } = useTranslation();

    return (
        visible && (
            <div className="contract-page">
                <img src={greenSmoke} className="smoke-img green-smoke top" alt="green smoke" />

                <img src={greenSmoke} className="smoke-img green-smoke left" alt="green smoke" />

                <img src={purpleSmoke} className="smoke-img purple-smoke right" alt="purple smoke" />

                <img src={purpleSmoke} className="smoke-img purple-smoke bottom" alt="purple smoke" />

                <div className="container-contract" role="presentation">
                    <h2 className="contract-title">
                        {t("Contrato")} {t("HypoLake")}
                    </h2>

                    <div className="contract-text">
                        <br />
                        <p>
                            <b>{t("ONLINE SOFTWARE USE SERVICES AGREEMENT - SAAS")}</b>
                        </p>

                        <p>
                            {t(
                                "On the one hand, AIRSTRIP S/A, a legal entity governed by private law, registered with the CNPJ/MF under No. 13.597.093/0001-25, headquartered at Avenida Eusébio Matoso No. 690, 6th floor, Pinheiros, São Paulo , SP, CEP 05423-000, hereinafter referred to as AIRSTRIP, and on the other, the USER duly identified by filling in the data in the online form available at URL www.hypolake.com/plan, pursuant to law 9.609/98, article 593 et seq. of the Civil Code , have just and agreed as follows"
                            )}
                            :
                        </p>
                        <br />

                        {/*  */}
                        <br />
                        <p>
                            <b>{t("1. OBJECT")}:</b>
                        </p>

                        <p>
                            {t(
                                `1.1 - The purpose of this contract is the provision of non-exclusive services for the use of the online software owned and developed exclusively by AIRSTRIP, called	"HYPOLAKE", whose main purpose is to enable the USER map the performance of users, through the use of public data collected in public profiles of social networks Instagram and data from Google Analytics, as well as the planning and analysis of campaigns, and other tasks/activities that may eventually be integrated into the tool;`
                            )}
                        </p>

                        <p>
                            {t(
                                "1.2 - The contracted plan will be the one that the USER chooses and which are available at the URL www.hypolake.com/planos, with all its specifications, prices, functionalities and other characteristics, being charged the values practiced by AIRSTRIP. The USER declares that before accepting the terms of this contract, he has read and understood all the characteristics of the contracted plan, and that the plan meets his expectations and is in accordance with all the information provided, there being no doubt that it can be used for termination. unfair;"
                            )}
                        </p>

                        <p>
                            {t(
                                "1.3 - The use of the program is exclusive on the USER's premises, and the services may not be assigned, lent, transferred, sold, leased, subleased or subjected to any type of use by third parties that are not part of this contract, under penalty of immediate cancellation. ;"
                            )}
                        </p>

                        <p>
                            {t(
                                "1.4 - Upon contracting, AIRSTRIP may identify the IP address of the connection and the MAC card address of the USER's machine, and will not pass this data on to any third party;"
                            )}
                        </p>

                        {/*  */}
                        <br />
                        <p>
                            <b>{t("2. - USE OF THE PLATFORM")}:</b>
                        </p>

                        <p>
                            {t(
                                "2.1 - For the use of the online program HYPOLAKE, it is mandatory that the USER has an internet connection and a computer that allows access to the world wide web. Under no circumstances will the program or services operate offline. The USER is aware that the performance of the platform HYPOLAKE may be affected depending on the quality of the equipment used and the internet connections of the USER and its agents, as well as the simultaneous use of other programs, without any responsibility on the part of AIRSTRIP. The platform will not work on smartphones;"
                            )}
                        </p>

                        <p>
                            {t(
                                "2.2 - The services and use of the program will be provided in the state they are in at the time of use, and is restricted to the functionalities that are available at the time of online acceptance of this contract, including with regard to the information it has and stores. It is forbidden for the USER to carry out any modification on the platform, even with the purpose of incorporating plugins, improvements, upgrades or updates, which can only be carried out by AIRSTRIP as the holder of economic and intellectual rights;"
                            )}
                        </p>

                        <p>
                            {t(
                                "2.3 - The USER may use the platform and its features for the duration of this agreement, and provided that they are in compliance with the payments of the license installments, using their personal and non-transferable login and password, by accessing the platform through the URL"
                            )}{" "}
                            <a href="https://dash.hypolake.com/#/login" target="_blank" rel="noreferrer">
                                https://dash.hypolake.com/#/login
                            </a>
                            .{" "}
                            {t(
                                "The USER may grant access to the platform environment to other users, using the same login, provided that they are their agents, simultaneous access is prohibited, as well as the assignment of access data. In any case, the USER will be solely responsible for all possible acts performed in these accesses;"
                            )}
                        </p>

                        <p>
                            {t(
                                "2.4 - All data and information collected and/or used with the direct or indirect assistance of the platform, as well as all the content generated as a result of the use of the platform may be used in its business activities, and cannot be distributed or resold to third parties, committing to maintain the confidentiality of the data, unless they are passed on to customers or due to a contract where the data will be used for or as a result of the USER's activities;"
                            )}
                        </p>

                        <p>
                            {t(
                                "2.5 - The USER is entirely and exclusively responsible for the administration, creation and maintenance of their research and full use of the tool. AIRSTRIP will not perform any configuration and/or administration of the tool on behalf of the USER, being limited to providing online support to the program, pursuant to clause 3.1;"
                            )}
                        </p>

                        <p>
                            {t(
                                "2.6 - AIRSTRIP does not guarantee the accuracy or updating of the information available for the use of the platform, since it does not have any interference or control over the data of social networks and Google Analytics of the information posted by users of the aforementioned platforms on which the program and the provision of services is based as they may be outdated, incomplete or incorrect. The object of this contract and the provision of services do not include any guarantees, only the technical and operational validity;"
                            )}
                        </p>

                        <p>
                            {t(
                                "2.7 - The platform may, from the first use,use local storage which facilitates and improves program usage and performance. AIRSTRIP will be able to monitor the USER's account and related reports, in order to optimize the use of the applications, keeping this data always confidential, and which will be used for the continuous improvement of the tool. The program will not store any data from the searches carried out, it is up to it, if it wants, to download this data, nor will it store sensitive data of the USER;"
                            )}
                        </p>

                        <p>
                            {t(
                                `2.8 - It is prohibited to use the platform for the mass sending of unsolicited messages "SPAM", for the promotion of products, services or entities of a commercial nature or not, with or without profit, under penalty of termination of the contract;`
                            )}
                        </p>

                        <p>
                            {t(
                                "2.9 - The data used by the tool are those that refer to users of the platform provided for in clause 1.1, provided that these profiles are open to the public, and that allow the analysis of public data of users. Under no circumstances will the program make intrusions or forced entries into closed or blocked profiles and this data cannot be used for any purpose;"
                            )}
                        </p>

                        <p>
                            {t(
                                "2.10 - All data will be delivered anonymously, statistically and impersonal, and it is not possible to identify users. In addition, it will not be possible, under any circumstances, to obtain personal or sensitive data from users. In relation to digital influencers, only the minimum data that allow their identification in the campaign registered and accompanied by the USER will be provided;"
                            )}
                        </p>

                        {/*  */}
                        <br />
                        <p>
                            <b>{t("3. - TECHNICAL SUPPORT")}:</b>
                        </p>

                        <p>
                            {t(
                                "3.1 - The license to use the platform includes basic and free technical support for the period of validity of this agreement, which will be provided by chat, directly on the platform, on working days and during business hours. The technical support service is limited to solving issues related to the use of platform features. Due to determinations by the Government, extraordinary events or events that prevent the provision of services in person, technical support may suffer interruptions or delays, without this being considered a breach of the contract;"
                            )}
                        </p>

                        <p>{t("3.2 - The use of the platform is fully excluded from support through from the Internet Explorer browser;")}</p>

                        <p>
                            {t(
                                "3.3 - As it is an online software, no program will be installed on the USER's machines, with no possibility of incompatibilities of any kind. the software HYPOLAKE it is compatible with all equipment and operating systems;"
                            )}
                        </p>

                        <p>
                            {t(
                                "3.4 - AIRSTRIP will not be responsible for the creation, maintenance or configuration of searches or advertising campaigns, or of any kind of searches with or without the use of the program;"
                            )}
                        </p>

                        {/*  */}
                        <br />
                        <b>
                            <p>{t("4. - SERVICES MAINTENANCE AND INTERRUPTIONS")}:</p>
                        </b>

                        <p>
                            {t(
                                "4.1 - AIRSTRIP undertakes to make every effort to ensure that the platform is always up to date, secure, free from errors and failures that may lead to the loss of content, data and/or information during the period of use of the HYPOLAKE. However, although AIRSTRIP adopts the measures it has for the maintenance of its systems, occasional interruptions or interruptions, programmed or not, may occur, as in any online service, in which case AIRSTRIP will not be responsible for any loss or damage that the USER may suffer as a result of said events. AIRSTRIP undertakes to communicate in writing the scheduled stops at least 24 hours in advance;"
                            )}
                        </p>

                        <p>
                            {t(
                                "4.2 - Due to its online nature, it is understood that if social networks or Google Analyticsare unavailable, or in case of interruption in internet connections, it is not guaranteed that the platform will be accessible at all times, without this being understood as disrespecting the minimum SLA provided for in clause 4.3. However, even if it is not possible to access the data, all content and data generated in the tool HYPOLAKE will be accessible;"
                            )}
                        </p>

                        <p>
                            {t(
                                "4.3 - AIRSTRIP guarantees that the program's SLA will be at least 90% of the accessible and functional time within the month, non-cumulative, except in the event of a fortuitous event or force majeure, excluding the events defined in clause 4.5;"
                            )}
                        </p>

                        <p>
                            {t(
                                "4.4 - AIRSTRIP may carry out updates, updates, upgrades or improvements to the platform, as well as establish new licensing rules, and the USER, before using them, must read and accept them, being understood as accepted in case of continuity of the use. If you do not agree with the new rules or the updates do not meet your interests, you may terminate the contract without any penalty;"
                            )}
                        </p>

                        <p>
                            {t(
                                "4.5 - In the case of unscheduled shutdowns, AIRSTRIP will make every effort to restore the operation of the program within a period of up to 48 hours from the beginning of inactivity and/or failure, provided that they arise from the operation of the platform itself and are not related to failures of third parties, such as value-added service providers or content providers and social networks themselves;"
                            )}
                        </p>

                        <p>
                            {t(
                                "4.6 - If the inactivity and/or failure of the program lasts for a period longer than 48 hours, the amount exceeded will be calculated proportionally and will be deducted from the price of the license purchased in the next month. If payment is being made in installments, the amounts will be deducted from the installments following the event. If the payment was made in cash, AIRSTRIP will reimburse these amounts in the form of excess credits;"
                            )}
                        </p>

                        {/*  */}
                        <br />
                        <b>
                            <p>{t("5. - AMOUNT AND PAYMENT METHOD")}:</p>
                        </b>

                        <p>
                            {t(
                                "5.1 - For the provision of services and use of the program, the USER will pay AIRSTRIP the amount corresponding to the chosen plan and that is in force at the time of payment, using the table available at the URL"
                            )}{" "}
                            <a href="http://hypolake.com/planos" target="_blank" rel="noreferrer">
                                http://hypolake.com/planos
                            </a>
                            .{" "}
                            {t(
                                "All payments must be made in advance, every 30 days (when applicable), using the credit card informed at the time of contracting, or others that may be implemented within the platform. The amounts will be adjusted annually or at the lowest frequency provided for by law, each year of validity of this instrument, by the annual variation of the IGP-M published by Fundação Getúlio Vargas (FGV) or by another index that may replace it;"
                            )}
                        </p>

                        <p>
                            {t(
                                "5.2 - At any time, the USER may choose another higher plan, paying the difference proportionally (if any). The change to a lower plan will only be possible after the expiry of the contract term in force. If the chosen plan is terminated, there will automatically be a migration from the current one to the one that replaces it, the USER bearing the differences, if any;"
                            )}
                        </p>

                        <p>
                            {t(
                                "5.3 - When choosing a payment method, the USER declares to be authorized to use it, as well as declares that all the information provided is true and accurate, and authorizes AIRSTRIP to charge it, and that the charge of all and any additional service that may be used, in accordance with the values ​​in force on the date of contracting;"
                            )}
                        </p>

                        <p>
                            {t(
                                "5.4 - In the event of delay or default of the obligations related to the payment of the license, a late payment fine of 10% on the amount due, late payment interest of 1% per month and monetary correction by the IGP-M, always calculated pro rata die, will apply, up to subsequent payment. If the default occurs for more than 7 days, access to the platform and all its resources will be fully suspended and will only be reinstated in the event of payment of all overdue installments. Once the payments have been settled and this contract has not been terminated, AIRSTRIP undertakes to restore access within a maximum period of 24 hours after the payment has been identified;"
                            )}
                        </p>

                        <p>
                            {t(
                                "5.5 - Even after the cancellation of this contract, all amounts that still remain open may be charged through all possible means, including sending the AIRSTRIP registration to the credit protection registers;"
                            )}
                        </p>

                        {/*  */}
                        <br />
                        <b>
                            <p>{t("6. - INFORMATION CONFIDENTIALITY")}:</p>
                        </b>

                        <p>
                            {t(
                                "6.1 - AIRSTRIP may access and preserve public information associated with the use of the platform, including, but not limited to, its content, profiles, campaign data, among others. Whenever AIRSTRIP is obliged to meet the requirements of authorities, as well as to maintain the services, apply the contract and protect its interests and/or rights or those of other users, this data may be made available in accordance with the terms of the Law. General for Data Protection of Brazil;"
                            )}
                        </p>

                        <p>
                            {t(
                                "6.2 - The PARTIES agree to treat as confidential all information that they may have access to during this contract or because of it, from each other, and undertake not to disclose, use, sell, copy them , decompile, develop, improve them, not reverse engineer them or allow them to be disclosed by third parties without the prior written consent of the OTHER PARTY, either in their natural state or modified at any level, for a period of 2 years after the end of the provision of services, whatever it may be, under penalty of being directly responsible for the breach of this clause, through the taking of the appropriate legal measures;"
                            )}
                        </p>

                        <p>
                            {t(
                                "6.3 - AIRSTRIP will adopt the best practices within its reach to provide a compatible level of privacy and data security, keeping its servers and systems up to date and secure, not being able, however, to guarantee the absolute and total inviolability of the system as a result of conducts that may be attributed to the actions of crackers or the like, or as a result of unknown, undisclosed failures, or for which AIRSTRIP has not yet developed adequate protection or adjustment;"
                            )}
                        </p>

                        {/*  */}
                        <br />
                        <b>
                            <p>{t("7. - COPYRIGHT")}:</p>
                        </b>

                        <p>
                            {t(
                                "7.1 - All software, technical schemes, databases, work methodologies and other tools used or that may be managed or developed by AIRSTRIP in its activities or under this contract are its exclusive property and are duly protected under the terms of paragraph 3 of article 2 and following of Law 9609/98, item XII of article 7, articles 18, 19 and following of Law 9610/98;"
                            )}
                        </p>

                        <p>
                            {t(
                                "7.2 - All inventions, discoveries and developments or improvements of software, hardware, technical and business schemes, work products of any nature, lists, data and information transmitted on the platform, and any other information to which you have access or develop during or by virtue of the service provision relationship, they will be the sole and exclusive property of AIRSTRIP, and the USER will not have any right to claim any profit, royalties, rights or patent of inventions or improvements, which may be used by the USER in its business activities;"
                            )}
                        </p>

                        <p>
                            {t(
                                "7.3 - The USER is prohibited from modifying the characteristics of the platform, decompile, disassemble, perform reverse engineering, modify the source codes, circumvent or circumvent technological protection measures of the platform or related to it, separate components or functionalities, expand or change them. them in any way without the express consent of AIRSTRIP, as well as remove or change the brands, copyright notices or intellectual and industrial property that accompany the platform and its respective documentation;"
                            )}
                        </p>

                        <p>
                            {t(
                                "7.4 - The brands and logos related to the HYPOLAKE are owned by AIRSTRIP, and their use is expressly prohibited and must be preceded by written authorization. AIRSTRIP may mention the USER as a client of the platform;"
                            )}
                        </p>

                        {/*  */}
                        <br />
                        <b>
                            <p>{t("8. - DATA CONFIDENTIALITY")}:</p>
                        </b>

                        <p>
                            {t(
                                "8.1 - The tool will have access exclusively to the user's navigation data within the platform, without this having any interference in the USER's transactions or business, being, from now on, clarified that the tool does not store or collect confidential, sensitive or personal data from program users HYPOLAKE or users of social networks, which allow their identification;"
                            )}
                        </p>

                        <p>
                            {t(
                                "8.2 - Pursuant to law 13.709/2018, all data obtained through the use of the program and the provision in services are public and of public profiles of the platforms mentioned in clause 1.1, but they cannot be subject to new treatment, and all data will be delivered anonymously by the tool, except for the object of the contract regarding open and knowledge data public, which will be treated with the sole and exclusive purpose of identifying the most relevant influencers to the purposes collimated by the USER;"
                            )}
                        </p>

                        <p>
                            {t(
                                "8.3 - The PARTS will process the personal data arising from this contract solely for the purpose of performing their contractual obligations and achieving their objectives. Each PARTS will be exclusively responsible for the purposes of the treatment for which they are considered as independent controllers, and jointly in relation to the purposes of the treatment for which they are eventually joint controllers;"
                            )}
                        </p>

                        <p>
                            {t(
                                "8.4 - The program and services will not identify, collect or process any type of data considered sensitive by users of social networks, but only and only data made public by the holder, in the form of paragraph 4 of article 7 of the General Protection Law of Data in Brazil, and all personal data will be treated in accordance with the guidelines of article 6 of the same Law, and may not be transferred abroad unless expressly authorized by AIRSTRIP. The only data processed will be the username and interactions with the digital influencer;"
                            )}
                        </p>

                        <p>
                            {t(
                                "8.5 - Through the use of the platform, it will not be possible to accurately and securely identify any user of social networks who interact with digital influencers. The tool will not collect any type of data that allows this type of identification, within the framework established by the RGDP  of the European Union, when applicable;"
                            )}
                        </p>

                        <p>
                            {t(
                                "8.6 - The use of data collected or otherwise processed on the basis of this contract for secondary purposes, whether derived or not related to its object, regardless of fault or intent, will lead to the assumption of full responsibility by the controller of that specific new treatment, throughout legality and legitimacy of this, including any damages or sanctions resulting from legal violation and misuse of purpose in the processing of data, fully excluding the other party that did not consent to this new treatment;"
                            )}
                        </p>

                        <p>
                            {t(
                                "8.7 - THE FURTHER PROCESSING OF THE DATA BY THE USER IS COMPLETELY PROHIBITED, IN ORDER TO REVERSE THE ANONYMIZATION, IDENTIFY AND COLLECT ANY OTHER DATA FROM USERS OF SOCIAL NETWORKS, UNDER PENALTY OF IMMEDIATE TERMINATION OF THE AGREEMENT FOR JUST CAUSE AND WITHOUT ANY SUBSEQUENT RIGHTS OF THE USER . IF THE USER PERFORMS THIS PRACTICE, HE WILL BE SOLELY RESPONSIBLE FOR ANY LOSSES THAT IT CAUSE;"
                            )}
                        </p>

                        <p>
                            {t(
                                "8.8 - In case of non-compliance with the privacy terms of this contract, the USER may be legally sued to be obliged to destroy all data obtained, as well as to cease the use of such data and reimburse AIRSTRIP for all damages it may cause. ;"
                            )}
                        </p>

                        <p>
                            {t(
                                "8.9 - In case of unauthorized access or accidental or unlawful situations of loss, alteration, disclosure or any other type of incident that allows the communication of personal or sensitive data of users or digital influencers and that is a result of the treatment carried out by the other Part under this contract, regardless of the reason that caused it, the responsible Part must notify the innocent Part, in writing, within 24 hours of being aware of the incident, containing, at least, the following information:"
                            )}{" "}
                            {t(
                                "(i) date and time of the incident ; (ii) date and time of acknowledgment by the Party; (iii) list of data types affected by the incident; (iv) certain or estimated number of affected holders; (v) contact details of the data protection officer and/or other person from whom it is possible to obtain further information about the incident; (vi) description of the possible consequences of the incident; and (vii) indication of measures being taken to repair the damage and prevent further incidents. If the responsible Part does not have all the information listed here at the time of sending the communication, it must send it gradually, in order to ensure the greatest possible celerity;"
                            )}
                        </p>

                        {/*  */}
                        <br />
                        <b>
                            <p>{t("9. - TERM AND TERMINATION")}:</p>
                        </b>

                        <p>
                            {t(
                                "9.1 - Permission to use the platform and the provision of services takes effect on the date of acceptance of this contract, and will remain in force while payments are being made by the USER, being automatically renewed. It is considered as tacitly renewed as long as payments are made, regardless of whether or not the platform is used;"
                            )}
                        </p>

                        <p>
                            {t(
                                "9.2 - Either party may terminate this agreement, immediately, for default by the other part that has not been remedied within 5 days after formal written communication, or in the event of bankruptcy, judicial or extrajudicial recovery, or even insolvency in payments;"
                            )}
                        </p>

                        <p>
                            {t(
                                "9.4 - AIRSTRIP may, at any time, suspend access to the software if abuse of use is identified, which may occur in different ways, notably by identifying an IP address incompatible with the USER's geographic location, through technical analysis. In this case, AIRSTRIP may, before suspending access, notify the USER of the facts that have occurred and if the disrespect continues, the login will be suspended for 3 calendar days. If the abuse continues, the contract will be justifiably terminated without the right to reimbursement of any amount paid;"
                            )}
                        </p>

                        <p>
                            {t(
                                "9.5 - In case of termination due to infractions resulting from the unauthorized use of data obtained with the tool, the USER may be called to the process or denounced the dispute (if applicable), and agrees to reimburse AIRSTRIP for all amounts and losses caused for its conduct or that of third parties connected to it;"
                            )}
                        </p>

                        {/*  */}
                        <br />
                        <b>
                            <p>{t("10. - COMMUNICATIONS")}:</p>
                        </b>

                        <p>{t("10.1 - All communications between the parts must be sent and carried out through the following electronic addresses and to the following people:")}</p>

                        <p>
                            {t("For AIRSTRIP")}: {t("contact@hypolake.com")}
                        </p>

                        <p>
                            {t("For the USER")}:{" "}
                            {t(
                                "through the data provided when completing the online form. If there is a change in contact data and this change is not communicated, all communications sent will be valid;"
                            )}
                        </p>

                        {/*  */}
                        <br />
                        <b>
                            <p>{t("11. - GENERAL PROVISIONS")}:</p>
                        </b>

                        <p>
                            {t(
                                "11.1 - Any and all changes to this contract must be made in writing, by sending a communication to the other part, with an exchange of e-mails with acknowledgment of receipt being sufficient;"
                            )}
                        </p>

                        <p>
                            {t(
                                "11.2 - AIRSTRIP may assign, at any time, in whole or in part, the rights and obligations assumed in this instrument, and the assignee must comply with and respect the terms set forth in this contract until the date of its termination;"
                            )}
                        </p>

                        <p>
                            {t(
                                "11.3 - AIRSTRIP will not be responsible, even in a subsidiary manner, for any direct or indirect damages arising from outdated, erroneous and/or incomplete information collected by the USER through the platform, provided that they come from the databases accessed and/or the social networking sites or social media covered, or even as a result of misuse or partial use of the program;"
                            )}
                        </p>

                        <p>
                            {t(
                                "11.4 - AIRSTRIP undertakes to keep the USER free from any and all claims by third parties who claim to have rights under the software and/or any possible violation of any intellectual property practiced by AIRSTRIP, assuming all damages and full defense, replacing -a in the passive pole and bearing all payments due;"
                            )}
                        </p>

                        <p>
                            {t(
                                "11.5 - The non-exercise of any right guaranteed by this instrument cannot be interpreted as a waiver thereof, and the precedents cannot be invoked as a tacit novation of the Agreement, the clauses of which will always remain in full force, with the possibility of, at any time, make the right not exercised until then effective;"
                            )}
                        </p>

                        <p>
                            {t(
                                "11.6 - The parties to this contract are independent and nothing mentioned herein shall be interpreted as an employment relationship, joint venture, de facto or de jure partnership or consortium between the parties. Neither party has any right, power or authority to enter into any agreement for or on behalf of the other party, or incur any obligation or liability on behalf of the other, under penalty of invalidity and to respond in isolation;"
                            )}
                        </p>

                        <p>
                            {t(
                                "11.7 - Neither PART is liable for the commercial failures of the other, nor for claims by third parties, except in cases where the deliberate action or omission of one PART to harm the other is proven;"
                            )}
                        </p>

                        <p>
                            {t(
                                "11.8 - THE PARTS undertake to comply with and ensure that all persons involved in this contract comply with all applicable laws, rules and regulations, including, without limitation, sanitary, environmental, health and safety, labor, social security and inspectors, who under no circumstances have employed or will employ any form of child labor or labor in conditions similar to slavery;"
                            )}
                        </p>

                        <p>
                            {t(
                                "11.9 - The USER declares that he has read this agreement and also the Tool Usage Policies that are in the URL - , and he also agrees with all its provisions;"
                            )}
                        </p>

                        <p>
                            {t(
                                "11.10 - Not arrivingto oneagreement, the parties will use all methods of consensual conflict resolution, and if necessary, will present the case to one of the Arbitration Chambers of the city of São Paulo, under the terms of Law 9.307/96, the loser bearing all the costs and fees resulting from the procedure;"
                            )}
                        </p>

                        <p>{t("11.11 - This contract obliges the parties, their heirs and successors in any capacity;")}</p>

                        <p>{t("11.12 - If one of the provisions of this contract is declared void, all others not affected will remain valid;")}</p>

                        <p>
                            {t(
                                "11.13 - The parties elect the forum of the Capital of the State of São Paulo, to the exclusion of any other, however privileged it may be, to settle any dispute arising from this Term."
                            )}
                        </p>

                        {/*  */}
                        <br />
                        <p>
                            {t(
                                "And, as they are fair and contracted, the USER declares that he has read this agreement and expressly accepted its terms when starting to use the program and making the payment of the installments arising from the contracted plan."
                            )}
                        </p>

                        <br />
                        <br />
                        <p>{t("São Paulo, March 31, 2022. (date of last update).")}</p>
                    </div>

                    <CheckBox checked={checked} onChange={() => updChecked(!checked)} text={t("Declaro que li e concordo com os termos e condições de uso")} />

                    <Button width="100%" disabled={!checked} className="button-submit" d text={t("Continuar compra")} onClick={() => onAccept()} />
                </div>
            </div>
        )
    );
};

ContractEN.defaultProps = {
    visible: true,
};

ContractEN.propTypes = {
    onAccept: PropTypes.func.isRequired,
    visible: PropTypes.bool,
};

export default ContractEN;
