import React, { useEffect } from "react";
import PropTypes from "prop-types";
//
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchPosts, selectTiktokDiscovery, updateInfluencerSelected } from "../../TikTokDiscoverySlice";
import StateStatus from "../../../../../utils/stateStatus";
//
import { Avatar } from "antd";
import Tooltip from "../../../Tooltip/Tooltip";
//
import Button from "../../../Button/Button";
import Loading from "../../../Loading/Loading";
import CardVideoSimple from "../../../CardVideoSimple/CardVideoSimple";
//
import { humanize } from "../../../../../utils/formatters";
import "./TiktokerDetails.scss";

const TiktokerDetails = ({ onAdd }) => {
    const { t } = useTranslation();
    //
    const dispatch = useDispatch();
    //
    const tiktokDiscoverySlice = useSelector(selectTiktokDiscovery);
    //
    const posts = tiktokDiscoverySlice.posts;
    const filters = tiktokDiscoverySlice.filters;
    const hasMore = tiktokDiscoverySlice.hasMore;
    const status = tiktokDiscoverySlice.statusPosts;
    const influencer = tiktokDiscoverySlice.influencer;
    const influencerSelected = tiktokDiscoverySlice.influencerSelected;
    //
    const interactions = [
        { key: "followers", name: t("Seguidores"), toooltip: null, icon: "group", value: influencer.followers || 0 },
        {
            key: "videos",
            name: t("Vídeos"),
            toooltip: t("Total de vídeos"),
            icon: "play",
            value: influencer.videosCount || 0,
        },
        {
            key: "likes",
            name: t("Curtidas"),
            toooltip: t("Média de curtidas"),
            icon: "likes",
            value: influencer.likesCount || 0,
        },
    ];
    // const cardData = [
    //     { name: t("Experiência com marcas"), value: influencer.expirence },
    //     { name: t("Sistema"), value: influencer.system },
    //     { name: t("País da audiência"), value: influencer.audience },
    //     { name: t("Gênero audiência"), value: influencer.gender },
    // ]

    const handleScroll = () => {
        const body = document.querySelector(".scroll-content");

        if (
            body.scrollHeight - 40 < body.offsetHeight + body.scrollTop &&
            hasMore &&
            status === StateStatus.succeeded
        ) {
            dispatch(fetchPosts());
        }
    };

    useEffect(() => {
        let body = document.querySelector(".scroll-content");

        body.addEventListener("scroll", handleScroll);

        return () => body.removeEventListener("scroll", handleScroll);
    }, [status]);

    useEffect(() => {
        dispatch(fetchPosts());
    }, [influencer]);

    return (
        <div className="tiktoker-details">
            {filters.length > 0 && (
                <div className="form-filters">
                    {filters.map(
                        (filter, key) =>
                            filter && (
                                <span key={key} className="item-filter-text">
                                    {filter}
                                </span>
                            )
                    )}
                </div>
            )}

            <span className="btn-back" onClick={() => dispatch(updateInfluencerSelected(null))}>
                <i className="icon icon-back" />
                {t("Voltar a buscar no TikTok")}
            </span>

            <div className="influencer-interactions">
                <div className="card-tiktoker influencer">
                    <div className="avatar-name">
                        <div className="avatar">
                            <Avatar src={influencer.avatar} />
                        </div>

                        <div className="name-location">
                            <span className="name">{influencer.name}</span>
                        </div>
                    </div>

                    <Button
                        color="secondary"
                        outline={true}
                        text={t("Adicionar a campanha")}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();

                            dispatch(updateInfluencerSelected(null));

                            onAdd(influencerSelected);
                        }}
                    />
                </div>

                <div className="interactions">
                    {interactions.map((interaction) => {
                        return (
                            <div key={interaction.key} className="interaction">
                                <div className="text">
                                    <i className={`icon icon-${interaction.icon}`} />

                                    <span className="name">{interaction.name}</span>

                                    {interaction.toooltip && <Tooltip child={interaction.toooltip} />}
                                </div>

                                <span className="value">{humanize(interaction.value)}</span>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="all-posts">
                <p className="title-posts">{t("Vídeos do perfil")}</p>
                <p className="text-posts">{t("Encontre perfis que combinem com a sua campanha.")}</p>

                <div className="influencer-posts">
                    {posts.map((post) => (
                        <CardVideoSimple key={post.id} post={post} />
                    ))}
                </div>
            </div>

            {status === StateStatus.loading && <Loading />}
        </div>
    );
};

TiktokerDetails.propTypes = {
    onAdd: PropTypes.func.isRequired,
};

export default TiktokerDetails;
