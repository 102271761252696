import { AirUtils } from '../utils/utils';

const socialMapper = {
    instagram: "https://instagram.com/"
}

class Contractor {
    id;
    name;
    airId;
    avatar;
    network;
    profileUrl;
    base = 0;
    airscore = 0;
    totalPosts = 0;
    interactions = [];
    histogram = [];
    connectStatus = "waiting";

    fromJson(data) {
        const utils = new AirUtils;

        this.id = data.id
        this.airId = data.air_id
        this.name = data.name
        this.profileUrl = data.url
        this.network = data.social_network
        this.connectUrl = data.connect_url
        this.avatar = utils.buildImage(data.avatar)
        // this.connected = data.connect_state === "connected";
        this.base = data.profile ? data.profile.followers_count : 0
        this.totalPosts = data.profile ? data.profile.media_count : 0
        
        if (data.profile) {
            this.connectStatus = data.profile.connect_state || "waiting";
        }
        else {
            this.connectStatus = data.connect_state || "waiting";
        }

        if(!this.network) {
            const nw = this.airId.split(":")[0]

            if(nw) this.network = nw
        }

        return this;
    }

    detailsFromJson(data) {
        const utils = new AirUtils;
        this.id = data.profile.air_id
        this.airId = data.profile.air_id
        this.name = data.profile.name || `@${data.profile.username}`
        this.profileUrl = `${socialMapper[data.profile.social_network]}${data.profile.username}`
        this.connectUrl = data.profile.connect_url
        this.avatar = data.profile.profile_picture_url ? utils.buildImage(data.profile.profile_picture_url) : ""
        // this.connected = data.connect_state == "connected";
        this.base = data.profile.followers_count
        this.totalPosts = data.profile.media_count

        this.network = data.profile.social_network

        if (data.profile) {
            this.connectStatus = data.profile.connect_state || "waiting";
        }
        else {
            this.connectStatus = data.connect_state || "waiting";
        }

        if (data.interactions) {
            this.interactions = data.interactions.map(item => {
                return {
                    "alias": item.label,
                    "value": item.value
                }
            })
        }

        if (data.chart.per_date) {
            this.histogram = data.chart.per_date.map((item) => {
                const initialValue = data.chart.per_date[0].value
                const growth = 100 * ((item.value - initialValue) / initialValue)
                return {
                    label: item.label,
                    value: item.value,
                    grow: Math.round((growth + Number.EPSILON) * 100) / 100
                }

            })
        }

        if(!this.network) {
            const nw = this.airId.split(":")[0]

            if(nw) this.network = nw
        }

        return this;
    }
}

export default Contractor;