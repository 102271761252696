import React from "react";
import PropTypes from "prop-types"
import { Select as AntSelect } from "antd";
// 
import iconTikTok from "../../../common/assets/icons/tiktok.svg"
import "./Select.scss";

const { Option } = AntSelect;

const Select = ({
    onChange,
    options,
    error,
    outline,
    disabled,
    textError,
    width,
    placeholder,
    defaultValue,
    className,
}) => {
    const callChange = (value) => {
       if (onChange) {
            let data = options.filter((opt) => {
                return opt && opt["value"] === value
            })

            onChange(data[0]);
       }
    }

    return <div className="container-select">
        <AntSelect
            className={`select select-${outline ? "outline" : "full"} ${error ? "select-error" : ""} ${className || className}`} 
            style={{ width: width }} 
            placeholder={placeholder} 
            value={defaultValue}
            disabled={disabled}
            onChange={(value) => callChange(value)}
        >
            {options && options.map((option, key) => {
                return option && (<Option key={key} value={option["value"]}>
                    {!option["icon"] ? 
                        null 
                        : 
                        (option["icon"].includes("tiktok") ? <img className="tiktok-icon" alt="tiktok icon" src={iconTikTok} /> : <i className={`icon ${option["icon"]}`} />)
                    }
                    {option["key"] || option["label"]}
                </Option>)
            })}
        </AntSelect>
        
        { error && <span className="text-error select-text-error">{textError}</span> }
    </div>
}

Select.defaultProps = {
    width: 250
}

Select.propTypes = {
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.object),
    error: PropTypes.bool,
    outline: PropTypes.bool,
    setValue: PropTypes.bool,
    disabled: PropTypes.bool,
    textError: PropTypes.string,
    width: PropTypes.any,
    placeholder: PropTypes.string,
    defaultValue: PropTypes.any,
    className: PropTypes.string,
}

export default Select