import api from '../../service/api'
import apiRoutes from '../../service/apiRoutes'

// profile 
export const updName = (data) => api.patch(apiRoutes.update_name, data)   
export const updEmail = (data) => api.patch(apiRoutes.update_email, data)  
export const updPassword = (data) => api.patch(apiRoutes.update_password, data)
// invoices
export const invoices = (page) => api.get(`${apiRoutes.get_invoices}/${page}`)
export const downloadInvoice = (id) => api.get(`${apiRoutes.load_invoices}/${id}`)
// payments
export const deletePlan = () => api.delete(apiRoutes.cancel_plan)
export const contractedPlan = () => api.get(apiRoutes.contractedPlan)
export const subscription = () => api.get(apiRoutes.subscription)
// accounts
export const getAccounts = () => api.post(apiRoutes.getAccounts)

export const saveNewAccount = (values) => api.post(apiRoutes.newAccount, values)
export const updAccountName = (id, data) => api.patch(`${apiRoutes.update_account_name}/${id}`, data)
export const updAccountEmail = (id, data) => api.patch(`${apiRoutes.update_account_email}/${id}`, data)
export const updAccountPassword = (id, data) => api.patch(`${apiRoutes.update_account_password}/${id}`, data)
export const removeAccount = (id) => api.delete(`${apiRoutes.newAccount}/${id}`)