import React from "react"
import PropTypes from "prop-types"
import { ConfigProvider, DatePicker } from "antd"
// 
import localePt from "antd/lib/locale/pt_BR"
import localeEn from "antd/lib/locale/en_US"
import localeEs from "antd/lib/locale/es_ES"
// 
import moment from "moment"
import "moment/locale/pt-br"
// 
import "./Calendar.scss"
import i18n from "../../../languages/i18n"

const { RangePicker } = DatePicker

moment.updateLocale("pt-br")

const Calendar = ({
    ref,
    value,
    error,
    oneInput,
    onChange,
    defaultValue,
    textError,
    separator,
    placeholderEndDate,
    placeholderStartDate,
    disabledStartDate,
    disabledEndDate,
}) => {
    const lang = i18n.language
    
    const languages = {
        "es": localeEs,
        "en": localeEn,
        "pt": localePt
    }

    const callChange = (date, dateString) => {
        onChange(date, dateString)
    }
  
    const disabledDate = (current) => {
        
        if(disabledStartDate && disabledEndDate) {
            const end = moment(disabledEndDate).toDate().getTime()
            const start = moment(disabledStartDate).toDate().getTime()

            if(current.valueOf() <= start) return current.valueOf() <= start
            else return current.valueOf() > end 
        } else {
            let today = new Date()

            return current && current.valueOf() <= (today.setDate(today.getDate() - 1))
        }
    }

    return <>
        <ConfigProvider locale={languages[lang] || localeEn}>
            <RangePicker
                value={value}
                separator={separator ? separator : ""}
                popupStyle={{}}
                allowClear={false} 
                suffixIcon={false}
                ref={ref}
                onChange={callChange}
                inputReadOnly={true}
                disabledDate={disabledDate}
                defaultValue={defaultValue}
                // disabled={[disabledStartDate, disabledEndDate]}
                // allowEmpty={[disabledStartDate, disabledEndDate]}
                format={lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY"}
                style={{ cursor: "pointer", color: "black" }}
                className={`calendar ${oneInput ? "only-one" : ""} ${error ? "input-error" : ""} ${disabledEndDate ? "calendar-disabled-end-date" : ""}`}
                placeholder={[placeholderStartDate || "Data de início", placeholderEndDate || "Data de término"]}
            />
        </ConfigProvider>

        { error && <span className="text-error">{textError}</span> }
    </>
}

Calendar.defaultProps = {
    disabledEndDate: false,
    disabledStartDate: false,
}

Calendar.propTypes = {
    ref: PropTypes.any,
    value: PropTypes.any,
    error: PropTypes.bool,
    disabledEndDate: PropTypes.any,
    disabledStartDate: PropTypes.any,
    oneInput: PropTypes.bool,
    onChange: PropTypes.func,
    defaultValue: PropTypes.array,
    textError: PropTypes.string,
    separator: PropTypes.string,
    placeholderEndDate: PropTypes.string,
    placeholderStartDate: PropTypes.string,
}

export default Calendar