import React, { useState } from "react"
import PropTypes from 'prop-types';
import { MaskedInput } from "antd-mask-input"
// 
import { Spin } from "antd";
// 
import "./InputLabel.scss"

const InputLabel = (props) => {
  const [showPass, updateShowPass] = useState(false)

  return <div className="input-label-junction">
    <input 
      data-testid="input" 
      className="input-label" 
      placeholder={props.placeholder} 
      {...props}
      type={!props.isPassword ? props.type : (showPass ? "text" : "password")}
    />
    
    {props.loading == true && <Spin size={20} />}

    {props.type === "password" &&
      <div
        className="icon-password"
        onClick={() => { updateShowPass(!showPass) }}>
        <i className={`icon icon-${showPass ? "eye" : "eye-blocked"}`} />
      </div>
    }
    {showPass}
  </div>
}

InputLabel.propTypes = {
  loading: PropTypes.bool,
  isPassword: PropTypes.bool,
  type: PropTypes.string,
  placeholder: PropTypes.string,
}

InputLabel.defaultProps = {
  isPassword: false,
  type: "text",
}

// --------------------------------

const RoundedMaskedInput = (props) => 
  <MaskedInput
    data-testid="input"
    className="input-label rounded-input"
    {...props}
  />

// --------------------------------

export { RoundedMaskedInput }

export default InputLabel
