import i18n from "i18next";
import {
    initReactI18next
} from "react-i18next";

import pt from "./pt";
import en from "./en";
import span from "./span";

const resources = {
    pt: {
        translation: pt
    },
    pt_br: {
        translation: pt
    },
    en: {
        translation: en
    },
    en_us: {
        translation: en
    },
    es: {
        translation: span,
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem("lang") || navigator.language || navigator.userLanguage || "en",
        keySeparator: false,
        nsSeparator: false,

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;