import React, { useEffect, useState } from "react";
//
import { Layout, Menu } from "antd";
//
import { useHistory } from "react-router-dom";
import { selectIndex } from "./sideMenuSlice";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
//
import hyp from "../../assets/icons/hyp.svg";
import o from "../../assets/icons/o.svg";
import lake from "../../assets/icons/lake.svg";
//
import "./SideMenu.scss";

const { Sider } = Layout;

const SideMenu = () => {
    const history = useHistory();
    const { t } = useTranslation();

    const selectedIndex = useSelector(selectIndex);

    const pages = [
        {
            label: t("Criar Campanha"),
            route: "/create-campaign/type",
            className: "create-campaign",
            icon: <i className="icon icon-create-campaign" />,
        },
        {
            label: t("Campanhas"),
            route: "/",
            className: "home",
            icon: <i className="icon icon-analytics" />,
        },
        {
            label: t("Resumo Campanha"),
            route: "/campaign-summary",
            className: "campaign-summary",
            icon: <i className="icon icon-overview" />,
        },
        // {
        //     "label": "divider"
        // },
        // {
        //     "label": t("TikTok Search"),
        //     "route": "/tiktok-discovery",
        //     "className": "tiktok-discovery",
        //     "icon": <i className="icon icon-tiktok" />,
        // }
    ];

    const [index, setIndex] = useState("1");
    const [collapsed, setCollapsed] = useState(true);

    const toggle = () => {
        setCollapsed(!collapsed);
        localStorage.setItem("state_menu", !collapsed);
    };

    useEffect(() => {
        setCollapsed(localStorage.getItem("state_menu") == "true");
    }, []);

    useEffect(() => {
        setIndex(selectedIndex);
    }, [selectedIndex]);

    return (
        <Sider
            collapsible
            width={210}
            trigger={null}
            onCollapse={toggle}
            collapsed={collapsed}
            style={{ overflow: "auto", height: "100vh", left: 0 }}
        >
            <div className={`hypo-lake ${!collapsed ? "opened" : ""}`} onClick={() => history.push("/")}>
                <img className="logo" alt="logo hypolake" src={o} />

                <div className="logo-label">
                    <img className="hyp" src={hyp} alt="icon hypolake" />
                    <div className="o"></div>
                    <img className="lake" src={lake} alt="icon hypolake" />
                </div>

                <p className="powered-text">Powered by Airfluencers</p>
            </div>

            <div className="divider"></div>

            <Menu theme="dark" mode="inline" defaultSelectedKeys={[index]} selectedKeys={[index]}>
                {pages &&
                    pages.map((page, key) => {
                        if (page.label === "divider") {
                            return <Menu.Divider key="divider" />;
                        } else {
                            return (
                                <Menu.Item
                                    key={key}
                                    icon={page.icon}
                                    className={page.className}
                                    onClick={() => {
                                        setIndex(key.toString());

                                        history.replace(`${page.route}`);
                                    }}
                                >
                                    <span className="option-label">{page.label}</span>
                                </Menu.Item>
                            );
                        }
                    })}
            </Menu>

            <div className={`container ${collapsed ? "" : "active"}`} onClick={() => toggle()}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                    <g
                        style={{
                            strokeWidth: "6.5",
                            strokeLinecap: "round",
                        }}
                    >
                        <path d="M72 82.286h28.75" fill="#009100" stroke="#4a4a4a" />
                        <path
                            d="M100.75 103.714l72.482-.143c.043 39.398-32.284 71.434-72.16 71.434-39.878 0-72.204-32.036-72.204-71.554"
                            fill="none"
                            stroke="#4a4a4a"
                        />
                        <path d="M72 125.143h28.75" fill="#009100" stroke="#4a4a4a" />
                        <path
                            d="M100.75 103.714l-71.908-.143c.026-39.638 32.352-71.674 72.23-71.674 39.876 0 72.203 32.036 72.203 71.554"
                            fill="none"
                            stroke="#4a4a4a"
                        />
                        <path d="M100.75 82.286h28.75" fill="#009100" stroke="#4a4a4a" />
                        <path d="M100.75 125.143h28.75" fill="#009100" stroke="#4a4a4a" />
                    </g>
                </svg>
            </div>
        </Sider>
    );
};

export default SideMenu;
