import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
//
import Header from "../../common/components/Header/Header";
import { Tabs } from "antd";
//
import EditProfile from "./pages/Profile/Profile";
import ConfigBilling from "./pages/Billing/Billing";
import ConfigPayment from "./pages/Payment/Payment";
//
import "./Configuration.scss";
import ManageProfiles from "./pages/ManageProfiles/ManageProfiles";

const { TabPane } = Tabs;

const Configuration = () => {
    const { t } = useTranslation();
    const history = useHistory();
    //
    const [tabActive, setTabActive] = useState(history.location.pathname.split("configuration/")[1] || "1");

    useEffect(() => {
        if (tabActive > 4 || tabActive < 1) {
            setTabActive(1);
        } else {
            history.replace(`/configuration/${tabActive}`);
        }
    }, [tabActive]);

    return (
        <div className="configuration-root">
            <Header title={t("Configurações")} />

            <Tabs activeKey={tabActive} onChange={(tab) => setTabActive(tab)}>
                <TabPane key="1" tab={t("Perfil")}>
                    <EditProfile />
                </TabPane>

                <TabPane key="2" tab={t("Cobrança")}>
                    <ConfigBilling />
                </TabPane>

                <TabPane key="3" tab={t("Pagamentos")}>
                    <ConfigPayment />
                </TabPane>

                <TabPane key="4" tab={t("Gerenciador de perfis")}>
                    <ManageProfiles />
                </TabPane>
            </Tabs>
        </div>
    );
};

export default Configuration;
