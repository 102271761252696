function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}
const GetSteps = (tour, history) => [
    {
        id: "welcome",
        beforeShowPromise: () => {
            history.push("/");
            sleep(400);
        },
        buttons: [
            {
                classes: "shepherd-button-secondary",
                text: "Pular",
                action: tour.cancel,
            },
            {
                classes: "shepherd-button-primary",
                text: "Próximo",
                action: tour.next,
            },
        ],
        highlightClass: "highlight",
        cancelIcon: {
            enabled: true,
        },
        title: null,
        text: [
            '<img src="/images/logo_guiding.png" width="100%"/><p style="margin-top:20px">',
            "Bem vindo ao Hypolake. Sua ferramenta de gestão de campanhas de digital influencers. Vamos começar?",
            "</p>",
        ],
    },
    {
        id: "user-profile",
        highlightClass: "highlight",
        attachTo: { element: ".user", on: "left" },
        title: null,
        text: "Clique no seu nome para ver as configurações.",
        advanceOn: { selector: ".user", event: "click" },
    },
    {
        id: "user-profile-settings",
        highlightClass: "highlight",
        attachTo: {
            element: ".ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light",
            on: "left",
        },
        title: null,
        beforeShowPromise: () => sleep(400),
        text: "Edite seu perfil, controle seus pagamentos, altere idioma ou saia clicando no seu nome e selecionando a melhor opção para você.",
        buttons: [
            {
                classes: "shepherd-button-primary",
                text: "Próximo",
                action: tour.next,
            },
        ],
    },
    {
        id: "create-campaign",
        highlightClass: "highlight",
        attachTo: { element: "li.create-campaign", on: "right" },
        title: null,
        text: "Clique em criar campanha.",
        advanceOn: { selector: "li.create-campaign", event: "click" },
    },
    {
        id: "create-campaign-page",
        highlightClass: "highlight",
        beforeShowPromise: () => sleep(400),
        attachTo: { element: ".cc-page > .cc-content .cc-steppers", on: "bottom" },
        buttons: [
            {
                classes: "shepherd-button-primary",
                text: "Próximo",
                action: tour.next,
            },
        ],
        title: null,
        text: "Crie a melhor campanha para você passando por todos os passos da página de criação de campanha.",
        advanceOn: { selector: "li.create-campaign", event: "click" },
    },
    {
        id: "list-campaigns",
        highlightClass: "highlight",
        attachTo: { element: ".ant-menu-item.home", on: "right" },
        title: null,
        text: "Clique no menu Campanhas.",
        advanceOn: { selector: "li.ant-menu-item.home", event: "click" },
    },
    {
        id: "list-campaigns-list",
        highlightClass: "highlight",
        beforeShowPromise: () => sleep(400),
        attachTo: { element: ".home-content", on: "left-start" },
        title: null,
        text: "Acompanhe todas suas campanhas e performance de cada uma na página de campanhas.",
        buttons: [
            {
                classes: "shepherd-button-primary",
                text: "Próximo",
                action: tour.next,
            },
        ],
    },
    {
        id: "campaign-summary",
        highlightClass: "highlight",
        attachTo: { element: "li.campaign-summary", on: "right" },
        title: null,
        text: "Clique para abrir o Resumo das Campanhas",
        advanceOn: { selector: "li.campaign-summary", event: "click" },
    },
    {
        id: "campaign-summary-content",
        highlightClass: "highlight",
        beforeShowPromise: () => sleep(400),
        attachTo: { element: ".summary-content", on: "left-start" },
        title: null,
        text: 'Acompanhe um resumo geral dos resultados das suas campanhas na página "Resumo Campanha"',
        buttons: [
            {
                classes: "shepherd-button-primary",
                text: "Próximo",
                action: tour.next,
            },
        ],
    },
    {
        id: "campaign-summary-tiktok",
        highlightClass: "highlight",
        beforeShowPromise: () => sleep(400),
        attachTo: { element: "li.tiktok-discovery", on: "right" },
        title: null,
        text: "Na busca do TikTok...",
        advanceOn: { selector: "li.tiktok-discovery", event: "click" },
    },

    {
        id: "campaign-summary-funnel",
        highlightClass: "highlight",
        beforeShowPromise: () => sleep(400),
        attachTo: { element: ".search-tiktoker", on: "left" },
        title: null,
        text: "Encontre digital influencers que se encaixem na sua campanha usando o filtro de busca para Tiktok. <br/><br/> Visualize dados como seguidores, média de likes e adicione diretamente perfis às suas campanhas",
        buttons: [
            {
                classes: "shepherd-button-primary",
                text: "Próximo",
                action: tour.next,
            },
        ],
    },
    // {
    //     id: "campaign-create-campaign-articles",
    //     highlightClass: "highlight",
    //     beforeShowPromise: () => {
    //         setTimeout(
    //             () => window.open("https://hypolake.com/hypolake-articles/category/create-campaign/", "__blank"),
    //             400
    //         );
    //     },
    //     title: null,
    //     text: "",
    //     when: {
    //         show: function () {
    //             tour.hide();
    //             tour.complete();
    //         },
    //     },
    // },
    // {
    //     id: "campaign-create-campaign",
    //     highlightClass: "highlight",
    //     beforeShowPromise: () => {
    //         history.push("/create-campaign/type");
    //     },
    //     title: null,
    //     text: "",
    //     when: {
    //         show: function (a, b, c) {
    //             console.log(a, b, c);
    //         },
    //     },
    // },
    // {
    //     id: "campaign-analyze-campaign",
    //     highlightClass: "highlight",
    //     beforeShowPromise: () => {
    //         window.open("https://hypolake.com/hypolake-articles/category/analyze-campaign/", "__blank");
    //     },
    //     title: null,
    //     text: "",
    //     when: {
    //         show: function () {
    //             tour.hide();
    //         },
    //     },
    // },
];

export default GetSteps;
