import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    createAccount,
    createBilling,
    createPayment,
    verifyCoupon,
    paymentStatus,
    planDetails,
    getProfile,
    contractedPlan,
    loginProviderAccount,
    updateAccount,
} from "./RegisterService";
import StateStatus from "../../utils/stateStatus";

const initialState = {
    status: StateStatus.idle,
    statusCoupon: StateStatus.idle,
    statusRegisterProvider: StateStatus.idle,
    statusRegistration: StateStatus.idle,
    statusPlan: StateStatus.idle,
    statusProfile: StateStatus.idle,
    statusBilling: StateStatus.idle,
    statusPayment: StateStatus.idle,
    statusCheckPayment: StateStatus.idle,
    statusPlanDetails: StateStatus.idle,
    msgError: "",

    // form
    price: 500.0,
    errIndx: -1,

    // utils
    maxStep: 1,
    tips: false,
    stepActive: 0,
    showContract: false,
    withGoogle: false,
    planId: null,
    msg: "",

    // body
    data: {
        // registration
        name: "",
        surname: "",
        company: "",
        email: "",
        phone: "",
        password: null,
        confirmPassword: null,

        // billing
        cep: "",
        address: "",

        // payment
        planId: "",
        coupon: "",
        subscriptionUrl: "",
        subscriptionId: null,
    },
    paymentCheckData: {},
    planDetails: {},
    coupon: {},
};

export const profile = createAsyncThunk("get-profile", async () => {
    const response = await getProfile();
    return response.data;
});

export const getContractedPlan = createAsyncThunk("get-contracted-plan", async () => {
    const response = await contractedPlan();
    return response.data;
});

export const couponIsValid = createAsyncThunk("validate-coupon", async (coupon) => {
    const response = await verifyCoupon({
        coupon,
    });
    return response.data;
});

export const sendPaymentSave = createAsyncThunk("payment-save", async (coupon, thunkAPI) => {
    const { register } = thunkAPI.getState();
    const toSendCopon = coupon || register.data.coupon || localStorage.getItem("coupon");
    const response = await createPayment({
        coupon: toSendCopon,
        plan_id: register.planDetails.id,
    });

    return response.data;
});

export const fetchPlanDetails = createAsyncThunk("plan-detais", async (planId) => {
    const response = await planDetails();
    const planData = response.data.items.filter((item) => item.id === planId)[0];
    return planData;
});

export const sendRegistration = createAsyncThunk("send-registration", async (_, thunkAPI) => {
    const { register } = thunkAPI.getState();

    const data = {
        name: register.data.name,
        surname: register.data.surname,
        password: register.data.password,
        email: register.data.email,
        //
        company: register.data.company,
        phone: register.data.phone,
    };

    try {
        let response = {};
        
        if (register.data.id) {
            if (register.withGoogle) {
                delete data["password"];
            }
            
            // This removes password because password cannot be changed without old password, so you shouldn't send it.
            delete data["email"];
            delete data["password"];
            response = await updateAccount(data);
        } else {
            response = await createAccount(data);

            window.localStorage.setItem("access_token", response.data.access_token);
            window.localStorage.setItem("refresh_token", response.data.refresh_token);
        }

        return response.data;
    } catch (error) {
        throw error.response ? error.response.data.message : error.message;
    }
});

export const sendBilling = createAsyncThunk("send-billing", async (_, thunkAPI) => {
    const { register } = thunkAPI.getState();

    const data = {
        zip_code: register.data.zipCode,
        city: register.data.city,
        address: register.data.address,
        country: register.data.country,
    };

    const response = await createBilling(data);

    return response.data;
});

export const checkPaymentStatus = createAsyncThunk("check-payment", async () => {
    const response = await paymentStatus();
    return response.data;
});

export const registerProvider = createAsyncThunk("register/provider", async (data) => {
    const response = await loginProviderAccount(data);

    return response.data;
});

export const register = createSlice({
    name: "register",
    initialState: initialState,
    reducers: {
        // utils
        clearStatus: (state) => {
            state.status = StateStatus.idle;
            state.statusCoupon = StateStatus.idle;
            state.statusRegistration = StateStatus.idle;
            state.statusBilling = StateStatus.idle;
            state.statusPlan = StateStatus.idle;
            state.statusPayment = StateStatus.idle;
            state.statusProfile = StateStatus.idle;
            state.statusCheckPayment = StateStatus.idle;
            state.statusPlanDetails = StateStatus.idle;
        },
        clearsSatusRegisterProvider: (state) => {
            state.statusRegisterProvider = StateStatus.idle;
        },
        clearStatusRegistration: (state) => {
            state.statusRegistration = StateStatus.idle;
        },
        updStep: (state, action) => {
            state.stepActive = action.payload;

            if (action.payload + 1 > state.maxStep) state.maxStep = action.payload + 1;
        },
        updTips: (state, action) => {
            state.tips = action.payload;
        },
        updShowContract: (state, action) => {
            state.showContract = action.payload;
        },

        setPlanId: (state, action) => {
            const id = action.payload;
            state.planId = id;
            state.planDetails = { id };
        },

        clearRegistration: (state) => {
            state.data.id = null;
            state.data.name = null;
            state.data.surname = null;
            state.data.company = null;
            state.data.email = null;
            state.data.phone = null;
            state.data.paymentAuthorized = null;
            state.data.cardName = null;
            state.data.cardNumber = null;
            state.data.cardValidityMonth = null;
            state.data.cardValidityYear = null;
            state.data.cardCVV = null;
            state.data.coupon = null;
            state.coupon = null;
            state.data.zipCode = null;
            state.data.address = null;
            state.data.city = null;
            state.data.country = null;
        },
        // registration
        updRegistration: (state, { payload }) => {
            state.data.name = payload.name || "";
            state.data.surname = payload.surname || "";
            state.data.company = payload.company || "";
            state.data.email = payload.email || "";
            state.data.password = payload.password;
            state.data.confirmPassword = payload.confirmPassword;
            state.data.phone = payload.phone || "";
        },

        // billing
        updBilling: (state, { payload }) => {
            state.data.zipCode = payload.zipcode || "";
            state.data.address = payload.address || "";
            state.data.city = payload.city || "";
            state.data.country = payload.country.value || "";
        },

        // payment
        updCoupon: (state, action) => {
            state.data.coupon = action.payload;
            localStorage.setItem("coupon", action.payload);
        },

        updObjCoupon: (state, action) => {
            state.coupon = action.payload;
        },

        updPayment: (state, action) => {
            state.data.cardName = action.payload.cardName;
            state.data.cardNumber = action.payload.cardNumber;
            state.data.cardValidityMonth = action.payload.cardValidityMonth;
            state.data.cardValidityYear = action.payload.cardValidityYear;
            state.data.cardCVV = action.payload.cardCVV;
            state.data.coupon = action.payload.coupon;
        },
    },
    extraReducers: {
        [profile.pending]: (state) => {
            state.statusProfile = StateStatus.loading;
        },
        [profile.fulfilled]: (state, action) => {
            const profile = action.payload;
            const group = profile.group;

            // account
            state.data.id = profile.id;
            state.data.name = profile.name;
            state.data.surname = profile.surname;
            state.data.company = profile.company;
            state.data.email = profile.email;
            state.data.paymentAuthorized = profile.payment_authorized;

            if(group) {
                if(group.phone) state.data.phone = group.phone
            }
            
            // billing
            if (group && group.country) {
                state.data.cep = group.zip_code;
                state.data.address = "---";
                state.data.city = "---";
                state.data.country = group.country.replace(/(?:^|\s)\S/g, function (a) {
                    return a.toUpperCase();
                });

                state.statusBilling = StateStatus.succeeded;
            }

            if (profile.authenticators && profile.authenticators.length > 0 && profile.authenticators.some((auth) => auth.provider === "google")) {
                state.withGoogle = true;
            }

            state.statusProfile = StateStatus.succeeded;
        },
        [profile.rejected]: (state, action) => {
            state.msg = action.error.message || "Erro! Tente novamente mais tarde";
            state.statusProfile = StateStatus.failed;
        },
        //
        [getContractedPlan.pending]: (state) => {
            state.statusPlan = StateStatus.loading;
        },
        [getContractedPlan.fulfilled]: (state, action) => {
            action.payload;
            state.statusPlan = StateStatus.succeeded;
        },
        [getContractedPlan.rejected]: (state, action) => {
            state.msg = action.error.message || "Erro! Tente novamente mais tarde";
            state.statusPlan = StateStatus.failed;
        },
        //
        [sendRegistration.pending]: (state) => {
            state.statusRegistration = StateStatus.loading;
        },
        [sendRegistration.fulfilled]: (state) => {
            state.statusRegistration = StateStatus.succeeded;
        },
        [sendRegistration.rejected]: (state, action) => {
            state.msg = action.error.message || "Erro! Tente novamente mais tarde";
            state.statusRegistration = StateStatus.failed;
        },
        //
        [sendBilling.pending]: (state) => {
            state.statusBilling = StateStatus.loading;
        },
        [sendBilling.fulfilled]: (state) => {
            state.statusBilling = StateStatus.succeeded;
        },
        [sendBilling.rejected]: (state, action) => {
            state.msg = action.error.message || "Erro! Tente novamente mais tarde";
            state.statusBilling = StateStatus.failed;
        },
        //
        [sendPaymentSave.pending]: (state) => {
            state.statusPayment = StateStatus.loading;
        },
        [sendPaymentSave.fulfilled]: (state, action) => {
            state.data.subscriptionUrl = action.payload.url;
            state.data.subscriptionId = action.payload.subscription?.provider_id;
            state.data.planId = action.payload.plan_id;
            state.statusPayment = StateStatus.succeeded;
        },
        [sendPaymentSave.rejected]: (state, action) => {
            state.msg = action.error.message || "Erro! Tente novamente mais tarde";
            state.statusPayment = StateStatus.failed;
        },
        //
        [couponIsValid.pending]: (state) => {
            state.coupon = {};
            state.statusCoupon = StateStatus.loading;
        },
        [couponIsValid.fulfilled]: (state, action) => {
            state.coupon = action.payload.coupon;
            state.data.name = profile.name || "";
            state.data.surname = profile.surname || "";
            state.data.company = profile.company || "";
            state.data.email = profile.email || "";
            state.data.phone = profile.phone || {};

            state.statusCoupon = StateStatus.succeeded;
        },
        [couponIsValid.rejected]: (state, action) => {
            state.coupon = {};
            state.msg = action.error.message || "Erro! Tente novamente mais tarde";
            state.statusCoupon = StateStatus.failed;
        },
        // check payment
        [checkPaymentStatus.pending]: (state) => {
            state.statusCheckPayment = StateStatus.loading;
        },
        [checkPaymentStatus.fulfilled]: (state, action) => {
            state.paymentCheckData = action.payload;
            state.data.subscriptionId = action.payload.provider_id;
            state.statusCheckPayment = StateStatus.succeeded;
        },
        [checkPaymentStatus.rejected]: (state, action) => {
            state.msg = action.error.message || "Erro! Tente novamente mais tarde";
            state.msgError = action.error.message;
            state.statusCheckPayment = StateStatus.failed;
        },
        // plan detais
        [fetchPlanDetails.pending]: (state) => {
            state.statusPlanDetais = StateStatus.loading;
        },
        [fetchPlanDetails.fulfilled]: (state, action) => {
            state.planDetails = action.payload;
            state.statusPlanDetais = StateStatus.succeeded;
        },
        [fetchPlanDetails.rejected]: (state, action) => {
            state.msg = action.error.message || "Erro! Tente novamente mais tarde";
            state.statusPlanDetais = StateStatus.failed;
        },
        // register with google
        [registerProvider.pending]: (state) => {
            state.statusRegisterProvider = StateStatus.loading;
        },
        [registerProvider.fulfilled]: (state, action) => {
            state.withGoogle = true;

            localStorage.setItem("access_token", action.payload.access_token);
            localStorage.setItem("refresh_token", action.payload.refresh_token);

            state.statusRegisterProvider = StateStatus.succeeded;
        },
        [registerProvider.rejected]: (state, action) => {
            state.msg = action.error.message;

            state.statusRegisterProvider = StateStatus.failed;
        },
    },
});

export const {
    // utils
    clearStatus,
    clearsSatusRegisterProvider,
    updStep,
    updTips,
    updShowContract,
    clearStatusRegistration,
    //
    clearRegistration,
    updRegistration,
    updBilling,
    updPayment,
    updObjCoupon,
    updCoupon,
    setPlanId,
} = register.actions;

export const selectRegister = (state) => state.register;

export default register.reducer;
