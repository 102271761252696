import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//
import { useSelector, useDispatch } from "react-redux";
import { changeObjAlert, showAlert } from "../../../../common/components/Alert/alertSlice";
import {
    selectRegister,
    couponIsValid,
    sendPaymentSave,
    checkPaymentStatus,
    updTips,
    updCoupon,
    clearStatus,
    updObjCoupon,
    clearRegistration,
} from "../../RegisterSlice";
//
import CheckBox from "../../../../common/components/CheckBox/CheckBox";
import ContractEN from "../../components/Contract/ContractEN";
import ContractPT from "../../components/Contract/ContractPT";
import EndTheSubscription from "../../components/EndTheSubscription/EndTheSubscription";
import { Form } from "antd";
import InputLabel from "../../../../common/components/InputLabel/InputLabel";
//
import StateStatus from "../../../../utils/stateStatus";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import Loading, { SizedLoading } from "../../../../common/components/Loading/Loading";
import "./Payment.scss";
import { refreshTokenCall } from "../../../../service/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import i18n from "../../../../languages/i18n";

const { useForm } = Form;

const Payment = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const Contract = ["en", "en_us"].includes(i18n.language) ? ContractEN : ContractPT;
    const paypalLocale = ["en", "en_us"].includes(i18n.language) ? "en_US" : "pt_BR";
    const history = useHistory();

    const registerState = useSelector(selectRegister);
    //
    const [form] = useForm();
    const [intervalId, setIntervalId] = useState(0);

    const [showContract, setShowContract] = useState(true);
    
    const [isValidatingPayment, setIsValidatingPayment] = useState(false);
    const [subscriptionId, setSubscriptionId] = useState(null);
    // 
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(null);
    // 
    const [endSubscription, setEndSubscription] = useState(false);

    const privacyUrl = ["en", "en_us"].includes(i18n.language)
        ? "https://hypolake.com/privacy-policy/"
        : "https://hypolake.com/pt/politica-de-privacidade/";

    const checkoutStatus = (status) => {
        if (status === "success") {
            const intervalId = setInterval(() => {
                dispatch(checkPaymentStatus());
            }, 5000);
            setIntervalId(intervalId);
            setIsValidatingPayment(true);

            window.gtag("event", "conversion", {
                allow_custom_scripts: true,
                send_to: "DC-13020883/invmedia/conve00+standard",
            });
        }
    };

    const alert = (type, title, text, question, cancelText, okText, onOk) => {
        dispatch(
            changeObjAlert({
                show: true,
                type: type,
                title: title,
                text: text,
                okText: okText,
                question: question,
                cancelText: cancelText,
                onCancel: () => {
                    dispatch(showAlert(false));
                },
                onOk: () => {
                    onOk;
                },
            })
        );
    };

    const couponValidationStatus = () => {
        if (registerState.statusCoupon === StateStatus.idle) {
            return undefined;
        }
        if (registerState.statusCoupon === StateStatus.failed) {
            return "warning";
        } else {
            return "success";
        }
    };

    useEffect(() => {
        if (registerState.statusCheckPayment === StateStatus.succeeded) {
            if (registerState.paymentCheckData.is_subscribed) {
                setPaymentSuccess(true);
                setPaymentLoading(false);
                
                clearInterval(intervalId);
                
                setEndSubscription(true)
            }
        } else if (registerState.statusCheckPayment === StateStatus.error) {
            clearInterval(intervalId);
        }
    }, [registerState.statusCheckPayment]);

    // seta função global que será chamada no arquivo payment_error.html e payment_success.html
    useEffect(() => {
        window["checkoutStatus"] = checkoutStatus;
        const couponValue = localStorage.getItem("coupon");

        if (couponValue) {
            if (![undefined, null].includes(couponValue) && couponValue.length > 3) {
                dispatch(couponIsValid(couponValue));
            } else if (couponValue.length === 0) {
                dispatch(clearStatus());
                dispatch(updObjCoupon({}));
            }
            dispatch(updCoupon(couponValue));
        }
    }, []);

    useEffect(() => {
        setSubscriptionId(registerState.data.subscriptionId);
    }, [registerState.data.subscriptionId]);

    useEffect(() => {
        if (registerState.statusPlanDetais == StateStatus.succeeded) {
            dispatch(checkPaymentStatus());
        }
    }, [registerState.statusPlanDetais]);

    useEffect(() => {
        if (registerState.statusCheckPayment == StateStatus.succeeded && registerState.data.subscriptionId == null) {
            dispatch(sendPaymentSave(registerState.data.coupon || null));
        }
    }, [registerState.statusCheckPayment]);

    useEffect(() => {
        if (registerState.statusPayment == StateStatus.failed) {
            alert("error", t("erro"), t(registerState.msg));
        }
    }, [registerState.statusPayment]);

    useEffect(() => {
        if (registerState.statusCoupon == StateStatus.succeeded) {
            const values = localStorage.getItem("coupon") || form.getFieldsValue();
            dispatch(sendPaymentSave(values.coupon));
        }
    }, [registerState.statusCoupon]);

    const getDiscountValue = (discountType, value, discountValue) => {
        if (discountType === "percentage") {
            return value * discountValue;
        }

        return discountValue;
    };

    return showContract ? (
        <Contract visible={showContract} onAccept={() => setShowContract(false)} />
    ) : (
        endSubscription ?
            <EndTheSubscription 
                visible={endSubscription} 
                name={registerState.data && registerState.data.name}
                planName={registerState.planDetails && registerState.planDetails.label}
                onAccept={() => {
                    // isso é necessário pois o status de pagamento fica no access_token, como o primeiro foi criado antes do pagamento, precisa renovar a token
                    refreshTokenCall()
                        .then(() => {
                            history.replace("/");
                            
                            dispatch(clearRegistration());
                            dispatch(clearStatus());
                        })
                        .catch(() => {
                            localStorage.removeItem("coupon");

                            localStorage.removeItem("access_token");
                            localStorage.removeItem("refresh_token");

                            history.replace("/login");
                            
                            dispatch(clearRegistration());
                            dispatch(clearStatus());
                        });
                }} 
            />
            :
            <Form form={form} layout="vertical">
                {/* coupon */}
                <Form.Item
                    name="coupon"
                    label={t("Cupom de desconto")}
                    initialValue={localStorage.getItem("coupon") || registerState.data.coupon}
                    validateStatus={couponValidationStatus()}
                >
                    <InputLabel
                        onBlur={() => {
                            const couponValue = form.getFieldValue("coupon");

                            if (![undefined, null].includes(couponValue) && couponValue.length > 3) {
                                if (couponValue === registerState.data.coupon) return;
                                dispatch(couponIsValid(couponValue));
                            } else if (couponValue.length === 0) {
                                dispatch(clearStatus());
                                dispatch(updObjCoupon({}));
                            }

                            dispatch(updCoupon(couponValue));
                        }}
                        placeholder={t("Informe o cupom de desconto")}
                        loading={registerState.statusCoupon == StateStatus.loading}
                        disabled={isValidatingPayment}
                    ></InputLabel>
                </Form.Item>
                {registerState.statusCoupon === StateStatus.failed && (
                    <div className="feedback-coupon error">
                        <p className="text">{t("Cupom inválido")}!</p>
                    </div>
                )}

                {registerState.coupon &&
                    Object.keys(registerState.coupon).includes("value") &&
                    Object.keys(registerState.planDetails).includes("value") && (
                        <div className="feedback-coupon">
                            <p className="text">{t("Cupom aplicado com sucesso")}!</p>

                            <p className="subtotal">
                                {t("Subtotal")}
                                <span>
                                    <b>
                                        {registerState.planDetails.value.toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "USD",
                                        })}
                                    </b>
                                </span>
                            </p>

                            <p className="price">
                                {t("Valor do desconto")}
                                <span>
                                    - {registerState.planDetails.currency}{" "}
                                    <b>
                                        {getDiscountValue(
                                            registerState.coupon.type,
                                            registerState.planDetails.value,
                                            registerState.coupon.value
                                        ).toLocaleString("en-US", {
                                            style: "currency",
                                            currency: "USD",
                                        })}
                                    </b>
                                </span>
                            </p>

                            <p className="new-price">
                                {t("Valor total")}
                                <span>
                                    {registerState.planDetails.currency}{" "}
                                    <b>
                                        {(
                                            registerState.planDetails.value -
                                            getDiscountValue(
                                                registerState.coupon.type,
                                                registerState.planDetails.value,
                                                registerState.coupon.value
                                            )
                                        ).toLocaleString("en-US", { style: "currency", currency: "USD" })}
                                    </b>
                                </span>
                            </p>
                        </div>
                    )}

                <div className="section-bottom">
                    <span className="text-terms-privacy">
                        {t("Clicando no botão abaixo você concorda com nossos")}{" "}
                        <b onClick={() => window.open(privacyUrl, "_blank")}>
                            {t("termos de uso e política de privacidade")}
                        </b>
                        .
                    </span>

                    <CheckBox
                        checked={registerState.tips}
                        onChange={() => {
                            dispatch(updTips(!registerState.tips));
                        }}
                        text={t("Receba dicas e novidades da HypoLake")}
                    />
                </div>

                {!subscriptionId && <Loading />}
                {subscriptionId && paymentLoading && (
                    <div className="loading">
                        <SizedLoading size={55} withContainer={false} />
                        {t("Seu pagamento está sendo processado, aguarde...")}
                    </div>
                )}
                <PayPalScriptProvider
                    options={{
                        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
                        vault: true,
                        intent: "subscription",
                        locale: paypalLocale,
                    }}
                >
                    {subscriptionId && !paymentLoading && (
                        <>
                            {!paymentSuccess && (
                                <>
                                    <PayPalButtons
                                        style={{ disableMaxWidth: true }}
                                        forceReRender={[subscriptionId]}
                                        createSubscription={() => {
                                            return subscriptionId;
                                        }}
                                        onApprove={() => {
                                            checkoutStatus("success");
                                            setPaymentLoading(true);
                                        }}
                                        onError={() => {
                                            checkoutStatus("error");
                                            setPaymentLoading(false);
                                            setPaymentSuccess(false);
                                            alert(
                                                "error",
                                                t("erro"),
                                                t(
                                                    "Erro ao processar pagamento. Verifique as informações e tente novamente."
                                                )
                                            );
                                        }}
                                        onCancel={() => {
                                            setPaymentLoading(false);
                                            setPaymentSuccess(null);
                                        }}
                                    />
                                </>
                            )}
                            {paymentSuccess === true && (
                                <div className="payment-success">
                                    {t("Pagamento realizado com sucesso! Redirecionando...")}
                                </div>
                            )}
                            {paymentSuccess === false && (
                                <div className="payment-error">
                                    {t("Erro ao processar pagamento. Verifique as informações e tente novamente.")}
                                </div>
                            )}
                        </>
                    )}
            </PayPalScriptProvider>
        </Form>
    );
};

export default Payment;
