import { configureStore } from '@reduxjs/toolkit';
// 
import { alert } from '../common/components/Alert/alertSlice';
import { campaign } from '../features/CampaignDetails/CampaignDetailsSlice';
import { campaigns } from '../features/Home/HomeSlice';
import { createCampaign } from '../features/CreateCampaign/createCampaignSlice';
import { charts } from '../features/CampaignDetails/components/Charts/ChartsSlice';
import { configuration } from '../features/Configurations/ConfigurationSlice';
import { menu } from '../common/components/SideMenu/sideMenuSlice';
import { profile } from '../features/Login/LoginSlice';
import { recoveryPassword } from '../features/RecoveryPassword/RecoveryPasswordSlice';
import { register } from '../features/Register/RegisterSlice';
import { summary } from '../features/CampaignSummary/CampaignSummarySlice';
import { tiktokDiscovery } from '../common/components/TikTokDiscovery/TikTokDiscoverySlice';
// 
import { routes } from '../routes/RoutesSlice';

export const configStore = () => {
    return configureStore({
        reducer: {
            // to alert
            alert: alert.reducer,

            // to side menu
            menu: menu.reducer,

            // create campaign
            createCampaign: createCampaign.reducer,

            // campaigns
            campaigns: campaigns.reducer,

            // campaign details
            campaign: campaign.reducer,

            // campaigns details - charts
            charts: charts.reducer,

            // campaign summary
            summary: summary.reducer,

            // login
            profile: profile.reducer,

            // register
            register: register.reducer,

            // configuration
            configuration: configuration.reducer,

            // tiktokDiscovery
            tiktokDiscovery: tiktokDiscovery.reducer,

            // recoveryPassword
            recoveryPassword: recoveryPassword.reducer,

            // routes
            routes: routes.reducer,
        },
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware({
                serializableCheck: false,
            }),
    })
}