import React, { useEffect } from 'react';
import logoSymbol from "../../common/assets/icons/hypolake_logo.svg";
import img0 from "../../common/assets/images/ga-login0.svg";
import img1 from "../../common/assets/images/ga-login1.svg";
import img2 from "../../common/assets/images/ga-login2.svg";
import { useTranslation } from "react-i18next";
import ButtonAuthGoogle from "../../common/components/ButtonAuthGoogle/ButtonAuthGoogle";
import { changeObjAlert, showAlert } from "../../common/components/Alert/alertSlice";
import { selectLogin, sendLoginProvider, clearStatus } from "../Login/LoginSlice";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Row } from 'antd';
import "./styles.scss";
import StateStatus from '../../utils/status';
import i18n from '../../languages/i18n';
import { LANGUAGES } from '../Login/Login';


const GoogleLogin = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const objLogin = useSelector(selectLogin);
    const history = useHistory()
    const planUrl = LANGUAGES[i18n.language] || "https://hypolake.com/plan/"

    const alert = (type, title, text, question, cancelText, okText, onOk, colorOk) => {
        dispatch(changeObjAlert({
            show: true,
            type: type,
            title: title,
            text: text,
            okText: okText,
            question: question,
            cancelText: cancelText,
            onCancel: () => { dispatch(showAlert(false)) },
            onOk: onOk,
            colorOk: colorOk,
        }))
    }

    useEffect(() => {
        if (objLogin.status.loginProvider === StateStatus.failed || objLogin.status.login === StateStatus.failed) {
            if (objLogin.googleLoginExists === false){
                alert("info", t("A conta do google não está cadastrada"), t("A conta do google ainda não está cadastrada na nossa plataforma, faça o registro para continuar."), t("Deseja fazer o cadastro?"), t("Cancelar"), t("Cadastrar-se"), ()=>{
                    window.location.href = planUrl;
                }, "blue")
            }
            else{
               alert("error", t("erro"), t(objLogin.msg))
            }
            dispatch(clearStatus())
        } else if (objLogin.status.loginProvider === StateStatus.succeeded || objLogin.status.login === StateStatus.succeeded) {
            history.replace("");
            dispatch(clearStatus())
        }
    }, [objLogin.status.login, objLogin.status.loginProvider, objLogin.googleLoginExists])


    return (
        <div className="google-login-container">
            <div className="header">
                <img className="logo-hypolake" src={logoSymbol} alt="hypolake-logo" />
            </div>
            <div className="body">
                <div className="body-title">{t("Google Analytics can help you to measure your social marketing campaign")}</div>

                <Row>
                    <Col sm={12}>
                        <div style={{ height: "100%", maxWidth: "554px" }}>
                            If you decided to login in to Hypolake using the Google button, you will asked to provide a permission to read your Google Analytics data. This step is important if you want to create campaigns based in conversion. 
                            Please, visit the our <a className="violet-link" href="https://hypolake.com/privacy-policy/">privacy policy</a> to know what data we will collect and store.
                            During the process of creation a campaign you&apos;ll have the opportunity to choose one or more goals configured in your Google Analytics account.
                             The Hypolake will use this information to measure with precision, how much conversions and traffic came from your influencers.
                            Connect Google Analytics and choose the goal used on campaign.
                        </div>
                    </Col>
                    <Col sm={12}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <img src={img0} />
                        </div>
                    </Col>
                </Row>

                <div className="body-container">
                    <Row>
                        <Col sm={12}>
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <img src={img1} />
                            </div>
                        </Col>
                        <Col sm={12}>
                            <div style={{ width: '100%', height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <div style={{ maxWidth: "455px" }}>
                                    On your campaign page, you will be able to measure conversions as well as traffic by influencer profile.
                                    Easy measurement of the influencer&apos;s end of the marketing funnel.
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div style={{ with: "100%", textAlign: "center", marginTop: "104px", marginBottom: "32px" }}>Measure by date or by profile and track the performance of your influencer campaign</div>
                <div style={{ display: "flex", justifyContent: "center", width: "100%", marginBottom: "127px" }}>
                    <img src={img2} />
                </div>
            </div>
            <div className="footer">
                <div className="text-helper">
                    Log in with your google account and access the Hypolake dashboard
                </div>
                <div style={{ maxWidth: "250px", marginBottom: "30px"}}>
                    <ButtonAuthGoogle
                        onAlert={alert}
                        isLoading={objLogin.status.loginProvider === StateStatus.loading}
                        onSend={(data) => dispatch(sendLoginProvider(data))}
                    />
                </div>
                <div><a rel="noreferrer" className="white-link" target="_blank" href="https://hypolake.com/privacy-policy/">Privacy Policy</a></div>
            </div>
        </div>
    )
}

export default GoogleLogin