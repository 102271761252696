import React, { useCallback } from "react";
import PropTypes from "prop-types";
//
import MaskedInput from "antd-mask-input/build/main/lib/MaskedInput";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import { InputNumber } from "antd";
//
import validators from "../../../utils/validators";
import "./FlattedInput.scss";
import en from "world_countries_lists/data/countries/en/world.json";
import pt from "world_countries_lists/data/countries/pt/world.json";
import i18n from "../../../languages/i18n";

const FlattedInput = (props) => <input data-testid="flatted-input" className="flatted-input" {...props} />;

// ---------------------------------

const FlattedMaskedInput = (props) => <MaskedInput data-testid="flatted-masked-input" className="flatted-input" {...props} />;

// ---------------------------------

const FlattedDropdownInput = (props) => (
    <div className="flatted-dropdown-input">
        <input className="flatted-input" {...props} />
        <i className="icon icon-down" />
    </div>
);

// ---------------------------------

const FlattedLabelInput = ({ label, ...props }) => (
    <div className="flatted-label-input">
        <label>{label}</label>
        <input className="flatted-input" {...props} />
    </div>
);

FlattedLabelInput.propTypes = {
    label: PropTypes.string,
};

// ---------------------------------

const FlattedInputNumber = ({ formatter, parser, ...props }) => <InputNumber className="flatted-input-number" formatter={formatter} parser={parser} {...props} />;

FlattedInputNumber.defaultProps = {
    formatter: (value) => value.replace(/\D/g, ""),
    parser: (value) => value.replace(/\D/g, ""),
};

FlattedInputNumber.propTypes = {
    formatter: PropTypes.func,
    parser: PropTypes.func,
};

// ---------------------------------

const FlattedInputCurrency = ({ ...props }) => {
    const formatCurrency = useCallback((value) => {
        value = value.toString();
        if (value.length === 0) return "";
        if (value.length <= 2) return `$ 0,${value.padStart(2, 0)}`;

        const prefixValue = value.slice(0, -2).replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return `$ ${prefixValue},${value.slice(-2)}`;
    });

    return <FlattedInputNumber formatter={formatCurrency} parser={(value) => value.replace(/\D/g, "")} placeholder="0,00" {...props} />;
};

// ---------------------------------

const FlattedPhoneInput = ({ value, onChange, placeholder }) => {
    return (
        <div className="flatted-phone-input">
            <ConfigProvider
                locale={i18n.language == "en" ? en : pt}
                areaSorter={(a, b) => {
                    if (a.name && b.name) {
                        const nameA = a.name.toUpperCase();
                        const nameB = b.name.toUpperCase();
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0;
                    }
                    return 0;
                }}
            >
                <CountryPhoneInput
                    placeholder={placeholder}
                    type="number"
                    value={{
                        short: (value && value.country) ? value.country : "US",
                        code: (value && value.code) ? value.code : 1,
                        phone: (value && value.number) ? `${value.number}` : "",
                    }}
                    onKeyDown={(evt) => {
                        if (["e", "E", "+", "-"].includes(evt.key)) evt.preventDefault();
                    }}
                    selectProps={{
                        filterOption: (input, option) => {
                            const key = option?.key.toString().toLowerCase();
                            const inputText = input.toLowerCase().trim();
                            const lastSpaceIndex = key.lastIndexOf(" ");

                            const name = key.substring(0, lastSpaceIndex);
                            const code = key.substring(lastSpaceIndex + 1);
                            return name.startsWith(inputText) || code.startsWith(inputText);
                        },
                    }}
                    onChange={(value) => {
                        const phone = `+${value.code}-${value.phone}`;
                        const values = {
                            country: value.short,
                            code: value.code,
                            number: value.phone,
                            phone: phone,
                            isValid: validators.phone(phone),
                        };
                        onChange(values);
                    }}
                />
            </ConfigProvider>
        </div>
    );
};

FlattedPhoneInput.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
};

// ---------------------------------

export { FlattedInput, FlattedMaskedInput, FlattedLabelInput, FlattedDropdownInput, FlattedPhoneInput, FlattedInputNumber, FlattedInputCurrency };

export default FlattedInput;
