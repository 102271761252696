import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
//
import StateStatus from '../../utils/stateStatus'
import updPassword from './RecoveryPasswordServices'

const initialState = {
	status: StateStatus.idle,
	
	msg: "",
}

export const sendPassword = createAsyncThunk(
	'upd-password',
	async (data) => {
		// data = {token, new_password}
		const response = await updPassword(data)

		return response
	}
)

export const recoveryPassword = createSlice({
	name: 'recovery-password',
	initialState,
	reducers: {
		clearStatus: (state) => {
			state.status = StateStatus.idle
		}
	},
	extraReducers: {
		[sendPassword.pending]: (state) => {
			state.status = StateStatus.loading
		},
		[sendPassword.fulfilled]: (state, action) => {
			state.msg = action.payload.data.msg || 'Senha atualizada com sucesso'
			
			state.status = StateStatus.succeeded
		},
		[sendPassword.rejected]: (state, action) => {
			state.msg = action.error.message || 'Erro ao atualizar senha'
			
			state.status = StateStatus.failed
		},
	},
})

export const { clearStatus } = recoveryPassword.actions
export const selectRecoveryPassword = (state) => state.recoveryPassword

export default recoveryPassword.reducer
