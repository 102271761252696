
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Spin } from 'antd';
// 
import { confirmEmail } from './ConfirmEmailService';
import { changeObjAlert, showAlert } from '../../common/components/Alert/alertSlice';

const ConfirmEmail = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()

  const alert = (type, title, text, question, cancelText, okText, onOk, onCancel) => {
    dispatch(changeObjAlert({
      show: true,
      type: type,
      title: title,
      text: text,
      okText: okText,
      question: question,
      cancelText: cancelText,
      onCancel: () => { dispatch(showAlert(false)); onCancel; },
      onOk: () => { onOk; }
    }))
  }

  useEffect(() => {
    const query = new URLSearchParams(window.location.hash.split('?')[1]);
    const token = query.get("token");

    confirmEmail(token).then(() => {
      alert("success", t("Sucesso"), t("E-mail atualizado com sucesso"), null, null, null, null, () => { history.replace("/") })

      setTimeout(() => {
        history.replace("/")
      }, 4000);
    }).catch(error => {
      console.log(`erro on confirm email: ${error}`);
      alert("error", t("Erro"), t("Não foi possível atualizar seu e-mail. Tente novamente"), null, null, null, null, () => { history.replace("/") })

      setTimeout(() => {
        history.replace("/")
      }, 4000);
    })
  }, [])

  return <div style={{ width: "100%", height: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
    <Spin />
  </div>;
}

export default ConfirmEmail;
