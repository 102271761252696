import Contractor from "./Contractor";
import Influencer from "./Influencer";
class Campaign {
    id;
    name;
    endDate;
    startDate;
    draft;
    status;
    metrics;
    objective;
    started;
    coin;
    currency = "";
    type = "hashtag";
    integrations = {};
    mentions = [];
    performance = 0;
    influencers = [];
    contractors = [];
    site = {
        domain: "",
        destiny: "",
        type: "comercial",
        goals: [],
    };
    networks = {
        instagram: 0,
        facebook: 0,
        twitter: 0,
        youtube: 0,
        pinterest: 0,
        tiktok: 0,
    };

    // metrics
    feedsTotal = 0;
    storiesTotal = 0;
    investmentTotal = 0;
    hiredPostsType;
    // only tikitok
    budgetType;
    budgetValue;
    objectiveTT;
    //
    locations;
    bid;
    conversionParameter;
    frequencyScheduling;
    frequency;
    categories;
    interests;
    orderId;
    trackedLink;
    analyticsConnectUrl;
    // list
    fromJson(data) {
        this.id = data.id;
        this.name = data.name;
        this.status = data.status || "active";
        this.type = data.campaign_type || "hashtag";
        this.endDate = data.end_date;
        this.startDate = data.start_date;
        this.objectives = data.objective_types;
        this.draft = data.is_draft;
        this.performance = data.performance || 0;
        this.charts = data.charts ? data.charts : [];
        this.chartEcommerce = data.chart_ecommerce ? data.chart_ecommerce : [];
        this.analyticsConnectUrl = data.analytics_connect_url;

        this.trackedLink = data.tracked_link;
        this.hiredPostsType = data.hired_posts_types;
        this.currency = data.currency;

        if (this.type != "hashtag" && data.influencer_contracts) {
            data.influencer_contracts.forEach((inf) => {
                const influencer = new Influencer();
                influencer.fromJson(inf);

                this.feedsTotal += influencer.contractFeeds;
                this.storiesTotal += influencer.contractStories;
                this.investmentTotal += influencer.investmentTotal;

                this.influencers.push(influencer);
                this.networks[influencer.network] += 1;
            });
        }
        if (this.type == "influencers") {
            if (this.objectives.includes("social_media")) {
                this.metrics = data.metrics.map((metric) => {
                    return { alias: metric.label, value: metric.value };
                });
            } else {
                this.metrics = [
                    { alias: "clicks", value: 0 },
                    { alias: "cost_per_click", value: 0 },
                    { alias: "conversion_rate", value: 0 },
                    { alias: "roi", value: 0 },
                ];
            }
        } else {
            this.metrics = [
                { alias: "total_post_hashtags", value: 0 },
                { alias: "interactions_total", value: 0 },
                { alias: "engagement_rate", value: 0 },
            ];
            this.metrics = data.metrics.map((metric) => {
                let alias = metric.label;

                if (alias === "posts") alias = "total_post_hashtags";
                else if (alias === "interactions") alias = "interactions_total";

                return { alias, value: metric.value };
            });
        }

        this.started = this._verifyStatus();
        this.isTiktokConnected = data.authenticators.filter((item) => item.provider === "tiktok").length > 0;
        return this;
    }

    // details
    detailsFromJson(data) {
        this.id = data.id;
        this.name = data.name;
        this.status = data.status;
        this.startDate = data.start_date;
        this.endDate = data.end_date;
        this.performance = data.performance || 0;
        this.metrics = data.metrics;
        this.charts = data.charts;
        this.type = data.campaign_type;
        this.objectives = data.objective_types;
        this.chartEcommerce = data.chart_ecommerce;
        this.trackedLink = data.tracked_link;
        this.mentions = [...data.hashtags, ...data.mentions];
        this.hiredPostsType = data.hired_posts_types;
        this.analyticsConnectUrl = data.analytics_connect_url;
        this.googleAccountInformation = data.google_account_information;

        const coins = {
            BRL: "R$",
            USD: "$",
            EUR: "€",
        };

        this.currency = coins[data.currency];

        if (data.contractor_profiles) {
            data.contractor_profiles.forEach((ctrc) => {
                const contractor = new Contractor();
                contractor.detailsFromJson(ctrc);

                this.contractors.push(contractor);
            });
        }

        if (data.influencer_contracts) {
            data.influencer_contracts.forEach((inf) => {
                this.networks[inf.profile.social_network] += 1;
            });
        }

        this.started = this._verifyStatus();
        this.isTiktokConnected = data.authenticators.filter((item) => item.provider === "tiktok").length > 0;

        this.site = {
            domain: data.site_domain ? data.site_domain : "",
            destiny: data.site_url ? data.site_url : "",
            type: data.analytics_integration_type ? data.analytics_integration_type : "comercial",
            goals: data.analytics_goals,
        };
        return this;
    }

    // create campaign
    ccFromJson(data) {
        const coins = [
            { value: "BRL", key: "Real brasileiro", symbol: "R$" },
            { value: "USD", key: "Dólar estadunidense", symbol: "$" },
            { value: "EUR", key: "Euro", symbol: "€" },
        ];

        this.id = data.id;

        this.name = data.name;
        this.startDate = data.start_date ? { date: new Date(data.start_date) } : null;
        this.endDate = data.end_date ? { date: new Date(data.end_date) } : null;
        this.type = data.campaign_type || "hashtag";
        this.analyticsConnectUrl = data.analytics_connect_url;
        this.coin = coins.find((c) => c.value == data.currency);

        this.contractors = data.contractor_profiles.map((c) => {
            let contractor = new Contractor();
            return contractor.fromJson(c);
        });

        this.mentions = [...data.mentions, ...data.hashtags];

        this.objectives = data.objective_types || [];

        this.influencers = data.influencer_contracts.map((c) => {
            let influencer = new Influencer();
            return influencer.fromJson(c);
        });

        if (data.google_account_information) this.integrations["google"] = data.google_account_information;

        this.site = {
            domain: data.site_domain ? data.site_domain : "",
            destiny: data.site_url ? data.site_url : "",
            type: data.analytics_integration_type ? data.analytics_integration_type : "comercial",
            goals: [],
        };

        return this;
    }

    _verifyStatus() {
        if (this.startDate && this.endDate) {
            let start = new Date(this.startDate).getTime();
            let now = new Date().getTime();

            return now >= start;
        }
    }
}

export default Campaign;
