import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import "./styles.scss";

const HypoModal = ({ isVisible, children, onClose }) => {
    return (
        <Modal onCancel={onClose} className="hypomodal" visible={isVisible}>
            {children}
        </Modal>
    )
}

HypoModal.propTypes = {
    isVisible: PropTypes.bool,
    children: PropTypes.any,
    onClose: PropTypes.func
}

export default HypoModal;