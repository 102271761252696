import React from "react";
import PropTypes from "prop-types"
import { showAlert } from "./alertSlice"
import { useDispatch } from "react-redux"
// 
import { Modal } from "antd";
import Button from "../../components/Button/Button"
// 
import "./Alert.scss";

const Alert = ({
  type,
  show,
  onOk,
  onCancel,
  title,
  text,
  question,
  okText,
  colorCancel, 
  colorOk, 
  cancelText,
}) => {
  const dispatch = useDispatch()

  const icons = {
    "success": <i className="icon icon-check" />,
    "error": <i className="icon icon-close" />,
    "warning": <i className="icon icon-alert" />,
    "info": <i className="icon icon-info" />,
  }

  return <Modal
    title="Modal"
    visible={show}
    onOk={onOk}
    onCancel={() => {
      dispatch(showAlert(false))
    }}
    okText="OK"
    cancelText="Cancel"
    className="modal"
  >
    <div className={`container-icon container-` + type} >{icons[type]}</div>

    <div className="container-content">
      <h2 className={`title title-` + type}>{title || type}!</h2>

      { text && <p className="text">{text}</p> }

      { question && <p className="question">{question}</p> }

      <div className="buttons">
        { cancelText && onCancel && 
          <Button 
            color={colorCancel}
            outline={true}
            text={cancelText || "Cancel"} 
            onClick={() => {
              onCancel();
              dispatch(showAlert(false))
            }}
          /> 
        }

        { okText && onOk &&  
          <Button 
            text={okText || "Ok"} 
            onClick={() => {
              onOk();
              setTimeout(() => {
                dispatch(showAlert(false))
              }, 200)
            }}
            color={colorOk}
          />
        } 
      </div>
    </div>
  </Modal>
}

Alert.defaultProps = {
  type: "success",
  colorCancel: "grey", 
  colorOk: "danger", 
}

Alert.propTypes = {
  show: PropTypes.bool,
  onOk: PropTypes.any,
  onCancel: PropTypes.any,
  type: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
  okText: PropTypes.string,
  colorOk: PropTypes.string,
  question: PropTypes.string,
  cancelText: PropTypes.string,
  colorCancel: PropTypes.string,
  render: PropTypes.func
}

export default Alert;