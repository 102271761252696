import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
//
import { Form } from 'antd'
//
import { useDispatch, useSelector } from 'react-redux'
import { sendPassword, selectRecoveryPassword, clearStatus } from './RecoveryPasswordSlice'
import { changeObjAlert, showAlert } from '../../common/components/Alert/alertSlice'
//
import InputLabel from '../../common/components/InputLabel/InputLabel'
import Button from '../../common/components/Button/Button'
import logoSymbol from "../../common/assets/icons/o.svg";
import StateStatus from '../../utils/stateStatus'
// 
import './RecoveryPassword.scss'


const RecoveryPassword = () => {
	const { t } = useTranslation();
	// 
	const dispatch = useDispatch()
	const history = useHistory()
	// 
	const rp = useSelector(selectRecoveryPassword)
	// 
	const [token, setToken] = useState()
	const [email, setEmail] = useState()
	// 
	const [confirmPass, setConfirmPass] = useState('')
	const [passValidation, setPassValidation] = useState({})
	// 
	const [form] = Form.useForm()

	const passwordRequests = [
		{ text: 'Minimo 6  caracteres; ', alias: 'hasSixLength' },
		{ text: 'Letras maiúscula; ', alias: 'hasOneCapitalLetter' },
		{ text: 'Letras minúsculas; ', alias: 'hasOneLowerLetter' },
		{ text: 'Pelo menos um numeral; ', alias: 'hasOneNumber' },
		{
			text: 'Pelo menos um caractere especial (@, #, $ e etc); ',
			alias: 'hasCharacterSpecial',
		},
	]

	const alert = (type, title, text, question, cancelText, okText, onOk) => {
		dispatch(
			changeObjAlert({
				show: true,
				type,
				title,
				text,
				okText,
				question,
				cancelText,
				onCancel: () => {
					dispatch(showAlert(false))
				},
				onOk: onOk || (() => { }),
			})
		)
	}

	const passwordValidation = (value) => {
		const result = {
			value,
			hasSixLength: value.length >= 8,
			hasOneNumber: value.search(/\d/g) !== -1,
			hasOneCapitalLetter: value.search(/[A-Z]/g) !== -1,
			hasOneLowerLetter: value.search(/[a-z]/g) !== -1,
			hasCharacterSpecial:
				value.search(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g) !== -1, // eslint-disable-line
		}

		setPassValidation(result)

		return result
	}

	const onFinish = async (values) => {
		dispatch(sendPassword({
			token,
			email,
			password: values.new_password,
		}))
	}

	// SHOW ALERT
	useEffect(() => {
		const query = new URLSearchParams(window.location.hash.split('?')[1]);

		if (query.get("token") && query.get("email")) {
			setToken(query.get("token"))
			setEmail(query.get("email"))
		} else {
			alert('error', t('erro'), t("É preciso do token e do e-mail de acesso para poder atualizar sua senha! Entre pelo link enviado ao seu e-mail para que o consiga."))
		}

		if (rp.status === StateStatus.failed) {
			alert('error', t('erro'), t(rp.msg))
		} else if (rp.status === StateStatus.succeeded) {
			alert('success', t('sucesso'), t(rp.msg))
			history.push("/login")
		}

		dispatch(clearStatus())
	}, [rp.status])

	return <div className="rp-page">
		<div className="background-themes"></div>
		
		<div className="rp-container">
			<img 
				className="logo-hypolake" 
				src={logoSymbol} 
				onClick={() => history.push("/login")}
				alt="logo hypolake"
			/>

			<Form form={form} onFinish={onFinish} layout="vertical">
				<Form.Item
					name="new_password"
					label={t("Senha")}
					rules={[{
						required: true,
						message: <span style={{ display: 'none' }}>/</span>,
					},
					() => ({
						validator(_, value) {
							const falses = Object.entries(passValidation).filter(
								(item) => item[1] === false
							)
							if (falses.length > 0 || !value) {
								return Promise.reject(new Error(t('Senha inválida')))
							}
							return Promise.resolve()
						},
					}),
					]}
				>
					<InputLabel
						isPassword={true}
						type="password"
						onChange={(event) => passwordValidation(event.target.value)}
						placeholder={t("Informe a senha")}
					/>
				</Form.Item>

				<div className="password-requests">
					<p className="pr-title">{t("Sua senha tem que possuir")}:</p>

					<ul>
						{passwordRequests.map((item) => (
							<li
								key={item.alias}
								className={passValidation[item.alias] ? 'checked' : ''}
							>
								{item.text}{' '}
								{passValidation[item.alias] && (
									<i className="icon icon-checkmark" />
								)}
							</li>
						))}
					</ul>
				</div>

				<Form.Item
					name="confirm_password"
					label={t("Confirmar senha")}
					rules={[{
						required: true,
						message: <span style={{ display: 'none' }}>/</span>,
					},
					() => ({
						validator() {
							const falses = Object.entries(passValidation).filter(
								(item) => item[1] === false
							)

							if (falses.length > 0) {
								return Promise.reject(new Error(t('Senha inválida')))
							}
							if (confirmPass !== passValidation.value) {
								return Promise.reject(new Error(t('As senhas não coincidem')))
							}

							return Promise.resolve()
						},
					}),
					]}
				>
					<InputLabel
						isPassword={true}
						placeholder={t("Confirme a senha")}
						type="password"
						onChange={(event) => {
							if (event.target.value) setConfirmPass(event.target.value)
						}}
					/>
				</Form.Item>

				<Button
					width="100%"
					loading={rp.status === StateStatus.loading}
					type="submit"
					htmlType="submit"
					className="button-submit"
					text={t("ALTERAR SENHA")}
					disabled={!token || !email}
				/>
			</Form>

			<button
				className="button-register"
				onClick={() => history.push("/login")}
			>
				{t("Fazer login")}
			</button>
		</div>
	</div>
}

export default RecoveryPassword
