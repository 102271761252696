import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile, selectCampaign, updateInfluencer } from "../../../createCampaignSlice";
//
import { updInfluencer } from "../../../CreateCampaignService";
import Button from "../../../../../common/components/Button/Button";
import Input from "../../../../../common/components/Input/Input";
import Radio from "../../../../../common/components/Radio/Radio";
import Tooltip from "../../../../../common/components/Tooltip/Tooltip";
//
import CurrencyFormat from "react-currency-format";
import checkUrl from "../../../../../utils/verifications";
//
import "./AddInstagramInfluencer.scss";

const AddInstagramInfluencer = ({ influencer, closeModal }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const objCampaign = useSelector(selectCampaign);

    const [error, setError] = useState(-1);
    //
    const [url, setUrl] = useState("");
    const [email, setEmail] = useState("");
    //
    const [quantPosts, setQuantPosts] = useState("");
    const [investmentPosts, setInvestmentPosts] = useState("");
    //
    const [quantStories, setQuantStories] = useState("");
    const [investmentStories, setInvestmentStories] = useState("");
    //
    const [quantVideos, setQuantVideos] = useState("");
    const [investmentVideos, setInvestmentVideos] = useState("");
    //
    const [typeContract, setTypeContract] = useState(!influencer ? "cachet" : null);

    const options = [
        { value: "cachet", label: t("Cachê ($)") },
        { value: "exchange", label: t("Permuta") },
    ];

    const sendInfluencer = async (edit = false) => {
        if (!checkUrl(url, !influencer ? objCampaign.campaign.influencers : null)) {
            setError(0); // error on input url
            return;
        }

        const isInstagram = url.includes("instagram.com");

        const nonZero = [quantPosts, quantStories, quantVideos].some((item) => item && parseInt(item) > 0);

        if (isInstagram) {
            if (!nonZero) {
                setError(3);
                return;
            }
            const isEmailValid = email
                ? /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/gi.test(
                      email
                  )
                : false;

            if (!isEmailValid) {
                setError(1);
                return;
            }
        }

        if (!edit) {
            // create
            const data = {
                quant_post: parseInt(quantPosts) || 0,
                investment_post: parseFloat(investmentPosts) || 0,
                quant_stories: parseInt(quantStories) || 0,
                investment_stories: parseFloat(investmentStories) || 0,
                quant_videos: parseInt(quantVideos) || 0,
                investment_videos: parseFloat(investmentVideos) || 0,
                type_contract: typeContract,
                email: email.length > 0 ? email : undefined,
            };

            dispatch(fetchProfile({ url: url, type: "influencer", additionalData: data }));

            setUrl("");
            setQuantPosts("");
            setInvestmentPosts("");
            setQuantStories("");
            setInvestmentStories("");
            setQuantVideos("");
            setInvestmentVideos("");
            setEmail("");
        } else {
            influencer.contractFeeds = quantPosts != "" ? parseInt(quantPosts) : 0;
            influencer.investmentFeeds =
                investmentPosts != "" ? parseFloat(investmentPosts.replace(/[^0-9.]+/g, "")) : 0;
            influencer.contractStories = quantStories != "" ? parseInt(quantStories) : 0;
            influencer.investmentStories =
                investmentStories != "" ? parseFloat(investmentStories.replace(/[^0-9.]+/g, "")) : 0;
            influencer.contractVideos = quantVideos != "" ? parseInt(quantVideos) : 0;
            influencer.investmentVideos =
                investmentVideos != "" ? parseFloat(investmentVideos.replace(/[^0-9.]+/g, "")) : 0;

            influencer.typeContract = typeContract;
            influencer.email = email;
            influencer.investmentTotal =
                (investmentStories != "" ? parseFloat(investmentStories) : 0) +
                (investmentPosts != "" ? parseFloat(investmentPosts) : 0) +
                (investmentVideos != "" ? parseFloat(investmentVideos) : 0);

            await updInfluencer(objCampaign.campaign.id, influencer.id, {
                contract_type: influencer.typeContract,
                posts_count: influencer.contractFeeds,
                posts_value: influencer.investmentFeeds,
                stories_count: influencer.contractStories,
                stories_value: influencer.investmentStories,
                videos_count: influencer.contractVideos,
                videos_value: influencer.investmentVideos,
                email: influencer.email?.length > 0 ? email : undefined,
            }).then(() => {
                dispatch(updateInfluencer({ influencer: influencer }));
            });
            setError(-1);
            closeModal();
        }
    };

    const calcTotal = () => {
        let value = 0;

        if (investmentPosts && investmentPosts.length > 0) value += parseFloat(investmentPosts);

        if (investmentStories && investmentStories.length > 0) value += parseFloat(investmentStories);

        if (investmentVideos && investmentVideos.length > 0) value += parseFloat(investmentVideos);

        return value == 0 ? "0.00" : value;
    };

    useEffect(() => {
        if (influencer) {
            // set data
            setUrl(influencer.profileUrl);
            setEmail(influencer.email);
            setQuantPosts(influencer.contractFeeds == 0 ? "" : `${influencer.contractFeeds}`);
            setInvestmentPosts(influencer.investmentFeeds == 0 ? "" : `${influencer.investmentFeeds}`);
            setQuantStories(influencer.contractStories == 0 ? "" : `${influencer.contractStories}`);
            setInvestmentStories(influencer.investmentStories == 0 ? "" : `${influencer.investmentStories}`);
            setQuantVideos(influencer.contractVideos == 0 ? "" : `${influencer.contractVideos}`);
            setInvestmentVideos(influencer.investmentVideos == 0 ? "" : `${influencer.investmentVideos}`);
            setTypeContract(influencer.typeContract);
        } else {
            // clear form
            setUrl("");
            setEmail("");
            setQuantPosts("");
            setInvestmentPosts("");
            setQuantStories("");
            setInvestmentStories("");
            setQuantVideos("");
            setInvestmentVideos("");
        }
    }, [influencer]);

    return (
        <div className="container-add-influencer">
            <p className="label-input">{t("Insira a URL completa do perfil público do influenciador")}</p>
            {/* URL */}
            <Input
                width="100%"
                value={url}
                error={error == 0}
                disabled={!!influencer}
                textError={t("URL inválida ou perfil/fanpage não é pública. Verifique se você já adicionou essa url")}
                placeholder="URL"
                onChange={(value) => {
                    setUrl(value);
                    if (error == 0) setError(-1);

                    if (
                        !value.includes("instagram.com") &&
                        (quantStories != "" || investmentStories != "" || quantVideos != "" || investmentVideos != "")
                    ) {
                        setInvestmentStories("");
                        setQuantStories("");
                        setInvestmentVideos("");
                        setQuantVideos("");
                        setEmail("");
                    }
                }}
            />
            <p className="label-input email-label">{t("E-mail de contato do influenciador")}</p>
            {/* URL */}
            <Input
                width="100%"
                value={email}
                error={error == 1}
                textError={t("Insira um e-mail válido")}
                placeholder="E-mail"
                onChange={(value) => {
                    setEmail(value);
                    if (error == 1) setError(-1);
                }}
            />
            <div className="posts-error">
                {error === 3 && t("Voce deve informar a quantidade de pelo menos post, stories ou reels.")}
            </div>
            {/* POSTSTORIES */}
            <div className="container-data-posts">
                {/* posts */}
                <div className="card-post-data">
                    <div className="card-header">
                        <p className="card-header-title">{t("Posts contratados do influenciador")}</p>
                        <Tooltip
                            child={t("Quantidade de post que o influenciador foi contratado para fazer na campanha.")}
                        />
                    </div>
                    <div className="card-body">
                        <Input
                            width="48%"
                            onlyNum={true}
                            error={error == 3}
                            value={quantPosts}
                            textError={t("Informe a quantia")}
                            placeholder={t("Quantidade contratada")}
                            onChange={(value) => {
                                setQuantPosts(value);
                                if (error == 3) setError(-1);
                            }}
                        />

                        <CurrencyFormat
                            value={investmentPosts}
                            decimalSeparator={"."}
                            thousandSeparator={","}
                            placeholder={t("Investimento")}
                            prefix={`${objCampaign.campaign.coin.symbol} `}
                            className={`ant-input input-currency ${error == 2 ? "input-error" : ""}`}
                            onValueChange={(value) => {
                                setInvestmentPosts(value.value);
                                if (error == 3) setError(-1);
                            }}
                        />

                        {error == 2 && <span className="span-error">{t("Informe o investimento")}</span>}
                    </div>
                </div>

                {/* stories */}
                <div className={`card-post-data ${url.includes("instagram.com") ? "" : "blocked"}`}>
                    <div className="card-header">
                        <p className="card-header-title">{t("Stories contratados do influenciador")}</p>
                        <Tooltip
                            child={t(
                                "Quantidade de stories que o influenciador foi contratado para fazer na campanha."
                            )}
                        />
                    </div>
                    <div className="card-body">
                        <Input
                            width="48%"
                            onlyNum={true}
                            error={error == 3}
                            disabled={!url.includes("instagram.com")}
                            textError={t("Informe a quantia")}
                            placeholder={t("Quantidade contratada")}
                            value={url.includes("instagram.com") ? quantStories : ""}
                            onChange={(value) => {
                                setQuantStories(value);
                                if (error == 3) setError(-1);
                            }}
                        />

                        <CurrencyFormat
                            value={investmentStories}
                            className={`ant-input input-currency ${error == 4 ? "input-error" : ""}`}
                            decimalSeparator={"."}
                            thousandSeparator={","}
                            placeholder={t("Investimento")}
                            prefix={`${objCampaign.campaign.coin.symbol} `}
                            disabled={!url.includes("instagram.com")}
                            onValueChange={(value) => {
                                setInvestmentStories(value.value);
                                if (error == 3) setError(-1);
                            }}
                        />
                        {error == 4 && <span className="span-error">{t("Informe o investimento")}</span>}
                    </div>
                </div>

                {/* reels  */}
                <div className={`card-post-data ${url.includes("instagram.com") ? "" : "blocked"}`}>
                    <div className="card-header">
                        <p className="card-header-title">{t("Reels contratados do influenciador")}</p>
                        <Tooltip
                            child={t("Quantidade de reels que o influenciador foi contratado para fazer na campanha.")}
                        />
                    </div>
                    <div className="card-body">
                        <Input
                            width="48%"
                            onlyNum={true}
                            error={error == 3}
                            disabled={!url.includes("instagram.com")}
                            textError={t("Informe a quantia")}
                            placeholder={t("Quantidade contratada")}
                            value={url.includes("instagram.com") ? quantVideos : ""}
                            onChange={(value) => {
                                setQuantVideos(value);
                                if (error == 3) setError(-1);
                            }}
                        />

                        <CurrencyFormat
                            value={investmentVideos}
                            className={`ant-input input-currency ${error == 6 ? "input-error" : ""}`}
                            decimalSeparator={"."}
                            thousandSeparator={","}
                            placeholder={t("Investimento")}
                            prefix={`${objCampaign.campaign.coin.symbol} `}
                            disabled={!url.includes("instagram.com")}
                            onValueChange={(value) => {
                                setInvestmentVideos(value.value);
                                if (error == 6) setError(-1);
                            }}
                        />
                        {error == 6 && <span className="span-error">{t("Informe o investimento")}</span>}
                    </div>
                </div>

                {/* total */}
                <div className={`card-post-data card-total ${url.includes("instagram.com") ? "" : "blocked"}`}>
                    <div className="card-header">
                        <p className="card-header-title">{t("Investimento total")}</p>
                        <Tooltip child={t("Valor total pago para o influenciador fazer as publicações da campanha.")} />
                    </div>
                    <div className="card-body">
                        <CurrencyFormat
                            disabled={true}
                            value={calcTotal()}
                            decimalSeparator={"."}
                            thousandSeparator={","}
                            prefix={`${objCampaign.campaign.coin.symbol} `}
                            className="ant-input input-currency"
                            style={{ width: "100%" }}
                        />
                    </div>
                </div>
            </div>
            {/* ADD */}
            <div className="junction-type-save">
                <div className="contract-type">
                    <p className="text">{t("Tipo de contrato")}</p>

                    <Radio
                        cardStyle={false}
                        options={options}
                        initialValue={!influencer ? 0 : influencer.typeContract === "cachet" ? 0 : 1}
                        onChange={(option) => {
                            setTypeContract(option.value);
                        }}
                    />
                </div>

                {!influencer ? (
                    <Button
                        icon="plus"
                        color="success"
                        text={t("Adicionar perfil")}
                        onClick={() => sendInfluencer()}
                        loading={objCampaign.status == "loading"}
                    />
                ) : (
                    <Button
                        icon="save"
                        color="success"
                        text={t("Salvar")}
                        onClick={() => sendInfluencer(true)}
                        loading={objCampaign.status == "loading"}
                    />
                )}
            </div>
        </div>
    );
};

AddInstagramInfluencer.propTypes = {
    index: PropTypes.number,
    influencer: PropTypes.any,
    closeModal: PropTypes.func,
};

export default AddInstagramInfluencer;
