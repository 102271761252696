import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
// 
import { Tooltip } from 'antd';
// 
import { AirUtils } from "../../../utils/utils";
import { humanize } from "../../../utils/formatters";
// 
import iconTikTok from "../../assets/icons/tiktok.svg"
import "./CardVideoSimple.scss";

const CardVideoSimple = ({ post }) => {
    const { t } = useTranslation();
    // 
    const airUtils = new AirUtils();

    return <div className="card-post-video-simple">
        {post && <>
            <div className="container-post">
                <div 
                    className="post-content"
                    onClick={() => {
                        window.open(post.link)
                    }} 
                >
                    <div 
                        className="post-img" 
                        style={{ backgroundImage: `url(${airUtils.buildImage(post.image)})` }} 
                    />

                    <div className="container-play">
                        <div className="play" />
                        <i className="icon icon-tiktok" />
                    </div>
                </div>

                {<div className={`icon-background background-${post.network}`}>
                    <img src={iconTikTok} className="tiktok-icon" alt="tiktok icon"/>   
                </div>}
            </div>

            <div className="post-footer">
                {post.interactions.map(item => 
                    <Tooltip key={item.key} title={t(item.label)}>
                        <div className="junction">
                            <i className={`icon icon-${item.key}`} />
                            {humanize(item.value)}
                        </div>
                    </Tooltip>
                )}
            </div>
        </>
        }
    </div>
}

CardVideoSimple.propTypes = {
    post: PropTypes.any,
}

export default CardVideoSimple;