import React from 'react';
// 
import { Modal } from 'antd';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types';
// 
import Button from "../../Button/Button";
// 
import './TranslateModal.scss';
import i18n from '../../../../languages/i18n';
import { useDispatch } from 'react-redux';
import { setLanguagePreference } from '../../../../features/Login/LoginSlice';

  
const TranslateModal = ({ show, hideModal }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
        

    const langs = [
        { label: t("Português"), key: "pt" },
        { label: t("Inglês"), key: "en" },
        // { label: t("Espanhol"), key: "es" },
    ]

    const changeLanguage = (lang) => {
        if(localStorage.getItem("lang") != lang) {
            localStorage.setItem("lang", lang);
            i18n.changeLanguage(lang)

            const backendLanguage = lang === "pt" ? "pt_BR" : "en_US"
            dispatch(setLanguagePreference(backendLanguage))
            // window.location.reload();
        }

        hideModal();
    }

    return <Modal
        visible={show}
        onCancel={hideModal}
        className="translate-modal"
    >
        <p className="title-translate-modal">{t("Selecione o idioma desejado")}</p>
        
        <div className="buttons">
            { langs.map((lang, key) => <Button 
                key={key}
                text={lang.label}
                className={"button-" + lang.key}
                outline={window.localStorage.getItem("lang") != lang.key}
                onClick={() => changeLanguage(lang.key)}
            />
            )}
        </div>
    </Modal>
}

TranslateModal.propTypes = {
    show: PropTypes.bool,
    hideModal: PropTypes.func,
}
  
export default TranslateModal