import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
//
import { useDispatch, useSelector } from "react-redux";
import { fetchCampaigns, selectCampaigns, clear, clearStatusAction } from "../../HomeSlice";
//
import Loading from "../../../../common/components/Loading/Loading";
import CampaignPreview from "../CampaignPreview/CampaignPreview";
import StateStatus from "../../../../utils/status";
//
import { changeObjAlert, showAlert } from "../../../../common/components/Alert/alertSlice";
import { clearStatusActionCD } from "../../../CampaignDetails/CampaignDetailsSlice";
import "./CampaignList.scss";
import { useHistory } from "react-router-dom";
import Pagination from "../../../../common/components/Pagination/Pagination";

const CampaignList = ({ filter }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const [selectedFilter, setSelectedFilter] = useState("");
    const [filterCampaigns, setFilterCampaigns] = useState([]);

    const dispatch = useDispatch();
    const objCampaigns = useSelector(selectCampaigns);

    // const handleScroll = () => {
    //     const body = document.querySelector(".scroll-content");

    //     if (
    //         body.scrollHeight - 40 < body.offsetHeight + body.scrollTop &&
    //         objCampaigns.data.page < objCampaigns.totalPages &&
    //         objCampaigns.status === StateStatus.succeeded
    //     ) {
    //         dispatch(fetchCampaigns({ page: objCampaigns.data.page + 1 }));
    //     }
    // };

    const sortCampaigns = () => {
        if (objCampaigns.results.length > 0 && filter != undefined && filter != selectedFilter) {
            setSelectedFilter(filter);

            const dataFilter = filter.includes("-") ? filter.split("-") : filter;

            const sorted = !filter.includes("status")
                ? [...objCampaigns.results].sort((a, b) => {
                      if (filter.includes("performance")) {
                          // performance
                          if (filter.includes("worse")) {
                              if (a[dataFilter[0]] < b[dataFilter[0]]) {
                                  return -1;
                              }
                              if (a[dataFilter[0]] > b[dataFilter[0]]) {
                                  return 1;
                              }
                          } else {
                              if (a[dataFilter[0]] > b[dataFilter[0]]) {
                                  return -1;
                              }
                              if (a[dataFilter[0]] < b[dataFilter[0]]) {
                                  return 1;
                              }
                          }
                          return 0;
                      }
                  })
                : [...objCampaigns.results].filter((campaign) => {
                      if (filter.includes("status")) {
                          // status
                          if (filter.includes("active")) {
                              return campaign.status == "active";
                          } else if (filter.includes("filed")) {
                              return campaign.status == "filed";
                          }
                      }
                  });
            setFilterCampaigns(sorted);
        }
    };

    const alert = (type, title, text, question, cancelText, okText, onOk) => {
        dispatch(
            changeObjAlert({
                show: true,
                type: type,
                title: title,
                text: text,
                okText: okText,
                question: question,
                cancelText: cancelText,
                onCancel: () => {
                    dispatch(showAlert(false));
                },
                onOk: () => {
                    onOk;
                },
            })
        );
    };

    // first get
    useEffect(async () => {
        await dispatch(clear());
        dispatch(fetchCampaigns({ page: objCampaigns.data.page || 1 }));
    }, []);

    // update list campaign
    useEffect(() => {
        if (objCampaigns.results) {
            setFilterCampaigns(objCampaigns.results);
            document.getElementsByClassName("scroll-content")[0].scrollTo({ top: -100, left: 0, behavior: "smooth" });
        }
    }, [objCampaigns.results]);

    // filter
    useEffect(() => {
        sortCampaigns();
    }, [filter]);

    // redirect after duplicate campaign
    useEffect(() => {
        if (objCampaigns.idToRedirectAfterDuplicate) {
            history.push(`/campaign/${objCampaigns.idToRedirectAfterDuplicate}`);
        }
    }, [objCampaigns.idToRedirectAfterDuplicate]);

    // infinite scroll
    // useEffect(() => {
    //     let body = document.querySelector(".scroll-content");

    //     body.addEventListener("scroll", handleScroll);

    //     return () => body.removeEventListener("scroll", handleScroll);
    // }, [objCampaigns]);

    useEffect(() => {
        if (objCampaigns.statusAction === StateStatus.succeeded) {
            alert("success", t("sucesso"), t(objCampaigns.msg));
        } else if (objCampaigns.statusAction === StateStatus.failed) {
            alert("error", t("erro"), t(objCampaigns.msg));
        }

        dispatch(clearStatusAction());
        dispatch(clearStatusActionCD());
    }, [objCampaigns.statusAction]);

    return (
        <div>
            {filterCampaigns.map((campaign, key) => {
                return (
                    <CampaignPreview
                        actionId={objCampaigns.campaignIdAction}
                        campaign={campaign}
                        key={key}
                        statusAction={objCampaigns.statusAction}
                        msg={objCampaigns.msg}
                    />
                );
            })}

            {objCampaigns.status === StateStatus.loading ? <Loading /> : null}

            {objCampaigns.totalPages > 0 && objCampaigns.itemsPerPage > 0 && objCampaigns.itemsCount > 0 && (
                <div className="pagination">
                    <Pagination
                        current={objCampaigns.data.page}
                        pageSize={objCampaigns.itemsPerPage}
                        total={objCampaigns.itemsCount}
                        onChange={(page) => {
                            dispatch(fetchCampaigns({ page: page }));
                        }}
                    />
                </div>
            )}

            {objCampaigns.status == StateStatus.succeeded &&
                filterCampaigns.length <= 0 &&
                objCampaigns.results.length <= 0 && (
                    <p className="text-empty-cmps">
                        {objCampaigns.data.search == "" ||
                        objCampaigns.data.search == undefined ||
                        objCampaigns.totalPages == 0
                            ? t("Você ainda não possui campanhas")
                            : `${t("A pesquisa por")} "${objCampaigns.data.search}" ${t("não retornou resultados.")}`}
                    </p>
                )}
        </div>
    );
};

CampaignList.propTypes = {
    filter: PropTypes.string,
    search: PropTypes.string,
};

export default CampaignList;
