import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
//
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeStep, selectCampaign, clearCampaign, updateStatusSave, saveStep } from "../../createCampaignSlice";
import { changeObjAlert, showAlert } from "../../../../common/components/Alert/alertSlice";
//
import Button from "../../../../common/components/Button/Button";
import Card from "../../../../common/components/Card/Card";
import ContractorTable from "../../../../common/components/ContractorTable/ContractorTable";
import InfluencerTable from "../../../../common/components/InfluencerTable/InfluencerTable";
import CardIntegrationProfile from "../../components/CardIntegrationProfile/CardIntegrationProfile";
import TagMentions from "../../components/TagMentions/TagMentions";
//
import { AirUtils } from "../../../../utils/utils";
import StateStatus from "../../../../utils/status";
import "./StepSave.scss";

const StepSave = () => {
    const { id } = useParams();
    const { t } = useTranslation();
    //
    const dispatch = useDispatch();
    const airUtils = new AirUtils();
    //
    const history = useHistory();
    const objCampaign = useSelector(selectCampaign);
    //
    const campaign = objCampaign.campaign;
    //
    const objects = {
        site: t("site / loja virtual"),
        social_media: t("redes sociais"),
    };

    const alert = (type, title, text, question, cancelText, okText, onOk) => {
        dispatch(
            changeObjAlert({
                show: true,
                type: type,
                title: title,
                text: text,
                okText: okText,
                question: question,
                cancelText: cancelText,
                onCancel: () => {
                    dispatch(showAlert(false));
                },
                onOk: () => {
                    onOk();
                },
            })
        );
    };

    useEffect(() => {
        if (objCampaign.statusSave === StateStatus.succeeded) {
            if (!window.localStorage.getItem("has_campaigns") && !id) {
                window.history.replaceState(null, "Home", "/#/create-campaign/concluded");
            }

            alert(
                "success",
                t("sucesso"),
                t(id ? "Sua campanha foi atualizada com sucesso!" : "Sua campanha foi criada com sucesso!")
            );

            history.replace("/");

            setTimeout(() => {
                dispatch(clearCampaign());
                window.location.href = id ? `/#/campaign/${id}/` : "/";
            }, 1000);
        } else if (objCampaign.statusSave === StateStatus.failed) {
            alert("error", t("erro"), t(objCampaign.errorMsg || "Erro ao logar com o TikTok!"));
        }

        dispatch(updateStatusSave());
    }, [objCampaign.statusSave]);

    return (
        <Card
            child={
                campaign.name != "" && (
                    <div className="body-save">
                        {/* name - data */}
                        <div className="name-date">
                            <h2 className="step-name">{campaign.name}</h2>
                        </div>

                        <p className="type-text">
                            <b>
                                {t("Campanha")} {campaign.name}:{" "}
                            </b>
                            {campaign.type != "hashtag"
                                ? t("Campanha por influenciadores")
                                : t("Campanha por hashtags")}
                        </p>

                        {campaign.description && (
                            <p className="type-text">
                                <b>{t("Descrição da campanha")}: </b>
                                {campaign.description}
                            </p>
                        )}

                        <p className="data-date">
                            <b className="period">{t("Período da campanha")}: </b>
                            {campaign.startDate.date && campaign.endDate.date && (
                                <>
                                    {airUtils.buildValue("date", new Date(campaign.startDate.date))}
                                    <span className="the"> - </span>
                                    {airUtils.buildValue("date", new Date(campaign.endDate.date))}
                                </>
                            )}
                        </p>

                        {/* type - objective */}
                        {campaign.type != "hashtag" && (
                            <p className="objective">
                                <b>{t("Objetivo")}: </b>
                                {campaign.objectives.map((item, key) => (
                                    <span key={key}>
                                        {" "}
                                        {key != 0 ? " - " : ""} {objects[item]}{" "}
                                    </span>
                                ))}
                            </p>
                        )}

                        {campaign.coin?.key && (
                            <p className="type-text">
                                <b>{t("Moeda")}</b>: {t(campaign.coin?.key)}
                            </p>
                        )}

                        {/* contractors */}
                        <div className="brand space-border">
                            <p className="subtitle">{t("Informações do contratante")}</p>

                            <p className="type-text">
                                <b>{t("Marca")}</b>: {t(campaign.brandName)}
                            </p>

                            {campaign.brandEmail && (
                                <p className="type-text">
                                    <b>{t("E-mail de contato")}</b>: {t(campaign.brandEmail)}
                                </p>
                            )}

                            {campaign.brandContactName && (
                                <p className="type-text">
                                    <b>{t("Contato")}</b>: {t(campaign.brandContactName)}
                                </p>
                            )}

                            {campaign.brandPhone && (
                                <p className="type-text">
                                    <b>{t("Telefone")}</b>: {t(campaign.brandPhone)}
                                </p>
                            )}
                        </div>

                        <div className="contractors space-border">
                            <p className="subtitle">{t("Perfil do contratante da campanha")}</p>
                            <ContractorTable contractors={campaign.contractors} />
                        </div>

                        {/* mentions */}
                        <div className="mentions space-border">
                            <p className="subtitle">
                                {campaign.type != "hashtag"
                                    ? t("Hashtags e perfis marcados nos posts da campanha")
                                    : t("Hashtags marcadas nos posts da campanha")}
                            </p>
                            <div className="tags-mentions">
                                {campaign.mentions.map((item, key) => {
                                    return <TagMentions key={key} index={key} text={item} />;
                                })}
                            </div>
                        </div>

                        {
                            /* integration-google */
                            campaign.type != "hashtag" && campaign.objectives.includes("site") && (
                                <>
                                    {
                                        <>
                                            {/* google account */}
                                            {campaign.integrations.google && (
                                                <div className="integration-google space-border">
                                                    <p className="subtitle">{t("Integração com o Google Analytics")}</p>
                                                    <div className="account">
                                                        <div className="profile">
                                                            <span>{t("Conta")}</span>
                                                            <CardIntegrationProfile
                                                                profile={campaign.integrations.google}
                                                                showEmail={true}
                                                            />
                                                        </div>
                                                        {campaign.site.goals.length > 0 && (
                                                            <div className="google-goals">
                                                                <span>
                                                                    {t("Meta")} ({campaign.site.goals.length})
                                                                </span>

                                                                <ul className="goals">
                                                                    {campaign.site.goals.map((goal, key) => {
                                                                        return (
                                                                            <li key={key} className="goal">
                                                                                - {goal.name};
                                                                            </li>
                                                                        );
                                                                    })}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}

                                            {/* urls */}
                                            {/* <div className="influencers space-border">
                                    <p className="subtitle">{t("Informações site / loja virtual") }</p>

                                    <p className="site-url">{t("URL de domínio do contratante")}: {campaign.site.domain}</p>
                                    <p className="site-url">{t("URL de destino do contratante")}: {campaign.site.destiny}</p>
                                </div> */}
                                        </>
                                    }{" "}
                                </>
                            )
                        }

                        {/* influencers */}
                        {campaign.influencers.length > 0 && (
                            <div className="influencers space-border">
                                <p className="subtitle">{t("Perfil dos influenciadores da campanha")}</p>
                                <InfluencerTable influencers={campaign.influencers} />
                            </div>
                        )}
                    </div>
                )
            }
            footer={
                <div className="cc-footer beetwen save-campaign">
                    <div className="button-step" onClick={() => dispatch(changeStep(objCampaign.index - 1))}>
                        <i className="icon icon-back" />
                        <span className="button-text deselect-text text-black">{t("Voltar")}</span>
                    </div>

                    <Button
                        color="success"
                        text={t("Salvar campanha")}
                        loading={objCampaign.statusSave === StateStatus.loading}
                        onClick={() => dispatch(saveStep({ step: "save", index: objCampaign.index }))}
                        icon="save"
                    />
                </div>
            }
        />
    );
};

export default StepSave;
