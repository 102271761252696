import api from "../../../service/api";

export const getInfluencers = async (data) => await api.post("/campaign/tiktok/discover", data);
export const getPosts = async (data) => await api.post("/campaign/tiktok/creator/posts", data);
export const getPossibleCampaigns = async (data) => await api.post("/campaign/influencers/campaign/search", data);
// 
export const addInfluencer = (id, data) => api.post(`campaign/${id}/influencers`, data);
 
// POST
// /v1/campaign/tiktok/{uid}/audience
// Tiktok update audience data

// POST
// /v1/campaign/tiktok/{uid}/location/search
// Tiktok search location ids

// POST
// /v1/campaign/tiktok/{uid}/recommended-interests/search
// Tiktok search interest ids

// POST
// /v1/campaign/tiktok/{uid}/interest-keywords/search
// Tiktok search interest ids

// POST
// /v1/campaign/tiktok/{uid}/category/search
// Tiktok search interest ids
// campaign-tiktok

// POST
// /v1/campaign/tiktok/discover
// Tiktok discover endpoint