import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Campaign from "../../models/Campaign";
import Feed from "../../models/Feed";
import Storie from "../../models/Storie";
import StateStatus from "../../utils/status";
import Influencer from "../../models/Influencer";
import Contractor from "../../models/Contractor";
import TiktokPostToApprove from "../../models/tiktokPostsToApprove";
import { filedCampaign, downloadCampaign, cloneCampaign } from "../Home/HomeService";
import {
    getCampaignFeeds,
    getCampaignStories,
    getCampaignInfluencers,
    getCampaignContractors,
    getAllInfluencerPosts,
    getAllInfluencerStories,
    getInfluencerMetrics,
    getContractorPosts,
    getInfluencerProfile,
    addPostToCampaign,
    removePostFromCampaign,
    addStorieToCampaign,
    removeStorieFromCampaign,
    requestSparkAdAuthorization,
    getFunnel,
    getHistogram,
    getCampaignVideos,
    getAllInfluencerVideos,
    addVideosToCampaign,
    removeVideosFromCampaign,
    getTikTokPostsToApprove,
    reproveTikTokPost,
    approveTikTokPost,
    getCampaignBasic,
    getCampaignLastUpdate,
} from "./CampaignDetailsService";

import { addIntegration } from "../CreateCampaign/CreateCampaignService";
import CampaignSummary from "../../models/CampaignSummary";
import Video from "../../models/Video";

const initialState = {
    // data
    influencerProfile: {},
    feedsPagination: {
        total: 0,
        totalPages: 0,
        itemsPerPage: 0,
    },
    storiesPagination: {
        total: 0,
        totalPages: 0,
        itemsPerPage: 0,
    },
    videosPagination: {
        total: 0,
        totalPages: 0,
        itemsPerPage: 0,
    },
    summary: {
        data: {},
        chart: {},
    },
    tiktokPostsToApprove: {
        status: {
            listPosts: StateStatus.idle,
            approvePost: StateStatus.idle,
            reprovePost: StateStatus.idle,
        },
        pagination: {
            page: 1,
            total: 0,
            totalPages: 0,
            itemsPerPage: 0,
        },
        posts: [],
        errMsg: null,
    },
    stories: [],
    videos: [],
    influencers: [],
    contractors: [],
    influencerMetrics: [],
    networks: [],
    network: null,
    campaign: null, // about
    lastUpdate: null,
    integrations: {},
    missingAnalyticsConnect: false,
    // ---------------
    // -- FILTERs ----
    // ---------------
    filterEndDate: null,
    filterStartDate: null,

    // ---------------
    // -- UTILS ------
    // ---------------
    urlTikTok: process.env.REACT_APP_TIKTOK_AUTH_URL,
    started: false,

    // ---------------
    // -- STATUS -----
    // ---------------
    status: StateStatus.idle,
    statusActionCD: StateStatus.idle,
    statusSparkAdAuthorization: StateStatus.idle,
    // Posts
    statusPosts: StateStatus.idle,
    statusStories: StateStatus.idle,
    statusVideos: StateStatus.idle,
    statusLastUpdate: StateStatus.idle,
    statusPostCampaign: StateStatus.idle,
    statusStoriesCampaign: StateStatus.idle,
    statusVideosCampaign: StateStatus.idle,
    statusTopPosts: StateStatus.idle, // feed, stories and videos - details
    // Influencer
    statusInfluencerMetrics: StateStatus.idle,
    statusInfluencerProfile: StateStatus.idle,
    // Contractor
    statusContractor: StateStatus.idle,
    // Funnel
    statusFunnel: StateStatus.idle,
    statusHistogram: StateStatus.idle,
    errorMsg: null,
};

// ---------------------
// -- ACTIONS ----------
// ---------------------
export const archiveCampaign = createAsyncThunk("filed/campaign", async (id) => {
    const response = await filedCampaign(id);

    return response;
});

export const duplicateCampaign = createAsyncThunk("duplicate/campaign", async (id) => {
    const response = await cloneCampaign(id);

    return response;
});

export const exportCampaign = createAsyncThunk("export/campaign", async (id) => {
    const response = await downloadCampaign(id);
    const { url } = response.data;

    window.open(url);

    return response;
});

// ---------------------
// -- GETS -------------
// ---------------------
export const fetchCampaign = createAsyncThunk("campaign", async (id) => {
    const response = await getCampaignBasic(id);

    let campaign = new Campaign();
    campaign.detailsFromJson(response.data);

    return campaign;
});

export const fetchCampaignLastUpdate = createAsyncThunk("campaign-last-update", async (id) => {
    const response = await getCampaignLastUpdate(id);
    return response.data;
});

// CAMPAIGN POSTs
//STARTS TIKTOK POSTS TO APPROVE
export const approvePostThunk = createAsyncThunk("tiktokPosts/approve", async (data) => {
    await approveTikTokPost(data.campaignId, data.orderId);
});

export const reproveTikTokPostThunk = createAsyncThunk("tiktokPosts/reprove", async (data) => {
    const response = await reproveTikTokPost(data.campaignId, data.orderId, data.reason);
    return response;
});

export const fetchTikTokPostsToApprove = createAsyncThunk("postsToApprove/list", async (data) => {
    const response = await getTikTokPostsToApprove(data.campaignId, data.page);

    const pagination = {
        page: data.page,
        total: response.data.count,
        totalPages: response.data.pages,
        itemsPerPage: response.data.items_per_page,
    };

    let videos = [];

    if (response.data.items) {
        response.data.items.forEach((item) => {
            const tiktokPost = new TiktokPostToApprove();
            videos.push(tiktokPost.fromJson(item));
        });
    }

    return {
        pagination,
        videos,
    };
});

// ENDS TIKTOK POSTS TO APPROVE

export const fetchCampaignPosts = createAsyncThunk("campaign/posts", async (data, thunkAPI) => {
    const { campaign } = thunkAPI.getState();
    const { id, page, nw, limit, isTiktok, filter } = data;

    const format = "YYYY-MM-DD";
    const startDate = campaign.filterStartDate ? campaign.filterStartDate.format(format) : null;
    const endDate = campaign.filterEndDate ? campaign.filterEndDate.format(format) : null;
    const response = await getCampaignFeeds(id, page, nw, limit, filter, startDate, endDate);

    const pagination = {
        total: response.data.count,
        totalPages: response.data.pages,
        itemsPerPage: response.data.items_per_page,
    };
    let feeds = [];

    if (response.data.items) {
        response.data.items.forEach((item) => {
            let feed = !isTiktok ? new Feed() : new Storie(); // to tiktok post
            feeds.push(feed.fromJson(item));
        });
    }

    return {
        pagination,
        feeds,
    };
});
export const fetchCampaignStories = createAsyncThunk("campaign/stories", async (data, thunkAPI) => {
    const { campaign } = thunkAPI.getState();
    const { id, page, nw, limit, filter } = data;

    const format = "YYYY-MM-DD";
    const startDate = campaign.filterStartDate ? campaign.filterStartDate.format(format) : null;
    const endDate = campaign.filterEndDate ? campaign.filterEndDate.format(format) : null;

    const response = await getCampaignStories(id, page, nw, limit, filter, startDate, endDate);

    let stories = [];
    const pagination = {
        total: response.data.count,
        totalPages: response.data.pages,
        itemsPerPage: response.data.items_per_page,
    };

    if (response.data.items) {
        response.data.items.forEach((item) => {
            let storie = new Storie();
            stories.push(storie.fromJson(item));
        });
    }

    return {
        stories,
        pagination,
    };
});
export const fetchCampaignVideos = createAsyncThunk("campaign/videos", async (data, thunkAPI) => {
    const { campaign } = thunkAPI.getState();
    const { id, page, nw, limit, filter } = data;
    const format = "YYYY-MM-DD";
    const startDate = campaign.filterStartDate ? campaign.filterStartDate.format(format) : null;
    const endDate = campaign.filterEndDate ? campaign.filterEndDate.format(format) : null;

    const response = await getCampaignVideos(id, page, nw, limit, filter, startDate, endDate);

    let videos = [];

    const pagination = {
        total: response.data.count,
        totalPages: response.data.pages,
        itemsPerPage: response.data.items_per_page,
    };
    fetchTikTokPostsToApprove;
    if (response.data.items) {
        response.data.items.forEach((item) => {
            let video = new Video();
            videos.push(video.detailsFromJson(item));
        });
    }

    return {
        videos,
        pagination,
    };
});

// CAMPAIGN TOP POSTs
export const fetchCampaignTopPosts = createAsyncThunk("campaign/top-posts", async (data, thunkAPI) => {
    const { campaign } = thunkAPI.getState();
    const { id, page, nw, limit, filter } = data;

    const format = "YYYY-MM-DD";
    const startDate = campaign.filterStartDate ? campaign.filterStartDate.format(format) : null;
    const endDate = campaign.filterEndDate ? campaign.filterEndDate.format(format) : null;

    const response = await getCampaignFeeds(id, page, nw, limit, filter, startDate, endDate);

    let feeds = [];

    if (response.data.items) {
        response.data.items.forEach((item) => {
            let feed = new Feed();
            feeds.push(feed.fromJson(item));
        });
    }

    return feeds;
});
export const fetchCampaignTopStories = createAsyncThunk("campaign/top-stories", async (data, thunkAPI) => {
    const { campaign } = thunkAPI.getState();
    const { id, page, nw, limit, filter } = data;

    const format = "YYYY-MM-DD";
    const startDate = campaign.filterStartDate ? campaign.filterStartDate.format(format) : null;
    const endDate = campaign.filterEndDate ? campaign.filterEndDate.format(format) : null;

    const response = await getCampaignStories(id, page, nw, limit, filter, startDate, endDate);

    let stories = [];

    if (response.data.items) {
        response.data.items.forEach((item) => {
            let story = new Storie();
            stories.push(story.fromJson(item));
        });
    }

    return stories;
});
export const fetchCampaignTopVideos = createAsyncThunk("campaign/top-videos", async (data, thunkAPI) => {
    const { campaign } = thunkAPI.getState();
    const { id, page, nw, limit, filter } = data;

    const format = "YYYY-MM-DD";
    const startDate = campaign.filterStartDate ? campaign.filterStartDate.format(format) : null;
    const endDate = campaign.filterEndDate ? campaign.filterEndDate.format(format) : null;

    const response = await getCampaignVideos(id, page, nw, limit, filter, startDate, endDate);

    let videos = [];

    const pagination = {
        total: response.data.count,
        totalPages: response.data.pages,
        itemsPerPage: response.data.items_per_page,
    };

    if (response.data.items) {
        response.data.items.forEach((item) => {
            let video = new Video();
            videos.push(video.detailsFromJson(item));
        });
    }

    return {
        videos,
        pagination,
    };
});

// INFLUENCER
export const fetchInfluencers = createAsyncThunk("campaign/influencers", async (data, thunkAPI) => {
    const { campaign } = thunkAPI.getState();

    const { id, network, limit } = data;

    const format = "YYYY-MM-DD";
    const startDate = campaign.filterStartDate ? campaign.filterStartDate.format(format) : null;
    const endDate = campaign.filterEndDate ? campaign.filterEndDate.format(format) : null;

    const response = await getCampaignInfluencers(id, network, limit, startDate, endDate);

    let influencers = [];

    if (response.data) {
        response.data.influencers.forEach((inf) => {
            let influencer = new Influencer();
            influencers.push(influencer.detailsFromjson(inf));
        });
    }

    return influencers;
});
export const fetchInfluencerMetrics = createAsyncThunk("campaign/influencer-metrics", async (data, thunkAPI) => {
    const { campaign } = thunkAPI.getState();

    const format = "YYYY-MM-DD";
    const startDate = campaign.filterStartDate ? campaign.filterStartDate.format(format) : null;
    const endDate = campaign.filterEndDate ? campaign.filterEndDate.format(format) : null;

    const response = await getInfluencerMetrics(data.campaignId, data.airId, data.body, startDate, endDate);

    return response.data.counters;
});
export const fetchInfluencerProfile = createAsyncThunk("campaign/influencer-profile", async (data) => {
    const response = await getInfluencerProfile(data.campaignId, data.airId, data.body);

    const influ = new Influencer();
    influ.fromJson(response.data);

    return influ;
});

// INFLUENCER POSTs
export const fetchAllInfluencersPosts = createAsyncThunk("campaign/influencer-all-feeds", async (data, thunkAPI) => {
    const { campaign } = thunkAPI.getState();

    const format = "YYYY-MM-DD";
    const startDate = campaign.filterStartDate ? campaign.filterStartDate.format(format) : null;
    const endDate = campaign.filterEndDate ? campaign.filterEndDate.format(format) : null;

    const response = await getAllInfluencerPosts(
        data.campaignId,
        data.airId,
        data.page,
        data.filter,
        startDate,
        endDate
    );

    const posts = [];

    const pagination = {
        total: response.data.count,
        totalPages: response.data.pages,
        itemsPerPage: response.data.items_per_page,
    };

    if (response.data) {
        response.data.items.forEach((inf) => {
            let post = new Feed();
            posts.push(post.fromJson(inf));
        });
    }

    return {
        posts,
        pagination,
    };
});
export const fetchAllInfluencersStories = createAsyncThunk(
    "campaign/influencer-all-stories",
    async (data, thunkAPI) => {
        const { campaign } = thunkAPI.getState();

        const format = "YYYY-MM-DD";
        const startDate = campaign.filterStartDate ? campaign.filterStartDate.format(format) : null;
        const endDate = campaign.filterEndDate ? campaign.filterEndDate.format(format) : null;

        const response = await getAllInfluencerStories(
            data.campaignId,
            data.airId,
            data.page,
            data.filter,
            startDate,
            endDate
        );
        const posts = [];
        const pagination = {
            total: response.data.count,
            totalPages: response.data.pages,
            itemsPerPage: response.data.items_per_page,
        };
        if (response.data) {
            response.data.items.forEach((inf) => {
                let post = new Storie();
                posts.push(post.fromJson(inf));
            });
        }

        return {
            posts,
            pagination,
        };
    }
);
export const fetchAllInfluencersVideos = createAsyncThunk("campaign/influencer-all-videos", async (data, thunkAPI) => {
    const { campaign } = thunkAPI.getState();
    const format = "YYYY-MM-DD";
    const startDate = campaign.filterStartDate ? campaign.filterStartDate.format(format) : null;
    const endDate = campaign.filterEndDate ? campaign.filterEndDate.format(format) : null;

    const response = await getAllInfluencerVideos(
        data.campaignId,
        data.airId,
        data.page,
        data.filter,
        startDate,
        endDate
    );

    const videos = [];

    const pagination = {
        total: response.data.count,
        totalPages: response.data.pages,
        itemsPerPage: response.data.items_per_page,
    };
    if (response.data) {
        response.data.items.forEach((inf) => {
            let video = new Video();
            videos.push(video.detailsFromJson(inf));
        });
    }

    return {
        videos,
        pagination,
    };
});

// CONTRACTOR
export const fetchContractorPosts = createAsyncThunk("campaign/allInfluencerStories", async (data) => {
    const response = await getContractorPosts(data.campaignId, data.airId, data.page, data.body);

    const posts = [];

    const pagination = {
        total: response.data.count,
        totalPages: response.data.pages,
        itemsPerPage: response.data.items_per_page,
    };

    if (response.data) {
        response.data.items.forEach((inf) => {
            let post = new Storie();
            posts.push(post.fromJson(inf));
        });
    }

    return {
        posts,
        pagination,
    };
});
export const fetchContractors = createAsyncThunk("campaign/contractors", async (data, thunkAPI) => {
    const { campaign } = thunkAPI.getState();
    const { id, network } = data;

    const response = await getCampaignContractors(id, network, campaign.filterEndDate, campaign.filterStartDate);

    let contractor = new Contractor();
    let contractors = [contractor.detailsFromJson(response.data)];

    return contractors;
});

// FUNNEL
export const fetchFunnel = createAsyncThunk("campaign/funnel", async ({ id }) => {
    const response = await getFunnel(id);

    return response.data;
});
export const fetchHistogram = createAsyncThunk("campaign/histogram", async ({ id, name, filter, filterDate }) => {
    const response = await getHistogram(id, name, filter, filterDate);

    return response.data;
});

// ACTIONS
export const AttachPostToCampaign = createAsyncThunk("campaign/add-post-in-campaign", async (data) => {
    await addPostToCampaign(data.campaignId, data.postId);
});
export const DetachPostToCampaign = createAsyncThunk("campaign/add-post-in-campaign", async (data) => {
    await removePostFromCampaign(data.campaignId, data.postId);
});
export const attachStoriesToCampaign = createAsyncThunk("campaign/add-storie-in-campaign", async (data) => {
    await addStorieToCampaign(data.campaignId, data.postId, data.postType);
});
export const detachStoriesToCampaign = createAsyncThunk("campaign/add-storie-in-campaign", async (data) => {
    await removeStorieFromCampaign(data.campaignId, data.postId, data.postType);
});
export const attachVideoToCampaign = createAsyncThunk("campaign/add-video-in-campaign", async (data) => {
    await addVideosToCampaign(data.campaignId, data.postId);
});
export const detachVideoToCampaign = createAsyncThunk("campaign/add-video-in-campaign", async (data) => {
    await removeVideosFromCampaign(data.campaignId, data.postId);
});

export const sparkAdAuthorization = createAsyncThunk("campaign/sparkAd", async (data) => {
    await requestSparkAdAuthorization(data.campaignId, data.orderId, data.authorizationDays);
    return {
        orderId: data.orderId,
    };
});

export const addCampaignAuthenticator = createAsyncThunk("create-campaign/add-authenticator", async (data) => {
    const { campaignId, ...integrationData } = data;

    await addIntegration(campaignId, integrationData);
});
export const campaign = createSlice({
    name: "campaign",
    initialState: initialState,
    reducers: {
        filterDate: (state, action) => {
            state.filterEndDate = action.payload.endDate;
            state.filterStartDate = action.payload.startDate;
        },
        clearStatusApprovePost: (state) => {
            state.tiktokPostsToApprove.status.reprovePost = StateStatus.idle;
            state.tiktokPostsToApprove.status.approvePost = StateStatus.idle;
        },
        clearStatusPostCampaign: (state) => {
            state.statusPostCampaign = StateStatus.idle;
        },
        clearStatusActionCD: (state) => {
            state.statusActionCD = StateStatus.idle;
        },
        clearInfluencerProfile: (state) => {
            state.influencerProfile = {};
        },
        setTiktokAsConnected: (state) => {
            state.campaign = { ...state.campaign, isTiktokConnected: true };
        },
        resetStatusSparkAdAuthorization: (state) => {
            state.statusSparkAdAuthorization = StateStatus.idle;
        },
        clearCampaign: (state) => {
            state.feeds = [];
            state.stories = [];
            state.networks = [];
            state.influencers = [];
            state.contractors = [];

            state.started = false;
            state.campaign = null;

            state.filterEndDate = null;
            state.filterStartDate = null;

            state.status = StateStatus.idle;
            state.statusActionCD = StateStatus.idle;
        },
    },
    extraReducers: {
        // ACTIONS
        [archiveCampaign.pending]: (state) => {
            state.statusActionCD = StateStatus.loading;
        },
        [archiveCampaign.fulfilled]: (state) => {
            state.statusActionCD = StateStatus.succeeded;
            state.msg = "Campanha arquivada com sucesso!";
        },
        [archiveCampaign.rejected]: (state, action) => {
            state.statusActionCD = StateStatus.failed;
            state.msg = action.error.message || "Erro ao arquivar campanha!";
        },
        [duplicateCampaign.pending]: (state) => {
            state.statusActionCD = StateStatus.loading;
        },
        [duplicateCampaign.fulfilled]: (state) => {
            state.statusActionCD = StateStatus.succeeded;
            state.msg = "Campanha exportada com sucesso!";
        },
        [duplicateCampaign.rejected]: (state, action) => {
            state.statusActionCD = StateStatus.failed;
            state.msg = action.error.message || "Erro ao duplicar campanha!";
        },
        [exportCampaign.pending]: (state) => {
            state.statusActionCD = StateStatus.loading;
        },
        [exportCampaign.fulfilled]: (state) => {
            state.statusActionCD = StateStatus.succeeded;
            state.msg = "Campanha exportada com sucesso!";
        },
        [exportCampaign.rejected]: (state, action) => {
            state.statusActionCD = StateStatus.failed;
            state.msg = action.error.message || "Erro ao exportar campanha!";
        },

        // GETS
        [fetchCampaign.pending]: (state, action) => {
            state.status = StateStatus.loading;
            state.page = action.meta.arg;
        },
        [fetchCampaign.fulfilled]: (state, action) => {
            let campaign = action.payload;
            let integrations = {};

            if (campaign.googleAccountInformation) {
                integrations["google"] = campaign.googleAccountInformation;
            } else {
                state.missingAnalyticsConnect = true;
            }
            state.networks = [];

            if (campaign.startDate && campaign.endDate) {
                let start = new Date(campaign.startDate).getTime();
                let now = new Date().getTime();

                state.started = now >= start;
                state.started = true;
            }
            state.integrations = integrations ? integrations : {};
            state.campaign = campaign;
            state.status = StateStatus.succeeded;
        },
        [fetchCampaign.rejected]: (state, action) => {
            state.status = StateStatus.failed;
            state.errorMsg = action.error.message;
        },

        [fetchCampaignLastUpdate.pending]: (state) => {
            state.statusLastUpdate = StateStatus.loading;
        },
        [fetchCampaignLastUpdate.fulfilled]: (state, action) => {
            const response = action.payload;
            state.lastUpdate = response["last_update_date"];

            state.statusLastUpdate = StateStatus.succeeded;
        },
        [fetchCampaignLastUpdate.rejected]: (state) => {
            state.statusLastUpdate = StateStatus.failed;
        },

        // posts
        [fetchCampaignPosts.pending]: (state, action) => {
            state.statusPosts = StateStatus.loading;
            state.page = action.meta.arg;
        },
        [fetchCampaignPosts.fulfilled]: (state, action) => {
            state.feeds = action.payload.feeds;
            state.feedsPagination = action.payload.pagination;
            state.statusPosts = StateStatus.succeeded;
        },
        [fetchCampaignPosts.rejected]: (state, action) => {
            state.statusPosts = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
        // stories
        [fetchCampaignStories.pending]: (state, action) => {
            state.page = action.meta.arg;
            state.statusPosts = StateStatus.loading;
        },
        [fetchCampaignStories.fulfilled]: (state, action) => {
            state.stories = action.payload.stories;
            state.storiesPagination = action.payload.pagination;
            state.statusPosts = StateStatus.succeeded;
        },
        [fetchCampaignStories.rejected]: (state, action) => {
            state.errorMsg = action.error.message;
            state.statusPosts = StateStatus.failed;
        },
        // videos
        [fetchCampaignVideos.pending]: (state, action) => {
            state.page = action.meta.arg;
            state.statusPosts = StateStatus.loading;
        },
        [fetchCampaignVideos.fulfilled]: (state, action) => {
            state.videos = action.payload.videos;
            state.videosPagination = action.payload.pagination;
            state.statusPosts = StateStatus.succeeded;
        },
        [fetchCampaignVideos.rejected]: (state, action) => {
            state.errorMsg = action.error.message;
            state.statusPosts = StateStatus.failed;
        },
        // tops feeds
        [fetchCampaignTopPosts.pending]: (state) => {
            state.statusTopPosts = StateStatus.loading;
        },
        [fetchCampaignTopPosts.fulfilled]: (state, action) => {
            state.feeds = action.payload;
            state.statusTopPosts = StateStatus.succeeded;
        },
        [fetchCampaignTopPosts.rejected]: (state, action) => {
            state.statusTopPosts = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
        // top stories
        [fetchCampaignTopStories.pending]: (state) => {
            state.statusTopPosts = StateStatus.loading;
        },
        [fetchCampaignTopStories.fulfilled]: (state, action) => {
            state.stories = action.payload;
            state.statusTopPosts = StateStatus.succeeded;
        },
        [fetchCampaignTopStories.rejected]: (state, action) => {
            state.statusTopPosts = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
        // top videos
        [fetchCampaignTopVideos.pending]: (state) => {
            state.statusTopPosts = StateStatus.loading;
        },
        [fetchCampaignTopVideos.fulfilled]: (state, action) => {
            state.videos = action.payload.videos;
            state.videosPagination = action.payload.pagination;
            state.statusTopPosts = StateStatus.succeeded;
        },
        [fetchCampaignTopVideos.rejected]: (state, action) => {
            state.errorMsg = action.error.message;
            state.statusTopPosts = StateStatus.failed;
        },
        // influencers
        [fetchInfluencers.pending]: (state) => {
            state.statusInfluencerMetrics = StateStatus.loading;
        },
        [fetchInfluencers.fulfilled]: (state, action) => {
            state.influencers = action.payload;

            state.influencers.forEach((inf) => {
                if (!state.networks.includes(inf.network)) {
                    state.networks.push(inf.network);

                    state.network = inf.network;
                }
            });
            state.statusInfluencerMetrics = StateStatus.succeeded;
        },
        [fetchInfluencers.rejected]: (state, action) => {
            state.statusInfluencerMetrics = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
        // contractors
        [fetchContractors.pending]: (state) => {
            state.statusContractor = StateStatus.loading;
        },
        [fetchContractors.fulfilled]: (state, action) => {
            state.contractors = action.payload;
            state.statusContractor = StateStatus.succeeded;
        },
        [fetchContractors.rejected]: (state, action) => {
            state.statusContractor = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
        // all influencer posts
        [fetchAllInfluencersPosts.pending]: (state) => {
            state.statusPosts = StateStatus.loading;
        },
        [fetchAllInfluencersPosts.fulfilled]: (state, action) => {
            state.feeds = action.payload.posts;
            state.feedsPagination = action.payload.pagination;
            state.statusPosts = StateStatus.succeeded;
        },
        [fetchAllInfluencersPosts.rejected]: (state) => {
            state.statusPosts = StateStatus.failed;
        },
        // all influencer stories
        [fetchAllInfluencersStories.pending]: (state) => {
            state.statusPosts = StateStatus.loading;
        },
        [fetchAllInfluencersStories.fulfilled]: (state, action) => {
            state.stories = action.payload.posts;
            state.storiesPagination = action.payload.pagination;
            state.statusPosts = StateStatus.succeeded;
        },
        [fetchAllInfluencersStories.rejected]: (state) => {
            state.statusPosts = StateStatus.failed;
        },
        // all influencer videos
        [fetchAllInfluencersVideos.pending]: (state) => {
            state.statusPosts = StateStatus.loading;
        },
        [fetchAllInfluencersVideos.fulfilled]: (state, action) => {
            state.videos = action.payload.videos;
            state.videosPagination = action.payload.pagination;

            state.statusPosts = StateStatus.succeeded;
        },
        [fetchAllInfluencersVideos.rejected]: (state) => {
            state.statusPosts = StateStatus.failed;
        },
        // get influencer metrics
        [fetchInfluencerMetrics.pending]: (state) => {
            state.statusInfluencerMetrics = StateStatus.loading;
        },
        [fetchInfluencerMetrics.fulfilled]: (state, action) => {
            state.influencerMetrics = action.payload;
            state.statusInfluencerMetrics = StateStatus.succeeded;
        },
        [fetchInfluencerMetrics.rejected]: (state) => {
            state.statusInfluencerMetrics = StateStatus.failed;
        },
        // get contractor posts
        [fetchContractorPosts.pending]: (state) => {
            state.statusPosts = StateStatus.loading;
        },
        [fetchContractorPosts.fulfilled]: (state, action) => {
            state.feedsPagination = action.payload.pagination;
            state.feeds = action.payload.posts;

            state.statusPosts = StateStatus.succeeded;
        },
        [fetchContractorPosts.rejected]: (state) => {
            state.statusPosts = StateStatus.failed;
        },
        // get influencer profile
        [fetchInfluencerProfile.pending]: (state) => {
            state.statusInfluencerProfile = StateStatus.loading;
        },
        [fetchInfluencerProfile.fulfilled]: (state, action) => {
            state.influencerProfile = action.payload;
            state.statusInfluencerProfile = StateStatus.succeeded;
        },
        [fetchInfluencerProfile.rejected]: (state) => {
            state.statusInfluencerProfile = StateStatus.failed;
        },
        // add post to campaign
        [AttachPostToCampaign.pending]: (state) => {
            state.statusPostCampaign = StateStatus.loading;
        },
        [AttachPostToCampaign.fulfilled]: (state) => {
            state.statusPostCampaign = StateStatus.succeeded;
        },
        [AttachPostToCampaign.rejected]: (state) => {
            state.statusPostCampaign = StateStatus.failed;
        },
        // detach post to campaign
        [DetachPostToCampaign.pending]: (state) => {
            state.statusPostCampaign = StateStatus.loading;
        },
        [DetachPostToCampaign.fulfilled]: (state) => {
            state.statusPostCampaign = StateStatus.succeeded;
        },
        [DetachPostToCampaign.rejected]: (state) => {
            state.statusPostCampaign = StateStatus.failed;
        },
        // add storie to campaign
        [attachStoriesToCampaign.pending]: (state) => {
            state.statusStoriesCampaign = StateStatus.loading;
        },
        [attachStoriesToCampaign.fulfilled]: (state) => {
            state.statusStoriesCampaign = StateStatus.succeeded;
        },
        [attachStoriesToCampaign.rejected]: (state) => {
            state.statusStoriesCampaign = StateStatus.failed;
        },
        // add video to campaign
        [attachVideoToCampaign.pending]: (state) => {
            state.statusVideosCampaign = StateStatus.loading;
        },
        [attachVideoToCampaign.fulfilled]: (state) => {
            state.statusVideosCampaign = StateStatus.succeeded;
        },
        [attachVideoToCampaign.rejected]: (state) => {
            state.statusVideosCampaign = StateStatus.failed;
        },
        // detach storie to campaign
        [detachStoriesToCampaign.pending]: (state) => {
            state.statusStoriesCampaign = StateStatus.loading;
        },
        [detachStoriesToCampaign.fulfilled]: (state) => {
            state.statusStoriesCampaign = StateStatus.succeeded;
        },
        [detachStoriesToCampaign.rejected]: (state) => {
            state.statusStoriesCampaign = StateStatus.failed;
        },
        // detach video to campaign
        [detachVideoToCampaign.pending]: (state) => {
            state.statusVideosCampaign = StateStatus.loading;
        },
        [detachVideoToCampaign.fulfilled]: (state) => {
            state.statusVideosCampaign = StateStatus.succeeded;
        },
        [detachVideoToCampaign.rejected]: (state) => {
            state.statusVideosCampaign = StateStatus.failed;
        },
        // spark status request
        [sparkAdAuthorization.pending]: (state) => {
            state.statusSparkAdAuthorization = StateStatus.loading;
        },
        [sparkAdAuthorization.fulfilled]: (state, action) => {
            state.statusSparkAdAuthorization = StateStatus.succeeded;
            state.stories = state.stories.map((item) => {
                if (item.orderId === action.payload.orderId) {
                    return { ...item, sparkState: "requested" };
                }

                return item;
            });
        },
        [sparkAdAuthorization.rejected]: (state, action) => {
            state.statusSparkAdAuthorization = StateStatus.failed;
            state.errorMsg = action.error.message ?? action.error.name;
        },
        // campaign funnel
        [fetchFunnel.pending]: (state) => {
            state.statusFunnel = StateStatus.loading;
        },
        [fetchFunnel.fulfilled]: (state, action) => {
            const cSummary = new CampaignSummary();
            cSummary.fromJson(action.payload);

            state.summary.data = cSummary;
            state.statusFunnel = StateStatus.succeeded;
        },
        [fetchFunnel.rejected]: (state) => {
            state.statusFunnel = StateStatus.failed;
        },
        // campaign funnel
        [fetchHistogram.pending]: (state) => {
            state.statusHistogram = StateStatus.loading;
        },
        [fetchHistogram.fulfilled]: (state, action) => {
            state.summary.chart = action.payload;

            state.statusHistogram = StateStatus.succeeded;
        },
        [fetchHistogram.rejected]: (state) => {
            state.statusHistogram = StateStatus.failed;
        },

        // tiktok list posts to approve
        [fetchTikTokPostsToApprove.pending]: (state) => {
            state.tiktokPostsToApprove.status.listPosts = StateStatus.loading;
        },
        [fetchTikTokPostsToApprove.fulfilled]: (state, action) => {
            state.tiktokPostsToApprove.posts = action.payload.videos;
            state.tiktokPostsToApprove.pagination = action.payload.pagination;

            state.tiktokPostsToApprove.status.listPosts = StateStatus.succeeded;
        },
        [fetchTikTokPostsToApprove.rejected]: (state, action) => {
            state.tiktokPostsToApprove.errMsg = action.error.message;
            state.tiktokPostsToApprove.status.listPosts = StateStatus.error;
        },

        // approve tiktok post
        [approvePostThunk.pending]: (state) => {
            state.tiktokPostsToApprove.status.approvePost = StateStatus.loading;
        },
        [approvePostThunk.fulfilled]: (state) => {
            state.tiktokPostsToApprove.status.approvePost = StateStatus.succeeded;
        },
        [approvePostThunk.rejected]: (state, action) => {
            state.tiktokPostsToApprove.errMsg = action.error.message;
            state.tiktokPostsToApprove.status.approvePost = StateStatus.error;
        },
        // reprove tiktok post
        [reproveTikTokPostThunk.pending]: (state) => {
            state.tiktokPostsToApprove.status.reprovePost = StateStatus.loading;
        },
        [reproveTikTokPostThunk.fulfilled]: (state) => {
            state.tiktokPostsToApprove.status.reprovePost = StateStatus.succeeded;
        },
        [reproveTikTokPostThunk.error]: (state, action) => {
            state.tiktokPostsToApprove.errMsg = action.error.message;
            state.tiktokPostsToApprove.status.reprovePost = StateStatus.error;
        },
    },
});

export const {
    filterDate,
    clearStatusPostCampaign,
    clearStatusActionCD,
    clearCampaign,
    clearStatusApprovePost,
    clearInfluencerProfile,
    setTiktokAsConnected,
    resetStatusSparkAdAuthorization,
} = campaign.actions;

export const selectCampaign = (state) => state.campaign;

export default campaign.reducer;
