import React from 'react';
import PropTypes from 'prop-types';
// 
import { Tooltip } from 'antd';
import { useTranslation } from "react-i18next";
// 
import './GrowOrDecrease.scss';
import { AirUtils } from '../../../utils/utils';

const GrowOrDecrease = ({
    icon,
    tooltip,
    value,
    className,
    changePercent,
}) => {
    const { t } = useTranslation();
    const utils = new AirUtils();

    return  <div className={className} style={{ display: 'flex' }}>
        {/* discover if is a double/float */}
        {
            (!value || value .toString().indexOf(".") === -1) ? value : utils.buildFloat(value, 2)
        }
        
        { changePercent &&
            <div  className={`style-value ${changePercent < 0 ? "fall" : (changePercent > 0) ? "growth" : "normal"}`}>
                {icon && (changePercent < 0 ?
                    <i className="icon icon-fall" /> :
                    changePercent > 0 && <i className="icon icon-growth" />
                )}

                <Tooltip title={tooltip != false && t("Desempenho")}>
                    {changePercent == 0 ? "0" : (changePercent > 1 ? `+${changePercent}` : `${changePercent}`)}%
                </Tooltip>

            </div>
        }
    </div>
}

GrowOrDecrease.propTypes = {
    icon: PropTypes.bool,
    tooltip: PropTypes.bool,
    value: PropTypes.any,
    className: PropTypes.string,
    changePercent: PropTypes.any
}

export default GrowOrDecrease