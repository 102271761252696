import React, { useEffect, useState } from "react";
//
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
// import { changeObjAlert, showAlert } from '../../../../common/components/Alert/alertSlice'
import {
    clearSomeStates,
    clearStatus,
    deleteAccount,
    loadAccounts,
    selectConfiguration,
} from "../../ConfigurationSlice";
//
import Loading from "../../../../common/components/Loading/Loading";
//
import EditProfile from "./components/EditProfile";
import StateStatus from "../../../../utils/stateStatus";
import "./ManageProfiles.scss";
import Button from "../../../../common/components/Button/Button";
import { changeObjAlert, showAlert } from "../../../../common/components/Alert/alertSlice";
import CreateProfile from "./components/CreateProfile";
import { selectProfile } from "../../../Login/LoginSlice";
import { Tooltip } from "antd";

const newUser = {
    name: "",
    surname: "",
    type: "admin",
    email: "",
    password: "",
};
const ConfigManageProfiles = () => {
    const { t } = useTranslation();
    //
    const dispatch = useDispatch();
    //
    const config = useSelector(selectConfiguration);
    const profile = useSelector(selectProfile);
    //
    const titles = [
        t("Nome"),
        t("E-mail"),
        // t("Permissions"),
        "",
        "",
    ];

    const [editUser, setEditUser] = useState();
    const [createUser, setCreateUser] = useState(false);
    const accountLimitReached = profile.data.plan?.account_limit
        ? (profile.data.stats?.accounts || 0) >= profile.data.plan?.account_limit
        : false;

    const alert = (type, title, text, question, cancelText, okText, onOk, onCancel) => {
        dispatch(
            changeObjAlert({
                show: true,
                type: type,
                title: title,
                text: text,
                okText: okText,
                question: question,
                cancelText: cancelText,
                onOk: () => {
                    onOk;
                },
                onCancel: () => {
                    onCancel;
                    dispatch(showAlert(false));
                },
            })
        );
    };

    useEffect(() => {
        if (config.statusLoadAccounts === StateStatus.idle) {
            dispatch(loadAccounts());
        }
    }, [config.statusLoadAccounts]);

    useEffect(() => {
        if (config.statusDeleteAccount === StateStatus.succeeded) {
            setTimeout(() => {
                alert("success", t("Sucesso"), t("Perfil excluído com sucesso"));

                dispatch(clearStatus());
            }, 600);
        } else if (config.statusDeleteAccount === StateStatus.failed) {
            setTimeout(() => {
                alert("error", t("Erro"), t("Erro ao remover perfil. Tente novamente mais tarde"));

                dispatch(clearSomeStates());
            }, 600);
        }
    }, [config.statusDeleteAccount]);

    return (
        <>
            {!createUser && !editUser && (
                <>
                    {(config.statusLoadAccounts === StateStatus.loading ||
                        config.statusLoadAccounts === StateStatus.idle) && <Loading />}

                    {config.statusLoadAccounts === StateStatus.failed && <p className="text-error">ERRO</p>}

                    {config.statusLoadAccounts === StateStatus.succeeded && (
                        <div className="card-payment-history">
                            <header className="card-p-h-header">
                                <span className="text-header">{t("Gerenciador de perfis")}</span>

                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "flex-end",
                                        flexDirection: "column",
                                        marginTop: "1em",
                                    }}
                                >
                                    <Tooltip
                                        title={t(
                                            accountLimitReached
                                                ? "Limite de perfis atingido, atualize seu plano."
                                                : "Novo perfil"
                                        )}
                                    >
                                        <Button
                                            text={t("Adicionar novo perfil")}
                                            onClick={() => setCreateUser(true)}
                                            disabled={
                                                accountLimitReached ||
                                                profile.status.getProfile != StateStatus.succeeded
                                            }
                                        />
                                    </Tooltip>
                                </div>
                            </header>
                            <p className="text-p-h">{t("Adicione ou edite um perfil")}</p>

                            <table className="table-p-h">
                                <thead>
                                    <tr>
                                        {titles.map((item, key) => (
                                            <th key={item + key}>{t(item)}</th>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody>
                                    {config.accounts.items.map((item) => {
                                        return (
                                            <tr key={item.uid}>
                                                <td>
                                                    {item.name} {item.surname}
                                                </td>

                                                <td>{item.email}</td>

                                                {/* <td>{item.type.toUpperCase()}</td> */}

                                                <td
                                                    className="td-edit"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setEditUser(item);
                                                    }}
                                                >
                                                    <i className="icon icon-edit" />
                                                </td>

                                                <td
                                                    className="td-trash"
                                                    onClick={() =>
                                                        dispatch(
                                                            changeObjAlert({
                                                                show: true,
                                                                type: "warning",
                                                                title: t("atenção"),
                                                                text: t("Essa ação não pode ser desfeita"),
                                                                question: `${t(
                                                                    "Você realmente deseja excluir o perfil"
                                                                )} ${item.name} ${item.surname}?)`,
                                                                okText: t("Sim, remover perfil"),
                                                                cancelText: t("Não, manter campanha"),
                                                                onCancel: () => {
                                                                    dispatch(showAlert(false));
                                                                },
                                                                onOk: () => {
                                                                    dispatch(deleteAccount({ id: item.id }));
                                                                },
                                                            })
                                                        )
                                                    }
                                                >
                                                    <i className="icon icon-trash" />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    )}
                </>
            )}

            {editUser && (
                <EditProfile
                    user={editUser || newUser}
                    isNewProfile={createUser}
                    onUpdate={(user) => setEditUser(user)}
                    onCancel={() => {
                        setEditUser(undefined);
                        if (createUser) {
                            setCreateUser(false);
                            dispatch(loadAccounts());
                        }
                    }}
                />
            )}

            {createUser && (
                <CreateProfile
                    onCancel={() => {
                        if (createUser) {
                            setCreateUser(false);
                        }
                    }}
                />
            )}
        </>
    );
};

export default ConfigManageProfiles;
