
class TiktokPostToApprove {
    orderId
    thumbnail
    videoId
    profile = {}


    fromJson(data) {
        this.orderId = data["order_id"]
        this.thumbnail = data["thumbnail"]
        this.videoId = data["video_id"]
        this.profile = {
            airId: data["profile"]["air_id"],
            url: data["profile"]["url"],
            avatar: data["profile"]["avatar"],
            name: data["profile"]["name"],
            socialNetwork: data["profile"]["social_network"],
        }

        return this
    }
}

export default TiktokPostToApprove