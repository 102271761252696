import axios from "axios";
import jwtDecode from "jwt-decode";
import apiRoutes from "./apiRoutes";
//
import { AirUtils } from "../utils/utils";

const apiUrl = process.env.REACT_APP_API_URL;

export const apiWhitoutInterceptors = axios.create({
    baseURL: apiUrl,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});

const api = axios.create({
    baseURL: apiUrl,
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
    },
});

const resetTokens = () => {
    const hash = window.location.hash.replace("#", "");
    if (!["/login", "/register"].includes(hash)) localStorage.setItem("originalUrl", hash);
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");

    window.location.href = "/#/login";
};
export const refreshTokenCall = async () => {
    const refreshToken = localStorage.getItem("refresh_token");
    if (!refreshToken) {
        resetTokens();
        return;
    }

    try {
        const response = await axios.get(`${apiUrl}${apiRoutes.refreshToken}`, {
            headers: { Authorization: `Bearer ${refreshToken}` },
        });
        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem("refresh_token", response.data.refresh_token);
        return response;
    } catch (err) {
        resetTokens();
        return;
    }
};
api.interceptors.request.use(async (config) => {
    const airUtils = new AirUtils();

    const hasRefresh = airUtils.findParamsUrl("psrefresh_token");
    const accessToken = window.localStorage.getItem("access_token");
    const notToken = [
        "/register/", // registration
        "/account/password/reset/confirmation", // reset password
    ];

    if (!notToken.includes(config.url))
        if (accessToken && !hasRefresh) {
            config.headers.Authorization = `Bearer ${accessToken}`;
        } else {
            const response = await refreshTokenCall();
            if (!response) return;
            config.headers.Authorization = `Bearer ${response.data.access_token}`;
        }

    return config;
});

api.interceptors.response.use(undefined, async (err) => {
    const customError = {
        name: "Api Error",
        message:
            err.response && err.response.status && err.response.status < 500
                ? err.response.data.message || err.response.message
                : "Erro no sistema, tente mais tarde!",
        data: err.response ? err.response.data : null,
    };
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    const originalRequest = err.config;

    const accessToken = localStorage.getItem("access_token");

    if (err.response.status === 401) {
        try {
            const tokenJwt = jwtDecode(accessToken);
            const currentDate = new Date();

            if (tokenJwt.exp * 1000 <= currentDate.getTime()) {
                const response = await refreshTokenCall();
                if (!response) return;

                axios.defaults.headers["Authorization"] = `Bearer ${response.data.access_token}`;
                originalRequest.headers["Authorization"] = `Bearer ${response.data.access_token}`;
            } else {
                resetTokens();
                return;
            }

            return axios(originalRequest);
        } catch (err) {
            const statusCode = err.response?.status;

            if (statusCode == 401) {
                resetTokens();
            }
        }
    }
    return Promise.reject(customError);
});

export default api;
