import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// 
import LineChart from "../../../../common/components/Charts/LineChart/LineChart";
//
import { AirUtils } from "../../../../utils/utils";
import "./ChartSummary.scss";

const ChartSummary = ({ chart }) => {
    const airUtils = new AirUtils();
    // 
    const [dataChart, setDataChart] = useState([])

    useEffect(() => {
      if(chart) {
        if(chart?.per_date && chart?.per_date.length > 0) {
          const list = JSON.parse(JSON.stringify(chart?.per_date))
          
          const data = list.map(item => {
            const date = new Date(item.label).getTime()
            
            return [date, item.value]
          })
    
          setDataChart([ { color: "#3BB481", data: data } ])
        }
      }
    }, [chart])

    return dataChart && <LineChart series={dataChart} color="#3BB481" hasPointer={false} formatter={(value) => airUtils.buildValue("humanize", value)} />
}

ChartSummary.propTypes = {
  chart: PropTypes.any,
}

export default ChartSummary;