import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useHistory } from "react-router-dom";
//
import { useDispatch, useSelector } from "react-redux";
import {
    fetchCampaign,
    fetchCampaignPosts,
    fetchCampaignStories,
    selectCampaign,
    filterDate,
    archiveCampaign,
    exportCampaign,
    duplicateCampaign,
    clearStatusActionCD,
    fetchCampaignVideos,
} from "../../CampaignDetailsSlice";
import { changeObjAlert, showAlert } from "../../../../common/components/Alert/alertSlice";
//
import DropDown from "../../../../common/components/DropDown/DropDown";
import Select from "../../../../common/components/Select/Select";
import Card from "../../../../common/components/Card/Card";
import Header from "../../../../common/components/Header/Header";
import Loading, { SizedLoading } from "../../../../common/components/Loading/Loading";
import CardFeed from "../../../../common/components/CardFeed/CardFeed";
import CardStories from "../../../../common/components/CardStories/CardStories";
import Pagination from "../../../../common/components/Pagination/Pagination";
import PostsModal from "../../components/PostsModal/PostsModal";
//
import { AirUtils } from "../../../../utils/utils";
import StateStatus from "../../../../utils/status";
import "./CampaignPosts.scss";

const CampaignPosts = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { typePost } = useParams();
    //
    const history = useHistory();
    const dispatch = useDispatch();
    const airUtils = new AirUtils();
    //
    const objCampaign = useSelector(selectCampaign);
    //
    const campaign = objCampaign.campaign;
    const feeds = objCampaign.feeds;
    const videos = objCampaign.videos;
    const stories = objCampaign.stories;
    //
    const [openActions, setOpenActions] = useState(false);
    const [visible, setVisible] = useState(false); // modal
    const [idxInfluencer, setIndexInfluencer] = useState(); // modal
    const [page, setPage] = useState(1);
    const [indexFilter, setIndexFilter] = useState(0);
    //
    const [filter, setFilter] = useState("last");
    const [filterPost, setFilterPost] = useState(typePost || "feeds");

    let actions = [
        { icon: "edit", key: "edit", value: t("Editar campanha") },
        { icon: "duplicate", key: "duplicate", value: t("Duplicar campanha") },
        { icon: "export", key: "export", value: t("Exportar campanha") },
        { icon: "archieve", key: "filed", value: t("Arquivar campanha") },
    ];

    if (campaign?.type == "hashtag") actions = actions.filter((item) => item.key != "export");

    const pagination =
        filterPost === "feeds" || filterPost === "hashtag"
            ? objCampaign.feedsPagination
            : filterPost === "stories"
            ? objCampaign.storiesPagination
            : objCampaign.videosPagination;

    const filterOptions = [
        { value: "feeds", key: "Feeds" },
        { value: "stories", key: "Stories" },
        { value: "videos", key: "Videos" },
    ];

    const filters = [
        { key: "last", text: t("Últimas postagens") },
        { key: "higher_performance", text: t("Maior desempenho") },
        { key: "lower_performance", text: t("Menor desempenho") },
        { key: "higher_interaction", text: t("Maior interação") },
        { key: "lower_interaction", text: t("Menor interação") },
    ];

    const closeEditModal = () => {
        setIndexInfluencer(null);
        setVisible(false);
    };

    const alert = (type, title, text, question, cancelText, okText, onOk) => {
        dispatch(
            changeObjAlert({
                show: true,
                type: type,
                title: title,
                text: text,
                okText: okText,
                question: question,
                cancelText: cancelText,
                onCancel: () => {
                    dispatch(showAlert(false));
                },
                onOk: () => {
                    onOk;
                },
            })
        );
    };

    useEffect(async () => {
        dispatch(filterDate({ startDate: null, endDate: null }));

        if (!objCampaign.campaign) {
            await dispatch(fetchCampaign(id));
        }
    }, []);

    useEffect(async () => {
        const path = history.location.pathname;

        if (filterPost === "feeds") {
            history.replace(path.replace("stories", "feeds").replace("videos", "feeds"));
            dispatch(fetchCampaignPosts({ id, page: page - 1, limit: 8, filter: filter }));
        } else if (filterPost === "hashtag") {
            history.replace(path.replace("stories", "hashtag").replace("videos", "hashtag"));
            dispatch(fetchCampaignPosts({ id, page: page - 1, limit: 8, filter: filter }));
        } else if (filterPost === "videos") {
            history.replace(path.replace("stories", "videos").replace("feeds", "videos"));
            dispatch(fetchCampaignVideos({ id, page: page - 1, limit: 8, filter: filter }));
        } else {
            history.replace(path.replace("feeds", "stories").replace("feeds", "stories"));
            dispatch(fetchCampaignStories({ id, page: page - 1, limit: 8, filter: filter }));
        }
    }, [filterPost, page, filter, campaign, typePost, objCampaign.filterEndDate, objCampaign.filterStartDate]);

    useEffect(() => {
        if (objCampaign.statusActionCD === StateStatus.succeeded) {
            alert("success", t("sucesso"), t(objCampaign.msg));
        } else if (objCampaign.statusActionCD === StateStatus.failed) {
            alert("error", t("erro"), t(objCampaign.msg));
        }

        dispatch(clearStatusActionCD());
    }, [objCampaign.statusActionCD]);

    const getPosts = () => {
        if (filterPost === "stories") return objCampaign.stories;
        else if (filterPost === "videos") return objCampaign.videos;

        return objCampaign.feeds;
    };
    return (
        <div className="campaign-posts background-page">
            <PostsModal
                visible={visible}
                index={idxInfluencer}
                onOk={() => closeEditModal()}
                onCancel={() => closeEditModal()}
                posts={getPosts()}
            />

            {objCampaign.status == StateStatus.succeeded && campaign && (
                <Header
                    back={true}
                    className="home-header"
                    limitEndDate={campaign.endDate}
                    limitStartDate={campaign.startDate}
                    subHeader={
                        <div className="subheader-left">
                            <div className="subheader-title">
                                <span className="span-name">{campaign.name}</span>

                                {objCampaign.statusActionCD === StateStatus.loading && (
                                    <SizedLoading size={24} withContainer={false} />
                                )}

                                {objCampaign.statusActionCD === StateStatus.succeeded && (
                                    <DropDown
                                        options={actions}
                                        optionsWithIcons={true}
                                        onChange={() => setOpenActions(!openActions)}
                                        onClick={(value) => {
                                            if (value.key === "edit") {
                                                history.push(`/edit-campaign/${campaign.id}/type`);
                                            } else if (value.key == "duplicate") {
                                                dispatch(duplicateCampaign(campaign.id));
                                            } else if (value.key == "export") {
                                                dispatch(exportCampaign(campaign.id));
                                            } else if (value.key == "filed") {
                                                dispatch(
                                                    changeObjAlert({
                                                        show: true,
                                                        type: "warning",
                                                        title: t("atenção"),
                                                        question: t(
                                                            "Deseja realmente arquivar essa campanha? (Essa ação não pode ser desfeita)"
                                                        ),
                                                        okText: t("Sim, arquivar campanha"),
                                                        cancelText: t("Não, manter campanha"),
                                                        onCancel: () => {
                                                            dispatch(showAlert(false));
                                                        },
                                                        onOk: () => {
                                                            dispatch(archiveCampaign(campaign.id));
                                                        },
                                                    })
                                                );
                                            }

                                            setOpenActions(false);
                                        }}
                                        child={
                                            <i className={`icon-more icon-more-${openActions ? "open" : "close"}`} />
                                        }
                                    />
                                )}
                            </div>

                            <span className="text">
                                {t("Campanha") + ": " + t("período de")}{" "}
                                {airUtils.buildValue("date", new Date(campaign.startDate))} -{" "}
                                {airUtils.buildValue("date", new Date(campaign.endDate))} | {t("tipo")}:{" "}
                                {campaign.type == "influencers" ? t("influenciadores") : t("hashtag")}
                                {campaign.objectives.length > 0 &&
                                    campaign.type == "influencers" &&
                                    ` | ${t("objetivo")}: ${campaign.objectives.includes("site") ? t("vendas") : ""} ${
                                        campaign.objectives.length > 1 ? ` ${t("e")} ` : ""
                                    } ${campaign.objectives.includes("social_media") ? t("redes socias") : ""}`}
                            </span>
                        </div>
                    }
                    funcDate={(date) => {
                        if (date) {
                            dispatch(filterDate({ startDate: date[0], endDate: date[1] }));
                        } else {
                            dispatch(filterDate({ startDate: null, endDate: null }));
                        }
                    }}
                />
            )}

            <div className="scroll-content">
                <div className="campaign-post-content">
                    {objCampaign.status == StateStatus.loading && <Loading />}

                    {objCampaign.status == StateStatus.failed && <p>{t("ERRO")}</p>}

                    {objCampaign.status == StateStatus.succeeded && campaign && (
                        <>
                            <Card
                                child={
                                    <>
                                        <div className="card-header">
                                            <h3 className="campaign-title-data">{t("Publicações da campanha")}</h3>

                                            {campaign.type != "hashtag" && (
                                                <Select
                                                    options={filterOptions}
                                                    defaultValue={filterPost}
                                                    onChange={(option) => {
                                                        setFilterPost(option.value);
                                                        setPage(1);
                                                    }}
                                                />
                                            )}
                                        </div>

                                        {((filterPost === "feeds" && feeds && feeds.length > 0) ||
                                            (filterPost === "stories" && stories && stories.length > 0) ||
                                            (filterPost === "videos" && videos && videos.length > 0)) && (
                                            <div
                                                className={`segment-filter-posts segment-filter-${objCampaign.network}`}
                                            >
                                                {filters.map((type, key) => {
                                                    return (
                                                        <div
                                                            key={key}
                                                            className={`option-post ${
                                                                filter == type.key ? `active` : ""
                                                            }`}
                                                            onClick={() => {
                                                                setPage(1);
                                                                setFilter(type.key);
                                                                setIndexFilter(key);
                                                            }}
                                                        >
                                                            <i className={`icon ${type.icon}`} />
                                                            <span className="text-option">{type.text}</span>
                                                        </div>
                                                    );
                                                })}
                                                <div
                                                    style={{ transform: `translate(${indexFilter * 100}%)` }}
                                                    className="segment-underline"
                                                />
                                            </div>
                                        )}

                                        {objCampaign.statusPosts == StateStatus.loading && <Loading />}

                                        {objCampaign.statusPosts == StateStatus.succeeded && (
                                            <div className="container-posts">
                                                {filterPost === "feeds" &&
                                                    (feeds && feeds.length > 0 ? (
                                                        feeds.map((post, key) => (
                                                            <CardFeed key={key} post={post} campaignId={campaign.id} />
                                                        ))
                                                    ) : (
                                                        <p className="empty-value">
                                                            {t("Nenhum post foi feito pelos influenciadores")}
                                                        </p>
                                                    ))}

                                                {filterPost == "hashtag" &&
                                                    (objCampaign.feeds && objCampaign.feeds.length > 0 ? (
                                                        objCampaign.feeds.map((post, key) => {
                                                            if (post.type === "video") {
                                                                return (
                                                                    <CardStories
                                                                        key={key}
                                                                        post={post}
                                                                        campaignId={objCampaign.campaign.id}
                                                                        onClick={() => {
                                                                            setVisible(true);
                                                                            setIndexInfluencer(key);
                                                                        }}
                                                                    />
                                                                );
                                                            }
                                                            return (
                                                                <CardFeed
                                                                    campaignId={objCampaign.campaign.id}
                                                                    post={post}
                                                                    key={key}
                                                                />
                                                            );
                                                        })
                                                    ) : (
                                                        <p className="empty-value">
                                                            {t("Nenhum post foi feito pelos influenciadores")}
                                                        </p>
                                                    ))}

                                                {filterPost === "stories" &&
                                                    (stories && stories.length > 0 ? (
                                                        stories.map((storie, key) => (
                                                            <CardStories
                                                                campaignId={campaign.id}
                                                                post={storie}
                                                                key={key}
                                                                onClick={() => {
                                                                    setVisible(true);
                                                                    setIndexInfluencer(key);
                                                                }}
                                                            />
                                                        ))
                                                    ) : (
                                                        <p className="empty-value">
                                                            {t("Nenhum story foi feito pelos influenciadores")}
                                                        </p>
                                                    ))}

                                                {filterPost === "videos" &&
                                                    (videos && videos.length > 0 ? (
                                                        videos.map((video, key) => (
                                                            <CardStories
                                                                campaignId={campaign.id}
                                                                key={key}
                                                                post={video}
                                                                onClick={() => {
                                                                    setVisible(true);
                                                                    setIndexInfluencer(key);
                                                                }}
                                                                onRequestSpaskSuccess={() =>
                                                                    dispatch(
                                                                        fetchCampaignVideos({
                                                                            id,
                                                                            page,
                                                                            limit: 8,
                                                                            filter: filter,
                                                                        })
                                                                    )
                                                                }
                                                            />
                                                        ))
                                                    ) : (
                                                        <p className="empty-value">
                                                            {t("Nenhum video foi feito pelos influenciadores")}
                                                        </p>
                                                    ))}
                                            </div>
                                        )}
                                    </>
                                }
                            />

                            {(((filterPost === "feeds" || filterPost === "hashtag") && feeds && feeds.length > 0) ||
                                (filterPost === "stories" && stories && stories.length > 0) ||
                                (filterPost === "videos" && videos && videos.length > 0)) && (
                                <Pagination
                                    current={page}
                                    total={pagination.total}
                                    pageSize={pagination.itemsPerPage}
                                    isDisabled={objCampaign.status == StateStatus.loading}
                                    onChange={(page) => setPage(page)}
                                />
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CampaignPosts;
