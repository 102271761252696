import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
//
import CardData from "../../common/components/CardData/CardData";
import GrowOrDecrease from "../../common/components/GrowOrDecrease/GrowOrDecrease";
import Header from "../../common/components/Header/Header";
import Loading from "../../common/components/Loading/Loading";
import CardSummary from "../../common/components/CardSummary/CardSummary";
//
import StateStatus from "../../utils/status";
import { fetchCampaignSummary, fetchChart, selectSummary } from "./CampaignSummarySlice";
//
import "./CampaignSummary.scss";

const CampaignSummary = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const objSummary = useSelector(selectSummary);

    const [summary, setSummary] = useState();

    useEffect(async () => {
        await dispatch(fetchCampaignSummary());
    }, []);

    useEffect(() => {
        if (objSummary.summary.data) {
            setSummary(objSummary.summary.data);
        }
    }, [objSummary.summary.data]);

    return (
        <div className="summary-page background-page">
            <Header title={t("Resumo das campanhas")} className="summary-header"></Header>

            <div className="scroll-content">
                <div className="summary-content">
                    {objSummary.status == StateStatus.succeeded && summary ? (
                        <>
                            <p className="subtitle-summary">{t("minhas campanhas")}</p>
                            <div className="cards-about-campaign list-cards">
                                {summary.myCampaigns.map((item, key) => {
                                    return (
                                        <CardData
                                            key={key}
                                            type="white"
                                            title={t(item.label)}
                                            tooltip={t(`tooltip_${item.label}`)}
                                            child={
                                                <GrowOrDecrease value={item.value} changePercent={item.performance} />
                                            }
                                        />
                                    );
                                })}
                            </div>

                            <p className="subtitle-summary">{t("Total de rastreamentos")}</p>
                            <div className="cards-traking list-cards">
                                {summary.linkTracking.map((item, key) => {
                                    return (
                                        <CardData
                                            key={key}
                                            type="white"
                                            title={t(item.label)}
                                            tooltip={t(`tooltip_${item.label}`)}
                                            child={
                                                <GrowOrDecrease value={item.value} changePercent={item.performance} />
                                            }
                                        />
                                    );
                                })}
                            </div>

                            <CardSummary
                                chart={objSummary.summary.chart}
                                funnel={summary.funnel}
                                loading={objSummary.statusChart === StateStatus.loading}
                                onSearch={(data) => dispatch(fetchChart(data))}
                            />
                        </>
                    ) : objSummary.status == StateStatus.failed ? (
                        t("ERRO")
                    ) : (
                        <Loading />
                    )}
                </div>
            </div>
        </div>
    );
};

export default CampaignSummary;
