import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCampaigns, filedCampaign, downloadCampaign, cloneCampaign, excludeCampaignDraft } from "./HomeService";
import Campaign from "../../models/Campaign";
import StateStatus from "../../utils/status";

const initialState = {
    data: {
        page: 1,
        search: "",
    },
    totalPages: 0,
    itemsPerPage: 0,
    itemsCount: 0,
    results: [],
    status: StateStatus.idle,

    campaignIdAction: -1,
    statusAction: StateStatus.idle,
    msg: null,
    idToRedirectAfterDuplicate: null,
};

export const archiveCampaign = createAsyncThunk("filed/campaign", async (id) => {
    await filedCampaign(id);
    return id;
});

export const duplicateCampaign = createAsyncThunk("duplicate/campaign", async (id) => {
    const response = await cloneCampaign(id);
    return response.data.id;
});

export const exportCampaign = createAsyncThunk("export/campaign", async (id) => {
    const response = await downloadCampaign(id);
    const { url } = response.data;

    window.open(url);

    return response;
});

export const excludeCampaign = createAsyncThunk("exclude/campaign", async (id) => {
    await excludeCampaignDraft(id);
    return id;
});

export const fetchCampaigns = createAsyncThunk("get/campaigns", async (data, thunkAPI) => {
    const { campaigns } = thunkAPI.getState();

    const { page, text } = data;
    const textSearch = text == "" || text ? text : campaigns.data.search;
    const response = await getCampaigns(page, textSearch);

    let results = response.data.items.map((item) => {
        let campaign = new Campaign();
        campaign.fromJson(item);
        return campaign;
    });

    const totalPages = response.data.pages;
    const itemsPerPage = response.data.items_per_page;
    const itemsCount = response.data.count;

    if (!textSearch || textSearch === "" || textSearch === " ") {
        window.localStorage.setItem("has_campaigns", results.length > 0);
    }

    if (page > 1) {
        results = [...results];
    }
    return {
        results,
        page,
        text,
        itemsPerPage,
        totalPages,
        itemsCount,
    };
});

export const campaigns = createSlice({
    name: "campaigns",
    initialState: initialState,
    reducers: {
        updateSort: (state, action) => {
            state.data.sort = action.payload;
        },
        updateSearch: (state, action) => {
            state.data.search = action.payload;
        },
        updateCampaignIdAction: (state, action) => {
            state.campaignIdAction = action.payload;
        },
        clearIdToRedirect: (state) => {
            state.idToRedirectAfterDuplicate = null;
        },
        clearStatusAction: (state) => {
            state.statusAction = StateStatus.idle;
        },
        clear: (state) => {
            state.results = [];
            state.status = StateStatus.idle;
            state.statusAction = StateStatus.idle;
        },
    },
    extraReducers: {
        [fetchCampaigns.pending]: (state) => {
            state.status = StateStatus.loading;
        },
        [fetchCampaigns.fulfilled]: (state, action) => {
            state.status = StateStatus.succeeded;

            state.results = action.payload.results;
            state.data.page = action.payload.page;
            state.data.search = action.payload.text;
            state.totalPages = action.payload.totalPages;
            state.itemsPerPage = action.payload.itemsPerPage;
            state.itemsCount = action.payload.itemsCount;
        },
        [fetchCampaigns.rejected]: (state) => {
            state.status = StateStatus.failed;
        },

        // ACTIONS
        [archiveCampaign.pending]: (state) => {
            state.statusAction = StateStatus.loading;
        },
        [archiveCampaign.fulfilled]: (state, action) => {
            state.statusAction = StateStatus.succeeded;
            state.msg = "Campanha arquivada com sucesso!";
            state.results = state.results.filter((item) => item.id !== action.payload);
        },
        [archiveCampaign.rejected]: (state, action) => {
            state.statusAction = StateStatus.failed;
            state.msg = action.error.message || "Erro ao arquivar campanha!";
        },
        [duplicateCampaign.pending]: (state) => {
            state.statusAction = StateStatus.loading;
        },
        [duplicateCampaign.fulfilled]: (state, action) => {
            state.statusAction = StateStatus.succeeded;
            state.idToRedirectAfterDuplicate = action.payload;
            state.msg = "Campanha duplicada com sucesso!";
        },
        [duplicateCampaign.rejected]: (state, action) => {
            state.statusAction = StateStatus.failed;
            state.msg = action.error.message || "Erro ao duplicar campanha!";
        },
        [exportCampaign.pending]: (state) => {
            state.statusAction = StateStatus.loading;
        },
        [exportCampaign.fulfilled]: (state) => {
            state.statusAction = StateStatus.succeeded;
            state.msg = "Campanha exportada com sucesso!";
        },
        [exportCampaign.rejected]: (state, action) => {
            state.statusAction = StateStatus.failed;
            state.msg = action.error.message || "Erro ao exportar campanha!";
        },
        [excludeCampaign.pending]: (state) => {
            state.statusAction = StateStatus.loading;
        },
        [excludeCampaign.fulfilled]: (state, action) => {
            state.statusAction = StateStatus.succeeded;
            state.msg = "Rascunho apagado com sucesso!";
            state.results = state.results.filter((item) => item.id !== action.payload);
        },
        [excludeCampaign.rejected]: (state, action) => {
            state.statusAction = StateStatus.failed;
            state.msg = action.error.message || "Erro ao apagar rascunho!";
        },
    },
});

export const { updateSort, updateSearch, clearStatusAction, clear, updateCampaignIdAction, clearIdToRedirect } =
    campaigns.actions;

export const selectCampaigns = (state) => state.campaigns;

export default campaigns.reducer;
