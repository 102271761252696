
class Storie {
    id;
    url;
    type;
    date;
    image;
    owner;
    network;
    reach;
    views;
    description;
    performance;
    inCampaign;
    link;
    interactions = [];


    fromJson(data) {
        this.owner = {
            name: data.author.name,
            username: data.author.username,
            profileUrl: data.author.profile_url,
            avatar: data.author.profile_picture_url,
            airId: data.author.air_id
        };

        this.id = data.post_id;
        this.description = data.caption;
        this.interactions = data.interactions
        this.inCampaign = data.is_in_campaign;
        this.link = data.permalink


        this.type = data.type
        this.url = data.medias[0].media_url;
        this.image = data.medias[0].thumbnail_url;

        this.performance = data.performance;
        this.network = data.social_network;
        this.date = new Date(data.timestamp * 1000);

        this.orderId = data.tiktok?.order_id
        this.sparkState = data.tiktok?.spark_state

        this.interactions.forEach((i) => {
            if (i.key == "impressions") this.views = i.value;
            if (i.key == "reach") this.reach = i.value;
        })

        return this;
    }
}

export default Storie;