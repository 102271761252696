import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
// 
import { useSelector, useDispatch } from 'react-redux'
import { fetchProfile, selectProfile } from '../../../Login/LoginSlice'
import { updateName, updateEmail, updatePassword, selectConfiguration, clearStatus } from "../../ConfigurationSlice"
import { changeObjAlert, showAlert } from '../../../../common/components/Alert/alertSlice';
// 
import { Form } from "antd"
import Card from "../../../../common/components/Card/Card"
import InputLabel from '../../../../common/components/InputLabel/InputLabel'
import InputPassword from '../../../../common/components/InputPassword/InputPassword'
import Button, { OutlinedGreyButton } from '../../../../common/components/Button/Button'
// 
import "./Profile.scss"
import StateStatus from '../../../../utils/stateStatus'

// NAME
const EditName = ({ user, config }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    // 
    const [form] = Form.useForm(null)
    // 
    const [isEditMode, updateEditMode] = useState(false)

    const onFinish = (values) => dispatch(updateName(values))

    const alert = (type, title, text, question, cancelText, okText, onOk, onCancel) => {
        dispatch(changeObjAlert({
            show: true,
            type: type,
            title: title,
            text: text,
            okText: okText,
            question: question,
            cancelText: cancelText,
            onCancel: () => { dispatch(showAlert(false)); onCancel; },
            onOk: () => { onOk; }
        }))
    }

    useEffect(() => {
        if (config.statusName === StateStatus.succeeded) {
            dispatch(fetchProfile())
            updateEditMode(false)
        }
    }, [config.statusName])

    useEffect(() => {
        if (config.statusName === StateStatus.failed) alert("error", t("Erro"), (t(config.msg ||"Erro ao atualizar nome!")))
        if (config.statusName === StateStatus.succeeded) alert("success", t("Sucesso"), (t(config.msg ||"Nome atualizado com sucesso!")))

        setTimeout(() => {
            dispatch(clearStatus())
        }, 300)
    }, [config.statusName])

    return <>
        <h2 className='card-title'>{t("Nome")}</h2>

        {isEditMode ?
            <Form form={form} onFinish={onFinish} className='edit-container'>
                <Form.Item
                    label={t("Nome")}
                    name="name"
                    initialValue={user.name}
                    rules={[{
                        required: true,
                        message: <span className='text-error'>{t("Informe um nome válido")}</span>,
                    }]}
                >
                    <InputLabel />
                </Form.Item>

                <Form.Item
                    label={t("Sobrenome")}
                    name="surname"
                    initialValue={user.surname}
                    rules={[{
                        required: true,
                        message: <span className='text-error'>{t("Informe um sobrenome válido")}</span>,
                    }]}
                >
                    <InputLabel />
                </Form.Item>
            </Form> :
            <span>{user.name} {user.surname}</span>
        }


        <div className={`card-footer-aside ${isEditMode ? "end" : "center"}`}>
            {isEditMode ?
                <>
                    <OutlinedGreyButton onClick={() => updateEditMode(false)}>{t("Cancelar")}</OutlinedGreyButton>
                    <Button loading={config.statusName === StateStatus.succeeded} onClick={() => form.submit()} text={t("Salvar")}/>
                </> :

                <OutlinedGreyButton onClick={() => updateEditMode(true)}>{t("Editar")}</OutlinedGreyButton>
            }
        </div>
    </>
}

EditName.propTypes = {
    user: PropTypes.object,
    config: PropTypes.object,
}

// E-MAIL
const EditEmail = ({ user, config }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    // 
    const [form] = Form.useForm(null)
    // 
    const [isEditMode, updateEditMode] = useState(false)

    const onFinish = (values) => dispatch(updateEmail(values))

    const alert = (type, title, text, question, cancelText, okText, onOk, onCancel) => {
        dispatch(changeObjAlert({
            show: true,
            type: type,
            title: title,
            text: text,
            okText: okText,
            question: question,
            cancelText: cancelText,
            onCancel: () => { dispatch(showAlert(false)); onCancel; },
            onOk: () => { onOk; }
        }))
    }
    
    useEffect(() => {
        if (config.statusName === StateStatus.succeeded) {
            dispatch(fetchProfile())
            updateEditMode(false)
        }
    }, [config.statusName])

    useEffect(() => {
        if (config.statusEmail === StateStatus.failed) alert("error", t("Erro"), (t(config.msg ||t("Erro ao atualizar e-mail"))))
        if (config.statusEmail === StateStatus.succeeded) alert("success", t("Sucesso"), (t(config.msg || t("Um e-mail de confirmação foi enviado para seu e-mail"))))

        setTimeout(() => {
            dispatch(clearStatus())
        }, 300)
    }, [config.statusEmail])

    return (
        <>
            <h2 className='card-title'>{t("E-mail")}</h2>

            {isEditMode ?
                <Form form={form} onFinish={onFinish} className='edit-container'>
                    <Form.Item
                        label={t("E-mail")}
                        name="email"
                        initialValue={user.email}
                        rules={[{
                            required: true,
                            message: <span className='text-error'>{t("Informe um e-mail válido")}</span>,
                        }]}
                    >
                        <InputLabel />
                    </Form.Item>
                </Form> :
                <span>{user.email}</span>
            }

            <div className={`card-footer-aside ${isEditMode ? "end" : "center"}`}>
                {isEditMode ?
                    <>
                        <OutlinedGreyButton onClick={() => updateEditMode(false)}>{t("Cancelar")}</OutlinedGreyButton>
                        <Button loading={config.statusEmail === StateStatus.loading} onClick={() => form.submit()} text={t("Salvar")} />
                    </> :

                    <OutlinedGreyButton onClick={() => updateEditMode(true)}>{t("Editar")}</OutlinedGreyButton>
                }
            </div>
        </>
    )
}

EditEmail.propTypes = {
    user: PropTypes.object,
    config: PropTypes.object,
}

// PASSWORD
const EditPassword = ({ config }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    // 
    const [form] = Form.useForm(null)
    // 
    const [isEditMode, updateEditMode] = useState(false)
    const [confirmPass, setConfirmPass] = useState("")
    const [passValidation, setPassValidation] = useState({})

    const passwordRequests = [
        { text: t("Minimo 6  caracteres;"), alias: "hasSixLength" },
        { text: t("Letras maiúscula;"), alias: "hasOneCapitalLetter" },
        { text: t("Letras minúsculas;"), alias: "hasOneLowerLetter" },
        { text: t("Pelo menos um numeral;"), alias: "hasOneNumber" },
        {
            text: "Pelo menos um caractere especial (@, #, $ e etc);",
            alias: "hasCharacterSpecial",
        },
    ]

    const onFinish = (values) => dispatch(updatePassword(values))

    const passwordValidation = (value) => {
        const result = {
            value,
            hasSixLength: value.length >= 8,
            hasOneNumber: value.search(/\d/g) !== -1,
            hasOneCapitalLetter: value.search(/[A-Z]/g) !== -1,
            hasOneLowerLetter: value.search(/[a-z]/g) !== -1,
            hasCharacterSpecial:
                value.search(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g) !== -1, // eslint-disable-line
        }

        setPassValidation(result)

        return result
    }

    const alert = (type, title, text, question, cancelText, okText, onOk, onCancel) => {
        dispatch(changeObjAlert({
            show: true,
            type: type,
            title: title,
            text: text,
            okText: okText,
            question: question,
            cancelText: cancelText,
            onCancel: () => { dispatch(showAlert(false)); onCancel; },
            onOk: () => { onOk; }
        }))
    }

    useEffect(() => {
        if (config.statusPassword === StateStatus.failed) alert("error", t("Erro"), (t(config.msg || t("Erro ao atualizar senha"))))
        if (config.statusPassword === StateStatus.succeeded) alert("success", t("Sucesso"), (t(config.msg || t("Senha atualizada com sucesso"))))

        setTimeout(() => {
            dispatch(clearStatus())
        }, 300)
    }, [config.statusPassword])

    useEffect(() => {
        if (config.statusName === StateStatus.succeeded) {
            dispatch(fetchProfile())
            updateEditMode(false)
        }
    }, [config.statusName])
    
    return (
        <>
            <h2 className='card-title'>{t("Senha")}</h2>

            {isEditMode ?
                <Form form={form} onFinish={onFinish} className='edit-container edit-password'>
                    <Form.Item
                        name="old_password"
                        label={t("Senha atual")}
                        rules={[{
                            required: true,
                            message: <span style={{ display: 'none' }}>/</span>,
                        },
                        () => ({
                            validator(_, value) {
                                const regPassword = new RegExp("^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,})(?=(.*[!@#$%^&*]){1,}).{8,}$");

                                if (!regPassword.test(value)) {
                                    return Promise.reject(new Error(t('Senha inválida')))
                                }
                                return Promise.resolve()
                            },
                        }),
                        ]}
                    >
                        <InputPassword
                            placeholder={t("Informe a senha atual")}
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        label={t("Nova senha")}
                        rules={[{
                            required: true,
                            message: <span style={{ display: 'none' }}>/</span>,
                        },
                        () => ({
                            validator(_, value) {
                                const falses = Object.entries(passValidation).filter(
                                    (item) => item[1] === false
                                )
                                if (falses.length > 0 || !value) {
                                    return Promise.reject(new Error(t('Senha inválida')))
                                }
                                return Promise.resolve()
                            },
                        }),
                        ]}
                    >
                        <InputPassword
                            onChange={(event) => passwordValidation(event.target.value)}
                            placeholder={t("Informe a nova senha")}
                        />
                    </Form.Item>

                    <div className="password-requests">
                        <p className="pr-title">{t("Sua senha tem que possuir")}:</p>

                        <ul>
                            {passwordRequests.map((item) => (<li
                                key={item.alias}
                                className={passValidation[item.alias] ? 'checked' : ''}
                            >
                                {item.text}{' '}
                                {passValidation[item.alias] && (
                                    <i className="icon icon-checkmark" />
                                )}
                            </li>
                            ))}
                        </ul>
                    </div>

                    <Form.Item
                        name="confirm_password"
                        label={t("Confirme sua nova senha")}
                        rules={[{
                            required: true,
                            message: <span style={{ display: "none" }}>/</span>,
                        },
                        () => ({
                            validator() {
                                const falses = Object.entries(passValidation).filter(
                                    (item) => item[1] === false
                                )

                                if (falses.length > 0) {
                                    return Promise.reject(new Error(t("Senha inválida")))
                                }
                                if (confirmPass !== passValidation.value) {
                                    return Promise.reject(new Error(t("As senhas não coincidem")))
                                }

                                return Promise.resolve()
                            },
                        }),
                        ]}
                    >
                        <InputPassword
                            placeholder={t("Informe a nova senha")}
                            onChange={(event) => {
                                if (event.target.value) setConfirmPass(event.target.value)
                            }}
                        />
                    </Form.Item>
                </Form> :
                <span>********</span>
            }
            <div className={`card-footer-aside ${isEditMode ? "end" : "center"}`}>
                {isEditMode ?
                    <>
                        <OutlinedGreyButton onClick={() => updateEditMode(false)}>{t("Cancelar")}</OutlinedGreyButton>
                        <Button loading={config.statusPassword === StateStatus.loading} onClick={() => form.submit()} text={t("Salvar")} />
                    </> :

                    <OutlinedGreyButton onClick={() => updateEditMode(true)}>{t("Editar")}</OutlinedGreyButton>
                }
            </div>
        </>
    )
}

EditPassword.propTypes = {
    user: PropTypes.object,
    config: PropTypes.object,
}

const EditProfile = () => {
    const user = useSelector(selectProfile).data
    const config = useSelector(selectConfiguration)

    return (
        <>
            <Card padding="24px 40px" child={<EditName user={user} config={config} />} />

            <Card padding="24px 40px" child={<EditEmail user={user} config={config} />} />

            <Card padding="24px 40px" child={<EditPassword config={config} />} />
        </>
    )
}

export default EditProfile