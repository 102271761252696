import { AirUtils } from "../utils/utils";

class Influencer {
    id;
    name;
    username;
    base;
    airId;
    avatar;
    network;
    airscore;
    profileUrl;
    connected;
    campaignInvitationStatus;
    waitingTcm;
    waiting;
    connectStatus;

    // per link
    link;
    originalLink;
    email;
    linkTrackingError;
    domainUrl;
    shortLink;
    connectUrl;

    // metrics
    contractVideos;
    deliveredVideos;
    contractFeeds;
    deliveredFeeds;
    investmentFeeds;
    contractStories;
    deliveredStories;
    investmentTotal;
    investmentStories;
    typeContract;

    // social table
    cpi;
    txe;
    performance;
    interactionsTotal;
    // ec table
    clicks;
    costPerClick;
    conversionRate;
    conversions;
    costPerConversion;
    roi;

    // tiktok
    contractVideos;
    deliveredVideos;
    investmentVideos;
    marketeplace;

    // tiktok metrics
    videosCount;
    likesCount;
    followers;
    followings;
    growing;

    // campaign list
    fromJson(data) {
        const utils = new AirUtils();
        const profile = data.profile || data;

        this.id = data.id;
        this.name = profile.name;
        this.username = profile.username;
        this.airId = profile.air_id;
        this.network = profile.social_network;
        this.connectStatus = profile.connect_state;
        this.profileUrl = profile.url;
        this.connectUrl = profile.connect_url;
        this.campaignInvitationStatus = data.campaign_invitation_status;
        this.contractId = data.contract_id;
        this.avatar = utils.buildImage(profile.avatar || profile.profile_picture_url);

        this.link = data.short_link;

        this.originalLink = data.full_link || data.original_link;
        this.email = data.track_link_email || data.email;

        // metrics in card campaign
        this.contractVideos = data.videos_count || 0;
        this.investmentVideos = data.videos_value || 0;

        this.contractFeeds = data.posts_count || 0;
        this.investmentFeeds = data.posts_value || 0;

        this.contractStories = data.stories_count || 0;
        this.investmentStories = data.stories_value || 0;
        this.typeContract = data.contract_type || "";

        this.investmentTotal = this.investmentFeeds + this.investmentStories;

        if(!this.network) {
            const nw = this.airId.split(":")[0]

            if(nw) this.network = nw
        }

        return this;
    }

    // influencer details
    detailsFromjson(data) {
        const utils = new AirUtils();

        const profile = data.profile;
        const store = data.store || {};

        this.id = profile.id;
        this.airId = profile.air_id;
        this.name = profile.name;
        this.username = profile.username;
        this.profileUrl = profile.url;
        this.network = data.social_network;
        this.connectUrl = data.connect_url;
        this.connectStatus = data.connect_state;
        this.contractId = data.contract_id;
        this.avatar = profile.profile_picture_url ? utils.buildImage(profile.profile_picture_url) : "";
        this.link = data.short_link;
        this.email = data.track_link_email || data.email;
        this.originalLink = data.full_link || data.original_link;
        this.campaignInvitationStatus = data.campaign_invitation_status;
        // metrics
        this.contractVideos = data.videos_hired || 0;
        this.deliveredVideos = data.videos || 0;
        this.investmentVideos = data.videos_value || 0;

        this.contractFeeds = data.posts_hired || 0;
        this.deliveredFeeds = data.posts || 0;
        this.investmentFeeds = data.posts_value || 0;

        this.contractStories = data.stories_hired || 0;
        this.deliveredStories = data.stories || 0;
        this.investmentStories = data.stories_value || 0;

        this.typeContract = data.contract_type || "";

        // // social table
        this.cpi = data.cost_per_interaction || 0;
        this.txe = data.txe || 0;
        this.base = data.base || 0;
        this.airscore = data.airscore || 0;
        this.performance = data.performance || 0;
        this.interactionsTotal = data.interactions || 0;

        // // ec table
        this.clicks = store.clicks || 0;
        this.costPerClick = store.cost_per_click || 0;
        this.conversionRate = store.conversion_rate || 0;
        this.conversions = store.conversions || 0;
        this.costPerConversion = store.cost_per_conversion || 0;
        this.roi = store.roi || 0;

        this.investmentTotal = this.investmentFeeds + this.investmentStories;

        return this;
    }

    // tiktok discovery
    tikTokerFromJson(data) {
        this.name = data["display_name"];
        this.username = data["handle_name"];
        this.followers = data["followers_count"];
        this.followings = data["followings_count"];
        this.likesCount = data["likes_count"];
        this.avatar = data["profile_image"];
        this.videosCount = data["videos_count"];
        this.growing = data["growing_fast"];

        return this;
    }

    toJson() {
        let data = {};

        data["contract_type"] = this.typeContract;
        data["profile_url"] = this.profileUrl;
        data["posts_count"] = this.contractFeeds;
        data["posts_value"] = this.investmentFeeds;
        data["stories_count"] = this.contractStories;
        data["stories_value"] = this.investmentStories;

        return data;
    }
}

export default Influencer;
