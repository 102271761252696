import React from "react";
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next";
// 
import { SizedLoading } from "../Loading/Loading"
import { useGoogleLogin } from "@react-oauth/google";
import GoogleImage from '../../assets/images/google.svg'
// 
import "./ButtonAuthGoogle.scss";

const ButtonAuthGoogle = ({
    username,
    text,
    onSend,
    onAlert,
    isLoading,
    isConcluded,
}) => {
    const { t } = useTranslation()

    const requiredPermissions = [
        "email",
        "profile",
        "openid",
        "https://www.googleapis.com/auth/analytics.readonly",
        "https://www.googleapis.com/auth/userinfo.profile",
        "https://www.googleapis.com/auth/userinfo.email",
    ]

    const checkScopePermissions = (scope) => {
        let isValid = true

        if (scope) {
            requiredPermissions.forEach(item => {
                if (!scope.includes(item) && isValid) {
                    isValid = false
                }
            });
        }

        if (!isValid) {
            onAlert("error", t("erro"), t("Todas as permissões são necessárias, por favor verifique e marque todas as caixas de seleção"));
        }

        return isValid
    }

    const responseGoogle = async (response) => {
        let scope = response.scope

        const isScopeValid = checkScopePermissions(scope)

        if (isScopeValid) {
            onSend({ provider: "google", authorization_code: response.code })
        }
    }

    const login = useGoogleLogin({
        flow: "auth-code",
        prompt: "consent",
        responseType: "code",
        approvalPrompt: "force",
        cookiePolicy: "single_host_origin",
        onSuccess: responseGoogle,
        onError: responseGoogle,
        scope: "https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/userinfo.profile"
    });

    return <div className="google-login">
        {!isConcluded &&
            <div role="button" className="google-btn" onClick={() => login()}>
                <div className="google-icon-wrapper">
                    <img className="google-icon" src={GoogleImage} />
                </div>
                
                {!isLoading 
                    ? <span className="btn-text">{t(text)}</span>    
                    : <SizedLoading />
                }
            </div>
        }

        {isConcluded && <>
            <div className="google-btn disabled">
                <div className="google-icon-wrapper">
                    <img className="google-icon" src={GoogleImage} />
                </div>
                
                <span className="btn-text">{username || "-"}</span>    
            </div>

            <i className="icon icon-check"/>
        </>}
    </div>
}

ButtonAuthGoogle.defaultProps = {
    text: "Fazer login com o google",
    isLoading: false,
    isConcluded: false,
}

ButtonAuthGoogle.propTypes = {
    text: PropTypes.string,
    username: PropTypes.string,
    onSend: PropTypes.func,
    onAlert: PropTypes.func,
    isLoading: PropTypes.bool,
    isConcluded: PropTypes.bool,
}

export default ButtonAuthGoogle