import React from "react";
import PropTypes from "prop-types";
import "./SocialNetworkIcon.scss"

import iconTikTok from "../../assets/icons/tiktok.svg"

const labels = {
    tiktok: "TikTok",
    instagram: "Instagram",
}

const backgroundColors = {
    instagram: "#833ab4",
    tiktok: "#161722"
}
const SocialNetworkIcon = ({width, network, showLabel}) => {
    let iconBackgroundStyle = { width, height: width }

    if (showLabel) iconBackgroundStyle.marginLeft = "5px"
    iconBackgroundStyle.backgroundColor = backgroundColors[network]

    return <>
        {showLabel && labels[network]}
        <div className={"icon-background"} style={iconBackgroundStyle}>
            {network === 'tiktok' ? <img src={iconTikTok} className="tiktok-icon social-network-icon" alt="tiktok icon" /> : <i className={`social-network-icon font-icon icon-${network}`} />}
        </div>
    </>
}

SocialNetworkIcon.defaultProps = {
    width: "22px",
    showLabel: false
}

SocialNetworkIcon.propTypes = {
    width: PropTypes.string,
    network: PropTypes.string,
    showLabel: PropTypes.bool
}


export default SocialNetworkIcon;