import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
//
import { useTranslation } from "react-i18next";
//
import Card from "../Card/Card";
import Select from "../Select/Select";
import ChartSummary from "../Charts/ChartSummary/ChartSummary";
import Loading from "../Loading/Loading";
//
import { AirUtils } from "../../../utils/utils";
//
import "./CardSummary.scss";

const CardSummary = ({ loading, chart, funnel, inCampaign, onSearch }) => {
    const { t } = useTranslation();

    const airUtils = new AirUtils();

    const [chartName, setChartName] = useState();
    const [filterDate, setFilterDate] = useState("7");

    const optionDates = [
        { value: "7", key: t("Últimos 7 dias") },
        { value: "14", key: t("Últimos 14 dias") },
        { value: "21", key: t("Últimos 21 dias") },
        { value: "29", key: t("Últimos 29 dias") },
        { value: null, key: t("Período da campanha") },
    ].filter((item) => inCampaign || item.value !== null);

    useEffect(() => {
        if (funnel) onSearch({ name: chartName || funnel[0].label, filterDate: filterDate });
    }, [chartName, filterDate, funnel]);

    return (
        <Card
            className="card-funnel"
            child={
                <>
                    <div className="card-funnel-header">
                        <p className="title-card-summary">{t("rastreamento de link")}</p>

                        <span>{t("Total da campanha")}</span>
                    </div>

                    <div className="card-funnel-body">
                        <div className="funnel">
                            {funnel &&
                                funnel.map((item, key) => {
                                    return (
                                        <div
                                            key={key}
                                            className={`container-funnel-item ${
                                                chartName == item.label ? "item-active" : ""
                                            }`}
                                            onClick={() => {
                                                setChartName(item.label);
                                            }}
                                        >
                                            <div className="funnel-item">
                                                <span className="item-name">{t(item.label)}</span>
                                                <span className="item-value">
                                                    {airUtils.buildValue("number", item.value)}
                                                </span>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>

                        <div className="container-chart">
                            <Select
                                outline={true}
                                options={optionDates}
                                defaultValue={filterDate}
                                onChange={(option) => {
                                    setFilterDate(option.value);
                                }}
                            />

                            <div className="chart-summary">
                                {loading ? (
                                    <div className="card-loading">
                                        {" "}
                                        <Loading />{" "}
                                    </div>
                                ) : (
                                    <ChartSummary chart={chart} />
                                )}
                            </div>
                        </div>
                    </div>
                </>
            }
        />
    );
};

CardSummary.defaultProps = {
    inCampaign: false,
    loading: false,
};

CardSummary.propTypes = {
    inCampaign: PropTypes.bool,
    loading: PropTypes.bool,
    chart: PropTypes.object,
    funnel: PropTypes.array,
    onSearch: PropTypes.func.isRequired,
};

export default CardSummary;
