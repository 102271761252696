import api from "../../service/api";

// CAMPAIGN
export const getCampaign = async (id) => await api.get(`/campaign/${id}`);
export const getCampaignBasic = async (id) => await api.get(`/campaign/${id}/basic`);
export const getCampaignLastUpdate = async (id) => await api.get(`/campaign/${id}/updated_at`);

// CHARTs
export const getCampaignMetricsAudience = async (id) => await api.post(`/campaign/${id}/audience/metrics`, {});
export const getCampaignMetricsEC = async (id, network) =>
    await api.get(`/campaign/${id}/store/metrics`, { social_network: network });
export const getCampaignMetricsNW = async (id, network, postType) =>
    await api.post(`/campaign/${id}/social/metrics`, { social_network: network, post_type: postType });

// PROFILEs
export const getCampaignInfluencers = async (id, network, limit, startDate, endDate) =>
    await api.post(`/campaign/${id}/influencers/metrics`, {
        social_network: network,
        limit: limit,
        start_date: startDate,
        end_date: endDate,
    });
export const getCampaignContractors = async (id, network) =>
    await api.post(`/campaign/${id}/contractors/metrics`, { social_network: network ? network : "instagram" });

// POSTs
export const getCampaignVideos = async (id, page, network, limit, filter, startDate, endDate) =>
    await api.post(`/campaign/${id}/videos/${page}`, {
        limit: limit,
        social_network: network,
        filter: filter,
        start_date: startDate,
        end_date: endDate,
    });
export const getCampaignFeeds = async (id, page, network, limit, filter, startDate, endDate) =>
    await api.post(`/campaign/${id}/posts/${page}`, {
        limit: limit,
        social_network: network,
        filter: filter,
        start_date: startDate,
        end_date: endDate,
    });
export const getCampaignStories = async (id, page, network, limit, filter, startDate, endDate) =>
    await api.post(`/campaign/${id}/stories/${page}`, {
        limit: limit,
        social_network: network,
        filter: filter,
        start_date: startDate,
        end_date: endDate,
    });

// CONTRACTOR POSTs
export const getContractorVideos = (campaignId, airId, page, body) =>
    api.post(`/campaign/${campaignId}/contractors/${airId}/videos/${page}`, body);
export const getContractorPosts = (campaignId, airId, page, body) =>
    api.post(`/campaign/${campaignId}/contractors/${airId}/posts/${page}`, body);
export const getInfluencerProfile = (campaignId, airId, body) =>
    api.post(`/campaign/${campaignId}/influencers/${airId}`, body);
export const getInfluencerMetrics = (campaignId, airId, body) =>
    api.post(`/campaign/${campaignId}/influencers/${airId}/statistics`, body);

// INFLUENCERS POSTs
export const getAllInfluencerVideos = (campaignId, contractId, page, filter, startDate, endDate) =>
    api.post(`/campaign/${campaignId}/influencers/${contractId}/videos/${page}`, {
        limit: 8,
        filter: filter,
        start_date: startDate,
        end_date: endDate,
    });
export const getAllInfluencerPosts = (campaignId, contractId, page, filter, startDate, endDate) =>
    api.post(`/campaign/${campaignId}/influencers/${contractId}/posts/${page}`, {
        limit: 8,
        filter: filter,
        start_date: startDate,
        end_date: endDate,
    });
export const getAllInfluencerStories = (campaignId, airId, page, filter, startDate, endDate) =>
    api.post(`/campaign/${campaignId}/influencers/${airId}/stories/${page}`, {
        limit: 8,
        filter: filter,
        start_date: startDate,
        end_date: endDate,
    });

// ACTION POSTs
export const addPostToCampaign = (campaignId, postId) => api.post(`/campaign/${campaignId}/posts/extra/${postId}`);
export const removePostFromCampaign = (campaignId, postId) =>
    api.post(`/campaign/${campaignId}/posts/extra/${postId}/ignore`);
//
export const addStorieToCampaign = (campaignId, postId, postType) =>
    api.post(`/campaign/${campaignId}/${postType}/extra/${postId}`, {});
export const removeStorieFromCampaign = (campaignId, postId, postType) =>
    api.post(`/campaign/${campaignId}/${postType}/extra/${postId}/ignore`, {});
//
export const addVideosToCampaign = (campaignId, postId) =>
    api.post(`/campaign/${campaignId}/videos/extra/${postId}`, { post_type: "videos" });
export const removeVideosFromCampaign = (campaignId, postId) =>
    api.post(`/campaign/${campaignId}/videos/extra/${postId}/ignore`, { post_type: "videos" });
//
export const requestSparkAdAuthorization = (campaignId, orderId, authorizationDays) =>
    api.post(`/campaign/${campaignId}/orders/${orderId}/spark/request/${authorizationDays}`);

// FUNNEL
export const getFunnel = (campaignId) => api.get(`/campaign/${campaignId}/funnel/metrics`);

export const getHistogram = (campaignId, name, filter, filterDate) =>
    api.post(`/campaign/${campaignId}/histogram`, { key: name, filter: filter, filter_date: filterDate });

export const getTikTokPostsToApprove = (campaignId, page) =>
    api.get(`/campaign/tiktok/${campaignId}/orders/approval/${page}`);

export const approveTikTokPost = (campaignId, orderId) =>
    api.post(`/campaign/tiktok/${campaignId}/orders/${orderId}/review`, { approved: true });

export const reproveTikTokPost = (campaignId, orderId, reason) =>
    api.post(`/campaign/tiktok/${campaignId}/orders/${orderId}/review`, { approved: false, reason });
