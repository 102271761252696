import React from "react";
import PropTypes from "prop-types";
// 
import { useSelector } from "react-redux";
import { selectTiktokDiscovery } from "./TikTokDiscoverySlice";
// 
import SearchTiktoker from "./components/SearchTiktoker/SearchTiktoker";
import TiktokerDetails from "./components/TiktokerDetails/TiktokerDetails";
// 
import "./TikTokDiscovery.scss";

const TikTokDiscovery = ({
    onBack,
    onAdd,
}) => {
    const tiktokDiscoverySlice = useSelector(selectTiktokDiscovery)
    // 
    const influencerSelected = tiktokDiscoverySlice.influencerSelected

    return <div className="tiktok-discovery">
        {influencerSelected && 
            <TiktokerDetails 
                onAdd={(username) => onAdd(username)}
            />
        }

        {!influencerSelected && 
            <SearchTiktoker 
                onBack={onBack}
                onAdd={(username) => onAdd(username)}
            />
        }
    </div>
}

TikTokDiscovery.propTypes = {
    onBack: PropTypes.func,
    onAdd: PropTypes.func.isRequired,
}

export default TikTokDiscovery