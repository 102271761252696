import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getChart, getData } from './CampaignSummaryService'
import CampaignSummary from '../../models/CampaignSummary'
import StateStatus from '../../utils/status'

const initialState = {
  summary: {
    data: null,
    chart: null
  },
  status: StateStatus.idle,
  statusChart: StateStatus.idle,
}

export const fetchCampaignSummary = createAsyncThunk(
  'campaign-summary/metrics',
  async (data, thunkAPI) => {
    const { summary } = thunkAPI.getState()

    let result = {}

    const response = await getData()
    if (summary) {
      if (response.status == 200) {
        let cSummary = new CampaignSummary()
        cSummary.fromJson(response.data)

        result = cSummary
      }
    }

    return result
  }
)

export const fetchChart = createAsyncThunk(
  'campaign-summary/chart',
  async data => {
    const { name, filter, filterDate } = data
    let result = await getChart(name, filter, filterDate)
    
    return result.data
  }
)

export const summary = createSlice({
  name: 'campaign-summary',
  initialState: initialState,
  reducers: {},
  extraReducers: {
    [fetchCampaignSummary.pending]: state => {
      state.status = StateStatus.loading
    },
    [fetchCampaignSummary.fulfilled]: (state, action) => {
      state.status = StateStatus.succeeded

      state.summary.data = action.payload
    },
    [fetchCampaignSummary.rejected]: state => {
      state.status = StateStatus.failed
    },
    //
    [fetchChart.pending]: state => {
      state.statusChart = StateStatus.loading
    },
    [fetchChart.fulfilled]: (state, action) => {
      state.statusChart = StateStatus.succeeded

      state.summary.chart = action.payload
    },
    [fetchChart.rejected]: state => {
      state.statusChart = StateStatus.failed
    }
  }
})

// export const {  } = summary.actions

export const selectSummary = state => state.summary

export default summary.reducer
