import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
// 
import Avatar from "../Avatar/Avatar";
import { GreenButton, RejectButton } from "../Button/Button";
// 
import "./styles.scss";

const CardTikTokApprove = ({ profileName, avatarUrl, thumbnailUrl, onApprove, onReprove, onProfile }) => {
    const { t } = useTranslation();

    return (
        <div className="tiktok-approve-container">
            <div className="tiktok-approve-header">
                <Avatar 
                    size="31px"
                    src={avatarUrl} 
                    network="tiktok"
                    onClick={() => onProfile()}
                />
                
                <span className="tiktok-username">{profileName}</span>

                <i className="icon icon-tiktok-circle" />
            </div>

            <div className="tiktok-approve-thumbnail-container">
                <img src={thumbnailUrl} />
            </div>
            
            <div className="tiktok-approve-footer">
                <RejectButton onClick={onReprove}>
                    <div className="button-container">
                        {t("Rejeitar")} <i className="icon icon-dislike"/> 
                    </div>
                </RejectButton>

                <GreenButton onClick={onApprove}>
                    <div className="button-container">
                        <i className="icon icon-like"/> {t("Aprovar")}
                    </div>
                </GreenButton>
            </div>
        </div>

    )
}

CardTikTokApprove.propTypes = {
    avatarUrl: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    onApprove: PropTypes.func,
    onReprove: PropTypes.func,
    onProfile: PropTypes.func,
    profileName: PropTypes.string
}

export default CardTikTokApprove