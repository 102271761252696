// 
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import PropTypes from "prop-types";
import React from "react";
// 
import { AirUtils } from "../../../../utils/utils";
import "../Charts.scss";

const LineChart = ({ series, className, height, currencySymbol, onChange, formatType, color = "#009081", showLegend = false, hasPointer = true }) => {
    const airUtils = new AirUtils();
    let finalSeries = [];
    
    if(series && showLegend)
        series.map(s => {
            s["selected"] = true 
            
            finalSeries.push(s);
        })

    const options = {
        chart: {
            height: height,
            className: className,
            styles: {
                width: "100%",
            },
            backgroundColor: "transparent"
        },
        title: {
            text: ""
        },
        yAxis: {
            title: ""
        },
        xAxis: {
            labels: {
                formatter: function () {
                    return airUtils.buildValue("dateString", this.value)
                }
            }
        },
        legend: {
            useHTML: true,
            enabled: showLegend,
            itemCheckboxStyle: {
                backgroundColor: color,
                cursor: "pointer"
            },
            itemStyle:  {
                "cursor": "pointer",
            },
        },
        plotOptions: {
            series: {
                useHTML: true,
                showCheckbox: true,
                dataLabels: {
                    enabled: false,
                },
                marker: {
                    enabled: hasPointer,
                    states: {
                        hover: {
                            enabled: hasPointer
                        }
                    }
                },
                point: {
                    events: {
                        mouseOver: function() {
                            if (onChange) {
                                onChange(this.index)
                            }
                        },
                    }
                },
                events: {
                    checkboxClick: function () {
                        if(this.visible) {
                            this.hide();
                        } else {
                            this.show();
                        }
                    },
                    legendItemClick: function () {
                        if (this.selected) {
                          this.selected = false;
                        } else {
                          this.selected = true;
                        }
                    
                        if (this.visible) {
                          this.checkbox.checked = false;
                        } else {
                          this.checkbox.checked = true;
                        }
                    }
                }
            },
            line: {
                color: color
            }
        },

        tooltip: {
            formatter: function () {
                return `<div class="high-tooltip">
                ${airUtils.buildValue("dateString", this.x)}: <b>${airUtils.buildValue(formatType, this.y, currencySymbol)}</b>
              </div>`
            }
        },

        series: showLegend ? finalSeries : series,
    };

    return series && <HighchartsReact
        highcharts={Highcharts}
        options={options}
    />
}

LineChart.propTypes = {
    color: PropTypes.string,
    height: PropTypes.string,
    className: PropTypes.string,
    formatType: PropTypes.string,
    currencySymbol: PropTypes.string,
    onChange: PropTypes.func,
    series: PropTypes.arrayOf(PropTypes.object),
    hasPointer: PropTypes.bool,
    showLegend: PropTypes.bool,
}

export default LineChart;