const routes = {
    getCampaigns: "/campaigns/",
    refreshToken: "account/refresh",
    coupon: "/payment/coupon/verify",
    register: "/account/",
    billing: "/account/billing",
    payment: "/payment/save",

    // configuration
    update_name: "/account/",
    update_email: "/account/",
    update_password: "/account/",
    get_invoices: "/payment/invoices",
    load_invoices: "/payment/invoice",
    cancel_plan: "/payment/cancel",
    confirm_email: "/account/email/confirmation",
    paymentStatus: "/payment/status",
    planDetails: "/payment/plans",
    contractedPlan: "/payment/plan",
    subscription: "/payment/status",
    getAccounts: "/group/account/list",
    newAccount: "/group/account",
    update_account_name: "/group/account",
    update_account_email: "/group/account",
    update_account_password: "/group/account",
}

export default routes