import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
//
import { useDispatch, useSelector } from "react-redux";
import { changeObjAlert, showAlert } from "../../common/components/Alert/alertSlice";
import { clearStatus, selectLogin, sendEmail, sendLogin } from "./LoginSlice";
import StateStatus from "../../utils/status";
//
import { Form } from "antd";
import Button from "../../common/components/Button/Button";
import InputLabel from "../../common/components/InputLabel/InputLabel";
//
import logoSymbol from "../../common/assets/icons/hypolake_logo.svg";
import googleLogo from "../../common/assets/images/google.svg";

import "./Login.scss";

export const LANGUAGES = {
    pt: "https://hypolake.com/pt/planos/",
    "pt-BR": "https://hypolake.com/pt/planos/",
    // Does not exist yet at hypolake website.
    es: "https://hypolake.com/es/planos/",
};

const UseGoogle = () => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <div onClick={() => history.push("/login-google")} className="use-google">
            <img className="google-icon" src={googleLogo} width="32" />
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <div>{t("USAR GOOGLE")}</div>
            </div>
        </div>
    );
};

const Login = () => {
    const { t, i18n } = useTranslation();

    const dispatch = useDispatch();
    //
    const history = useHistory();
    const objLogin = useSelector(selectLogin);
    //
    const [openForgot, setOpenForgot] = useState();
    const planUrl = LANGUAGES[i18n.language] || "https://hypolake.com/plan/";

    const verifyHubLogin = () => {
        const hash = window.location.hash;
        const path = hash.substring(hash.indexOf("?"));
        const searchParams = new URLSearchParams(path);
        const redirectToken = searchParams.get("redirect_token");
        const hub = searchParams.get("hub");

        if (hub) {
            window.location.href = process.env.REACT_APP_HUB_URL;
        }
        if (redirectToken) {
            dispatch(sendLogin({ redirectToken }));
        }
    };

    const alert = (type, title, text, question, cancelText, okText, onOk) => {
        dispatch(
            changeObjAlert({
                show: true,
                type: type,
                title: title,
                text: text,
                okText: okText,
                question: question,
                cancelText: cancelText,
                onCancel: () => {
                    dispatch(showAlert(false));
                },
                onOk: () => {
                    onOk;
                },
            })
        );
    };

    const onFinish = (values) => {
        dispatch(
            !openForgot
                ? sendLogin({ email: values.email, password: values.password })
                : sendEmail({ email: values.email })
        );
    };

    useEffect(() => {
        if (objLogin.status.forgotPassword === StateStatus.failed) {
            alert("error", t("erro"), t(objLogin.msg));
            dispatch(clearStatus());
        } else if (objLogin.status.forgotPassword === StateStatus.succeeded) {
            alert("success", t("sucesso"), t(objLogin.msg));
            setOpenForgot(false);
            dispatch(clearStatus());
        }
    }, [objLogin.status.forgotPassword]);

    useEffect(() => {
        if (objLogin.status.loginProvider === StateStatus.failed || objLogin.status.login === StateStatus.failed) {
            alert("error", t("erro"), t(objLogin.msg));
            dispatch(clearStatus());
        } else if (
            objLogin.status.loginProvider === StateStatus.succeeded ||
            objLogin.status.login === StateStatus.succeeded
        ) {
            const originalUrl = localStorage.getItem("originalUrl");
            if (originalUrl) localStorage.removeItem("originalUrl");
            history.replace(originalUrl || "");
            dispatch(clearStatus());
        }
    }, [objLogin.status.login, objLogin.status.loginProvider]);

    useEffect(() => {
        verifyHubLogin();
    }, []);

    useEffect(() => {
        if (window.localStorage.getItem("access_token")) {
            if (!window.localStorage.getItem("refresh_token")) {
                window.localStorage.removeItem("access_token");
                window.localStorage.removeItem("refresh_token");
            } else history.replace("/");
        }
    }, []);

    return (
        <div className="login-page">
            <div className="login-container">
                <img className="logo-hypolake" src={logoSymbol} alt="hypolake-logo" />

                <Form onFinish={onFinish} layout="vertical" className={!openForgot ? "login" : "forgot"}>
                    <Form.Item
                        name="email"
                        label={t(!openForgot ? "E-mail" : "E-mail de recuperação")}
                        rules={[
                            {
                                required: true,
                                message: <div className="normal-12">{t("E-mail inválido!")}</div>,
                            },
                            {
                                type: "email",
                                message: <div className="normal-12">{t("E-mail inválido!")}</div>,
                            },
                        ]}
                    >
                        <InputLabel type="email" placeholder={t("Informe seu e-mail")} />
                    </Form.Item>

                    {!openForgot && (
                        <div className="junction-password">
                            <Form.Item
                                name="password"
                                label={t("Senha")}
                                className={openForgot != null ? "animate" : ""}
                                rules={[
                                    {
                                        required: true,
                                        message: <span style={{ display: "none" }}>/</span>,
                                    },
                                    () => ({
                                        validator(_, value) {
                                            const regex = "^(?=(.*[a-z]){1,})(?=(.*[A-Z]){1,})(?=(.*[0-9]){1,}).{8,}$";
                                            const regPassword = new RegExp(regex);

                                            if (!regPassword.test(value)) {
                                                return Promise.reject(
                                                    new Error(
                                                        t("A senha precisa ter no mínimo 8 caracteres contendo") +
                                                            ": " +
                                                            t(
                                                                "letra maiúscula, letra minúscula, caracter especial e número"
                                                            )
                                                    )
                                                );
                                            }

                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <InputLabel type="password" isPassword={true} placeholder={t("Informe sua senha")} />
                            </Form.Item>

                            <p className="forgot-password" onClick={() => setOpenForgot(true)}>
                                {t("Esqueci a senha")}
                            </p>
                        </div>
                    )}

                    <Button
                        width="100%"
                        type="submit"
                        htmlType="submit"
                        className="button-login"
                        text={t(!openForgot ? "Entrar" : "Enviar")}
                        loading={
                            objLogin.status.login === StateStatus.loading ||
                            objLogin.status.forgotPassword === StateStatus.loading
                        }
                    />

                    <UseGoogle />
                </Form>
                <button
                    className="button-register"
                    onClick={() => (!openForgot ? (window.location.href = planUrl) : setOpenForgot(false))}
                >
                    {t(!openForgot ? "Cadastrar-se" : "Fazer login")}
                </button>
            </div>
        </div>
    );
};

export default Login;
