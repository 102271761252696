import api, { apiWhitoutInterceptors } from "../../service/api";

export const sendAccount = async (data) => await apiWhitoutInterceptors.post("/account/auth", data);
export const sendHubAuth = async (redirectToken) =>
    await apiWhitoutInterceptors.post("/account/auth/hub", { redirect_token: redirectToken });
export const sendProviderAccount = async (data) => await apiWhitoutInterceptors.post("/account/auth/external", data);
//
export const forgotPassword = async (email) => await apiWhitoutInterceptors.post("/account/password/reset", { email });
export const getProfile = async () => await api.get("/account/me");

export const updateGuidingStep = async (step) => await api.post("/account/guiding", { step });
export const sendLanguagePreference = async (language) => await api.put("/account/preferences", { language });
