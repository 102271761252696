import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
//
import { Modal } from "antd";
import Slider from "react-slick";
// import ReactPlayer from "react-player";
//
import { humanize } from "../../../../utils/formatters";
import iconTikTok from "../../../../common/assets/icons/tiktok.svg";
import "./PostsModal.scss";

const PostsModal = ({ posts, index, visible, onOk, onCancel, isHashtag }) => {
    const { t } = useTranslation();
    //
    const [postActive, setPostActive] = useState();
    const [idxActive, setIdxActive] = useState();
    //
    const [slider, setSlider] = useState();
    // const [playing, setPlaying] = useState(false);
    const [play, setPlay] = useState(false);
    // const [videoError, setVideoError] = useState(false);
    //

    // const playerRef = useRef();
    const interactions = {
        engagement: t("Engajamento"),
        reach: t("Alcance"),
        interactions: t("Interações"),
        impressions: t("Impressões"),
        replies: t("Respostas"),
        taps_forward: t("Próxima"),
        taps_back: t("Voltar"),
        likes: t("Curtidas"),
        views: t("Visualizações"),
        shares: t("Compartilhamentos"),
        comments: t("Comentários"),
        reactions: t("Reações"),
        exits: t("Saída"),
        saved: t("Salvos"),
        engajament_rate: t("Taxa de engajamento (TXE)"),
        reach_rate: t("Taxa de alcance (TXE)"),
    };

    useEffect(() => {
        setIdxActive(index);
        setPostActive(posts && posts.at(index));
    }, [index]);

    useEffect(() => {
        // setPlaying(false);

        setTimeout(() => {
            setPostActive(posts && posts.at(idxActive));
        }, 250);
    }, [idxActive]);

    return (
        <>
            {postActive && (
                <Modal
                    visible={visible}
                    onOk={() => onOk()}
                    className="posts-modal"
                    onCancel={() => onCancel()}
                    maskClosable={true}
                >
                    <div className={`header-inf-name background-${postActive ? postActive.network : ""}`}>
                        {postActive.owner.name}
                    </div>

                    <div className="container-slider">
                        <i
                            className={`icon icon-back1 ${idxActive < 1 ? "disable" : "active"}`}
                            onClick={(event) => {
                                event.preventDefault();
                                if (slider && idxActive > 0) {
                                    setIdxActive(idxActive - 1);
                                    slider.slickGoTo(idxActive - 1, false);
                                }
                            }}
                        ></i>
                        <Slider
                            arrows={false}
                            speed={500}
                            infinite={false}
                            slidesToShow={posts.length == 1 ? 1 : 3}
                            centerMode={true}
                            className="center"
                            centerPadding="60px"
                            focusOnSelect={true}
                            initialSlide={index}
                            slidesToScroll={1}
                            ref={(c) => setSlider(c)}
                            beforeChange={() => {
                                setPlay(false);
                                // setPlaying(false);
                            }}
                            afterChange={(idx) => {
                                setIdxActive(idx);
                            }}
                        >
                            {posts.map((post, key) => (
                                <div key={key} className="container-post">
                                    <div className="post">
                                        {/* {post.network != "tiktok" && idxActive === key && post.url && !videoError ? (
                                            <ReactPlayer
                                                width="138px"
                                                height="246px"
                                                url={post.url}
                                                playing={playing}
                                                ref={playerRef}
                                                onEnded={() => setPlay(false)}
                                                onClick={(e) => {
                                                    if (e.target && e.target.nodeName == "VIDEO") {
                                                        if (!e.target.paused) {
                                                            setPlaying(false);
                                                            e.target.pause();
                                                            e.target.currentTime = 0;
                                                        } else {
                                                            window.open(post.link, "_blank");
                                                        }
                                                    }
                                                }}
                                                onError={() => {
                                                    setVideoError(true);
                                                }}
                                                style={{ cursor: playing ? "pointer" : "default" }}
                                            />
                                        ) : (
                                            <img
                                                className={`img img-${post.link ? "clickable" : ""}`}
                                                alt="post image"
                                                src={post.image}
                                                onError={(e) => (e.target.style.display = "none")}
                                                onClick={() => {
                                                    if (post.link && idxActive === key)
                                                        window.open(post.link, "_blank");
                                                }}
                                            />
                                        )} */}
                                        <div
                                            className={`img img-${post.link ? "clickable" : ""}`}
                                            alt="post image"
                                            style={{ backgroundImage: `url(${post.image})` }}
                                            onClick={() => {
                                                if (post.link && idxActive === key) window.open(post.link, "_blank");
                                            }}
                                        />

                                        {/* {post.network != "tiktok" &&
                                            post.type === "video" &&
                                            idxActive === key &&
                                            !playing && (
                                                <div
                                                    className="icon-cplay"
                                                    onClick={() => {
                                                        console.log("clicked");
                                                        if (post.url && !videoError && post.network !== "tiktok") {
                                                            const video = playerRef.current?.getInternalPlayer();
                                                            console.log(video);
                                                            video.play();
                                                            setPlaying(true);
                                                            return;
                                                        }

                                                        window.open(post.link, "_blank");
                                                    }}
                                                />
                                            )} */}
                                    </div>

                                    {(idxActive !== key || !play) && (
                                        <div
                                            className={`icon-background background-${post.network}`}
                                            style={{ width: "90px", height: "90px" }}
                                        >
                                            {post.network === "tiktok" ? (
                                                <img src={iconTikTok} className="tiktok-icon" alt="tiktok icon" />
                                            ) : (
                                                <i className={`icon icon-${post.network}`} />
                                            )}
                                            <div className="post"></div>
                                        </div>
                                    )}
                                </div>
                            ))}

                            {/* não sei explicar o pq disso, mas é necessário */}
                            <div className="post"></div>
                            <div className="post"></div>
                        </Slider>

                        <i
                            className={`icon icon-advance1 ${idxActive > posts.length - 2 ? "disable" : "active"}`}
                            onClick={(event) => {
                                event.preventDefault();
                                if (slider && idxActive < posts.length - 1) {
                                    setIdxActive(idxActive + 1);
                                    slider.slickGoTo(idxActive + 1, false);
                                }
                            }}
                        />
                    </div>

                    <div className="posts-interactions">
                        {postActive.interactions
                            .filter((interaction) => {
                                if (isHashtag && ["views", "interactions"].includes(interaction.key)) {
                                    return false;
                                }
                                return true;
                            })
                            .map((interaction, key) => (
                                <p key={key} className={`interaction interaction-${interaction.key}`}>
                                    <span className="interaction-label">{interactions[interaction.key]}</span>
                                    <span className="interaction-value">{humanize(interaction.value)}</span>
                                </p>
                            ))}
                    </div>
                </Modal>
            )}
        </>
    );
};

PostsModal.propTypes = {
    posts: PropTypes.array,
    index: PropTypes.number,
    visible: PropTypes.bool,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    isHashtag: PropTypes.bool,
};

export default PostsModal;
