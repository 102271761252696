import React from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import "./styles.scss"


const { TextArea } = Input;


const HypoTextArea = ({value, placeholder, onChange}) => {
 return <TextArea onChange={onChange} className="hypotextarea" value={value} placeholder={placeholder}/>
}


HypoTextArea.propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func
}

export default HypoTextArea;