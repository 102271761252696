import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";

import { clearCharts } from "./components/Charts/ChartsSlice";
import { changeObjAlert, showAlert } from "../../common/components/Alert/alertSlice";
import {
    clearStatusActionCD,
    duplicateCampaign,
    exportCampaign,
    archiveCampaign,
    filterDate,
    fetchCampaign,
    fetchInfluencers,
    fetchCampaignTopPosts,
    fetchCampaignTopStories,
    fetchContractors,
    selectCampaign,
    clearCampaign,
    fetchFunnel,
    fetchHistogram,
    fetchCampaignTopVideos,
    fetchTikTokPostsToApprove,
    clearStatusApprovePost,
    reproveTikTokPostThunk,
    approvePostThunk,
    fetchCampaignLastUpdate,
} from "./CampaignDetailsSlice";
import { clearIdToRedirect, clearStatusAction, selectCampaigns } from "../Home/HomeSlice";
import StateStatus from "../../utils/status";
//
import Avatar from "../../common/components/Avatar/Avatar";
import Card from "../../common/components/Card/Card";
import ChartEC from "./components/Charts/ChartEC";
import ConnectAndLinks from "../../common/components/InfluencerTable/ConnectAndLinks";
import ConnectStatus from "../../common/components/ConnectStatus/ConnectStatus";
import CardSummary from "../../common/components/CardSummary/CardSummary";
import { ChartNW } from "./components/Charts/ChartNW";
import CardFeed from "../../common/components/CardFeed/CardFeed";
import CardStories from "../../common/components/CardStories/CardStories";
import ChartContractor from "./components/Charts/ChartContractor";
import DropDown from "../../common/components/DropDown/DropDown";
import Header from "../../common/components/Header/Header";
import InfluencerDetailsTable from "../../common/components/InfluencerTable/InfluencerDetailsTable";
import Loading, { SizedLoading } from "../../common/components/Loading/Loading";
import PostsModal from "./components/PostsModal/PostsModal";
import Performance from "../../common/components/PerformanceBar/PerformanceBar";
import Select from "../../common/components/Select/Select";
import TagMentions from "../../features/CreateCampaign/components/TagMentions/TagMentions";
import CardTikTokApprove from "../../common/components/CardTiktokApprove";
import Pagination from "../../common/components/Pagination/Pagination";
//
import { humanize } from "../../utils/formatters";
import { AirUtils } from "../../utils/utils";
import "./CampaignDetails.scss";
import ReproveTiktokModal from "./components/ReproveTiktokModal";
import useWindowDimensions from "../../utils/dimensions";

const CampaignDetails = () => {
    const { t } = useTranslation();
    const { id, nw } = useParams();
    //
    const history = useHistory();
    const dispatch = useDispatch();
    const airUtils = new AirUtils();
    //
    const objCampaign = useSelector(selectCampaign);
    const objHome = useSelector(selectCampaigns);
    //
    const [campaign, setCampaign] = useState();
    const [getBasic, setGetBasic] = useState(false);
    const [openActions, setOpenActions] = useState(false);
    // modal
    const [visible, setVisible] = useState(false);

    const [idxInfluencer, setIndexInfluencer] = useState();
    // per table
    const [filterNwTable, setFilterNwTable] = useState(nw ? nw : "all");
    const [influencers, setInfluencers] = useState([]);
    const [contractors, setContractors] = useState([]);

    // tiktok approval
    const [isReproveModalVisible, setIsReproveModalVisible] = useState(false);
    const [orderId, setOrderId] = useState();

    const [limit, setLimit] = useState(4);

    const { width } = useWindowDimensions();
    useEffect(() => {
        if (id === objHome.idToRedirectAfterDuplicate) {
            dispatch(clearIdToRedirect());
        }
    });
    useEffect(() => {
        if (width <= 1100) {
            if (limit != 2) setLimit(2);
        } else if (width <= 1280) {
            if (limit != 3) setLimit(3);
        } else {
            setLimit(4);
        }
    }, [width]);

    // filters
    const filterPosts = [
        { value: "posts", key: "Posts" },
        { value: "stories", key: "Stories" },
        { value: "videos", key: "Reels/Videos" },
    ];

    const [hiredPostTypes, setHiredPostTypes] = useState([]);
    const [filterPost, setFilterPost] = useState("posts");
    const [allowedFilterPosts, setAllowedFilterPosts] = useState(filterPosts);

    let actions = [
        { icon: "edit", key: "edit", value: t("Editar campanha") },
        { icon: "duplicate", key: "duplicate", value: t("Duplicar campanha") },
        { icon: "export", key: "export", value: t("Exportar campanha") },
        { icon: "archieve", key: "filed", value: t("Arquivar campanha") },
    ];

    if (campaign?.type == "hashtag") actions = actions.filter((item) => item.key != "export");

    // utils
    const closeEditModal = () => {
        setIndexInfluencer(null);
        setVisible(false);
    };

    const generateLink = ({ text = "", name = "connect" }) => {
        navigator.clipboard.writeText(text);
        let message = t(
            "Link copiado! Envie este link para o influenciador. Através deste link, o influenciador poderá compartilhar seu link próprio para medirmos seu desempenho."
        );

        if (name === "connect")
            message = t(
                "Link copiado! Envie este link para o influenciador. Através deste link, o influenciador poderá se cadastrar no Airscore e assim você terá métricas mais completas sobre sua campanha."
            );
        else if (name === "contractor")
            message = t(
                "Link copiado! Através deste link, o contratante poderá se cadastrar no Airscore e assim você terá métricas mais completas sobre sua campanha."
            );

        alert("success", t("sucesso"), message);
    };

    const alert = (type, title, text, question, cancelText, okText, onOk) => {
        dispatch(
            changeObjAlert({
                show: true,
                type: type,
                title: title,
                text: text,
                okText: okText,
                question: question,
                cancelText: cancelText,
                onCancel: () => {
                    dispatch(showAlert(false));
                },
                onOk: () => {
                    onOk;
                },
            })
        );
    };

    useEffect(() => {
        if (objCampaign.campaign != null) setCampaign(objCampaign.campaign);
        else setCampaign(null);
    }, [objCampaign]);

    useEffect(() => {
        if (campaign && campaign.type == "hashtag") setFilterPost("hashtag");
    }, [campaign]);

    // gets
    useEffect(async () => {
        // clear
        if (objCampaign.campaign?.id != id) {
            await dispatch(clearCharts());

            await dispatch(clearCampaign());

            await dispatch(fetchCampaign(id));
            setGetBasic(true);
        }
    }, [objCampaign.filterEndDate, objCampaign.filterStartDate, objCampaign.campaign?.id]);

    useEffect(async () => {
        await dispatch(clearCharts());
        await dispatch(clearCampaign());
        await dispatch(fetchCampaign(id));
        await dispatch(fetchCampaignLastUpdate(id));
        setGetBasic(true);
    }, []);
    useEffect(() => {
        if (getBasic && campaign && objCampaign.started)
            if (filterPost === "stories") {
                dispatch(fetchCampaignTopStories({ id, page: 0, nw: nw, limit: limit, loading: false }));
            } else if (filterPost === "posts" || filterPost === "hashtag") {
                dispatch(fetchCampaignTopPosts({ id, page: 0, nw: nw, limit: limit, loading: false }));
            } else if (filterPost === "videos") {
                dispatch(fetchCampaignTopVideos({ id, page: 0, nw: nw, limit: limit, loading: false }));
            }
    }, [objCampaign.filterEndDate, objCampaign.filterStartDate, filterPost, campaign, limit]);

    useEffect(async () => {
        if (getBasic && campaign && objCampaign.started && campaign.objectives.includes("social_media"))
            dispatch(fetchInfluencers({ id, network: filterNwTable == "all" ? null : filterNwTable, limit: 5 }));
    }, [objCampaign.filterEndDate, filterNwTable, campaign]);

    useEffect(async () => {
        if (getBasic && campaign && objCampaign.started) {
            dispatch(fetchContractors({ id, network: null }));
            dispatch(fetchFunnel({ id }));
        }
    }, [objCampaign.filterEndDate, campaign]);

    // sets
    useEffect(() => {
        if (objCampaign.influencers) setInfluencers(objCampaign.influencers);
    }, [objCampaign.influencers]);

    useEffect(() => {
        if (objCampaign.contractors) setContractors(objCampaign.contractors);
    }, [objCampaign.contractors]);

    useEffect(() => {
        if (objCampaign.campaign?.hiredPostsType) {
            setHiredPostTypes(objCampaign.campaign?.hiredPostsType);
            setAllowedFilterPosts(
                filterPosts.filter((item) => objCampaign.campaign.hiredPostsType.includes(item.value))
            );
            setFilterPost(objCampaign.campaign?.hiredPostsType[0]);
        }
    }, [objCampaign.campaign?.hiredPostsType]);

    //get first page for tiktok approve posts
    useEffect(() => {
        dispatch(fetchTikTokPostsToApprove({ campaignId: id, page: 1 }));
    }, [id]);

    useEffect(() => {
        if (objCampaign.tiktokPostsToApprove.status.listPosts === StateStatus.succeeded) {
            dispatch(clearStatusApprovePost());
        }
    }, [objCampaign.tiktokPostsToApprove.status.listPosts]);

    useEffect(() => {
        if (objCampaign.tiktokPostsToApprove.status.approvePost === StateStatus.succeeded) {
            alert(
                "success",
                t("Video aprovado com sucesso"),
                t("Em instantes a publicação será publicada na rede do influenciador.")
            );

            setTimeout(() => {
                dispatch(fetchTikTokPostsToApprove({ campaignId: id, page: 1 }));
            }, 500);

            if (filterPost === "videos")
                dispatch(fetchCampaignTopVideos({ id, page: 0, nw: nw, limit: limit, loading: false }));
        }
    }, [objCampaign.filterEndDate, objCampaign.tiktokPostsToApprove.status.approvePost]);

    useEffect(() => {
        if (objCampaign.tiktokPostsToApprove.status.reprovePost === StateStatus.succeeded) {
            alert(
                "success",
                t("Comentário enviado com sucesso"),
                t(
                    "Agora que você mandou um feedback o perfil poderá aplicar alguns ajustes e enviar o conteúdo novamente."
                )
            );

            dispatch(clearStatusApprovePost());

            setTimeout(() => {
                dispatch(fetchTikTokPostsToApprove({ campaignId: id, page: 1 }));
            }, 500);
        }
    }, [objCampaign.tiktokPostsToApprove.status.reprovePost]);

    useEffect(() => {
        if (objCampaign.statusActionCD === StateStatus.succeeded) {
            alert("success", t("sucesso"), t(objCampaign.msg));
        } else if (objCampaign.statusActionCD === StateStatus.failed) {
            alert("error", t("erro"), t(objCampaign.msg));
        }

        dispatch(clearStatusAction());
        dispatch(clearStatusActionCD());
    }, [objCampaign.statusActionCD]);

    const getPostsSource = () => {
        if (filterPost === "stories") return objCampaign.stories;
        else if (filterPost === "videos") return objCampaign.videos;
        else return objCampaign.feeds;
    };
    return (
        <div className="campaign-details-page background-page">
            <PostsModal
                visible={visible}
                index={idxInfluencer}
                onOk={() => closeEditModal()}
                onCancel={() => closeEditModal()}
                posts={getPostsSource()}
                isHashtag={filterPost === "hashtag"}
            />

            <ReproveTiktokModal
                isLoading={StateStatus.loading === objCampaign.tiktokPostsToApprove.status.reprovePost}
                isVisible={isReproveModalVisible}
                onClose={() => setIsReproveModalVisible(false)}
                onOk={(reason) => {
                    dispatch(reproveTikTokPostThunk({ campaignId: id, orderId, reason }));
                }}
            />

            {objCampaign.status == StateStatus.succeeded && campaign && (
                <Header
                    back={true}
                    className="home-header"
                    limitEndDate={campaign.endDate}
                    limitStartDate={campaign.startDate}
                    subHeader={
                        <div className="subheader-left">
                            <div className="subheader-title">
                                <span className="span-name">{campaign.name}</span>

                                {objCampaign.statusActionCD === StateStatus.loading && (
                                    <SizedLoading size={24} withContainer={false} />
                                )}

                                <DropDown
                                    options={actions}
                                    optionsWithIcons={true}
                                    onChange={() => setOpenActions(!openActions)}
                                    onClick={(value) => {
                                        if (value.key === "edit") {
                                            history.push(`/edit-campaign/${campaign.id}/type`);
                                        } else if (value.key == "duplicate") {
                                            dispatch(duplicateCampaign(campaign.id));
                                        } else if (value.key == "export") {
                                            dispatch(exportCampaign(campaign.id));
                                        } else if (value.key == "filed") {
                                            dispatch(
                                                changeObjAlert({
                                                    show: true,
                                                    type: "warning",
                                                    title: t("atenção"),
                                                    question: t(
                                                        "Deseja realmente arquivar essa campanha? (Essa ação não pode ser desfeita)"
                                                    ),
                                                    okText: t("Sim, arquivar campanha"),
                                                    cancelText: t("Não, manter campanha"),
                                                    onCancel: () => {
                                                        dispatch(showAlert(false));
                                                    },
                                                    onOk: () => {
                                                        dispatch(archiveCampaign(campaign.id));
                                                    },
                                                })
                                            );
                                        }

                                        setOpenActions(false);
                                    }}
                                    child={<i className={`icon-more icon-more-${openActions ? "open" : "close"}`} />}
                                />
                            </div>

                            <span className="text">
                                {t("Campanha") + ": " + t("período de")}{" "}
                                {airUtils.buildValue("date", new Date(campaign.startDate))} -{" "}
                                {airUtils.buildValue("date", new Date(campaign.endDate))} | {t("tipo")}:{" "}
                                {campaign.type == "influencers" ? t("influenciadores") : t("hashtag")}
                                {campaign.objectives.length > 0 &&
                                    campaign.type == "influencers" &&
                                    ` | ${t("objetivo")}: ${campaign.objectives.includes("site") ? t("vendas") : ""} ${
                                        campaign.objectives.length > 1 ? ` ${t("e")} ` : ""
                                    } ${campaign.objectives.includes("social_media") ? t("redes socias") : ""}`}
                            </span>
                        </div>
                    }
                    funcDate={(date) => {
                        if (date) {
                            dispatch(filterDate({ startDate: date[0], endDate: date[1] }));
                        } else {
                            dispatch(filterDate({ startDate: null, endDate: null }));
                        }
                    }}
                />
            )}

            <div className="scroll-content">
                <div className="campaign-details-content">
                    {objCampaign.status == StateStatus.succeeded && objCampaign.started && getBasic && campaign && (
                        <>
                            <div className="top-wrapper">
                                <div className="campaign-last-update">
                                    {/* {objCampaign.lastUpdate && (
                                        <>
                                            {t("Dados atualizados em ")}
                                            {airUtils.buildValue("datetime", objCampaign.lastUpdate)}
                                        </>
                                    )} */}
                                </div>
                                {/* BODY */}
                                {campaign.type != "hashtag" && <Performance performance={campaign.performance} />}
                            </div>

                            {/* mentions */}
                            {campaign.type == "hashtag" && (
                                <Card
                                    child={
                                        <>
                                            <h3 className="campaign-title-data">
                                                {t("Hashtags oficiais da campanha")}
                                            </h3>
                                            {campaign && campaign.mentions.length > 0 ? (
                                                <div className="campaign-mentions">
                                                    {campaign.mentions.map((mention, key) => {
                                                        return <TagMentions key={key} text={mention} />;
                                                    })}
                                                </div>
                                            ) : (
                                                <p className="empty-value">
                                                    {t("Sem hashtahgs ou perfil na campanha")}
                                                </p>
                                            )}
                                        </>
                                    }
                                />
                            )}

                            {/* chart e-commerce */}
                            {campaign.type != "hashtag" && campaign.objectives.includes("site") && (
                                <ChartEC
                                    currencySymbol={campaign.currency}
                                    title={t("Performance da campanha em seu site/loja virtual")}
                                    campaignId={objCampaign.campaign.id}
                                />
                            )}

                            {/* chart socials */}
                            {(campaign.type === "hashtag" ||
                                (campaign.objectives.includes("social_media") && objCampaign.networks.length > 0)) && (
                                <ChartNW
                                    currencySymbol={campaign.currency}
                                    networks={objCampaign.networks}
                                    network={objCampaign.networks.length > 1 ? null : objCampaign.networks[0]}
                                    showFilterNw={campaign.type != "hashtag"}
                                    type={campaign.type == "hashtag" ? "hash" : "influ"}
                                    title={t(
                                        campaign.type == "hashtag"
                                            ? "Desempenho das hashtags na campanha"
                                            : "Desempenho dos influenciadores na campanha"
                                    )}
                                    // subtitle={t("Apenas as publicações relacionadas à campanha são contabilizadas")}
                                    hiredPostTypes={hiredPostTypes}
                                    onChange={(key) => {
                                        if (key === "feed") {
                                            setFilterPost("posts");
                                            return;
                                        }
                                        if (!["general", "audience"].includes(key)) {
                                            setFilterPost(key);
                                        }
                                    }}
                                />
                            )}

                            {/* aprove post */}
                            {objCampaign.networks.includes("tiktok") && (
                                <Card
                                    child={
                                        <>
                                            <div className="card-header">
                                                <h3 className="campaign-title-data">{t("Publicações pendentes")}</h3>

                                                <Pagination
                                                    pageSize={4}
                                                    total={objCampaign.tiktokPostsToApprove.pagination.total}
                                                    current={objCampaign.tiktokPostsToApprove.pagination.page}
                                                    isDisabled={
                                                        objCampaign.tiktokPostsToApprove.status.listPosts ===
                                                        StateStatus.loading
                                                    }
                                                    onChange={(page) => {
                                                        dispatch(fetchTikTokPostsToApprove({ campaignId: id, page }));
                                                    }}
                                                />
                                            </div>

                                            <div
                                                className={`card-content posts ${
                                                    objCampaign.tiktokPostsToApprove.posts.length < 4 ? "start" : ""
                                                }`}
                                            >
                                                {objCampaign.tiktokPostsToApprove.status.listPosts ===
                                                    StateStatus.succeeded &&
                                                    (objCampaign.tiktokPostsToApprove.posts.length > 0 ? (
                                                        objCampaign.tiktokPostsToApprove.posts.map((post) => (
                                                            <CardTikTokApprove
                                                                key={post.videoId}
                                                                thumbnailUrl={post.thumbnail}
                                                                avatarUrl={post.profile.avatar}
                                                                profileName={post.profile.name}
                                                                onProfile={() => {
                                                                    history.push(
                                                                        `/campaign/${id}/influencer/${post.profile.airId}/feeds`
                                                                    );
                                                                }}
                                                                onReprove={() => {
                                                                    setIsReproveModalVisible(true);
                                                                    setOrderId(post.orderId);
                                                                }}
                                                                onApprove={() => {
                                                                    dispatch(
                                                                        changeObjAlert({
                                                                            show: true,
                                                                            type: "warning",
                                                                            title: t("atenção"),
                                                                            text: `${t(
                                                                                "Deseja aceitar essa publicação"
                                                                            )}?`,
                                                                            okText: t("Sim, aceitar publicação"),
                                                                            cancelText: t("Não"),
                                                                            colorCancel: "secondary",
                                                                            onCancel: () => dispatch(showAlert(false)),
                                                                            onOk: () =>
                                                                                dispatch(
                                                                                    approvePostThunk({
                                                                                        campaignId: id,
                                                                                        orderId: post.orderId,
                                                                                    })
                                                                                ),
                                                                        })
                                                                    );
                                                                }}
                                                            />
                                                        ))
                                                    ) : (
                                                        <p className="empty-value">{t("Nenhum post pendente")}</p>
                                                    ))}

                                                {objCampaign.tiktokPostsToApprove.status.listPosts ===
                                                    StateStatus.loading && (
                                                    <div className="card-loading">
                                                        {" "}
                                                        <Loading />{" "}
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    }
                                />
                            )}

                            {/* posts */}
                            <Card
                                child={
                                    <>
                                        <div className="card-header">
                                            <div className="card-titles">
                                                <h3 className="campaign-title-data">{t("Publicações da campanha")}</h3>
                                            </div>
                                            {campaign.type != "hashtag" && (
                                                <Select
                                                    options={allowedFilterPosts}
                                                    defaultValue={filterPost}
                                                    onChange={(option) => {
                                                        setFilterPost(option.value);
                                                    }}
                                                />
                                            )}
                                        </div>

                                        {objCampaign.statusTopPosts == StateStatus.failed && "ERRO"}

                                        {objCampaign.statusTopPosts == StateStatus.loading && (
                                            <div className="card-loading">
                                                {" "}
                                                <Loading />{" "}
                                            </div>
                                        )}

                                        {objCampaign.statusTopPosts == StateStatus.succeeded && (
                                            <div
                                                className={`card-content posts ${filterPost}-posts ${
                                                    filterPost == "posts"
                                                        ? objCampaign.feeds.length < 4
                                                            ? "start"
                                                            : ""
                                                        : objCampaign.stories.length < 4
                                                        ? "start"
                                                        : ""
                                                }`}
                                            >
                                                {filterPost == "posts" &&
                                                    (objCampaign.feeds && objCampaign.feeds.length > 0 ? (
                                                        objCampaign.feeds.map((post, key) => (
                                                            <CardFeed
                                                                campaignId={objCampaign.campaign.id}
                                                                post={post}
                                                                key={key}
                                                            />
                                                        ))
                                                    ) : (
                                                        <p className="empty-value">
                                                            {t("Nenhum post foi feito pelos influenciadores")}
                                                        </p>
                                                    ))}

                                                {filterPost == "hashtag" &&
                                                    (objCampaign.feeds && objCampaign.feeds.length > 0 ? (
                                                        objCampaign.feeds.map((post, key) => {
                                                            if (post.type === "video") {
                                                                return (
                                                                    <CardStories
                                                                        key={key}
                                                                        post={post}
                                                                        campaignId={objCampaign.campaign.id}
                                                                        onClick={() => {
                                                                            setVisible(true);
                                                                            setIndexInfluencer(key);
                                                                        }}
                                                                    />
                                                                );
                                                            }
                                                            return (
                                                                <CardFeed
                                                                    campaignId={objCampaign.campaign.id}
                                                                    post={post}
                                                                    key={key}
                                                                />
                                                            );
                                                        })
                                                    ) : (
                                                        <p className="empty-value">
                                                            {t("Nenhum post foi feito pelos influenciadores")}
                                                        </p>
                                                    ))}

                                                {filterPost == "stories" &&
                                                    (objCampaign.stories && objCampaign.stories.length > 0 ? (
                                                        objCampaign.stories.map((storie, key) => (
                                                            <CardStories
                                                                campaignId={objCampaign.campaign.id}
                                                                post={storie}
                                                                key={key}
                                                                onClick={() => {
                                                                    setVisible(true);
                                                                    setIndexInfluencer(key);
                                                                }}
                                                            />
                                                        ))
                                                    ) : (
                                                        <p className="empty-value">
                                                            {t("Nenhum story foi feito pelos influenciadores")}
                                                        </p>
                                                    ))}

                                                {filterPost == "videos" &&
                                                    (objCampaign.videos && objCampaign.videos.length > 0 ? (
                                                        objCampaign.videos.map((video, key) => (
                                                            <CardStories
                                                                key={key}
                                                                post={video}
                                                                campaignId={objCampaign.campaign.id}
                                                                onClick={() => {
                                                                    setVisible(true);
                                                                    setIndexInfluencer(key);
                                                                }}
                                                                onRequestSpaskSuccess={() =>
                                                                    dispatch(
                                                                        fetchCampaignTopVideos({
                                                                            id: objCampaign.page,
                                                                            page: 0,
                                                                            nw: "tiktok",
                                                                            limit: 4,
                                                                        })
                                                                    )
                                                                }
                                                            />
                                                        ))
                                                    ) : (
                                                        <p className="empty-value">
                                                            {t("Nenhum video foi feito pelos influenciadores")}
                                                        </p>
                                                    ))}
                                            </div>
                                        )}
                                    </>
                                }
                                footer={
                                    <div
                                        onClick={() => {
                                            history.push(
                                                `/campaign/${id}/posts/${filterPost == "posts" ? "feeds" : filterPost}`
                                            );
                                        }}
                                    >
                                        <span>{t("Ver todas as publicações da campanha")}</span>
                                        <i className="icon icon-advance1" />
                                    </div>
                                }
                            />

                            {campaign.objectives.includes("social_media") && ( // compatarive table
                                <Card
                                    child={
                                        <>
                                            <div className="card-header">
                                                <h3 className="campaign-title-data">
                                                    {t("Tabela comparativa de influenciadores")}
                                                </h3>

                                                {objCampaign.networks.length > 1 && (
                                                    <Select
                                                        defaultValue={
                                                            !filterNwTable || filterNwTable == "all"
                                                                ? t("geral")
                                                                : filterNwTable
                                                        }
                                                        onChange={(nw) => {
                                                            setFilterNwTable(nw.key == t("geral") ? "all" : nw.key);
                                                        }}
                                                        options={[t("geral"), ...objCampaign.networks].map((nw) => {
                                                            return { value: nw, key: t(nw), icon: `icon-${nw}-circle` };
                                                        })}
                                                    />
                                                )}
                                            </div>

                                            <div className="table-card">
                                                {objCampaign.statusInfluencerMetrics == StateStatus.loading ? (
                                                    <div className="card-loading">
                                                        {" "}
                                                        <Loading />{" "}
                                                    </div>
                                                ) : influencers.length > 0 ? (
                                                    <InfluencerDetailsTable
                                                        campaignId={campaign.id}
                                                        influencers={influencers}
                                                        symbolCoin={campaign.currency}
                                                        withTracking={campaign.objectives.includes("site")}
                                                    />
                                                ) : (
                                                    <p className="empty-value">
                                                        {t("A campanha não possui influenciadores")}
                                                    </p>
                                                )}
                                            </div>
                                        </>
                                    }
                                    footer={
                                        <div
                                            onClick={() => {
                                                history.push(`/campaign/${id}/comparative-table`);
                                            }}
                                        >
                                            <span>{t("Ver tabela comparativa")}</span>
                                            <i className="icon icon-advance1" />
                                        </div>
                                    }
                                />
                            )}
                            {campaign.objectives.includes("social_media") && (
                                <Card
                                    child={
                                        <>
                                            <div className="card-header">
                                                <h3 className="campaign-title-data">{t("Conectes e Links")}</h3>
                                            </div>
                                            <div className="table-card">
                                                {objCampaign.statusInfluencerMetrics == StateStatus.loading ? (
                                                    <div className="card-loading">
                                                        {" "}
                                                        <Loading />{" "}
                                                    </div>
                                                ) : influencers.length > 0 ? (
                                                    <ConnectAndLinks
                                                        campaignId={campaign.id}
                                                        influencers={influencers}
                                                        withTracking={campaign.objectives.includes("site")}
                                                        withTCMCampaignInvitation={
                                                            objCampaign.networks &&
                                                            objCampaign.networks.includes("tiktok")
                                                        }
                                                    />
                                                ) : (
                                                    <p className="empty-value">
                                                        {t("A campanha não possui influenciadores")}
                                                    </p>
                                                )}
                                            </div>
                                        </>
                                    }
                                    footer={
                                        <div
                                            onClick={() => {
                                                history.push(`/campaign/${id}/comparative-table`);
                                            }}
                                        >
                                            <span>{t("Ver conectes e links")}</span>
                                            <i className="icon icon-advance1" />
                                        </div>
                                    }
                                />
                            )}

                            {objCampaign.statusContractor == StateStatus.loading ? (
                                <div className="card-loading">
                                    {" "}
                                    <Loading />{" "}
                                </div>
                            ) : (
                                <>
                                    {contractors.length > 0 &&
                                        contractors.map((contractor, key) => {
                                            // contractors
                                            return (
                                                <div key={key}>
                                                    <Card
                                                        child={
                                                            <>
                                                                <h3 className="campaign-title-data">
                                                                    {t(
                                                                        "Performance do contratante no período da campanha"
                                                                    )}
                                                                </h3>

                                                                <div className="contractor-card">
                                                                    <div className="contractor-preview">
                                                                        <div className="contractor-profile">
                                                                            <Avatar
                                                                                src={contractor.avatar}
                                                                                size="48px"
                                                                            />
                                                                            <span className="name">
                                                                                {contractor.name}
                                                                            </span>
                                                                        </div>
                                                                        <div className="contractor-metrics">
                                                                            <div className="contractor-metric">
                                                                                <i
                                                                                    className={`icon icon-${contractor.network}-circle`}
                                                                                />
                                                                                <span className="value">
                                                                                    {humanize(contractor.base)}
                                                                                </span>
                                                                            </div>

                                                                            {/* <div className="contractor-metric">
                                                            <i className={`icon icon-airscore-leak`} />
                                                            <span className="value">{contractor.airscore}</span>
                                                        </div> */}

                                                                            <div className="contractor-metric">
                                                                                <i className={`icon icon-posts`} />
                                                                                <span className="value">
                                                                                    {humanize(contractor.totalPosts)}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {contractor.interactions &&
                                                                        contractor.interactions.map(
                                                                            (interaction, key) => {
                                                                                return (
                                                                                    <div
                                                                                        key={key}
                                                                                        className="interaction-card"
                                                                                    >
                                                                                        <div className="interaction-junction">
                                                                                            <i
                                                                                                className={`icon icon-${interaction.alias}`}
                                                                                            />
                                                                                            <span className="interaction-quant">
                                                                                                {humanize(
                                                                                                    interaction.value
                                                                                                )}
                                                                                            </span>
                                                                                        </div>
                                                                                        <span className="name">
                                                                                            {t(interaction.alias)}
                                                                                        </span>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        )}

                                                                    <div
                                                                        className="interaction-card"
                                                                        onClick={() => {
                                                                            !contractor.connect &&
                                                                                generateLink({
                                                                                    text: contractor.connectUrl,
                                                                                    name: "contractor",
                                                                                });
                                                                        }}
                                                                    >
                                                                        <ConnectStatus
                                                                            status={contractor.connectStatus}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                        footer={
                                                            <div
                                                                onClick={() => {
                                                                    history.push(`/campaign/${id}/contractor/${contractor.id}`);
                                                                }}
                                                            >
                                                                <span>
                                                                    {t("Ver todas as publicações do contratante")}
                                                                </span>
                                                                <i className="icon icon-advance1" />
                                                            </div>
                                                        }
                                                    />
                                                    <ChartContractor
                                                        title={t(
                                                            "Histórico de base do contratante no período da campanha"
                                                        )}
                                                        data={contractor.histogram}
                                                        network={contractor.network}
                                                    />{" "}
                                                </div>
                                            );
                                        })}
                                </>
                            )}
                            {campaign.objectives.includes("site") &&
                            objCampaign.statusFunnel === StateStatus.succeeded ? (
                                <CardSummary
                                    inCampaign={true}
                                    chart={objCampaign.summary.chart}
                                    funnel={objCampaign.summary.data.funnel}
                                    loading={objCampaign.statusHistogram === StateStatus.loading}
                                    onSearch={(data) => dispatch(fetchHistogram({ ...data, id }))}
                                />
                            ) : null}

                            {/* END CONTENT */}
                        </>
                    )}
                    {objCampaign.status === StateStatus.failed && "ERRO"}
                    {objCampaign.status === StateStatus.loading && <Loading />}
                </div>
            </div>
        </div>
    );
};

export default CampaignDetails;
