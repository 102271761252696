import React, { useState } from "react";
import PropTypes from "prop-types";
// 
import { Modal, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
// 
import Input from "../Input/Input";
// 
import "./ModalAddInfluencer.scss";
import CurrencyFormat from "react-currency-format";
import checkUrl from "../../../utils/verifications";
import Button from "../Button/Button";

const ModalAddInfluencer = ({
    url,
    symbolCoin,
    isVisible,
    isLoading,
    onAdd,
    onClose
}) => {
    const { t } = useTranslation();
    // 
    const [quantVideos, setQuantVideos] = useState("");
    const [investmentVideos, setInvestmentVideos] = useState("");
    // 
    const [error, setError] = useState(-1);

    const sendInfluencer = () => {
        if(!checkUrl(url)) {
            setError(0); // error on input url
            return;
        } 
        if(!quantVideos || parseInt(quantVideos) <= 0) {
            setError(1); // error quant posts
            return;
        } 
        
        const data = {
            url: url,
            quant_video: quantVideos,
            investment_video: investmentVideos
        }

        onAdd(data)
    }

    const calcTotal = () => {
        let value = 0;

        if(investmentVideos && investmentVideos.length > 0) value += parseFloat(investmentVideos);
        
        return (!value || value === "" || value == 0) ? "0.00" : value;
    }

    return <Modal
        visible={isVisible}
        title={t("Selecione uma das opções")}
        className="modal-add-influencer"
        onOk={() => onClose()}
        onCancel={() => onClose()}
    >
        <div className="container-add-tiktok-influencer">
            <p className="label-input">{t("Insira a URL completa do perfil público do influenciador")}</p>
            
            {/* URL */}
            <Input 
                width="100%"
                value={url}
                error={error == 0} 
                disabled={true}
                textError={t("URL inválida ou perfil/fanpage não é pública. Verifique se você já adicionou essa url")} 
                placeholder="URL" 
                onChange={() => { 
                    if(error == 0) setError(-1) 
                }}
            />

            {/* POSTSTORIES */}
            <div className="container-data-posts">
                {/* posts */}
                <div className="card-post-data">
                    <div className="card-header">
                        <p className="card-header-title">{t("Videos")}</p>
                        <Tooltip child={t("Quantidade de post que o influenciador foi contratado para fazer na campanha.")} />
                    </div>

                    <div className="card-body">
                        <Input error={error == 1} textError={t("Informe a quantia")} onlyNum={true} value={quantVideos} width="48%" placeholder={t("Quantidade contratada")} onChange={(value) => { setQuantVideos(value); if(error == 1) setError(-1)}} />

                        <CurrencyFormat 
                            value={investmentVideos} 
                            className={`ant-input input-currency ${error == 2 ? "input-error" : ""}`} 
                            decimalSeparator={"."} thousandSeparator={","} 
                            prefix={`${symbolCoin} `} 
                            placeholder={t("Investimento")} 
                            onValueChange={(value) => { 
                                setInvestmentVideos(value.value)
                                if(error == 2) setError(-1) 
                            }}
                        />
                        
                        { error == 2 && <span className="span-error">{t("Informe o investimento")}</span> }
                    </div>
                </div>

                {/* total */}
                <div className={`card-post-data card-total ${url.includes("instagram.com") ? "" : "blocked"}`}>
                    <div className="card-header">
                        <p className="card-header-title">{t("Total")}</p>
                        <Tooltip child={t("Valor total pago para o influenciador fazer as publicações da campanha.")} />
                    </div>
                    <div className="card-body">
                        <CurrencyFormat  
                            disabled={true} 
                            value={calcTotal()} 
                            // placeholder={calcTotal()} 
                            decimalSeparator={"."} thousandSeparator={","} 
                            prefix={`${symbolCoin} `} 
                            className="ant-input input-currency"
                            style={{ width: "100%" }}
                        />
                    </div>
                </div>
            </div>
            
            {/* ADD */}
            <div className="junction-type-save">
                <Button 
                    icon="save"
                    color="success"
                    text={t("Salvar")} 
                    onClick={() => sendInfluencer()}
                    loading={isLoading}
                />
            </div>
        </div>
    </Modal>
}

ModalAddInfluencer.defaultProps = {
    loading: false,
    isVisible: false,
}

ModalAddInfluencer.propTypes = {
    url: PropTypes.string.isRequired,
    symbolCoin: PropTypes.string.isRequired,
    onAdd: PropTypes.func,
    onClose: PropTypes.func,
    isLoading: PropTypes.bool,
    isVisible: PropTypes.bool,
}

export default ModalAddInfluencer