import React, { useState } from "react";
import { useTranslation } from "react-i18next";
//
import { useDispatch, useSelector } from "react-redux";
import StateStatus from "../../../../utils/stateStatus";
//
import Card from "../../../../common/components/Card/Card";
import CardFooter from "../../components/CardFooter/CardFooter";
import CheckBox from "../../../../common/components/CheckBox/CheckBox";
//
import { selectCampaign, updateObjectives, saveStep } from "../../createCampaignSlice";
//
import "./StepTypeObjective.scss";

const StepTypeObjective = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    //
    const [error, setError] = useState(false);
    //
    const objCampaign = useSelector(selectCampaign);
    //
    const campaign = objCampaign.campaign;

    const objectiveOptions = [
        {
            alias: "social_media",
            text: t("Redes sociais / influenciador"),
            icon: "",
            description: t(
                "Selecione redes sociais / influenciador se o seu principal objetivo de campanha for engajamento e awareness em social."
            ),
        },
        {
            alias: "site",
            text: t("Site / loja virtual"),
            icon: "",
            description: t(
                "Selecione site / loja virtual se o seu objetivo for tráfego e conversões em site ou loja virtual."
            ),
        },
    ];

    const isValid = async () => {
        if (
            campaign.type === "hashtag" ||
            (objCampaign.campaign.objectives.length > 0 && campaign.type === "influencers")
        ) {
            dispatch(saveStep({ step: "type-objective", index: objCampaign.index + 1 }));
        } else {
            setError(true);
        }
    };

    return (
        <Card
            child={
                <>
                    {campaign.type == "influencers" && (
                        <>
                            <h2 className="step-name">{t("Selecione o objetivo da campanha")}</h2>
                            <h3 className="step-description">
                                {t("Selecione uma opção para criar a campanha ou ambas para cruzar os dados.")}
                            </h3>

                            <div className="objective-options">
                                {objectiveOptions.map((option, key) => {
                                    return (
                                        <CheckBox
                                            key={key}
                                            checked={objCampaign.campaign.objectives.includes(option.alias)}
                                            cardStyle={true}
                                            text={option.text}
                                            icon={option.icon}
                                            description={option.description}
                                            onChange={() => {
                                                setError(false);
                                                dispatch(updateObjectives(option.alias));
                                            }}
                                        />
                                    );
                                })}
                            </div>
                            {error && (
                                <>
                                    <br></br>
                                    <p className="text-empty">
                                        {t("Selecione ao menos uma das opções para porder prosseguir")}!
                                    </p>
                                </>
                            )}
                        </>
                    )}
                </>
            }
            footer={
                <CardFooter
                    loading={objCampaign.status == StateStatus.loading}
                    hasBack={false}
                    onNext={() => isValid()}
                />
            }
        />
    );
};

export default StepTypeObjective;
