// 
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
// 
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { saveStep, addMentions, removeMentions, selectCampaign } from '../../createCampaignSlice';
// 
import Card from '../../../../common/components/Card/Card';
import TagMentions from '../../components/TagMentions/TagMentions';
// 
import "./StepMentions.scss";
import CardFooter from '../../components/CardFooter/CardFooter';

const StepMentions = ({ onlyHash = false }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const objCampaign = useSelector(selectCampaign);

    const [error, setError] = useState(-1);

    // effects
    useEffect(() => {
        var input = document.querySelector(".input-mentions");
        input.addEventListener("keydown", (e) => {
            if (e.code === "Enter" || e.code === "NumpadEnter") {
                dispatch(addMentions({ text: e.target.value, onlyHash: onlyHash }));
                e.target.value = "";
            }
        });
    }, [])

    const isValid = () => {
        if (objCampaign.campaign.mentions.length > 0) {
            dispatch(saveStep({ step: "mentions", index: objCampaign.index + 1 }));
        } else {
            setError(1);
        }
    }

    return (
        <Card
            child={
                <>
                    <h2 className="step-name">{onlyHash ? t("Hashtags citadas/marcadas nos posts da campanha") : t("Hashtags e perfis citados/marcados nos posts da campanha")}</h2>

                    <p className="step-label">{t("Insira abaixo as #hashtags que deseja monitorar no período da campanha")}.</p>

                    <div className={`container-mentions aa ${error == 1 ? "error-mentions" : "aa"}`} onClick={() => {
                        document.querySelector(".ant-input").focus();
                        setError(-1);
                    }}>{
                            objCampaign.campaign.mentions.map((item, key) => {
                                return <TagMentions
                                    key={key}
                                    index={key}
                                    text={item}
                                    deleteMention={() => {
                                        dispatch(removeMentions(key));
                                    }}
                                />
                            })
                        }

                        <input
                            width="100%"
                            className="ant-input input input-full input-mentions"
                            onBlur={(e) => {
                                setError(-1);
                                dispatch(addMentions({ text: e.target.value, onlyHash: onlyHash }));
                                e.target.value = "";
                            }}
                        >
                        </input>
                    </div>
                    {error == 1 && <p className="text-empty">{t("Adicione menções para poder prosseguir")}!</p>
                    }
                </>
            }
            footer={
                <CardFooter 
                    loading={objCampaign.status == "loading"}
                    onBack={() => dispatch(saveStep({ step: "mentions", index: objCampaign.index - 1 }))}
                    onNext={() => isValid() }
                />
            }
        />
    );
}

StepMentions.propTypes = {
    onlyHash: PropTypes.bool
}

export default StepMentions;