import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
//
import { useDispatch, useSelector } from "react-redux";
import { changeObjAlert, showAlert } from "../Alert/alertSlice";
import { updateInfluencer } from "../../../features/CreateCampaign/createCampaignSlice";
import { influencerLink } from "../../../features/CreateCampaign/CreateCampaignService";
import { selectCampaign } from "../../../features/CampaignDetails/CampaignDetailsSlice";
//
import { Tooltip, Typography } from "antd";
import Avatar from "../Avatar/Avatar";
import Input from "../Input/Input";
import Button from "../Button/Button";
import ConnectStatus from "../ConnectStatus/ConnectStatus";
//
import iconTikTok from "../../assets/icons/tiktok.svg";
import iconSendEmail from "../../../common/assets/icons/send_email.svg";
import "./InfluencerTable.scss";

const ConnectAndLinks = ({ influencers = [], campaignId, withTracking = false, withTCMCampaignInvitation = false }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [orderInfluencers, setOrderInfluencers] = useState([]);
    const objCampaign = useSelector(selectCampaign);

    const [bigger] = useState({
        airscore: null,
        base: null,
        interactions: null,
        posts: null,
        performance: null,
        stories: null,
        txe: null,
    });

    const [minors] = useState({
        airscore: null,
        base: null,
        interactions: null,
        posts: null,
        performance: null,
        stories: null,
        txe: null,
    });

    const alert = (type, title, text, question, cancelText, okText, onOk) => {
        dispatch(
            changeObjAlert({
                show: true,
                type: type,
                title: title,
                text: text,
                okText: okText,
                question: question,
                cancelText: cancelText,
                onCancel: () => {
                    dispatch(showAlert(false));
                },
                onOk: () => {
                    onOk;
                },
            })
        );
    };

    const generateLink = ({ text = "", name = "coneect" }) => {
        if (text == "") {
            dispatch(
                changeObjAlert({
                    show: true,
                    type: "warning",
                    title: t("warning"),
                    text: t(""),
                    onCancel: () => {
                        dispatch(showAlert(false));
                    },
                })
            );
        } else {
            navigator.clipboard.writeText(text);

            dispatch(
                changeObjAlert({
                    show: true,
                    type: "success",
                    title: t("sucesso"),
                    text: t(
                        name === "connect"
                            ? t(
                                  "Link copiado! Envie este link para o influenciador. Através deste link, o influenciador poderá se cadastrar no Airscore e assim você terá métricas mais completas sobre sua campanha."
                              )
                            : t(
                                  "Link copiado! Envie este link para o influenciador. Através deste link, o influenciador poderá compartilhar seu link próprio para medirmos seu desempenho."
                              )
                    ),
                    onCancel: () => {
                        dispatch(showAlert(false));
                    },
                })
            );
        }
    };

    const sendLink = (influencer, index) => {
        setLoading(true);

        dispatch(updateInfluencer({ influencer: influencer, index: index }));

        influencerLink(objCampaign.campaign.id, influencer.contractId, {
            link: influencer.originalLink,
            email: influencer.email,
        })
            .then(() => {
                alert(
                    "success",
                    t("sucesso"),
                    `${t("O e-mail foi enviado para o influenciador")} (${influencer.name})`
                );

                setLoading(false);
            })
            .catch((err) => {
                alert("error", t("erro"), t(err.message || "Erro ao enviar e-mail"));
                setLoading(false);
            });
    };

    useEffect(() => {
        if (influencers.length > 0) {
            setOrderInfluencers(influencers);

            influencers.map((inf) => {
                if (inf.airscore > bigger.airscore || bigger.airscore == null) {
                    bigger.airscore = inf.airscore;
                }
                if (inf.base > bigger.base || bigger.base == null) {
                    bigger.base = inf.base;
                }
                if (inf.cpi > bigger.cpi || bigger.cpi == null) {
                    bigger.cpi = inf.cpi;
                }
                if (inf.interactionsTotal > bigger.interactionsTotal || bigger.interactionsTotal == null) {
                    bigger.interactionsTotal = inf.interactionsTotal;
                }
                if (inf.deliveredFeeds > bigger.posts || bigger.posts == null) {
                    bigger.posts = inf.deliveredFeeds;
                }
                if (inf.performance > bigger.performance || bigger.performance == null) {
                    bigger.performance = inf.performance;
                }
                if (inf.deliveredStories > bigger.stories || bigger.stories == null) {
                    bigger.stories = inf.deliveredStories;
                }
                if (inf.txe > bigger.txe || bigger.txe == null) {
                    bigger.txe = inf.txe;
                }
                //
                if (inf.airscore < minors.airscore || minors.airscore == null) {
                    minors.airscore = inf.airscore;
                }
                if (inf.base < minors.base || minors.base == null) {
                    minors.base = inf.base;
                }
                if (inf.cpi < minors.cpi || minors.cpi == null) {
                    minors.cpi = inf.cpi;
                }
                if (inf.interactionsTotal < minors.interactionsTotal || minors.interactionsTotal == null) {
                    minors.interactionsTotal = inf.interactionsTotal;
                }
                if (inf.deliveredFeeds < minors.posts || minors.posts == null) {
                    minors.posts = inf.deliveredFeeds;
                }
                if (inf.performance < minors.performance || minors.performance == null) {
                    minors.performance = inf.performance;
                }
                if (inf.deliveredStories < minors.stories || minors.stories == null) {
                    minors.stories = inf.deliveredStories;
                }
                if (inf.txe < minors.txe || minors.txe == null) {
                    minors.txe = inf.txe;
                }
            });
        }
    }, [influencers]);

    return (
        <table className="table-influencer table-influencer-details">
            <thead>
                <tr className="table-c-titles">
                    <th rowSpan="1" className="th- " onClick={() => {}}></th>
                    <th rowSpan="1" className="th-network" onClick={() => {}}>
                        <span className="name-column">{t("Network")}</span>
                    </th>
                    <th rowSpan="2" className="th-name" onClick={() => {}}>
                        <span className="name-column">{t("influenciador")}</span>
                    </th>
                    <th rowSpan="2" className="th-connect-status" onClick={() => {}}>
                        <div className="th-header-container">
                            <span className="name-column">{t("Conecte")}</span>
                        </div>
                    </th>

                    {withTCMCampaignInvitation && (
                        <th rowSpan="2" className="th-campaign-invitation-status" onClick={() => {}}>
                            <div className="th-header-container">
                                <span className="name-column">{t("Convite da Campanha")}</span>
                            </div>
                        </th>
                    )}

                    {withTracking && (
                        <th rowSpan="2" className="th-trackingLink" onClick={() => {}}>
                            <div className="th-header-container">
                                <span className="name-column">{t("Link de rastreiamento")}</span>
                            </div>
                        </th>
                    )}
                </tr>
            </thead>

            <tbody>
                {orderInfluencers.length > 0 &&
                    orderInfluencers.map((influencer, key) => {
                        return (
                            <tr key={key}>
                                <th className="th-position" width="5%">
                                    {key + 1}º
                                </th>

                                <th className={`th-network color-${influencer.network}`}>
                                    {influencer.network === "tiktok" ? (
                                        <img className="tiktok-icon-circle" alt="tiktok icon" src={iconTikTok} />
                                    ) : (
                                        <i className={`icon icon-${influencer.network}-circle`} />
                                    )}
                                </th>

                                <th className="th-profile">
                                    <Avatar
                                        size="24px"
                                        network={influencer.network}
                                        src={influencer.avatar}
                                        onClick={() => {
                                            history.push(
                                                `/campaign/${campaignId}/influencer/${influencer.airId}/feeds`
                                            );
                                        }}
                                    />
                                    <Tooltip title={`@${influencer.username}`}>
                                        {influencer.name || influencer.username}
                                    </Tooltip>
                                </th>

                                <th
                                    className={`th-in-airscore ${!influencer.connected && "in-airscore"}`}
                                    onClick={() => {
                                        if (!influencer.connected && influencer.network !== "tiktok") {
                                            generateLink({ text: influencer.connectUrl });
                                        }
                                    }}
                                >
                                    <ConnectStatus
                                        status={
                                            influencer.network === "tiktok" &&
                                            ["waiting", "expired"].includes(influencer.connectStatus)
                                                ? "connected"
                                                : influencer.connectStatus
                                        }
                                    />
                                </th>

                                {withTCMCampaignInvitation && (
                                    <th className={`th-campaign-invitation-status`}>
                                        {influencer.campaignInvitationStatus && (
                                            <label
                                                className={`label-campaign-invitation-status label-campaign-invitation-status-${influencer.campaignInvitationStatus}`}
                                            >
                                                {t(influencer.campaignInvitationStatus)}
                                            </label>
                                        )}
                                    </th>
                                )}

                                {withTracking && (
                                    <th className="th-tracking">
                                        {!influencer.originalLink && (
                                            <div>
                                                <Typography>Necessário cadastrar</Typography>
                                            </div>
                                        )}

                                        {influencer.originalLink && (
                                            <div>
                                                <Input
                                                    width="100%"
                                                    // error="{error}"
                                                    textError={t("Informe uma url de domínio válida!")}
                                                    defaultValue={influencer.email}
                                                    disabled={influencer.email}
                                                    placeholder={t("Insira o e-mail")}
                                                    onBlur={(text) => {
                                                        influencer.email = text;
                                                    }}
                                                />

                                                <Button
                                                    disabled={false}
                                                    onClick={() => {
                                                        sendLink(influencer, key);
                                                    }}
                                                    loading={loading}
                                                    text={
                                                        <Tooltip
                                                            title={t("Enviar link para o e-mail do influenciador")}
                                                        >
                                                            {!loading ? (
                                                                <img
                                                                    className="send-email-icon"
                                                                    alt="send email"
                                                                    src={iconSendEmail}
                                                                />
                                                            ) : (
                                                                ""
                                                            )}
                                                        </Tooltip>
                                                    }
                                                />

                                                <Tooltip title={t("Copiar link do influenciador")}>
                                                    <i
                                                        className="icon icon-copy"
                                                        onClick={() => {
                                                            alert(
                                                                "success",
                                                                t("sucesso"),
                                                                t("Link copiado com sucesso")
                                                            );

                                                            navigator.clipboard.writeText(influencer.originalLink);
                                                        }}
                                                    />
                                                </Tooltip>
                                            </div>
                                        )}
                                    </th>
                                )}
                            </tr>
                        );
                    })}
            </tbody>
        </table>
    );
};

ConnectAndLinks.propTypes = {
    influencers: PropTypes.arrayOf(PropTypes.object),
    withTracking: PropTypes.bool,
    campaignId: PropTypes.string,
    withTCMCampaignInvitation: PropTypes.string,
};

export default ConnectAndLinks;
