import React, { useEffect, useState } from 'react'
//
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { changeObjAlert, showAlert } from '../../../../common/components/Alert/alertSlice'
//
import Button from '../../../../common/components/Button/Button'
//
import StateStatus from '../../../../utils/stateStatus';
import PlanCancelModal from './components/PlanCancelModal/PlanCancelModal'
import { selectConfiguration, cancelPlan, getContractedPlan, getSubscription } from '../../ConfigurationSlice'
import Card from '../../../../common/components/Card/Card';
import Loading from '../../../../common/components/Loading/Loading'
import { AirUtils } from '../../../../utils/utils'

const ConfigPayment = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const config = useSelector(selectConfiguration)
	const utils = new AirUtils()

	const [showModal, setShowModal] = useState(false);

	const alert = (type, title, text, question, cancelText, okText, onOk) => {
		dispatch(
			changeObjAlert({
				show: true,
				type,
				title,
				text,
				okText,
				question,
				cancelText,
				onCancel: () => {
					dispatch(showAlert(false))
				},
				onOk: onOk || (() => { }),
			})
		)
	}

	useEffect(() => {
		if (config.statusCancel === StateStatus.failed) alert("error", t("erro"), config.msg)
		if (config.statusCancel === StateStatus.succeeded) { 
			alert("success", t("sucesso"), config.msg)
			dispatch(getSubscription())
		}

		setShowModal(false)
	}, [config.statusCancel])

	useEffect(() => {
		dispatch(getContractedPlan())
		dispatch(getSubscription())
	}, [])
	
	return <>
		<PlanCancelModal
			show={showModal}
			loading={config.statusCancel === StateStatus.loading}
			onOk={() => { dispatch(cancelPlan()) }}
			onCancel={() => setShowModal(false)}
		/>
		
		{config.statusPayment === StateStatus.loading || config.statusContractedPlan === StateStatus.loading
			? <Loading />
			:
			<Card padding="24px 40px" child={
				<>
					<h2 className='card-title'>{t("Plano contratado")}</h2>

					<span className="data-value">{`${config.objPayment.name} ${t("mensal")} - ${utils.buildValue("currency", config.subscription.last_payment_value || config.objPayment.value )}`}</span>
					{config.subscription.canceled && config.subscription.expires_date && <>
						<span className="data-value" style={{ color: "#aaa" }}>{`${t("plano válido até")} ${utils.buildValue("date", new Date(config.subscription.expires_date))}`}</span>
					</>}

					<div className="card-footer-aside">
						{config.subscription.canceled && <>
							{t("Plano cancelado")}
						</>}

						{!config.subscription.canceled && config.objPayment.value !== 0 &&
							<Button
								outline
								color="grey"
								text={t("Cancelar plano")}
								onClick={() => setShowModal(true)}
							/>
						} 
					</div>
				</>
			} />
		}
	</>
}

export default ConfigPayment
