import { createSlice } from '@reduxjs/toolkit';

const perAlert = () => { }

const  initialState = {
    value: {
        // values
        show: false,
        type: "success",
        title: "",
        text: "",
        question: "",
        okText: "",
        cancelText: "",
        onOk: perAlert(),
        onCancel: perAlert(),
    },
    status: 'dynamic-alert'
}

export const alert = createSlice({
    name: 'alert',
    initialState: initialState,
    reducers: {
        showAlert: (state, action) => {
            state.value.show = action.show;
        },
        changeObjAlert: (state, action) => {
            state.value = {
                // values
                show: action.payload.show || false,

                type: action.payload.type || "success",

                title: action.payload.title || "",
                text: action.payload.text || "",
                question: action.payload.question || "",

                colorCancel: action.payload.colorCancel || "danger",
                colorOk: action.payload.colorOk || "grey",

                okText: action.payload.okText || "",
                cancelText: action.payload.cancelText || "",

                onOk: action.payload.onOk,
                onCancel: action.payload.onCancel,
            };
        }
    }
})

export const { changeObjAlert, showAlert } = alert.actions;
export const selectObjAlert = (state) => state.alert.value;

export default alert.reducer;