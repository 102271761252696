import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
//
import { useDispatch, useSelector } from "react-redux";
import {
    addInfluencerInCampaign,
    clearAddInfluencerInCampaign,
    fetchPossibleCampaigns,
    selectTiktokDiscovery,
} from "../../common/components/TikTokDiscovery/TikTokDiscoverySlice";
import { changeObjAlert, showAlert } from "../../common/components/Alert/alertSlice";
//
import { Form, Modal } from "antd";
import Card from "../../common/components/Card/Card";
import Radio from "../../common/components/Radio/Radio";
import Header from "../../common/components/Header/Header";
import Select from "../../common/components/Select/Select";
import TikTokDiscovery from "../../common/components/TikTokDiscovery/TikTokDiscovery";
//
import "./TikTok.scss";
import StateStatus from "../../utils/stateStatus";
import Loading from "../../common/components/Loading/Loading";
import ModalAddInfluencer from "../../common/components/ModalAddInfluencer/ModalAddInfluencer";

const TikTok = () => {
    const { t } = useTranslation();
    //
    const history = useHistory();
    const dispatch = useDispatch();
    //
    const [form] = Form.useForm(null);
    //
    const tiktokDiscoverySlice = useSelector(selectTiktokDiscovery);
    const campaigns = tiktokDiscoverySlice.campaigns;
    const status = tiktokDiscoverySlice.statusPossibleCampaigns;
    //
    const options = [
        { value: "create", label: t("Criar nova campanha") },
        { value: "add", label: t("Adicionar a uma campanha existente") },
    ];
    //
    const [isNewCampaign, setIsNewCampaign] = useState(true);
    const [showAddOptions, setShowAddOptions] = useState(false);
    //
    const [selectedCampaign, setSelectedCampaign] = useState();
    const [selectedInfluencer, setSelectedInfluencer] = useState();

    const onFinish = ({ type, id }) => {
        if (!type || type.value === "create") {
            history.push({
                pathname: "/create-campaign/type",
                search: "?influencer=" + window.btoa(`https://www.tiktok.com/@${selectedInfluencer || ""}`),
            });
        } else if (type.value === "add" && id) {
            const selectedId = id.value;

            const objCampaign = campaigns.find((campaign) => campaign.id === selectedId);

            setIsNewCampaign(true);
            setSelectedCampaign(objCampaign);
        }
    };

    useEffect(() => {
        if (!isNewCampaign && selectedInfluencer) dispatch(fetchPossibleCampaigns({ username: selectedInfluencer }));
    }, [isNewCampaign]);

    useEffect(() => {
        const stats = tiktokDiscoverySlice.statusAddInfluencerInCampaign;

        if (stats === StateStatus.succeeded || stats === StateStatus.failed) {
            setIsNewCampaign(true);
            setShowAddOptions(false);
            setSelectedCampaign(null);

            dispatch(
                changeObjAlert({
                    show: true,
                    type: stats === StateStatus.succeeded ? "success" : stats === StateStatus.succeeded,
                    title: stats === StateStatus.succeeded ? t("sucesso") : t("erro"),
                    text:
                        stats === StateStatus.succeeded
                            ? `${t("Perfil adicionado")}`
                            : `${t("Erro ao adicionar perfil")}`,
                    onCancel: () => dispatch(showAlert(false)),
                })
            );

            form.resetFields();
            dispatch(clearAddInfluencerInCampaign());
        }
    }, [tiktokDiscoverySlice.statusAddInfluencerInCampaign]);

    return (
        <div className="tiktok-page background-page">
            <Header title={t("TikTok Search")} className="tiktok-header" />

            <ModalAddInfluencer
                url={`https://www.tiktok.com/@${selectedInfluencer || ""}`}
                symbolCoin={selectedCampaign && selectedCampaign.coin}
                isVisible={selectedCampaign !== null && selectedCampaign !== undefined}
                isLoading={tiktokDiscoverySlice.statusAddInfluencerInCampaign === StateStatus.loading}
                onClose={() => setSelectedCampaign(null)}
                onAdd={(data) => {
                    dispatch(addInfluencerInCampaign({ id: selectedCampaign.id, ...data }));
                }}
            />

            <Modal
                title={t("Selecione uma das opções")}
                className="modal-add-options"
                visible={showAddOptions}
                onOk={() => setShowAddOptions(false)}
                onCancel={() => setShowAddOptions(false)}
            >
                <Form onFinish={onFinish} form={form}>
                    <Form.Item name="type" defaultValue={options[0]}>
                        <Radio
                            cardStyle={false}
                            options={options}
                            onChange={(item) => setIsNewCampaign(item.value === "create")}
                        />
                    </Form.Item>

                    {!isNewCampaign && (
                        <>
                            {status === StateStatus.succeeded && (
                                <Form.Item
                                    name="id"
                                    rules={[
                                        {
                                            required: true,
                                            message: <span className="text-error">{t("Selecione uma opção")}</span>,
                                        },
                                    ]}
                                >
                                    <Select
                                        placeholder={t("Selecione a campanha")}
                                        options={campaigns.map((campaign) => ({
                                            value: campaign.id,
                                            key: campaign.name,
                                        }))}
                                    />
                                </Form.Item>
                            )}

                            {status === StateStatus.loading && <Loading />}
                        </>
                    )}

                    <button className="button pattern-secondary">{t("Confirmar")}</button>
                </Form>
            </Modal>

            <div className="tiktok-content scroll-content">
                <Card
                    child={
                        <>
                            <p className="tiktok-card-title">{t("Buscar no TikTok")}</p>
                            <p className="tiktok-card-subtitle">
                                {t("Encontre perfis que combinem com a sua campanha.")}
                            </p>

                            <TikTokDiscovery
                                key="tiktok-page"
                                onAdd={(username) => {
                                    setShowAddOptions(true);
                                    setSelectedInfluencer(username);
                                }}
                            />
                        </>
                    }
                />
            </div>
        </div>
    );
};

export default TikTok;
