import React from 'react'
import HypoModal from '../../../../common/components/Modal'
import HypoTextArea from '../../../../common/components/TextArea'
import PropTypes from 'prop-types'
import { GreenButton } from '../../../../common/components/Button/Button'
import { Form } from 'antd'
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";
import { Spin } from 'antd';
import "./styles.scss"



const ReproveTiktokModal = ({ isVisible, onClose, onOk, isLoading }) => {
    const { t } = useTranslation()
    const [form] = Form.useForm()
    const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

    const onFinish = (values) => {
        onOk(values.reason)
        form.resetFields()
    }

    return (
        <HypoModal onClose={onClose} isVisible={isVisible}>
            <div className="approve-tiktok-modal-title">{t("Comentários")}</div>
            <div>Envie comentários informando o motivo da publicação ser rejeitada.</div>
            <br />
            <Form onFinish={onFinish} form={form}>
                <Form.Item
                    name="reason"
                    rules={
                        [
                            {
                                required: true,
                                message: "Campo obrigatório"
                            }
                        ]
                    }
                >
                    <HypoTextArea placeholder={t("Insira seu comentário")} />
                </Form.Item>
                <GreenButton className="send-button"><div className="button-container">{isLoading ? <Spin indicator={loadingIcon} /> : null} {t("Enviar")}</div></GreenButton>
            </Form>
        </HypoModal>
    )
}

ReproveTiktokModal.defaultProps = {
    onOk: () => { },
    isLoading: false,
}

ReproveTiktokModal.propTypes = {
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
    onOk: PropTypes.func,
    isLoading: PropTypes.bool,
}

export default ReproveTiktokModal