import React, { useState, useEffect} from 'react';
import PropTypes from 'prop-types';
// 
import { Radio as AntRadio } from 'antd';
// 
import './Radio.scss';

const Radio = ({
  cardStyle,
  initialValue,
  onChange,
  options,
  style,
}) => {
    const [value, setValue] = useState(!initialValue ? 0 : initialValue);

    const callChange = e => {
      setValue(e.target.value);
      
      onChange && onChange(options[e.target.value]);
    };

    useEffect(() => {
      setValue(!initialValue ? 0 : initialValue);
    }, [initialValue])

    return <div className={`junction-radios ${cardStyle ? "radio-card":  "radio-normal"}`}>
      <AntRadio.Group 
        onChange={(val) => { callChange(val); }} 
        value={value}
      >
        {options && options.map((option, key) => 
          <div className="option" key={key}>
            <AntRadio style={style} value={key}>
              <span className="option-value"> 
                {option.label} 
                { option.icon && <i className={`icon ${option.icon}`} /> }
                { option.element && option.element }
              </span>
            </AntRadio>

            <p className="option-text">{option.text}</p>
          </div>
        )}
      </AntRadio.Group>

      <div className="labels">
        {options && options.map((option, key) => <p key={key} className="option-text">{option.text}</p> )}
      </div>
    </div>
}

Radio.propTypes = {
  cardStyle: PropTypes.bool,
  initialValue: PropTypes.number,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
  style: PropTypes.object,
}

export default Radio;