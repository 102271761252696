// import { Tooltip } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
// 
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { changeObjAlert, showAlert } from '../../../common/components/Alert/alertSlice';
// 
import Avatar from "../Avatar/Avatar";
// 
// import logo from "../../assets/icons/o.svg";
import iconTikTok from "../../assets/icons/tiktok.svg"
import ConnectStatus from "../ConnectStatus/ConnectStatus"
import "./ContractorTable.scss";

const ContractorTable = ({ contractors, deleteContractor }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [sorts, setSorts] = useState(["name"]);
    const [boolBigger, setBoolBigger] = useState(false);
    const [arrowLocation, setArrowLocation] = useState(2);
    const [orderContractor, setOrderContractor] = useState([]);

    const columns = [
        { alias: "name", title: t("contratante"), span: 0 },
        { alias: "network", title: t("rede"), span: 0 },
        { alias: "connect", title: t("connect"), span: 0 },
        { alias: "", title: "", span: 0 },
    ];

    const orderBy = (obj = "perNumber", index) => {
        setArrowLocation(index);

        if (!sorts.includes(obj)) {
            const newOrder = [...orderContractor].sort((a, b) => {
                if (obj === "name" || obj === "network") {
                    return a[obj].toLowerCase() > b[obj].toLowerCase() ? -1 : 1;
                } else {
                    return a[obj] > b[obj] ? -1 : 1;
                }
            });
            setBoolBigger(true);
            setOrderContractor(newOrder);
            setSorts((oldArray) => [...oldArray, obj]);
        } else {
            const newOrder = [...orderContractor].sort((a, b) => {
                if (obj === "name" || obj === "network") {
                    return a[obj].toLowerCase() < b[obj].toLowerCase() ? -1 : 1
                } else {
                    return a[obj] < b[obj] ? -1 : 1;
                }
            });
            setBoolBigger(false);
            setOrderContractor(newOrder);
            setSorts(sorts.filter(item => item !== obj))
        }
    }

    const generateLink = (text) => {
        navigator.clipboard.writeText(text);

        dispatch(changeObjAlert({
            show: true,
            type: "success",
            title: t("sucesso"),
            text: t("Link copiado! Através deste link, o contratante poderá se cadastrar no Airscore e assim você terá métricas mais completas sobre sua campanha"),
            onCancel: () => { dispatch(showAlert(false)) },
        }))
    }

    useEffect(() => {
        if (contractors != null) {
            setOrderContractor(contractors);
        }
    }, [contractors]);

    return <table className="table-contractor">
        <thead>
            <tr className="table-c-titles">
                {columns.map((item, key) => {
                    return (item.alias == "" && deleteContractor == null) ? null : (
                        <th
                            key={key}
                            // rowSpan={item.span}
                            className={`th-contractor ${item.alias == "" ? "default" : item.alias}`}
                            onClick={() => item.alias != "" && orderBy(item.alias, key, item.withTotal)}
                        >
                            <span className="name-column">{item.title}</span>
                            {key === arrowLocation && <i className={`icon icon-down ${boolBigger ? "bigger" : "smaller"}`} />}
                        </th>
                    )
                })}
            </tr>
        </thead>
        <tbody>
            {
                orderContractor.length > 0 && orderContractor.map((contractor, key) => {
                    return <tr key={key}>
                        <th className="th-profile" width={"30%"}>
                            <Avatar
                                size="24px"
                                network={contractor.network}
                                src={contractor.avatar}
                            />
                            {contractor.name}
                        </th>

                        <th className={`th-network color-${contractor.network}`} width={"25%"}>
                            {contractor.network === "tiktok" ?
                                <img className="tiktok-icon-circle" alt="tiktok icon" src={iconTikTok} /> :
                                <i className={`icon icon-${contractor.network}-circle`} />
                            }
                        </th>

                        <th width={"100%"} className={`th-connected ${!contractor.connected && "connected"}`} onClick={() => { !contractor.connected && generateLink(contractor.connectUrl); }}>
                            <ConnectStatus status={contractor.connectStatus} />
                        </th>

                        {
                            deleteContractor != null &&
                            <th className="th-trash">
                                <i className="icon icon-trash" onClick={() => {
                                    dispatch(changeObjAlert({
                                        show: true,
                                        type: "warning",
                                        title: t("atenção"),
                                        text: `${t("Deseja excluir esse perfil da campanha")}?`,
                                        okText: t("Sim, remover perfil"),
                                        cancelText: t("Não, manter perfil"),
                                        onCancel: () => { dispatch(showAlert(false)) },
                                        onOk: () => { setTimeout(() => { deleteContractor(contractor.id); }, 300) }
                                    }))
                                }} />
                            </th>
                        }
                    </tr>
                })
            }
        </tbody>
    </table>
}

ContractorTable.propTypes = {
    contractors: PropTypes.array,
    deleteContractor: PropTypes.func
}

export default ContractorTable;