class Feed {
    id;
    url;
    link;
    type;
    date;
    image;
    owner;
    description;
    performance;
    inCampaign;
    network;
    interactions = [];
    orderId;

    fromJson(data) {
        this.owner = {
            name: data.author.name,
            username: data.author.username,
            profileUrl: data.author.profile_url,
            avatar: data.author.profile_picture_url,
            airId: data.author.air_id,
        };

        this.id = data.post_id;
        this.description = data.caption;
        this.interactions = data.interactions;
        this.inCampaign = data.is_in_campaign;

        this.link = data.permalink;
        this.url = data.medias[0].media_url;
        this.type = data.medias[0].media_type;
        this.image = data.medias[0].thumbnail_url;

        if (!this.image) {
            const cleanLink = this.link.replace(/\/$/, "").replace("/reel/", "/p/");
            this.image = `https://imagefiles.airstrip.cloud/v1/external/nocors?url=${cleanLink}/media?size=m`;
        }

        this.performance = data.performance.toFixed(2) || 0;
        this.network = data.social_network;
        this.date = new Date(data.timestamp * 1000);

        this.orderId = data.tiktok?.order_id;
        this.sparkState = data.tiktok?.spark_state;

        return this;
    }
}

export default Feed;
