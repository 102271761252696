import { Form } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { changeObjAlert, showAlert } from "../../../../common/components/Alert/alertSlice";
import { clearStatus, clearStatusRegistration, selectRegister, sendRegistration, updRegistration, updTips } from "../../RegisterSlice";
import StateStatus from "../../../../utils/stateStatus";
//
import Button from "../../../../common/components/Button/Button";
import CheckBox from "../../../../common/components/CheckBox/CheckBox";
import InputLabel from "../../../../common/components/InputLabel/InputLabel";
import InputPassword from "../../../../common/components/InputPassword/InputPassword";
import { FlattedPhoneInput } from "../../../../common/components/FlattedInput/FlattedInput";
//
import { worldCountries } from "../../../../utils/lists";
//
import i18n from "../../../../languages/i18n";
import "./Registration.scss";

const Registration = () => {
    const { t } = useTranslation();
    const { path } = useRouteMatch();
    const history = useHistory();

    const dispatch = useDispatch();
    const registerState = useSelector(selectRegister);
    const privacyUrl = ["en", "en_us"].includes(i18n.language) ? "https://hypolake.com/privacy-policy/" : "https://hypolake.com/pt/politica-de-privacidade/";
    const [phone, updatePhone] = useState({
        code: 1,
        country: "US",
        isValid: false,
        number: null,
        phone: null,
    });

    const [form] = Form.useForm();
    const [loading, updLoading] = useState(false);
    const [phoneError, updPhoneError] = useState(false);
    const [confirmPass, updateConfirmPass] = useState("");
    const [passValidation, updatePassValidation] = useState({});

    const passwordRequests = [
        { text: t("Minimo 6  caracteres;"), alias: "hasSixLength" },
        { text: t("Letras maiúscula;"), alias: "hasOneCapitalLetter" },
        { text: t("Letras minúsculas;"), alias: "hasOneLowerLetter" },
        { text: t("Pelo menos um numeral;"), alias: "hasOneNumber" },
        { text: t("Pelo menos um caractere especial (@, #, $ e etc);"), alias: "hasCharacterSpecial" },
    ];

    const passwordValidation = (value) => {
        const result = {
            value: value,
            hasSixLength: value.length >= 8,
            hasOneNumber: value.search(/\d/g) !== -1,
            hasOneCapitalLetter: value.search(/[A-Z]/g) !== -1,
            hasOneLowerLetter: value.search(/[a-z]/g) !== -1,
            hasCharacterSpecial: value.search(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g) !== -1, // eslint-disable-line
        };
        updatePassValidation(result);

        return result;
    };

    const onFinish = async (values) => {
        if (phone && phone.number) {
            values["phone"] = phone.phone;

            dispatch(updRegistration(values));

            updLoading(true);
            await dispatch(sendRegistration());
            updLoading(false);
        } else {
            updPhoneError(true);
        }
    };

    const alert = (type, title, text, question, cancelText, okText, onOk) => {
        dispatch(
            changeObjAlert({
                show: true,
                type: type,
                title: title,
                text: text,
                okText: okText,
                question: question,
                cancelText: cancelText,
                onCancel: () => {
                    dispatch(showAlert(false));
                },
                onOk: () => {
                    onOk;
                },
            })
        );
    };

    useEffect(() => {
        if(registerState && registerState.data && registerState.data.phone) {
            const p = registerState.data.phone
            
            const code = parseInt((p.split("-")[0]).replace(/\D/g, ""))
            const country = worldCountries.find(item => item.dial_code === code)
            
            const phone = {
                code: code,
                isValid: false,
                phone: p,
                number: p.split("-")[1],
            }

            if(country) phone.country = country.code
    
            updatePhone(phone)
        }

        if (registerState.data.password) {
            updateConfirmPass(registerState.data.confirmPassword);
            passwordValidation(registerState.data.password);
        }

        if (registerState.data) form.setFieldsValue(registerState.data);
    }, [registerState.data]);

    useEffect(() => {
        if (registerState.statusRegistration == StateStatus.succeeded) {
            if(registerState.data.company) localStorage.removeItem("subscription")

            history.push(`${path}/payment`)

            dispatch(clearStatusRegistration())
        } else if (registerState.statusRegistration == StateStatus.failed) {
            alert("error", t("erro"), t(registerState.msg));

            dispatch(clearStatus());
        } 
    }, [registerState.statusRegistration]);

    useEffect(() => {}, []);
    return (
        <Form onFinish={onFinish} layout="vertical" form={form}>
            {/* name */}
            <div className="junction-name">
                <Form.Item
                    name="name"
                    label={t("Nome")}
                    initialValue={registerState.data.name}
                    rules={[
                        {
                            required: true,
                            message: <div className="normal-12">{t("Nome inválido!")}</div>,
                        },
                    ]}
                >
                    <InputLabel readOnly={registerState.withGoogle} placeholder={t("Informe seu nome")}></InputLabel>
                </Form.Item>

                <Form.Item
                    name="surname"
                    label={t("Sobrenome")}
                    initialValue={registerState.data.surname}
                    rules={[
                        {
                            required: true,
                            message: <div className="normal-12">{t("Sobrenome inválido!")}</div>,
                        },
                    ]}
                >
                    <InputLabel readOnly={registerState.withGoogle} placeholder={t("Informe seu sobrenome")}></InputLabel>
                </Form.Item>
            </div>

            {/* company name */}
            <Form.Item
                name="company"
                label={t("Nome da empresa")}
                initialValue={registerState.data.company}
                rules={[
                    {
                        required: true,
                        message: <div className="normal-12">{t("Nome da empresa inválido!")}</div>,
                    },
                ]}
            >
                <InputLabel placeholder={t("Informe o nome da empresa")}></InputLabel>
            </Form.Item>

            {/* email */}
            <Form.Item
                name="email"
                label={t("E-mail")}
                initialValue={registerState.data.email}
                rules={[
                    {
                        required: true,
                        message: <div className="normal-12">{t("E-mail inválido!")}</div>,
                    },
                    {
                        type: "email",
                        message: <div className="normal-12">{t("Email inválido")}</div>,
                    },
                ]}
            >
                <InputLabel readOnly={(registerState.data && registerState.data.id) || registerState.withGoogle} placeholder={t("Informe seu e-mail")} type="email"></InputLabel>
            </Form.Item>

            {!registerState.withGoogle && !registerState.data.id && (
                <>
                    {/* password */}
                    <Form.Item
                        name="password"
                        label={t("Configure uma senha")}
                        initialValue={registerState.data.password}
                        rules={[
                            () => ({
                                validator(_, value) {
                                    const falses = Object.entries(passValidation).filter((item) => item[1] === false);
                                    if (falses.length > 0 || !value) {
                                        return Promise.reject(new Error(t("Senha inválida")));
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <InputPassword
                            onChange={(event) => {
                                passwordValidation(event.target.value);
                            }}
                            placeholder={t("Informe a senha")}
                        ></InputPassword>
                    </Form.Item>

                    {/* password requests */}
                    <div className="password-requests">
                        <p className="pr-title">{t("Sua senha tem que possuir")}:</p>

                        <ul>
                            {passwordRequests.map((item, key) => {
                                return (
                                    <li key={key} className={passValidation[item.alias] ? "checked" : ""}>
                                        {item.text} {passValidation[item.alias] && <i className="icon icon-checkmark" />}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>

                    {/* confirm password */}
                    <Form.Item
                        name="confirmPassword"
                        label={t("Confirmar senha")}
                        initialValue={registerState.data.confirmPassword}
                        rules={[
                            () => ({
                                validator() {
                                    const falses = Object.entries(passValidation).filter((item) => item[1] === false);

                                    if (falses.length > 0) {
                                        return Promise.reject(new Error(t("Senha inválida")));
                                    } else if (confirmPass !== passValidation.value) {
                                        return Promise.reject(new Error(t("As senhas não coincidem")));
                                    }
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    >
                        <InputPassword
                            placeholder={t("Confirme a senha")}
                            onChange={(event) => {
                                if (event.target.value) updateConfirmPass(event.target.value);
                            }}
                        ></InputPassword>
                    </Form.Item>
                </>
            )}

            {/* phone */}
            <div className="ant-form-item">
                <div className="ant-form-item-label">{t("Telefone")}</div>
                <FlattedPhoneInput
                    value={phone}
                    onChange={(values) => {
                        updPhoneError(false);
                        updatePhone(values);
                    }}
                    placeholder={t("Informe seu número de telefone")}
                />
                {phoneError && <p className="text-error"> {t("Telefone inválido!")} </p>}
            </div>

            <div className="section-bottom">
                <span className="text-terms-privacy">
                    {t("Clicando no botão abaixo você concorda com nossos")} <b onClick={() => window.open(privacyUrl, "_blank")}>{t("termos de uso e política de privacidade")}</b>.
                </span>

                <CheckBox
                    checked={registerState.tips}
                    onChange={() => {
                        dispatch(updTips(!registerState.tips));
                    }}
                    text={t("Receba dicas e novidades da HypoLake")}
                />

                <Button width="100%" className="button-submit" type="submit" htmlType="submit" loading={loading} text={t("Continuar compra")} />
            </div>
        </Form>
    );
};

export default Registration;
