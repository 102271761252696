import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
//
import { useDispatch, useSelector } from "react-redux";
import { fetchChartEC, selectCharts } from "./ChartsSlice";
import { changeObjAlert, showAlert } from "../../../../common/components/Alert/alertSlice";
//
import Card from "../../../../common/components/Card/Card";
import LineChart from "../../../../common/components/Charts/LineChart/LineChart";
import Loading from "../../../../common/components/Loading/Loading";
import Select from "../../../../common/components/Select/Select";
//
import { AirUtils } from "../../../../utils/utils";
import StateStatus from "../../../../utils/status";
import "./Chart.scss";

import StepIntegrationGO from "../../../CreateCampaign/steps/integrationGO/StepIntegrationGO";
import { selectCampaign } from "../../CampaignDetailsSlice";

const ChartEC = ({ title, currencySymbol, campaignId }) => {
    const { t } = useTranslation();
    const airUtils = new AirUtils();
    const dispatch = useDispatch();

    const { id, nw } = useParams();

    // selectors
    const objCharts = useSelector(selectCharts);
    const objCampaign = useSelector(selectCampaign);

    const filterTypeOptions = [
        { value: "per_date", key: t("Por data") },
        { value: "per_profile", key: t("Por perfil") },
    ];
    const humanFormatType = {
        clicks: "integer",
        cost_per_click: "currency",
        conversion_rate: "percentage",
        conversions: "integer",
        cost_per_conversion: "currency",
        roi: "percentage",
    };
    // const isCumulative = {
    //     clicks: true,
    //     cost_per_click: false,
    //     conversion_rate: true,
    //     conversions: true,
    //     cost_per_conversion: false,
    //     roi: true
    // }
    const [counters, setCounters] = useState(); // chart = {}
    const [charts, setCharts] = useState(); // chart = {}
    const [loading, setLoading] = useState(false); // chart = {}

    const [filterType, setFilterType] = useState("per_date"); // per_date || per_profile

    const [indexSelectedChart, setIndexSelectedChart] = useState(0);
    const [chartType, setChartType] = useState("clicks");

    const selectedChart = charts
        ? charts[Object.keys(charts)[indexSelectedChart]][filterType]
        : { historical: [], daily: [] };

    const buildChart = () => {
        if (filterType == "per_date") {
            const historical = selectedChart.historical.map((item) => [Date.parse(item.label), item.value]);
            const daily = selectedChart.daily.map((item) => [Date.parse(item.label), item.value]);

            return (
                <LineChart
                    showLegend
                    series={[
                        { data: daily, name: t("Diário") },
                        { data: historical, color: "#4285F4", name: t("Histórico") },
                    ]}
                    formatType={humanFormatType[chartType]}
                    currencySymbol={currencySymbol}
                />
            );
        } else {
            const series = selectedChart.map((item) => {
                return { data: item.daily.map((item) => [Date.parse(item.label), item.value]), name: item.profile };
            });

            return (
                <LineChart
                    showLegend
                    series={series}
                    formatType={humanFormatType[chartType]}
                    currencySymbol={currencySymbol}
                />
            );
        }
    };

    const alert = (type, title, text, question, cancelText, okText, onOk) => {
        dispatch(
            changeObjAlert({
                show: true,
                type: type,
                title: title,
                text: text,
                okText: okText,
                question: question,
                cancelText: cancelText,
                onCancel: () => {
                    dispatch(showAlert(false));
                },
                onOk: () => {
                    onOk();
                },
            })
        );
    };

    useEffect(async () => {
        setIndexSelectedChart(0);

        setLoading(true);
        await dispatch(fetchChartEC({ id, network: nw }));

        setLoading(false);
    }, []);

    useEffect(() => {
        if (objCharts.statusEC == StateStatus.failed) {
            alert("error", t("atenção"), t(objCharts.msgError));
        }
    }, [objCharts.statusEC]);

    useEffect(() => {
        if (objCharts.commerce) {
            setCounters(objCharts.commerce.counters);
            setCharts(objCharts.commerce.charts);
        }
    }, [objCharts]);

    return (
        <Card
            className={`chart-card chart-ec`}
            child={
                <>
                    <div className="chart-header">
                        <span className="chart-title campaign-title-data">{title}</span>
                        {!objCampaign.missingAnalyticsConnect && (
                            <Select
                                options={filterTypeOptions}
                                defaultValue={filterType}
                                onChange={(option) => {
                                    setFilterType(option.value);
                                    // setIndexSelectedChart(0);
                                }}
                            />
                        )}
                    </div>

                    {loading ? (
                        <div className="container-loading">
                            <Loading />
                        </div>
                    ) : (
                        <>
                            {" "}
                            {!objCampaign.missingAnalyticsConnect &&
                                objCampaign.campaign.site.goals.length > 0 &&
                                selectedChart && (
                                    <div className="container-segment-chart">
                                        <div className={`segment-chart`}>
                                            {counters != null && (
                                                <>
                                                    {counters.map((item, key) => {
                                                        return (
                                                            <div
                                                                key={key}
                                                                className={`chart-option ${
                                                                    indexSelectedChart == key && `active `
                                                                }`}
                                                                style={{ width: `${100 / counters.length}%` }}
                                                                onClick={() => {
                                                                    setIndexSelectedChart(key);
                                                                    setChartType(item.label);
                                                                }}
                                                            >
                                                                <span className="text-option">{t(item.label)}</span>
                                                                <span className="text-value">
                                                                    {airUtils.buildValue(
                                                                        humanFormatType[item.label],
                                                                        item.value,
                                                                        currencySymbol
                                                                    )}
                                                                </span>
                                                            </div>
                                                        );
                                                    })}
                                                    <div
                                                        style={{
                                                            width: `${100 / counters.length}%`,
                                                            transform: `translate(${indexSelectedChart * 100}%)`,
                                                        }}
                                                        className={`segment-underline`}
                                                    ></div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                            <div className="chart-container">
                                {objCampaign.missingAnalyticsConnect ? (
                                    <div className="missing-analytics-connect">
                                        <p>{t("Para acompanhar os dados da sua campanha faça o connect")}</p>
                                        <StepIntegrationGO
                                            campaignId={campaignId}
                                            connectLink={objCampaign.campaign.analyticsConnectUrl}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        {objCampaign.campaign.site.goals.length === 0 ? (
                                            <div className="select-goals-analytics-connect">
                                                <h3>{t("Connect realizado com sucesso")}</h3>
                                                <p>{t("Selecione suas metas para continuar")}</p>
                                                <button
                                                    onClick={() => {
                                                        window.location.href = `/#/edit-campaign/${objCampaign.campaign.id}/site`;
                                                    }}
                                                >
                                                    {t("Selecionar metas")}
                                                </button>
                                            </div>
                                        ) : selectedChart ? (
                                            buildChart()
                                        ) : (
                                            <p className="empty-text-value">
                                                {t("A campanha ainda não possui dados para esse gráfico")}
                                            </p>
                                        )}
                                    </>
                                )}
                            </div>
                        </>
                    )}
                </>
            }
        />
    );
};

ChartEC.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    currencySymbol: PropTypes.string,
    networks: PropTypes.any,
    campaignId: PropTypes.string,
};

export default ChartEC;
