import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import PropTypes from 'prop-types'
import { clearStatus, createAccount, selectConfiguration } from "../../../ConfigurationSlice"

import { Form } from 'antd'
import { changeObjAlert, showAlert } from '../../../../../common/components/Alert/alertSlice'
import Button, { OutlinedGreyButton } from "../../../../../common/components/Button/Button"
import InputLabel from '../../../../../common/components/InputLabel/InputLabel'
import InputPassword from '../../../../../common/components/InputPassword/InputPassword'
import StateStatus from '../../../../../utils/stateStatus'
import Card from '../../../../../common/components/Card/Card'

const CreateProfile = ({ onCancel = () => { } }) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    // 
    const config = useSelector(selectConfiguration)
    //
    const [form] = Form.useForm(null)
    const [confirmPass, setConfirmPass] = useState("")
    const [passValidation, setPassValidation] = useState({})

    const passwordRequests = [
        { text: t("Minimo 6  caracteres;"), alias: "hasSixLength" },
        { text: t("Letras maiúscula;"), alias: "hasOneCapitalLetter" },
        { text: t("Letras minúsculas;"), alias: "hasOneLowerLetter" },
        { text: t("Pelo menos um numeral;"), alias: "hasOneNumber" },
        { text: "Pelo menos um caractere especial (@, #, $ e etc);", alias: "hasCharacterSpecial" },
    ]

    const passwordValidation = (value) => {
        const result = {
            value,
            hasSixLength: value.length >= 8,
            hasOneNumber: value.search(/\d/g) !== -1,
            hasOneCapitalLetter: value.search(/[A-Z]/g) !== -1,
            hasOneLowerLetter: value.search(/[a-z]/g) !== -1,
            hasCharacterSpecial:
                value.search(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g) !== -1, // eslint-disable-line
        }

        setPassValidation(result)

        return result
    }

    const alert = (type, title, text, question, cancelText, okText, onOk, onCancel) => {
        dispatch(changeObjAlert({
            show: true,
            type: type,
            title: title,
            text: text,
            okText: okText,
            question: question,
            cancelText: cancelText,
            onCancel: () => { dispatch(showAlert(false)); onCancel; },
            onOk: () => { onOk; }
        }))
    }

    const onFinish = (values) => {
        delete values.confirm_password
        values.type = "admin"

        dispatch(createAccount(values))
    }

    useEffect(() => {
        if (config.statusCreateAccount === StateStatus.succeeded) {
            alert("success", t("Sucesso"), (t(config.msg || t("Perfil criado com sucesso"))))
            onCancel()
        }
        if (config.statusCreateAccount === StateStatus.failed) {
            alert("error", t("Erro"), (t(config.msg || t("Erro ao criar perfil"))))
        }

        dispatch(clearStatus())
    }, [config.statusCreateAccount])

    return (<>
        <Card
            className="card-create-profile"
            padding="24px 40px"
            child={
                <Form form={form} onFinish={onFinish}>
                    <p className="form-title">{t("Criar perfil")}</p>

                    {/* NAME */}
                    <Form.Item
                        label={t("Nome")}
                        name="name"
                        rules={[{
                            required: true,
                            message: <span className='text-error'>{t("Informe um nome válido")}</span>,
                        }]}
                    >
                        <InputLabel
                            placeholder={t("Informe seu nome")}
                        />
                    </Form.Item>

                    <Form.Item
                        label={t("Sobrenome")}
                        name="surname"
                        rules={[{
                            required: true,
                            message: <span className='text-error'>{t("Informe um sobrenome válido")}</span>,
                        }]}
                    >
                        <InputLabel
                            placeholder={t("Informe seu sobrenome")}
                        />
                    </Form.Item>

                    {/* EMAIL */}
                    <Form.Item
                        label={t("E-mail")}
                        name="email"
                        rules={[{
                            required: true,
                            message: <span className='text-error'>{t("Informe um e-mail válido")}</span>,
                        }]}
                    >
                        <InputLabel
                            placeholder={t("Informe seu e-mail")}
                        />
                    </Form.Item>

                    {/* PASSWORD */}
                    <Form.Item
                        name="password"
                        label={t("Nova senha")}
                        rules={[{
                            required: true,
                            message: <span style={{ display: 'none' }}>/</span>,
                        },
                        () => ({
                            validator(_, value) {
                                const falses = Object.entries(passValidation).filter(
                                    (item) => item[1] === false
                                )
                                if (falses.length > 0 || !value) {
                                    return Promise.reject(new Error(t('Senha inválida')))
                                }
                                return Promise.resolve()
                            },
                        }),
                        ]}
                    >
                        <InputPassword
                            onChange={(event) => passwordValidation(event.target.value)}
                            placeholder={t("Informe a nova senha")}
                        />
                    </Form.Item>

                    <div className="password-requests">
                        <p className="pr-title">{t("Sua senha tem que possuir")}:</p>

                        <ul>
                            {passwordRequests.map((item) => (<li
                                key={item.alias}
                                className={passValidation[item.alias] ? 'checked' : ''}
                            >
                                {item.text}{' '}
                                {passValidation[item.alias] && (
                                    <i className="icon icon-checkmark" />
                                )}
                            </li>
                            ))}
                        </ul>
                    </div>

                    <Form.Item
                        name="confirm_password"
                        label={t("Confirme sua nova senha")}
                        rules={[{
                            required: true,
                            message: <span style={{ display: "none" }}>/</span>,
                        },
                        () => ({
                            validator() {
                                const falses = Object.entries(passValidation).filter(
                                    (item) => item[1] === false
                                )

                                if (falses.length > 0) {
                                    return Promise.reject(new Error(t("Senha inválida")))
                                }
                                if (confirmPass !== passValidation.value) {
                                    return Promise.reject(new Error(t("As senhas não coincidem")))
                                }

                                return Promise.resolve()
                            },
                        }),
                        ]}
                    >
                        <InputPassword
                            placeholder={t("Informe a nova senha")}
                            onChange={(event) => {
                                if (event.target.value) {
                                    setConfirmPass(event.target.value)
                                }
                            }}
                        />
                    </Form.Item>

                </Form>
            }
        />

        <div style={{ float: "right", display: "flex" }}>
            <OutlinedGreyButton
                onClick={() => onCancel()}> {t("Voltar")}
            </OutlinedGreyButton>

            <Button
                style={{ marginLeft: "1em" }}
                text={t("Salvar")}
                onClick={() => form.submit()}
                loading={config.statusCreateAccount === StateStatus.loading}
            />
        </div>
    </>)
}

CreateProfile.propTypes = {
    onCancel: PropTypes.func,
}


export default CreateProfile