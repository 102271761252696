import React from 'react';
import PropTypes from 'prop-types'
import Tooltip from '../Tooltip/Tooltip';
// 
import './CardData.scss';

const CardData = ({
    className,
    title,
    child,
    tooltip,
    type
}) => 
    <div className={`c-card-data ${className || ""} ${type || ""}`}>
        <div className="c-card-data-title">
            {title}

            { tooltip && <Tooltip child={tooltip} /> }
        </div> 
        <div className="c-card-data-content">
            {child}
        </div> 
    </div>

CardData.propTypes = {
    className: PropTypes.string,
    title: PropTypes.any,
    child: PropTypes.element,
    tooltip: PropTypes.string,
    type: PropTypes.string,
}

export default CardData