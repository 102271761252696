//
import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
//
import Button from "../../../../common/components/Button/Button";
import Card from "../../../../common/components/Card/Card";
import CardFooter from "../../components/CardFooter/CardFooter";
import InfluencerTable from "../../../../common/components/InfluencerTable/InfluencerTable";
//
import { useDispatch, useSelector } from "react-redux";
import { changeStep, removeInfluencers, selectCampaign } from "../../createCampaignSlice";
//
import AddTiktokInfluencer from "./AddTiktokInfluencer/AddTiktokInfluencer";
import AddInstagramInfluencer from "./AddInstagramInfluencer/AddInstagramInfluencer";
import ImportInfluencers from "./ImportInfluencers/ImportInfluencers";
import { rmvInfluencer, exportInfluencers } from "../../CreateCampaignService";
//
import "./StepInfluencers.scss";

const StepInfluencers = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const objCampaign = useSelector(selectCampaign);
    const [stepActive, setStepActive] = useState(0);

    const [error, setError] = useState(-1);

    // to edit modeal
    const [index, setIndex] = useState();
    const [influencer, setInfluencer] = useState();
    const [visible, setVisible] = useState(false);

    const options = [
        { text: t("Tiktok"), icon: "icon-add-influencer1" },
        { text: t("Instagram"), icon: "icon-add-influencer1" },
        { text: t("Importar influenciadores"), icon: "icon-import" },
    ];

    const closeEditModal = () => {
        setIndex(null);
        setVisible(false);
        setInfluencer(null);
    };

    const isValid = () => {
        if (objCampaign.campaign.influencers.length > 0) {
            dispatch(changeStep(objCampaign.index + 1));
        } else {
            setError(1);
        }
    };

    useEffect(() => {
        if (objCampaign.campaign.influencers.length > 0) {
            setError(-1);
        }
    }, [objCampaign.campaign.influencers]);

    return (
        <div className="step-influencer">
            <Modal
                className="edit-influencer-modal"
                visible={visible}
                onOk={() => closeEditModal()}
                onCancel={() => closeEditModal()}
            >
                {influencer && influencer.network === "tiktok" && (
                    <AddTiktokInfluencer index={index} influencer={influencer} closeModal={() => closeEditModal()} />
                )}

                {influencer && influencer.network === "instagram" && (
                    <AddInstagramInfluencer index={index} influencer={influencer} closeModal={() => closeEditModal()} />
                )}
            </Modal>

            <Card
                child={
                    <>
                        <h2 className="step-name">{t("Adicionar influenciadores")}</h2>

                        <div className="segment-influencer">
                            {options.map((option, key) => (
                                <div
                                    key={key}
                                    className={`segment-option-add-influencer ${stepActive == key ? "active" : ""}`}
                                    onClick={() => setStepActive(key)}
                                >
                                    <span className="text-add-import">{option.text}</span>
                                </div>
                            ))}

                            <div className="segment-underline" style={{ left: `${stepActive * 33.3}%` }} />
                        </div>

                        {stepActive === 0 && <AddTiktokInfluencer />}
                        {stepActive === 1 && <AddInstagramInfluencer />}
                        {stepActive === 2 && <ImportInfluencers />}
                    </>
                }
            />

            {objCampaign.campaign.influencers.length > 0 && (
                <>
                    <Card
                        className="card-influencer-table"
                        child={
                            <div className="container-influencer-table">
                                <div className="junction-influencer-table">
                                    <p className="count-influencers">
                                        {t("Influenciadores adicionados")}: {objCampaign.campaign.influencers.length}
                                    </p>
                                    <Button
                                        outline={true}
                                        text={t("Baixar influenciadores")}
                                        onClick={async () => {
                                            exportInfluencers(objCampaign.campaign.id).then((res) => {
                                                if (res.status === 200 && res.data.success) {
                                                    window.open(res.data.url);
                                                }
                                            });
                                        }}
                                        icon="export"
                                    />
                                </div>
                                <InfluencerTable
                                    symbolCoin={objCampaign.campaign.coin ? objCampaign.campaign.coin.symbol : null}
                                    influencers={objCampaign.campaign.influencers}
                                    deleteInfluencer={async (id) => {
                                        rmvInfluencer(objCampaign.campaign.id, id).then(() => {
                                            dispatch(removeInfluencers(id));
                                        });
                                    }}
                                    openEdit={(influencer, index) => {
                                        setInfluencer(influencer);
                                        setIndex(index);
                                        setVisible(true);
                                    }}
                                />
                            </div>
                        }
                        footer={
                            <CardFooter
                                onBack={() => dispatch(changeStep(objCampaign.index - 1))}
                                onNext={() => isValid()}
                            />
                        }
                    />

                    {error == 1 && (
                        <p className="text-empty text-empty-influencers">
                            {t("Adicione ao menos um influenciador para poder prosseguir!")}
                        </p>
                    )}
                </>
            )}
        </div>
    );
};

export default StepInfluencers;
