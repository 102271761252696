import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import categories from "../../common/mocks/categories";
import interests from "../../common/mocks/interests";
import Contractor from "../../models/Contractor";
import Influencer from "../../models/Influencer";
import StateStatus from "../../utils/status";
import {
    getCampaignData,
    getGoals,
    addInfluencer,
    addContractor,
    updMentions,
    addName,
    updName,
    updSite,
    imptInfluencers,
    influencersLink,
    saveCampaign,
    addIntegration,
    influencerLinkBatch,
    influencersLinkGlobal,
    updBrand,
    updObjective,
    updType,
} from "./CreateCampaignService";
import { profile } from "../Login/LoginSlice";

const initialState = {
    campaign: {
        //  step type
        type: "influencers",
        //  step name-date
        name: "",
        startDate: null,
        endDate: null,
        description: null,
        //  step mentions
        mentions: [],
        objectives: [],
        contractors: [],
        influencers: [],
        integrations: {},
        site: {
            domain: "",
            destiny: "",
            type: "comercial",
            goals: [],
        },

        // tiktok - data
        budgetType: null,
        budgetValue: null,
        objectiveTT: "range",
        trackedLink: null,
        influencerLinkTrackings: [],
        // tiktok - audience
        bid: null,
        conversionParameter: null,
        frequencyScheduling: null,
        frequency: null,
        locations: [],
        locationsLabels: [],
        categories: [],
        categoriesLabels: [],
        interests: [],
        interestsLabels: [],
        analyticsConnectUrl: null,
    },

    status: StateStatus.idle,
    statusSave: StateStatus.idle,
    statusLinkBatch: StateStatus.idle,
    statusGetCampaign: StateStatus.idle,

    // UTILS - normal
    index: null,
    socialCampaign: null,
    errorMsg: null,
    errorItems: [],
    indexGoals: [],
    goals: [],
    coins: [
        { alias: "BRL", label: "Real brasileiro", symbol: "R$" },
        { alias: "USD", label: "Dólar estadunidense", symbol: "$" },
        { alias: "EUR", label: "Euro", symbol: "€" },
    ],
    // UTILS - tiktok
    blockedEndDate: true,
    locations: [],
    categories: [],
    interests: [],
    budgetTypes: [
        { alias: "diary", label: "Diário" },
        { alias: "total", label: "Total" },
        { alias: "unlimited", label: "Ilimitado" },
    ],
    urlTikTok:
        "https://ads.tiktok.com/marketing_api/auth?app_id=7069724021973057538&state=your_cust[…]ect_uri=https%3A%2F%2Fconnect.hypolake.com%2F&rid=7olzw9qz9t6",
};

export const fetchCampaign = createAsyncThunk("camapign/edit", async (id, thunkAPI) => {
    const { createCampaign } = thunkAPI.getState();

    let campaign = {};
    if (createCampaign) {
        await getCampaignData(id).then((res) => {
            if (res.data) {
                let data = res.data;
                data.publicName = data.public_name;
                campaign = data;
            }
        });
    }

    return campaign;
});

export const fetchGoals = createAsyncThunk("camapign/get-goals", async (id, thunkAPI) => {
    const { createCampaign } = thunkAPI.getState();
    const campaign = createCampaign.campaign;

    let result = campaign.goals;

    await getGoals(id).then((res) => {
        if (res.data.items) {
            result = res.data.items;
        }
    });

    return { result };
});

export const importInfluencers = createAsyncThunk("import/profile", async (file, thunkAPI) => {
    const { createCampaign } = thunkAPI.getState();
    const campaign = createCampaign.campaign;

    let result = [];
    let response = {};

    await imptInfluencers(campaign.id, file).then((res) => {
        response = res.data;

        res.data.influencer_contracts.forEach((data) => {
            let influencer = new Influencer();

            influencer.fromJson(data);
            result.push(influencer);
        });
    });

    return {
        data: response,
        result: result,
    };
});

export const fetchProfile = createAsyncThunk("get/profile", async (data, thunkAPI) => {
    const { createCampaign } = thunkAPI.getState();
    const campaign = createCampaign.campaign;

    const { url, type, additionalData } = data;

    let result = [];

    if (type == "contractor") {
        try {
            await addContractor(campaign.id, { profile_url: url }).then((res) => {
                let contractor = new Contractor();

                contractor.fromJson(res.data);

                result = contractor;
            });
        } catch (error) {
            const errorData = error.data;

            if (errorData.error === "CANNOT_INVITE_PROFILE_TO_TIKTOK") {
                const newError = {
                    ...error,
                    items: errorData.extra_information.reasons,
                };

                return thunkAPI.rejectWithValue(newError);
            } else {
                throw error;
            }
        }
    } else {
        const data = {
            profile_url: url,
            contract_type: additionalData["type_contract"],
            videos_count: additionalData["quant_videos"],
            videos_value: additionalData["investment_videos"],
            posts_count: additionalData["quant_post"],
            posts_value: additionalData["investment_post"],
            stories_count: additionalData["quant_stories"],
            stories_value: additionalData["investment_stories"],
            email: additionalData["email"],
        };

        let influencer = new Influencer();
        try {
            await addInfluencer(campaign.id, data).then((res) => {
                influencer.fromJson(res.data);
            });
        } catch (error) {
            const errorData = error.data;

            if (errorData.error === "CANNOT_INVITE_PROFILE_TO_TIKTOK") {
                const newError = {
                    ...error,
                    items: errorData.extra_information.reasons,
                };

                return thunkAPI.rejectWithValue(newError);
            } else {
                throw error;
            }
        }

        result = influencer;
    }

    return { type, result };
});

export const addCampaignAuthenticator = createAsyncThunk("create-campaign/add-authenticator", async (data) => {
    const { campaignId, ...integrationData } = data;

    await addIntegration(campaignId, integrationData);
});

export const sendInfluencerLinkBatch = createAsyncThunk("create-campaign/add-authenticator", async (data) => {
    const { items, campaignId } = data;

    const requestItems = items
        .map((item) => {
            return {
                link: item.originalLink,
                email: item.email,
                contract_id: item.id,
                send: true,
            };
        })
        .filter((item) => item.link && item.email && item.link.length > 0 && item.email.length > 0);

    await influencerLinkBatch(campaignId, requestItems);
});

export const fetchCategories = createAsyncThunk("get/categories", async () => {
    const list = categories;

    return list;
});

export const fetchInterests = createAsyncThunk("get/interests", async () => {
    const list = interests;

    return list;
});

// UPDATES
export const saveStep = createAsyncThunk("create-campaign/pattern", async (data, thunkAPI) => {
    const { createCampaign } = thunkAPI.getState();
    const campaign = createCampaign.campaign;

    const { step, index, selectedOption, globalUrl } = data;

    if (step == "name") {
        const data = {
            name: campaign.name,
            public_name: campaign.publicName,
            start_date: campaign.startDate.date,
            end_date: campaign.endDate.date,
            currency: campaign.coin?.value,
            description: campaign.description,
        };

        let id = null;
        let analyticsConnectUrl = null;
        if (campaign.id) {
            await updName(campaign.id, data);
        } else {
            (data["type"] = campaign.type), (data["objectives"] = campaign.objectives);

            await addName(data).then((res) => {
                id = res.data.id;
                analyticsConnectUrl = res.data.analytics_connect_url;
            });

            profile.actions.increaseCampaignsCount();
        }

        return { id, index, analyticsConnectUrl };
    } else if (step == "type-objective") {
        if (campaign.id) {
            await updType(campaign.id, { type: campaign.type });
            await updObjective(campaign.id, { objectives: campaign.objectives });
        }
    } else if (step == "brand") {
        const data = {
            brand_name: campaign.brandName,
            brand_email: campaign.brandEmail,
            brand_contact_name: campaign.brandContactName,
            brand_phone: campaign.brandPhone,
        };

        await updBrand(campaign.id, data);
    } else if (step == "mentions") {
        await updMentions(campaign.id, { markers: campaign.mentions });
    } else if (step == "tracking") {
        if (selectedOption === "tracked_link") {
            await influencersLinkGlobal(campaign.id, globalUrl);
        } else {
            const influencers = campaign.influencers.map((inf) => {
                const obj = {
                    contract_id: inf.id,
                    link: inf.link,
                    email: inf.email,
                    send: false,
                };

                return obj;
            });
            await influencersLink(campaign.id, influencers);
        }
    } else if (step == "site") {
        await updSite(campaign.id, {
            analytics_integration_type: campaign.site.type,
            goals: campaign.site.goals,
            site_domain: campaign.site.domain,
            site_url: campaign.site.destiny,
        });
    } else if (step == "save") {
        await saveCampaign(campaign.id);
    }

    return { step, index };
});

// https://api.analytics.global.airstrip.cloud/v1/campaign/0cd9a466e3254dcbaaddf7f24c2e57e9/brand

export const createCampaign = createSlice({
    name: "createCampaign",
    initialState: initialState,
    reducers: {
        updateCampaign: (state, action) => {
            state.campaign = action.payload;
        },
        updateInfluencerDefault: (state, action) => {
            state.influencerDefault = action.payload;
        },

        // NORMAL - type
        updateType: (state, action) => {
            state.campaign.type = action.payload;
        },
        // NORMAL - date and name
        updateName: (state, action) => {
            if (action.payload) {
                state.campaign.name = action.payload;
            } else {
                delete state.campaign.name;
            }
        },
        updatePublicName: (state, action) => {
            if (action.payload) {
                state.campaign.publicName = action.payload;
            } else {
                delete state.campaign.publicName;
            }
        },
        updateDescription: (state, action) => {
            if (action.payload) {
                state.campaign.description = action.payload;
            } else {
                delete state.campaign.description;
            }
        },
        updateDates: (state, action) => {
            state.campaign.startDate = action.payload[0];
            state.campaign.endDate = action.payload[1];
        },
        updateCoin: (state, action) => {
            state.campaign.coin = action.payload;
        },

        // NORMAL - contractor
        addContractor: (state, action) => {
            state.campaign.contractors = [...state.campaign.contractors, action.payload];
        },
        removeContractor: (state, action) => {
            state.campaign.contractors = state.campaign.contractors.filter((item) => item.id != action.payload);
        },

        // NORMAL - brand
        updateBrandName: (state, action) => {
            state.campaign.brandName = action.payload;
        },
        updateBrandEmail: (state, action) => {
            state.campaign.brandEmail = action.payload;
        },
        updateBrandContactName: (state, action) => {
            state.campaign.brandContactName = action.payload;
        },
        updateBrandPhone: (state, action) => {
            state.campaign.brandPhone = action.payload;
        },

        // NORMAL - mentions
        addMentions: (state, action) => {
            const text = action.payload.text;
            let newMentions = text.split(" ").join(",").split(";").join(",").split("-").join(",").split(",");

            newMentions.forEach((item) => {
                item.replace(/\s+/g, "");
                if (
                    item.length > 1 &&
                    !state.campaign.mentions.includes(item) &&
                    (item[0].includes("#") || (!action.payload.onlyHash && item[0].includes("@")))
                ) {
                    state.campaign.mentions = [...state.campaign.mentions, item];
                }
            });
        },
        removeMentions: (state, action) => {
            state.campaign.mentions.splice(action.payload, 1);
        },

        // NORMAL - objectives
        updateObjectives: (state, action) => {
            if (state.campaign.objectives.includes(action.payload)) {
                state.campaign.objectives = state.campaign.objectives.filter((item) => item != action.payload);
            } else {
                state.campaign.objectives = [...state.campaign.objectives, action.payload];
            }

            if (!state.campaign.objectives.includes("site")) {
                // clear site data
                state.campaign.integrations = {};
                state.campaign.site = {
                    domain: "",
                    destiny: "",
                    type: "comercial",
                    goals: [],
                };
            }
        },

        // NORMAL - integrations
        updateIntegrations: (state, action) => {
            state.campaign.integrations[action.payload.provider] = action.payload.data;
        },
        removeIntegration: (state, action) => {
            delete state.campaign.integrations[action.payload.provider];
        },

        // NORMAL - site / loja
        updateDomainUrl: (state, action) => {
            state.campaign.site.domain = action.payload;
        },
        updateDestinyUrl: (state, action) => {
            state.campaign.site.destiny = action.payload;
        },
        updateGoals: (state, action) => {
            if (action.payload == "set") {
                state.campaign.site.goals = state.goals;
            } else if (action.payload == "unset") {
                state.campaign.site.goals = [];
            } else {
                let exists = false;

                state.campaign.site.goals.forEach((goal) => {
                    if (goal && goal.selfLink == action.payload.selfLink) {
                        exists = true;
                        return;
                    }
                });

                if (exists) {
                    state.campaign.site.goals = state.campaign.site.goals.filter(
                        (item) => item && item.selfLink != action.payload.selfLink
                    );
                } else {
                    state.campaign.site.goals = [...state.campaign.site.goals, action.payload];
                }
            }
        },
        updateGoEc: (state, action) => {
            state.campaign.site.type = action.payload;
            state.campaign.site.goals = [];
        },
        setGoals: (state, action) => {
            state.goals = action.payload;
        },

        // NORMAL - influencers
        updateInfluencer: (state, action) => {
            let index;

            for (let i = 0; i < state.campaign.influencers.length; i++) {
                if (action.payload.influencer.id == state.campaign.influencers[i].id) {
                    index = i;
                    return;
                }
            }
            if (index) {
                state.campaign.influencers[index] = action.payload.influencer;
            }
        },
        updateInfluencerLinkTracking: (state, action) => {
            const payload = action.payload;
            const { index, linkTrackingError } = payload;

            state.campaign.influencerLinkTrackings = state.campaign.influencerLinkTrackings.map((item, i) => {
                if (i === index) {
                    return linkTrackingError;
                }
                return item;
            });
        },
        updateTrackedLink: (state, action) => {
            const payload = action.payload;
            state.campaign.trackedLink = payload;
        },
        removeInfluencers: (state, action) => {
            state.campaign.influencers = state.campaign.influencers.filter((item) => item.id != action.payload);
        },

        // -------------------------------------------

        // TIKTOK - data
        updateBudgetType: (state, action) => {
            state.campaign.budgetType = action.payload;
        },
        updateBudgetValue: (state, action) => {
            state.campaign.budgetValue = action.payload;
        },
        updateObjectiveTT: (state, action) => {
            state.campaign.objectiveTT = action.payload;
        },

        // TIKTOK - audience
        updateLocations: (state, action) => {
            state.campaign.locations = action.payload.values;
            state.campaign.locationsLabels = action.payload.labels;
        },
        updateBID: (state, action) => {
            state.campaign.bid = action.payload;
        },
        updateConversionParameter: (state, action) => {
            state.campaign.conversionParameter = action.payload;
        },
        updateFrequencyScheduling: (state, action) => {
            state.campaign.frequencyScheduling = action.payload;
        },
        updateFrequency: (state, action) => {
            state.campaign.frequency = action.payload;
        },
        updateCategories: (state, action) => {
            state.campaign.categories = action.payload.values;
            state.campaign.categoriesLabels = action.payload.labels;
        },
        updateInterest: (state, action) => {
            state.campaign.interests = action.payload.values;
            state.campaign.interestsLabels = action.payload.labels;
        },
        updateBlockedEndDate: (state) => {
            state.blockedEndDate = !state.blockedEndDate;
        },

        // -------------------------------------------

        // UTILS

        // social campaign
        updateSocialCampaign: (state, action) => {
            state.index = 0;
            state.socialCampaign = action.payload;
            state.campaign.socialCampaign = action.payload;
        },
        clear: (state) => {
            delete state.campaign.name;
            delete state.campaign.type;
            delete state.campaign.mentions;
        },
        changeStep: (state, action) => {
            state.index = action.payload;
        },
        clearCampaign: (state) => {
            state.index = 0;
            state.campaign = initialState.campaign;
        },
        updateStatus: (state) => {
            state.status = StateStatus.idle;
        },
        updateStatusSave: (state) => {
            state.statusSave = StateStatus.idle;
        },
    },
    extraReducers: {
        // CAMPAIGN DATA
        [fetchCampaign.pending]: (state) => {
            state.statusGetCampaign = StateStatus.loading;
        },
        [fetchCampaign.fulfilled]: (state, action) => {
            const campaign = action.payload;
            //
            let integrations = {};

            const coin = state.coins.find((c) => c.alias == campaign.currency);

            const contractors = campaign.contractor_profiles.map((c) => {
                let contractor = new Contractor();
                return contractor.fromJson(c);
            });

            const influencers = campaign.influencer_contracts.map((i) => {
                let influencer = new Influencer();
                return influencer.fromJson(i);
            });

            if (campaign.google_account_information) {
                integrations["google"] = campaign.google_account_information;
            }

            const indexGoals = campaign.analytics_goals.map((g) => {
                return g.selfLink;
            });

            state.indexGoals = indexGoals;

            let influencerLinkTrackings = influencers
                ? influencers.map(() => {
                      return -1;
                  })
                : [];

            if (!influencerLinkTrackings) {
                influencerLinkTrackings = [];
            }

            state.campaign = {
                //  step type
                id: campaign.id,

                type: campaign.campaign_type || (campaign.objective_types.length > 0 ? "influencers" : "hashtag"),
                //  step name-date
                name: campaign.name,
                publicName: campaign.public_name,
                description: campaign.description,
                startDate: campaign.start_date ? { date: new Date(campaign.start_date) } : null,
                endDate: campaign.end_date ? { date: new Date(campaign.end_date) } : null,
                // brand
                brandName: campaign.brand_name,
                brandEmail: campaign.brand_email,
                brandContactName: campaign.brand_contact_name,
                brandPhone: campaign.brand_phone,
                // contractors
                contractors: contractors ? contractors : [],
                //  step mentions
                mentions: [...campaign.mentions, ...campaign.hashtags],
                objectives: campaign.objective_types ? campaign.objective_types : [],
                draft: campaign.is_draft,
                influencers: influencers ? influencers : [],
                influencerLinkTrackings: influencerLinkTrackings,
                integrations: integrations ? integrations : {},
                trackedLink: campaign.tracked_link,
                analyticsConnectUrl: campaign.analytics_connect_url,
                site: {
                    domain: campaign.site_domain ? campaign.site_domain : "",
                    destiny: campaign.site_url ? campaign.site_url : "",
                    type: campaign.analytics_integration_type ? campaign.analytics_integration_type : "comercial",
                    goals: [],
                },
                coin: {
                    value: coin?.alias,
                    key: coin?.label,
                    symbol: coin?.symbol,
                },
            };

            state.statusGetCampaign = StateStatus.succeeded;
        },
        [fetchCampaign.rejected]: (state, action) => {
            state.errorMsg = action.error.message;
            state.statusGetCampaign = StateStatus.failed;
        },

        // UPDATES
        [saveStep.pending]: (state) => {
            state.status = StateStatus.loading;
        },
        [saveStep.fulfilled]: (state, action) => {
            if (action.payload.id && action.payload.id != null) {
                state.campaign.id = action.payload.id;
                state.campaign.analyticsConnectUrl = action.payload.analyticsConnectUrl;
            }

            if (action.payload.step == "save") {
                state.statusSave = StateStatus.succeeded;
                state.status = StateStatus.succeeded;
            } else {
                state.index = action.payload.index;
                state.status = StateStatus.succeeded;
            }
        },
        [saveStep.rejected]: (state, action) => {
            state.status = StateStatus.failed;
            state.errorMsg = action.error.message;
        },

        // import influencers
        [importInfluencers.pending]: (state) => {
            state.status = StateStatus.loading;
        },
        [importInfluencers.fulfilled]: (state, action) => {
            state.status = StateStatus.succeeded;
            console.log(action.payload.result);
            state.campaign.influencers = [...state.campaign.influencers, ...action.payload.result];
        },
        [importInfluencers.rejected]: (state, action) => {
            state.status = StateStatus.failed;
            state.errorMsg = action.error.message;
        },

        // contractors and influencers - get profile
        [fetchProfile.pending]: (state) => {
            state.status = StateStatus.loading;
        },
        [fetchProfile.fulfilled]: (state, action) => {
            state.status = StateStatus.succeeded;

            if (action.payload.type == "contractor") {
                state.campaign.contractors = [...state.campaign.contractors, action.payload.result];
            } else {
                state.campaign.influencers = [...state.campaign.influencers, action.payload.result];
            }
        },
        [fetchProfile.rejected]: (state, action) => {
            const error = action.payload;
            state.status = StateStatus.failed;
            state.errorMsg = error ? error.message : action.error.message;
            state.errorItems = error ? error.items : [];
        },

        // goals
        [fetchGoals.pending]: (state) => {
            state.status = StateStatus.loading;
        },
        [fetchGoals.fulfilled]: (state, action) => {
            state.goals = action.payload.result;

            const formatedGoals = (state.goals || []).map((goal) => {
                if (state.indexGoals.includes(goal.selfLink)) {
                    return {
                        name: goal.name,
                        accountId: goal.accountId,
                        profileId: goal.profileId,
                        id: goal.id,
                        webPropertyId: goal.webPropertyId,
                        selfLink: goal.selfLink,
                    };
                }
            });

            state.campaign.site.goals = formatedGoals && formatedGoals.length > 0 ? formatedGoals : [];

            state.status = StateStatus.succeeded;
        },
        [fetchGoals.rejected]: (state, action) => {
            state.status = StateStatus.failed;
            state.errorMsg = action.error.message;
        },

        // --------------------------------------

        // categories
        [fetchCategories.pending]: (state) => {
            state.status = StateStatus.loading;
        },
        [fetchCategories.fulfilled]: (state, action) => {
            state.categories = action.payload;
            state.status = StateStatus.succeeded;
        },
        [fetchCategories.rejected]: (state, action) => {
            state.status = StateStatus.failed;
            state.errorMsg = action.error.message;
        },

        // interests
        [fetchInterests.pending]: (state) => {
            state.status = StateStatus.loading;
        },
        [fetchInterests.fulfilled]: (state, action) => {
            state.interests = action.payload;
            state.status = StateStatus.succeeded;
        },
        [fetchInterests.rejected]: (state, action) => {
            state.status = StateStatus.failed;
            state.errorMsg = action.error.message;
        },

        // sendInfluencerLinkBatch
        [sendInfluencerLinkBatch.pending]: (state) => {
            state.statusLinkBatch = StateStatus.loading;
        },
        [sendInfluencerLinkBatch.fulfilled]: (state) => {
            state.statusLinkBatch = StateStatus.succeeded;
        },
        [sendInfluencerLinkBatch.rejected]: (state, action) => {
            state.statusLinkBatch = StateStatus.failed;
            state.errorMsg = action.error.message;
        },
    },
});

export const {
    buildTiktokCampaign,
    updateInfluencerDefault,
    // NORMAL
    updateName,
    updatePublicName,
    updateDates,
    updateCoin,
    updateDescription,
    //
    updateType,
    //
    removeContractor,
    updateBrandName,
    updateBrandEmail,
    updateBrandContactName,
    updateBrandPhone,
    //
    removeMentions,
    addMentions,
    //
    updateIntegrations,
    removeIntegration,
    //
    setGoals,
    updateGoals,
    updateGoEc,
    updateDomainUrl,
    updateDestinyUrl,
    //
    removeInfluencers,
    updateInfluencer,
    updateInfluencerLinkTracking,
    updateTrackedLink,

    // TIKTOK
    updateObjectives,
    updateBudgetType,
    updateBudgetValue,
    updateObjectiveTT,
    //
    updateLocations,
    updateBID,
    updateConversionParameter,
    updateFrequencyScheduling,
    updateFrequency,
    updateCategories,
    updateInterest,
    updateBlockedEndDate,

    // UTILS
    updateSocialCampaign,
    clear,
    clearCampaign,
    changeStep,
    updateStatus,
    updateStatusSave,
    updateCampaign,
} = createCampaign.actions;

export const selectCampaign = (state) => state.createCampaign;

export default createCampaign.reducer;
