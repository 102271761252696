import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import StateStatus from "../../../../utils/status";
import { getCampaignMetricsAudience, getCampaignMetricsEC, getCampaignMetricsNW } from "../../CampaignDetailsService";
import i18n from "../../../../languages/i18n";

const initialState = {
    network: null,
    commerce: null,
    saved: {
        chart: null,
        counter: null,
    },
    missingAnalyticsConnect: false,
    status: StateStatus.idle,
    statusEC: StateStatus.idle,
    statusAudience: StateStatus.idle,
    msgError: "",
};

export const fetchChartNw = createAsyncThunk("campaign/metrics/details/chart-nw", async (data) => {
    const responseChartNW = await getCampaignMetricsNW(data.id, data.network, data.postType);

    return responseChartNW;
});

export const fetchChartEC = createAsyncThunk("campaign/metrics/details/chart-ec", async (data) => {
    const responseChartEC = await getCampaignMetricsEC(data.id, data.network).catch((err) => {
        return err;
    });

    return responseChartEC;
});

export const fetchChartAudience = createAsyncThunk(
    "campaign/metrics/details/chart-audience",
    async (data, thunkAPI) => {
        const { charts } = thunkAPI.getState();

        const response = await getCampaignMetricsAudience(data.id);

        return {
            saved: charts.saved,
            ...response,
        };
    }
);

export const charts = createSlice({
    name: "charts",
    initialState: initialState,
    reducers: {
        clearCharts(state) {
            state.network = null;
            state.commerce = null;
            state.status = StateStatus.idle;
            state.statusEC = StateStatus.idle;
            state.statusAudience = StateStatus.idle;
            state.msgError = "";
        },
    },
    extraReducers: {
        // Chart nw
        [fetchChartNw.pending]: (state) => {
            state.status = StateStatus.loading;
        },
        [fetchChartNw.fulfilled]: (state, action) => {
            if (!action.payload.data.charts) {
                state.msgError = action.payload.data
                    ? action.payload.data.message
                    : "Entre em contato conosco para resolver esse problema!";
                state.status = StateStatus.failed;
            } else {
                const removeds = ["airscore", "video_completion_rate"];

                removeds.forEach((name) => {
                    if (action.payload.data.charts && action.payload.data.charts[name]) {
                        delete action.payload.data.charts[name];
                    }
                    if (action.payload.data.counters) {
                        action.payload.data.counters = action.payload.data.counters.filter(
                            (counter) => counter.label !== name
                        );
                    }
                });

                // clear null's and sets engagement_rate to fixed value
                Object.keys(action.payload.data.charts).forEach((key) => {
                    if (action.payload.data.charts[key] == null) delete action.payload.data.charts[key];
                });

                state.network = action.payload.data;

                state.status = StateStatus.succeeded;
            }
        },
        [fetchChartNw.rejected]: (state) => {
            state.status = StateStatus.failed;
        },
        // Chart ec
        [fetchChartEC.pending]: (state) => {
            state.statusEC = StateStatus.loading;
        },
        [fetchChartEC.fulfilled]: (state, action) => {
            if (!action.payload.data || !action.payload.data.charts) {
                if (
                    action.payload.data?.error === "GOOGLE_AUTHENTICATION_MISSING" ||
                    action.payload.data?.error === "EMPTY_ANALYTICS_GOALS"
                ) {
                    state.statusEC = StateStatus.succeeded;
                    state.missingAnalyticsConnect = true;
                    return;
                }
                state.msgError = action.payload.data
                    ? action.payload.data.message
                    : "Erro ao gerar gráfico do analytics. Entre em contato conosco para resolver esse problema!";
                state.statusEC = StateStatus.failed;
            } else {
                state.commerce = action.payload.data;
                state.statusEC = StateStatus.succeeded;
            }
        },
        [fetchChartEC.rejected]: (state) => {
            state.statusEC = StateStatus.failed;
        },
        // Chart audience
        [fetchChartAudience.pending]: (state) => {
            state.statusAudience = StateStatus.loading;
        },
        [fetchChartAudience.fulfilled]: (state, action) => {
            try {
                let counters = [];
                let chartData = {};
                //
                const data = action.payload.data;

                Object.keys(data).forEach((item) => {
                    const label = item.replace("lifetime_", "").replace("_distribution", "");

                    if (data[item].length > 0) {
                        const max = data[item].reduce((a, b) => (a.value > b.value ? a : b));

                        counters.push({
                            label: i18n.t(label),
                            value: `${(max.value * 100).toFixed(0)}% ${i18n.t(max.label)}`,
                        });
                    } else {
                        counters.push({
                            label: i18n.t(label),
                            value: 0,
                        });
                    }
                });

                Object.keys(data).forEach((item) => {
                    const label = item.replace("lifetime_", "").replace("_distribution", "");

                    if (data[item].length > 0) {
                        chartData[label] = {
                            per_profile: data[item].map((chart) => ({
                                label: i18n.t(chart.label),
                                value: (chart.value * 100).toFixed(2),
                            })),
                        };
                    } else {
                        chartData[label] = {
                            per_profile: [],
                        };
                    }
                });

                state.network.charts = chartData;
                state.network.counters = counters;
            } catch (error) {
                state.network = {
                    charts: {},
                    counters: [],
                };
                action.payload.data.counters = [];
            }

            state.status = StateStatus.succeeded;
        },
        [fetchChartAudience.rejected]: (state) => {
            state.statusAudience = StateStatus.failed;
        },
    },
});

export const { clearCharts } = charts.actions;

export const selectCharts = (state) => state.charts;

export default charts.reducer;
