import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    forgotPassword,
    getProfile,
    sendAccount,
    sendHubAuth,
    sendLanguagePreference,
    sendProviderAccount,
} from "./LoginService";
import StateStatus from "../../utils/status";

const initialState = {
    status: {
        login: StateStatus.idle,
        loginProvider: StateStatus.idle,
        forgotPassword: StateStatus.idle,
        getProfile: StateStatus.idle,
    },
    data: {
        name: null,
        surname: null,
        email: null,
        uid: null,
        preferences: {
            language: "en ",
        },
        plan: null,
        stats: null,
        guiding_step: -1,
    },
    provider: {},
    msg: null,
    googleLoginExists: null,
};

export const fetchProfile = createAsyncThunk("getLogin", async () => {
    const response = await getProfile();

    return response.data;
});

export const sendLogin = createAsyncThunk("login", async (data) => {
    let response;
    const { redirectToken } = data;

    if (redirectToken) {
        response = await sendHubAuth(redirectToken);
    } else {
        response = await sendAccount(data);
    }

    if (response.status == 200 && response.data) {
        window.localStorage.setItem("access_token", response.data.access_token);
        window.localStorage.setItem("hub", response.data.hub_id ? true : false);
        window.localStorage.setItem("refresh_token", response.data.refresh_token);
    }

    return {
        name: response.data.name,
        surname: response.data.surname,
        email: data.email,
        uid: response.data.uid,
    };
});

export const sendEmail = createAsyncThunk("send-email", async (data) => {
    const { email } = data;

    const response = await forgotPassword(email);

    return response;
});

export const sendLoginProvider = createAsyncThunk(
    "login/provider",
    async (data, { rejectWithValue }) => {
        try {
            const response = await sendProviderAccount(data);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const setLanguagePreference = createAsyncThunk("login/language", async (data) => {
    const response = await sendLanguagePreference(data);

    return response.data;
});

export const profile = createSlice({
    name: "profile",
    initialState: initialState,
    reducers: {
        clearStatus: (state) => {
            state.status = {
                login: StateStatus.idle,
                setLanguage: StateStatus.idle,
                forgotPassword: StateStatus.idle,
                getProfile: StateStatus.idle,
            };
        },
        increaseCampaignsCount: (state) => {
            state.data.stats.campaigns += 1;
        },
        increaseAccountsCount: (state) => {
            state.data.stats.accounts += 1;
        },
    },
    extraReducers: {
        // PROFILE
        [sendLogin.pending]: (state) => {
            state.status.login = StateStatus.loading;
        },
        [sendLogin.fulfilled]: (state) => {
            state.status.login = StateStatus.succeeded;
        },
        [sendLogin.rejected]: (state) => {
            // state.msg = action.error.message || "Não foi possível fazer o login. Favor usar uma conta existente!"
            state.msg = "Não foi possível fazer o login. Favor usar uma conta existente!";

            state.status.login = StateStatus.failed;
        },
        [setLanguagePreference.pending]: (state) => {
            state.status.setLanguage = StateStatus.loading;
        },
        [setLanguagePreference.fulfilled]: (state) => {
            state.status.setLanguage = StateStatus.succeeded;
        },
        [setLanguagePreference.rejected]: (state) => {
            state.status.setLanguage = StateStatus.failed;
        },
        // FORGOT
        [sendEmail.pending]: (state) => {
            state.status.forgotPassword = StateStatus.loading;
        },
        [sendEmail.fulfilled]: (state, action) => {
            state.msg = action.payload.data.msg || "E-mail enviado com sucesso";
            state.status.forgotPassword = StateStatus.succeeded;
        },
        [sendEmail.rejected]: (state, action) => {
            state.msg = action.error.message || "Erro ao enviar e-mail";
            state.status.forgotPassword = StateStatus.failed;
        },
        // profile
        [fetchProfile.pending]: (state) => {
            state.status.getProfile = StateStatus.loading;
        },
        [fetchProfile.fulfilled]: (state, action) => {
            const profile = action.payload;

            const uid = profile.uid;

            state.data.email = profile.email;
            state.data.name = profile.name;
            state.data.surname = profile.surname;
            state.data.uid = uid;
            state.data.id = profile.id;
            state.data.preferences.language = profile.preferences?.language;
            state.data.plan = profile.plan;
            state.data.stats = profile.stats;
            state.data.guiding_step = profile.guiding_step;
            
            if(profile.group) {
                if(profile.group.phone) state.data.phone = profile.group.phone;
            }

            if (!profile.payment_authorized) {
                if (!profile.company || !profile.phone) {
                    window.location.href = "/#/register";
                } else if (!profile.payment_authorized) {
                    window.location.href = "/#/register/payment";
                }
            }

            state.status.getProfile = StateStatus.succeeded;
        },
        [fetchProfile.rejected]: (state) => {
            state.status.getProfile = StateStatus.failed;
        },
        // login with google
        [sendLoginProvider.pending]: (state, action) => {
            state.provider = {
                accessToken: action.meta.arg.accessToken,
                provider: action.meta.arg.provider,
                email: action.meta.arg.email,
            };

            state.status.loginProvider = StateStatus.loading;
        },
        [sendLoginProvider.fulfilled]: (state, action) => {
            localStorage.setItem("access_token", action.payload.access_token);
            localStorage.setItem("refresh_token", action.payload.refresh_token);

            state.status.loginProvider = StateStatus.succeeded;
        },
        [sendLoginProvider.rejected]: (state, action) => {
            const { error, message } = action.payload;
            console.log(error, message, action.payload);
            if (error === "NOT_AUTHORIZED") {
                state.googleLoginExists = false;
            } else {
                state.msg = message;
            }

            state.status.loginProvider = StateStatus.failed;
        },
    },
});

export const { clearStatus } = profile.actions;

export const selectLogin = (state) => state.profile;
export const selectProfile = (state) => state.profile;

export default profile.reducer;
