import React from 'react';
import PropTypes from 'prop-types'
// 
import { Avatar as AntAvatar, Tooltip } from 'antd';
import { AirUtils } from '../../../utils/utils';
// 
import './Avatar.scss';

const Avatar = ({ tooltip, network, onClick, size, src }) => {
    const utils = new AirUtils();

    return <Tooltip title={tooltip}>
        <AntAvatar 
            className={`avatar avatar-${network} ${onClick ? "hover" : ""}`} 
            style={{
                width: size || "35px",
                height: size || "35px",
            }}
            src={utils.getThumbnail(src)} 
            onClick={onClick} 
        />
    </Tooltip>
}

Avatar.propTypes = {
    src: PropTypes.string,
    size: PropTypes.string,
    tooltip: PropTypes.string,
    network: PropTypes.string,
    onClick: PropTypes.func,
}

export default Avatar;