import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
// 
import { configStore } from './app/store';
import App from './app/App';
// 
import './index.scss';
import './languages/i18n';

const store = configStore()

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <App />
    </HashRouter>
  </Provider>,
    
  document.getElementById('root')
);
