const getNetworkByUrl = (url) => {
    if (!url) return '';

    const pattInsta = new RegExp('instagram', 'i');
    const pattTwitter = new RegExp('twitter', 'i');
    const pattYoutube = new RegExp('youtube', 'i');
    const pattFacebook = new RegExp('facebook', 'i');

    if (pattInsta.test(url)) { return 'instagram'; }
    if (pattTwitter.test(url)) { return 'twitter'; }
    if (pattYoutube.test(url)) { return 'youtube'; }
    if (pattFacebook.test(url)) { return 'facebook'; }
    return '';
}

const verifyUrlIsDuplicated = (url, list) => {
    const nw = getNetworkByUrl(url);

    let username = url.split('?')[0].split('/');
    
    username = username[username.length - 1] === '' ? username[username.length - 2] : username[username.length - 1];
    
    for (const i in list) {
        const profile = list[i];
        
        if (profile.network === nw && profile.name === username) {
            return true;
        }
    }

    return false;
}

const checkUrl = (url, list, urlInclude) => {
    if(list) {
        const isDuplicated = verifyUrlIsDuplicated(url, list);
    
        if (isDuplicated) {
            return false;
        }
    }

    const regexp = /((http|https):\/\/)?[A-Za-z0-9-]{3,}\.[A-Za-z]{2}/;

    // TODO: verificar se o twitter e o youtube irão entrar e remover a verificação do ticoteco
    // if (url[0] != " " && (regexp.test(url) && (urlInclude ? url.includes(urlInclude) : url.includes("instagram.com/") || url.includes("twitter.com/") || url.includes("youtube.com/"))) ) {
    if (url[0] != " " && (regexp.test(url) && (urlInclude ? url.includes(urlInclude) : url.includes("instagram.com/") || url.includes("tiktok.com/") || url.includes("twitter.com/") || url.includes("youtube.com/"))) ) {
        const user = url.split(".com/");

        if(user.length > 1 && !/^\s*$/.test(user[1]) && user[1].length > 1) 
            return true
    }
    
    return false;
}

export default checkUrl;