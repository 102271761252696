import api, { apiWhitoutInterceptors } from "../../service/api"
import apiRoutes from "../../service/apiRoutes"

export const getProfile = async () => await api.get("/account/me");
export const contractedPlan = () => api.get(apiRoutes.contractedPlan)
export const loginProviderAccount = async (data) => await apiWhitoutInterceptors.post("/account/", data)
// 
export const createAccount = (data) => apiWhitoutInterceptors.post(apiRoutes.register, data)
export const updateAccount = (data) => api.patch(apiRoutes.register, data)
// 
export const createBilling = (data) => api.put(apiRoutes.billing, data)
// 
export const createPayment = (data) => api.post(apiRoutes.payment, data)
// 
export const verifyCoupon = (data) => api.post(apiRoutes.coupon, data)
export const paymentStatus = () => api.get(apiRoutes.paymentStatus)
export const planDetails = () => apiWhitoutInterceptors.get(apiRoutes.planDetails)
