import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
// 
import Highcharts from "highcharts"
import HighchartsReact from "highcharts-react-official"
// 
import { AirUtils } from "../../../../utils/utils";
import ChartColors from "../ChartColors";
// 
import "../Charts.scss";

const BarChart = ({ data, className, height, color, nameFormat, isPercentage, showLegend = true }) => {
    const airUtils = new AirUtils();
    const cColors = new ChartColors();

    const [series, setSeries] = useState([]);

    useEffect(() => {
        const newSeries = [];

        data.forEach((data, i) => {
            const value = typeof data.value === "string" ? parseFloat(data.value) : data.value

            if (showLegend)
                newSeries.push({
                    name: data.label,
                    data: [value],
                    color: color ? color : cColors.getColor(i),
                    selected: true,
                })
            else
                newSeries.push({
                    name: data.label,
                    data: [value],
                    color: color ? color : cColors.getColor(i)
                })
        })

        setSeries(newSeries);
    }, [data])

    const options = {
        chart: {
            type: "column",
            height: height,
            className: className,
            styles: {
                width: "100%"
            }
        },
        title: {
            text: ""
        },
        yAxis: {
            title: ""
        },
        xAxis: {
            visible: false
        },
        legend: {
            enabled: showLegend
        },
        plotOptions: {
            series: {
                showCheckbox: true,
                dataLabels: {
                    enabled: false,
                },
                events: {
                    checkboxClick: function () {
                        if (this.visible) {
                            this.hide();
                        } else {
                            this.show();
                        }
                    },
                    legendItemClick: function () {
                        if (this.selected) {
                            this.selected = false;
                        } else {
                            this.selected = true;
                        }

                        if (this.visible) {
                            this.checkbox.checked = false;
                        } else {
                            this.checkbox.checked = true;
                        }
                    }
                }
            }
        },

        tooltip: {
            formatter: function () {
                const value = `${airUtils.buildValue(nameFormat, this.series.userOptions.data[this.series.userOptions.data.length - 1])}${isPercentage ? "%" : ""}`

                return `<div class="high-tooltip">
                    ${this.series.userOptions.name}: <b>${value}</b>
                </div>`
            }
        },

        series: series,
    };

    return series && <HighchartsReact
        highcharts={Highcharts}
        options={options}
    />
}

BarChart.defaultProps = {
    nameFormat: "number"
}

BarChart.propTypes = {
    data: PropTypes.any,
    color: PropTypes.string,
    nameFormat: PropTypes.string,
    height: PropTypes.string,
    className: PropTypes.string,
    showLegend: PropTypes.bool,
    isPercentage: PropTypes.bool,
}

export default BarChart;