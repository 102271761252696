import React from 'react';
import PropTypes from 'prop-types'
// 
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// 
import "./Loading.scss";

const antIcon = (size) => <LoadingOutlined style={{ fontSize: size || 24 }} spin />

const Loading = () => <div className="container-loading"> <Spin indicator={antIcon(55)} /> </div>

// --------------------------------

const SizedLoading = ({ size = 24, withContainer = true }) => 
    <div className={withContainer ? "container-loading" : ""}>
        <Spin indicator={antIcon(size)} />
    </div>

SizedLoading.propTypes = {
    size: PropTypes.number,
    withContainer: PropTypes.bool,
}

// --------------------------------

export { SizedLoading }

export default Loading