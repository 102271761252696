import React from "react";
import PropTypes from 'prop-types'
// 
import "./Card.scss";

const Card = ({
    className,
    padding,
    footer,
    child
}) => 
    <div className={`card ${className ? className : ""}`}>
        <div 
            className="card-content"
            style={{ 
                padding: padding || "24px 24px 40px",
                borderRadius: !footer ? "4px" : "4px 4px 0 0"                       
            }}
        >
            { child }
        </div>

        {footer && <div className="card-footer"> { footer } </div>}
    </div>

Card.propTypes = {
    className: PropTypes.string,
    padding: PropTypes.string,
    footer: PropTypes.element,
    child: PropTypes.element
}

export default Card