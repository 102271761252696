import React from "react";
import PropTypes from "prop-types";
// 
import { AirUtils } from "../../../../utils/utils";
// 
import BarChart from "../BarChart/BarChart";
// 
import "./ThreeCharts.scss";

const ThreeCharts = ({ datas, names, filter }) => {
    const airUtils = new AirUtils();

    return Object.keys(datas).length > 2 && <div className="container-three-charts">
        <BarChart className="one-chart" formatter={(value) => airUtils.buildValue("humanize", value)} data={datas[names[0]][filter]} />

        <div className="aside-three-charts">
            <BarChart className="first-chart" formatter={(value) => airUtils.buildValue("humanize", value)} data={datas[names[1]]["per_profile"]} showLegend={false} height="148px" />
            <div className="line-division"></div>
            <BarChart formatter={(value) => airUtils.buildValue("humanize", value)} data={datas[names[2]]["per_profile"]} showLegend={false} height="148px" />
        </div>
    </div>
}

ThreeCharts.propTypes = {
    datas: PropTypes.any,
    names: PropTypes.any,
    filter: PropTypes.string,
}

export default ThreeCharts;
