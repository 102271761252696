import React, { useState, useEffect } from "react";
import { ConfigProvider } from "antd-country-phone-input";
import en from "world_countries_lists/data/countries/en/world.json";
import pt from "world_countries_lists/data/countries/pt/world.json";
//
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { changeIndex } from "../common/components/SideMenu/sideMenuSlice";
import { selectObjAlert, showAlert } from "../common/components/Alert/alertSlice";
import { selectLogin } from "../features/Login/LoginSlice";
//
import { Layout } from "antd";
import Alert from "../common/components/Alert/Alert";
import SideMenu from "../common/components/SideMenu/SideMenu";
//
import Routes from "../routes/Routes";
import i18n from "../languages/i18n";
import Guiding from "../common/components/Guiding/Guiding";
import { ShepherdTour } from "react-shepherd";
import "shepherd.js/dist/css/shepherd.css";

const App = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const alert = useSelector(selectObjAlert);
    const login = useSelector(selectLogin);
    const tourOptions = {
        defaultStepOptions: {
            cancelIcon: {
                enabled: true,
            },
        },
        useModalOverlay: true,
    };
    const [showMenu, setShowMenu] = useState(
        window.location.href.indexOf("login") === -1 &&
            window.location.href.indexOf("register") === -1 &&
            window.location.href.indexOf("password/reset") === -1 &&
            window.location.href.indexOf("terms-privacy") === -1
    );

    const verifyQueryLanguage = () => {
        const { hash } = window.location;
        const paramsIndex = hash.indexOf("?");
        const hashPage = paramsIndex > -1 ? hash.slice(hash.indexOf("#"), paramsIndex) : hash;

        const query = new URLSearchParams(window.location.hash.replace(hashPage, ""));

        const queryLang = query.get("lang");
        return queryLang;
    };

    const verifyLanguage = () => {
        let lang = null;

        if (login.data.preferences.language === "pt_BR") lang = "pt";
        if (login.data.preferences.language === "en_US") lang = "en";

        const queryLang = verifyQueryLanguage();

        if (queryLang) {
            localStorage.setItem("lang", queryLang);
            i18n.changeLanguage(queryLang);
        } else if (lang) {
            localStorage.setItem("lang", lang);
            i18n.changeLanguage(lang);
        }
    };

    const verifyPage = () => {
        const path = history.location.pathname;

        dispatch(
            changeIndex(
                path.includes("create-campaign")
                    ? "0"
                    : path === "/"
                    ? "1"
                    : path.includes("campaign-summary")
                    ? "2"
                    : path.includes("tiktok-discovery")
                    ? "3"
                    : "-1"
            )
        );
    };

    history.listen(() => {
        setShowMenu(
            window.location.href.indexOf("login") === -1 &&
                window.location.href.indexOf("register") === -1 &&
                window.location.href.indexOf("password/reset") === -1 &&
                window.location.href.indexOf("terms-privacy") === -1
        );

        verifyPage();
    });

    useEffect(() => {
        verifyPage();
    }, []);

    useEffect(() => {
        verifyLanguage();
    }, [login.data.preferences?.language]);
    return (
        <ConfigProvider locale={i18n.language == "en" ? en : pt}>
            <ShepherdTour tourOptions={tourOptions} steps={[]}>
                <Layout style={{ height: "100%" }}>
                    <Guiding />
                    {showMenu && <SideMenu history={history} />}

                    <Routes />

                    <Alert
                        show={alert.show}
                        type={alert.type}
                        title={alert.title}
                        text={alert.text}
                        question={alert.question}
                        okText={alert.okText}
                        colorOk={alert.colorOk}
                        cancelText={alert.cancelText}
                        colorCancel={alert.colorCancel}
                        onOk={alert.onOk || function () {}}
                        onCancel={alert.onCancel || dispatch(showAlert(false))}
                    />
                </Layout>
            </ShepherdTour>
        </ConfigProvider>
    );
};

export default App;
