import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// 
import Card from '../../../../common/components/Card/Card';
import GrowOrDecrease from '../../../../common/components/GrowOrDecrease/GrowOrDecrease';
import LineChart from '../../../../common/components/Charts/LineChart/LineChart';
// 
import { AirUtils } from '../../../../utils/utils';
import { humanize } from '../../../../utils/formatters';
import "./Chart.scss";
import { networkColors } from '../../../../utils/colors';

const ChartContractor = ({ data, title, network }) => {
    const { t } = useTranslation();

    const airUtils = new AirUtils();
    const [dataChart, setDataChart] = useState([]) 

    useEffect(() => {
        setDataChart(data)
    }, [data])

    return dataChart.length > 0 && <Card
        className={`chart-card contractor-chart border-${network}-color`}
        child={<>
            <div className="chart-header">
                <span className="campaign-title-data">{title}</span>

                <span className="campaign-subtitle-data">
                    {t("Base de seguidores no início da campanha")}: 
                    <span className={`value font-${network}-color`}>{humanize(dataChart[0] ? dataChart[0].value : "-")}</span> 
                    | {t("Crescimento de seguidores")}: 
                    <GrowOrDecrease tooltip={false} icon={false} changePercent={dataChart[0] ? dataChart[dataChart.length - 1].grow : "-"} />
                </span>
            </div>

            <div className="chart-container">
                <LineChart series={[{data: data.map(item => [Date.parse(item.label), item.value]), color: networkColors[network] }]} formatter={(value) => airUtils.buildValue("humanize", value) } />
            </div>

            <span className="chart-footer">hypolake.com</span>
        </>}
    /> 
}

ChartContractor.propTypes = {
    title: PropTypes.string,
    data: PropTypes.any,
    network: PropTypes.string
}

export default ChartContractor;