import React, { useState } from "react";
import PropTypes from "prop-types";
import { Spin } from "antd";
import { useGoogleLogin } from "@react-oauth/google";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { changeObjAlert, showAlert } from "../Alert/alertSlice";
import "./GoogleLogin.scss";
function GoogleLogin({ onSuccess, onError, connectLink }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const login = useGoogleLogin({
        flow: "auth-code",
        prompt: "consent",
        responseType: "code",
        approvalPrompt: "force",
        cookiePolicy: "single_host_origin",
        onSuccess: async (response) => {
            setLoading(true);
            await Promise.resolve(onSuccess(response));
            setLoading(false);
        },
        onError: async (response) => {
            setLoading(true);
            await Promise.resolve(onError(response));
            setLoading(false);
        },
        scope: "https://www.googleapis.com/auth/analytics.readonly https://www.googleapis.com/auth/userinfo.profile",
    });

    const copyLink = (link) => {
        navigator.clipboard.writeText(link);

        dispatch(
            changeObjAlert({
                show: true,
                type: "success",
                title: t("sucesso"),
                text: t("Link copiado!"),
                onCancel: () => {
                    dispatch(showAlert(false));
                },
            })
        );
    };

    const [loading, setLoading] = useState(false);

    return (
        <div className="div-button-go">
            <div type="button" className="google-btn" onClick={() => login()}>
                <div className="google-icon-wrapper">
                    <img
                        className="google-icon"
                        src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                    />
                </div>
                <p className="btn-text">Sign in with google</p>
            </div>
            {loading && <Spin />}
            {connectLink && (
                <div className="connect-link-holder">
                    {t("Ou") + " "}
                    <a onClick={() => copyLink(connectLink)}>{t("copie o link do connect")}</a>
                    {" " + t("e envie para outra pessoa")}
                </div>
            )}
        </div>
    );
}

GoogleLogin.propTypes = {
    onSuccess: PropTypes.func,
    onError: PropTypes.func,
    connectLink: PropTypes.string,
};
GoogleLogin.defaultProps = {
    onSuccess: () => {},
    onError: () => {},
    connectLink: null,
};
export default GoogleLogin;
