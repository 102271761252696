import React from 'react';
import PropTypes from 'prop-types';
// 
import Avatar from '../../../../common/components/Avatar/Avatar';
// 
import './CardIntegrationProfile.scss';

const CardIntegrationProfile = ({ profile, showEmail = false }) => {
    return <div className="card-integration-profile">
        <Avatar
            size="33px"
            src={ !profile.avatar ? profile.picture : profile.avatar }
            network={ "google" }
        />
        <div className="data-profile">
            <span className="profile-name">{ profile.name }</span>
            {showEmail  && <span className="profile-email">{ profile.email }</span>}
        </div>
    </div>
}

CardIntegrationProfile.propTypes = {
    profile: PropTypes.any,
    showEmail: PropTypes.bool,
}

export default CardIntegrationProfile;