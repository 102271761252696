import moment from "moment";
import i18n from "../languages/i18n";
import { humanize } from "./formatters";

export class AirUtils {
    getThumbnail(url) {
        const img = new Image();

        new Promise((resolve) => {
            img.onload = () =>
                resolve({
                    url,
                    status: "ok",
                });
            img.onerror = () =>
                resolve({
                    url,
                    status: "error",
                });

            img.src = url;
        });

        return img.src.indexOf("undefined") !== -1
            ? "https://www.drnitinborse.com/wp-content/uploads/2018/02/user-icon.png"
            : img.src;
    }

    getLanguage() {
        const preferencesLanguage = i18n.language;

        if (preferencesLanguage) {
            return preferencesLanguage === "pt" ? "pt-BR" : "en-US";
        }
        const lang = navigator.userLanguage || navigator.language || "en-US";

        return lang;
    }

    buildFloat(value, precision = 2) {
        const lang = this.getLanguage();
        return value.toFixed(precision).toLocaleString(lang);
    }

    buildInteger(value) {
        const lang = this.getLanguage();
        return value.toLocaleString(lang);
    }

    buildImage(url, network) {
        if (url === null) {
            return "";
        }

        return network ? url : url;
    }

    buildValue(name, value, additionalValue, precision = 2, currency = null) {
        if (name == "date") {
            const lang = i18n.language || "en";
            return moment(value).format(lang === "en" ? "MM/DD/yyyy" : "DD/MM/yyyy");
        } else if (name == "datetime") {
            const lang = i18n.language || "en";
            return moment(value).format(lang === "en" ? "MM/DD/YYYY [at] hh:mm A" : "DD/MM/YYYY [às] HH:mm");
        } else if (name == "dateString") {
            const lang = i18n.language || "en";
            return moment(value).format(lang === "en" ? "MM/DD" : "DD/MM");
        } else if (name == "number") {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        } else if (name == "fixed") {
            return `${this.buildFloat(value * 100, precision)}`;
        } else if (name == "engagement_rate") {
            return `${this.buildFloat(value * 100, precision)}%`;
        } else if (name == "reach_rate" || name == "is_percent") {
            return `${value}%`;
        } else if (
            [
                "humanize",
                "total_interactions",
                "interactions",
                "sum_of_bases",
                "base",
                "total_posts_per_hashtags",
                "reach",
                "average_views",
            ].includes(name)
        ) {
            return humanize(value);
        } else if (name == "publications") {
            return `${value} de ${additionalValue}`;
        } else if (name == "percentage") {
            return `${this.buildFloat(value * 100, precision)}%`;
        } else if (name == "integer") {
            const lang = this.getLanguage();
            return value.toLocaleString(lang);
        } else if (name == "video_completion_rate") {
            return `${(value * 100).toFixed(precision)}%`;
        } else if (name == "currency" || name === "cost_per_interaction" || name == "investment") {
            const format = {
                minimumFractionDigits: 2,
            };

            if (currency) {
                format.style = "currency";
                format.currency = currency;
                format.currencyDisplay = "narrowSymbol";
            }

            const formatter = new Intl.NumberFormat(this.getLanguage(), format);

            return (
                (!additionalValue ? "" : `${additionalValue} `) +
                formatter.format(value.toFixed ? value.toFixed(2) : value)
            );
        }

        return value;
    }

    findParamsUrl(param) {
        let result = null;
        let tmp = [];

        location.hash
            .substr(1)
            .split("&")
            .forEach((item) => {
                tmp = item.split("=");

                if (tmp[0].replace(/[^a-zA-Z_]/g, "") === param) {
                    result = decodeURIComponent(tmp[1]);
                }
            });

        return result;
    }

    getParameterByName(name, url = window.location.href) {
        name = name.replace(/[[]]/g, "\\$&");
        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return "";
        return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
}

export const generateProfileUrl = (network, username) => {
    const networks = {
        instagram: `https://www.instagram.com/${username}`,
        tiktok: `https://tiktok.com/@${username}`,
    };

    return networks[network];
};
