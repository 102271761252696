import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect, useRef } from "react";
import StateStatus from "../../../utils/stateStatus";
//
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    addCampaignAuthenticator,
    attachStoriesToCampaign,
    detachStoriesToCampaign,
    resetStatusSparkAdAuthorization,
    selectCampaign,
    setTiktokAsConnected,
    sparkAdAuthorization,
} from "../../../features/CampaignDetails/CampaignDetailsSlice";
//
import Avatar from "../Avatar/Avatar";
import { Switch } from "antd";
import { Spin } from "antd";
import { Tooltip } from "antd";
//
import { AirUtils } from "../../../utils/utils";
import { humanize } from "../../../utils/formatters";
// import GrowOrDecrease from "../GrowOrDecrease/GrowOrDecrease";
//
import ReactPlayer from "react-player";
import "./CardStories.scss";
import Button, { GreenButton, OutlinedGreenButton } from "../Button/Button";
import SocialNetworkIcon from "../SocialNetworkIcon/SocialNetworkIcon";
import HypoModal from "../Modal";
import Select from "../Select/Select";
import { LoadingOutlined } from "@ant-design/icons";

const CardStories = ({ post, onClick, campaignId, onRequestSpaskSuccess = () => {} }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    //
    const objCampaign = useSelector(selectCampaign);
    //
    const history = useHistory();
    const airUtils = new AirUtils();
    //
    const [inCampaign, setInCampaign] = useState();
    const [loading, setLoading] = useState(false);
    //
    const [playing, setPlaying] = useState(false);
    const [play] = useState(false);
    const playerRef = useRef();
    const [videoError, setVideoError] = useState(false);

    const [showTiktokConnectPopup, setShowTiktokConnectPopup] = useState(false);
    const [showAskSparkPeriod, setShowAskPeriod] = useState(false);
    const [sparkPeriod, setSparkPeriod] = useState(null);

    const sparkPeriodOptions = [
        {
            value: 7,
            label: t("7 dias"),
        },
        {
            value: 30,
            label: t("30 dias"),
        },
        {
            value: 60,
            label: t("60 dias"),
        },
        {
            value: 365,
            label: t("365 dias"),
        },
    ];

    const loadingIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

    const togglePost = async () => {
        setLoading(true);
        const postType = post.type;
        if (inCampaign) {
            await dispatch(detachStoriesToCampaign({ campaignId, postId: post.id, postType }));
        } else {
            await dispatch(attachStoriesToCampaign({ campaignId, postId: post.id, postType }));
        }

        setLoading(false);
    };

    const requestSparkAd = (authorizationDays) => {
        dispatch(sparkAdAuthorization({ campaignId, orderId: post.orderId, authorizationDays }));
    };

    const responseTikTokAuth = async (status, token) => {
        // TODO: Make some action when status === 'error'
        if (status === "success") {
            await dispatch(addCampaignAuthenticator({ campaignId, authorization_code: token, provider: "tiktok" }));
            setShowTiktokConnectPopup(false);
            dispatch(setTiktokAsConnected());
        }
    };

    useEffect(() => {
        window["responseTikTokAuth"] = responseTikTokAuth;
    }, []);

    useEffect(() => {
        if (objCampaign.statusStoriesCampaign == StateStatus.succeeded) setInCampaign(!inCampaign);

        if (!objCampaign.campaign.isTiktokConnected && post.sparkState === "accepted") {
            setShowTiktokConnectPopup(true);
        }
    }, [loading]);

    useEffect(() => {
        if (post) setInCampaign(post.inCampaign);
    }, []);

    useEffect(() => {
        if (objCampaign.statusSparkAdAuthorization === StateStatus.succeeded) {
            setShowAskPeriod(false);
            dispatch(resetStatusSparkAdAuthorization());
            onRequestSpaskSuccess();
        }
    }, [objCampaign.statusSparkAdAuthorization, dispatch, resetStatusSparkAdAuthorization]);

    return (
        <div className="card-stories">
            {
                <HypoModal onClose={() => setShowAskPeriod(false)} isVisible={showAskSparkPeriod}>
                    <div className="ask-spark-title">{t("Solicitar Autorização")}</div>
                    <div className="ask-spark-text">
                        {t("Escolha por quanto tempo deseja ter autorização para impulsionar esta video:")}
                    </div>
                    <br />
                    <Select
                        placeholder={t("Selecionar período")}
                        width="100%"
                        onChange={(option) => setSparkPeriod(option.value)}
                        options={sparkPeriodOptions}
                        outline
                    />
                    {/* estilizar erro */}

                    {objCampaign.statusSparkAdAuthorization === StateStatus.failed ? objCampaign.errorMsg : null}

                    <div className="ask-spark-buttons">
                        <OutlinedGreenButton onClick={() => setShowAskPeriod(false)}>
                            {t("Cancelar")}
                        </OutlinedGreenButton>
                        <GreenButton onClick={() => requestSparkAd(sparkPeriod)}>
                            <div className="button-container">
                                {objCampaign.statusSparkAdAuthorization === StateStatus.loading ? (
                                    <Spin indicator={loadingIcon} />
                                ) : null}
                                {t("Solicitar")}
                            </div>
                        </GreenButton>
                    </div>
                </HypoModal>
            }
            {post && (
                <>
                    {showTiktokConnectPopup && (
                        <>
                            {" "}
                            <div className="popup-backdrop" />
                            <div className="popup">
                                <p>{t("Agora você precisa fazer o Connect com o TikTok")}</p>
                                <Button
                                    outline={true}
                                    text={t("Connect to tiktok")}
                                    icon="tiktok"
                                    onClick={async () => {
                                        // setButtonClicked(0);
                                        // setError(false);
                                        window.open(objCampaign.urlTikTok, "HypoLake", "width=600,height=700");
                                    }}
                                />
                            </div>
                        </>
                    )}
                    <div className="post-header">
                        <Tooltip title={post.owner.name}>
                            <div className="influencer">
                                {post.owner.avatar != "" && (
                                    <Avatar
                                        src={post.owner.avatar}
                                        network={post.network}
                                        size="35px"
                                        onClick={() => {
                                            history.push(
                                                `/campaign/${campaignId}/influencer/${post.owner.airId}/stories`
                                            );
                                        }}
                                    />
                                )}
                                <div>
                                    <span className="name">{post.owner.name}</span>
                                    <div className="data">
                                        {t("Data")}: {airUtils.buildValue("date", post.date)}
                                    </div>
                                </div>
                            </div>
                        </Tooltip>

                        <Tooltip title={t("Adicionar ou remover story da campanha")}>
                            <div className="in-campaign">
                                {loading ? (
                                    <Spin />
                                ) : (
                                    <Switch size="small" checked={inCampaign} onChange={() => togglePost()} />
                                )}
                            </div>
                        </Tooltip>

                        {/* <GrowOrDecrease tooltip={true} changePercent={post.performance} className="grow-or-decrease"/> */}
                        <div className="social-network-wrapper">
                            <div
                                className="social-network"
                                style={{
                                    borderRight:
                                        post.network == "tiktok" && post.sparkState ? "1px solid #ADADAD" : "0px",
                                }}
                            >
                                <SocialNetworkIcon network={post.network} width="19px" showLabel={true} />
                            </div>
                            {post.network === "tiktok" && post.sparkState && (
                                <Tooltip title={t("Requisitar autorização para Spark Ad")}>
                                    <div className="spark-container">
                                        {post.sparkState === "waiting" && (
                                            <Button
                                                outline={true}
                                                className="spark-ad-button"
                                                styleButton={{ fontSize: "12px", color: "#000" }}
                                                text={t("Solicitar SparkAd")}
                                                loading={false}
                                                onClick={() => setShowAskPeriod(true)}
                                            />
                                        )}
                                        {post.sparkState === "requested" && (
                                            <div
                                                className="spark-status"
                                                style={{ backgroundColor: "#ffdd9d", borderColor: "#fac767" }}
                                            >
                                                {t("Aguardando confirmação")}
                                            </div>
                                        )}
                                        {post.sparkState === "accepted" && (
                                            <div
                                                className="spark-status"
                                                style={{ backgroundColor: "#ffdd9d", borderColor: "#ffdd9d" }}
                                            >
                                                {!objCampaign.campaign.isTiktokConnected && t("Aguardando connect")}
                                                {objCampaign.campaign.isTiktokConnected &&
                                                    t("Impulsionamento em andamento")}
                                            </div>
                                        )}
                                        {post.sparkState === "applied" && (
                                            <div
                                                className="spark-status"
                                                style={{ backgroundColor: "#dbe6e1", borderColor: "#cce5da" }}
                                            >
                                                {t("Impusionamento realizado")}
                                            </div>
                                        )}
                                        {post.sparkState === "refused" && (
                                            <div
                                                className="spark-status"
                                                style={{ backgroundColor: "#f3a59d", borderColor: "#f3a59d" }}
                                            >
                                                {t("Impusionamento recusado")}
                                            </div>
                                        )}
                                    </div>
                                </Tooltip>
                            )}
                        </div>
                    </div>

                    <div className="container-post">
                        <div className="post-content">
                            {post.url && !videoError && post.network !== "tiktok" ? (
                                <ReactPlayer
                                    style={{ cursor: playing ? "pointer" : "default" }}
                                    className="post-video"
                                    width="100%"
                                    height="100%"
                                    url={post.url}
                                    playing={playing}
                                    ref={playerRef}
                                    onClick={(e) => {
                                        setPlaying((state) => !state);
                                        if (e.target && e.target.nodeName == "VIDEO") {
                                            if (!e.target.paused) {
                                                e.target.pause();
                                                e.target.currentTime = 0;
                                            } else {
                                                e.target.play();
                                            }
                                        }
                                    }}
                                    onError={() => {
                                        setVideoError(true);
                                    }}
                                />
                            ) : (
                                <div
                                    className="post-img"
                                    onClick={() => {
                                        if (!post.url || videoError) window.open(post.link, "_blank");
                                    }}
                                    style={{ backgroundImage: `url(${airUtils.buildImage(post.image)})` }}
                                />
                            )}

                            {!playing && (
                                <div
                                    className="container-play"
                                    onClick={() => {
                                        if (post.url && !videoError && post.network !== "tiktok") {
                                            setPlaying(true);
                                            return;
                                        }

                                        window.open(post.link, "_blank");
                                    }}
                                />
                            )}
                        </div>
                        {!play && (
                            <div className={`content-icon-background background-${post.network}`}>
                                <i className={`content-icon icon-${post.network}`} />
                            </div>
                        )}
                        {post.description && (
                            <div className="container-caption">{`${post.description.substring(0, 100)}...`}</div>
                        )}
                    </div>

                    <div className="post-footer">
                        {post.reach && (
                            <Tooltip title={t("Alcance")}>
                                <div className="junction">
                                    <i className="icon icon-reach1" />
                                    {humanize(post.reach)}
                                </div>
                            </Tooltip>
                        )}
                        {post.views && (
                            <Tooltip title={t("Visualizações")}>
                                <div className="junction">
                                    <i className="icon icon-overview" />
                                    {humanize(post.views)}
                                </div>
                            </Tooltip>
                        )}

                        {!post.views && !post.reach && <div className="junction"></div>}
                        <Tooltip title={t("Ver todos os dados do story")}>
                            <div
                                className="junction see-all"
                                onClick={() => {
                                    if (onClick) onClick();
                                }}
                            >
                                {t("Ver dados")}
                                <i className="icon icon-advance1" />
                            </div>
                        </Tooltip>
                    </div>
                </>
            )}
        </div>
    );
};

CardStories.propTypes = {
    post: PropTypes.any,
    influencer: PropTypes.any,
    campaignId: PropTypes.any,
    onClick: PropTypes.func,
    onRequestSpaskSuccess: PropTypes.func,
};

export default CardStories;
