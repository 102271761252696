import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ShepherdTourContext } from "react-shepherd";
import GetSteps from "./Steps";
import { useTranslation } from "react-i18next";
import OLogo from "../../assets/icons/o.svg";
import { Popover, Button, Progress, List } from "antd";
import { ReactComponent as GoalCompleteIcon } from "../../assets/icons/goal_complete.svg";
import { ReactComponent as GoalEmptyIcon } from "../../assets/icons/goal_empty.svg";
import { ReactComponent as GoalLockedIcon } from "../../assets/icons/lock.svg";
import "./index.scss";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { selectProfile } from "../../../features/Login/LoginSlice";
import { updateGuidingStep } from "../../../features/Login/LoginService";
import StateStatus from "../../../utils/status";

const GuidingFloatButton = ({ onClick, badgeCount }) => {
    return (
        <>
            <Button className="float-button" onClick={onClick}>
                <img src={OLogo} alt="H" width={20} />
            </Button>
            <div style={{ display: badgeCount ? "" : "none" }} className="float-button-badge">
                {badgeCount}
            </div>
        </>
    );
};

const GoalIcon = ({ currentStep, startStep, finishStep, unlockStep }) => {
    if (currentStep >= finishStep) return <GoalCompleteIcon className="goal-complete-icon" />;
    else if (currentStep >= startStep || currentStep >= unlockStep)
        return <GoalEmptyIcon className="goal-empty-icon" />;
    return <GoalLockedIcon className="goal-locked-icon" />;
};
const GuidingPopover = ({ open, openChange, initialStep }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const profile = useSelector(selectProfile);
    const tour = useContext(ShepherdTourContext);
    const [currentStep, setCurrentStep] = useState(0);
    const steps = GetSteps(tour, history);

    const updateStepIndex = async (stepIndex) => {
        if (stepIndex > currentStep) {
            setCurrentStep(stepIndex);
            await updateGuidingStep(stepIndex);
        }
    };

    tour.once("show", (data) => {
        const stepIndex = steps.findIndex((step) => step.id === data.step.id);
        updateStepIndex(stepIndex);
    });

    useEffect(() => {
        if (initialStep > currentStep) {
            setCurrentStep(initialStep);
        }
    }, [initialStep]);

    const categories = [
        { title: t("1. Bem-vindo ao Hypolake"), startStep: -1, finishStep: 10, unlockStep: -1 },
        {
            title: t("2. Como criar uma campanha?"),
            startStep: 10,
            finishStep: 11,
            unlockStep: -1,
            execution: () => window.open("https://hypolake.com/hypolake-articles/category/create-campaign/", "__blank"),
        },
        {
            title: t("3. Criar uma campanha"),
            startStep: 11,
            finishStep: 12,
            unlockStep: 11,
            execution: () => history.push("/create-campaign/type"),
        },
        {
            title: t("4. Como analisar uma campanha?"),
            startStep: 12,
            finishStep: 13,
            unlockStep: 13,
            execution: () =>
                window.open("https://hypolake.com/hypolake-articles/category/analyze-campaign/", "__blank"),
        },
    ];

    const getIncompleteCategories = () => {
        return categories.filter((category) => {
            return currentStep < category.finishStep;
        }).length;
    };

    const onStartStep = (step) => {
        if (step.execution) {
            step.execution();
            updateStepIndex(step.finishStep);
            return;
        }

        const transformedSteps = steps.slice(step.startStep + 1, step.finishStep + 1).map((step) => {
            const translateText =
                typeof step.text === "string" ? t(step.text) : step.text.map((item) => t(item)).join("");

            const buttons = step.buttons
                ? step.buttons.map((button) => {
                      return { ...button, text: t(button.text) };
                  })
                : step.buttons;
            return { ...step, buttons, title: t(step.title), text: translateText };
        });

        tour.addSteps(transformedSteps);
        tour.start();
    };

    return (
        <Popover
            content={
                <>
                    <h1>{t("Conheça o Hypolake")}</h1>
                    <h3>{t("Primeiros passos")}</h3>
                    <Progress
                        strokeColor="#6a53bd"
                        percent={Math.ceil(((categories.length - getIncompleteCategories()) / categories.length) * 100)}
                        strokeLinecap="square"
                        size="default"
                    />
                    <List
                        itemLayout="horizontal"
                        dataSource={categories}
                        renderItem={(item, index) => (
                            <List.Item key={index}>
                                <List.Item.Meta
                                    className="goal-item"
                                    avatar={
                                        <div className="custom-bullet">
                                            <GoalIcon
                                                currentStep={currentStep}
                                                startStep={item.startStep}
                                                finishStep={item.finishStep}
                                                unlockStep={item.unlockStep}
                                            />
                                        </div>
                                    }
                                    description={item.title}
                                    onClick={() => onStartStep(item)}
                                />
                            </List.Item>
                        )}
                    />
                </>
            }
            arrowPointAtCenter={false}
            trigger="click"
            visible={open}
            onVisibleChange={openChange}
        >
            {profile.status.getProfile === StateStatus.succeeded && (
                <GuidingFloatButton badgeCount={getIncompleteCategories()} onClick={() => openChange(true)} />
            )}
        </Popover>
    );
};

function Guiding() {
    const [open, setOpen] = useState(false);
    const profile = useSelector(selectProfile);
    const tour = useContext(ShepherdTourContext);

    tour.once("complete", async () => {
        setOpen(true);
    });

    return (
        <>
            <GuidingPopover open={open} openChange={setOpen} initialStep={profile.data.guiding_step} />
        </>
    );
}

Guiding.propTypes = {
    children: PropTypes.node,
};
GuidingPopover.propTypes = {
    open: PropTypes.bool,
    openChange: PropTypes.func,
    initialStep: PropTypes.number,
};
GuidingFloatButton.propTypes = {
    onClick: PropTypes.func,
    badgeCount: PropTypes.number,
};

GoalIcon.propTypes = {
    currentStep: PropTypes.number,
    startStep: PropTypes.number,
    finishStep: PropTypes.number,
    unlockStep: PropTypes.number,
};

export default Guiding;
