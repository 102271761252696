import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
//
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
    fetchInfluencers,
    selectTiktokDiscovery,
    updateFilters,
    updateFiltersText,
    updateInfluencer,
    updateInfluencerSelected,
} from "../../TikTokDiscoverySlice";
import StateStatus from "../../../../../utils/stateStatus";
//
import { Form, Switch, Tooltip } from "antd";
import Select from "../../../Select/Select";
import Loading from "../../../Loading/Loading";
import ModalValue from "../ModalValue/ModalValue";
import CardTiktoker from "../CardTiktoker/CardTiktoker";
import Pagination from "../../../../components/Pagination/Pagination";
//
import { addPoints } from "../../../../../utils/formatters";
import { tiktokCountries } from "../../../../../utils/lists";
import "./SearchTiktoker.scss";

const SearchTiktoker = ({ onBack, onAdd }) => {
    const { t } = useTranslation();
    //
    const dispatch = useDispatch();
    //
    const tiktokDiscoverySlice = useSelector(selectTiktokDiscovery);
    //
    const count = tiktokDiscoverySlice.count;
    const current = tiktokDiscoverySlice.page;
    const status = tiktokDiscoverySlice.status;
    const influencers = tiktokDiscoverySlice.influencers;
    //
    const [form] = Form.useForm();
    const [filtersText, setFiltersText] = useState({});
    const [order, setOrder] = useState("followers_count");
    // modal
    const [modalOpen, setModalOpen] = useState(false);
    const [keyModalValue, setKeyModalValue] = useState();
    const [minModalValue, setMinModalValue] = useState(0);
    const [maxModalValue, setMaxModalValue] = useState(100000);
    //
    const titlesModal = {
        followers: t("Número de seguidores"),
        views: t("Número de visualizações"),
        engagement_rate: t("Taxa de engajamento"),
    };

    useEffect(() => {
        form.setFieldsValue(tiktokDiscoverySlice.filters);
        setFiltersText(tiktokDiscoverySlice.filtersText);
    }, []);

    useEffect(() => {
        dispatch(updateFiltersText(filtersText));
    }, [filtersText]);
    // filters
    const followOptions = [
        { type: "all", label: t("Todos"), value: null },
        { type: "normal", label: "10K-100K", value: [10000, 100000] },
        { type: "normal", label: "100K-1M", value: [100000, 1000000] },
        { type: "normal", label: "1M-10M", value: [1000000, 10000000] },
        { type: "normal", label: ">10M", value: [10000000, null] },
        { type: "personalize", label: t("Personalizar"), value: [1001, 100000000000] },
    ];
    const viewOptions = [
        { type: "all", label: t("Todos"), value: [null, null] },
        { type: "normal", label: "0-50K", value: [0, 50000] },
        { type: "normal", label: "50K-150K", value: [50000, 150000] },
        { type: "normal", label: "150K-500K", value: [150000, 500000] },
        { type: "normal", label: "500K-1M", value: [500000, 1000000] },
        { type: "normal", label: "1M-10M", value: [1000000, 10000000] },
        { type: "normal", label: ">10M", value: [1000000, null] },
        { type: "personalize", label: t("Personalizar"), value: [1, 100000000000] },
    ];
    const engagementOptions = [
        { type: "all", label: t("Todos"), value: [null, null] },
        { type: "normal", label: "0%-5%", value: [0, 5] },
        { type: "normal", label: "5%-10%", value: [5, 10] },
        { type: "normal", label: "10%-15%", value: [10, 15] },
        { type: "normal", label: "15%-20%", value: [15, 20] },
        { type: "normal", label: ">20%", value: [20, null] },
    ];
    const countryOptions = [
        { label: t("Todos"), value: null },
        ...tiktokCountries.map((country) => ({ label: t(country.label), value: country.value })),
    ];
    const audienceOptions = [
        { label: t("Todos"), value: null },
        ...tiktokCountries.map((country) => ({ label: t(country.label), value: country.value })),
    ];
    const systemOptions = [
        { type: "all", label: t("Todos"), value: null },
        { type: "normal", label: "Android", value: "android" },
        { type: "normal", label: "iOS", value: "ios" },
    ];
    const expirenceOptions = [
        { type: "all", label: t("Todos"), value: null },
        { type: "normal", label: t("Especialista"), value: "expert" },
        { type: "normal", label: t("Emergente"), value: "emerging" },
    ];
    const genderOptions = [
        { type: "all", label: t("Todos"), value: null },
        { type: "normal", label: t("Feminino"), value: "female" },
        { type: "normal", label: t("Masculino"), value: "male" },
    ];
    const orderOptions = [
        // { label: t("Todos"), value: null },
        { label: t("Ordenar por seguidores"), value: "followers_count" },
        { label: t("Ordenar por visualizações"), value: "avg_views" },
        { label: t("Ordenar por engajamento"), value: "engagement_rate" },
    ];

    const onFinish = (values) => {
        dispatch(fetchInfluencers({ min_avg_views: 1, ...values }));
    };

    const buildFormObject = (page) => {
        const object = form.getFieldsValue();

        Object.keys(object).forEach((key) => {
            if (!object[key]) delete object[key];
        });

        if (!object["min_avg_views"]) object["min_avg_views"] = 1;
        // object["sort_field"] = order || null
        // object["sort_field"] = order || "followers_count"

        object["page"] = page;

        return object;
    };

    useEffect(() => {
        const object = buildFormObject(1);

        dispatch(fetchInfluencers(object));
    }, [filtersText, order]);

    return (
        <div className="search-tiktoker">
            {onBack && (
                <span className="btn-back" onClick={() => onBack()}>
                    <i className="icon icon-back" />
                    {t("Voltar a Adiconar a Campanha")}
                </span>
            )}

            <p className="subtitle">{t("Filtrar")}</p>

            <Form onFinish={onFinish} form={form}>
                <Form.Item name="min_followers" className="item-followers">
                    <Select
                        defaultValue={filtersText.followers || t("Seguidores")}
                        options={followOptions}
                        onChange={({ type, label, value }) => {
                            const filters = {
                                ...tiktokDiscoverySlice.filters,
                                min_followers: value && value[0] ? value[0] : null,
                                max_followers: value && value[1] ? value[1] : null,
                            };

                            dispatch(updateFilters(filters));

                            if (type === "personalize") {
                                setMinModalValue(value && value[0] ? value[0] : null);
                                setMaxModalValue(value && value[1] ? value[1] : null);
                                setKeyModalValue("followers");

                                setModalOpen(true);
                            } else {
                                setFiltersText({ ...filtersText, ...{ followers: label } });
                                //
                                form.setFieldsValue({ min_followers: value && value[0] ? value[0] : null });
                                form.setFieldsValue({ max_followers: value && value[1] ? value[1] : null });
                            }
                        }}
                    />
                </Form.Item>

                <Form.Item name="min_avg_views" className="item-views">
                    <Select
                        defaultValue={filtersText.views || t("Visualizações")}
                        options={viewOptions}
                        onChange={({ type, label, value }) => {
                            const filters = {
                                ...tiktokDiscoverySlice.filters,
                                min_avg_views: value && value[0] ? value[0] : null,
                                max_avg_views: value && value[1] ? value[1] : null,
                            };

                            dispatch(updateFilters(filters));

                            if (type === "personalize") {
                                setMinModalValue(value && value[0] ? value[0] : null);
                                setMaxModalValue(value && value[1] ? value[1] : null);
                                setKeyModalValue("views");

                                setModalOpen(true);
                            } else {
                                setFiltersText({ ...filtersText, ...{ views: label } });
                                //
                                form.setFieldsValue({ min_avg_views: value && value[0] ? value[0] : null });
                                form.setFieldsValue({ max_avg_views: value && value[1] ? value[1] : null });
                            }
                        }}
                    />
                </Form.Item>

                <Form.Item name="engagement_rate" className="item-engagement">
                    <Select
                        defaultValue={filtersText.engagement_rate || t("Engajamentos")}
                        options={engagementOptions}
                        onChange={({ type, label, value }) => {
                            const filters = {
                                ...tiktokDiscoverySlice.filters,
                                engagement_rate: label.split("%").join(""),
                            };

                            dispatch(updateFilters(filters));
                            if (type === "personalize") {
                                setMinModalValue(value && value[0] ? value[0] : null);
                                setMaxModalValue(value && value[1] ? value[1] : null);
                                setKeyModalValue("engagement_rate");

                                setModalOpen(true);
                            } else {
                                setFiltersText({ ...filtersText, ...{ engagement_rate: label } });
                                //
                                form.setFieldsValue({ engagement_rate: label.split("%").join("") });
                            }
                        }}
                    />
                </Form.Item>

                <Form.Item name="country_code" className="item-country">
                    <Select
                        defaultValue={filtersText.country_code || t("País influenciador")}
                        options={countryOptions}
                        onChange={({ label, value }) => {
                            dispatch(
                                updateFilters({
                                    ...tiktokDiscoverySlice.filters,
                                    country_code: value,
                                })
                            );
                            setFiltersText({ ...filtersText, ...{ country_code: label } });
                            //
                            form.setFieldsValue({ country_code: value });
                        }}
                    />
                </Form.Item>

                <Form.Item name="major_audience_country_code" className="item-audience-country">
                    <Select
                        defaultValue={filtersText.major_audience_country_code || t("País audiência")}
                        options={audienceOptions}
                        onChange={({ label, value }) => {
                            dispatch(
                                updateFilters({
                                    ...tiktokDiscoverySlice.filters,
                                    major_audience_country_code: value,
                                })
                            );
                            setFiltersText({ ...filtersText, ...{ major_audience_country_code: label } });
                            //
                            form.setFieldsValue({ major_audience_country_code: value });
                        }}
                    />
                </Form.Item>

                <Form.Item name="mobile_os" className="item-system">
                    <Select
                        defaultValue={filtersText.mobile_os || t("Sistema")}
                        options={systemOptions}
                        onChange={({ label, value }) => {
                            dispatch(
                                updateFilters({
                                    ...tiktokDiscoverySlice.filters,
                                    mobile_os: value,
                                })
                            );
                            setFiltersText({ ...filtersText, ...{ mobile_os: label } });
                            //
                            form.setFieldsValue({ mobile_os: value });
                        }}
                    />
                </Form.Item>

                <Form.Item name="brand_experience" className="item-expirence">
                    <Select
                        defaultValue={filtersText.brand_experience || t("Experiência com marcas")}
                        options={expirenceOptions}
                        onChange={({ label, value }) => {
                            dispatch(
                                updateFilters({
                                    ...tiktokDiscoverySlice.filters,
                                    brand_experience: value,
                                })
                            );
                            setFiltersText({ ...filtersText, ...{ brand_experience: label } });
                            //
                            form.setFieldsValue({ brand_experience: value });
                        }}
                    />
                </Form.Item>

                <Form.Item name="major_audience_gender" className="item-gender">
                    <Select
                        defaultValue={filtersText.major_audience_gender || t("Gênero audiência")}
                        options={genderOptions}
                        onChange={({ label, value }) => {
                            dispatch(
                                updateFilters({
                                    ...tiktokDiscoverySlice.filters,
                                    major_audience_gender: value,
                                })
                            );
                            setFiltersText({ ...filtersText, ...{ major_audience_gender: label } });
                            //
                            form.setFieldsValue({ major_audience_gender: value });
                        }}
                    />
                </Form.Item>

                <div className="item-space" />
                <Form.Item className="item-max-followers" name="max_followers">
                    {" "}
                    <input />{" "}
                </Form.Item>
                <Form.Item className="item-max-avg-views" name="max_avg_views">
                    {" "}
                    <input />{" "}
                </Form.Item>

                <Form.Item name="fast_growing" className="item-fast-growing">
                    <Switch
                        checked={filtersText.fast_growing || false}
                        onChange={(value) => {
                            dispatch(
                                updateFilters({
                                    ...tiktokDiscoverySlice.filters,
                                    fast_growing: value,
                                })
                            );
                            setFiltersText({
                                ...filtersText,
                                ...{ fast_growing: value ? t("Com crescimento acelerado") : null },
                            });
                            //
                            form.setFieldsValue({ fast_growing: value });
                        }}
                    />

                    <span>{t("Perfil com crescimento acelerado")}</span>
                    <Tooltip child="..." />
                </Form.Item>
            </Form>

            <div className="form-filters">
                {Object.keys(filtersText).map((key) => {
                    return (
                        filtersText[key] && (
                            <span key={key} className="item-filter-text">
                                {filtersText[key]}
                                <i
                                    role="button"
                                    className="icon icon-close"
                                    onClick={() => {
                                        if (key === "followers") {
                                            form.resetFields(["min_followers", "max_followers"]);
                                        } else if (key === "views") {
                                            form.resetFields(["min_avg_views", "max_avg_views"]);
                                        } else {
                                            form.resetFields([key]);
                                        }
                                        dispatch(
                                            updateFilters({
                                                ...tiktokDiscoverySlice.filters,
                                                [key]: null,
                                            })
                                        );
                                        setFiltersText({ ...filtersText, ...{ [key]: null } });
                                    }}
                                />
                            </span>
                        )
                    );
                })}

                {Object.keys(filtersText).some((key) => filtersText[key] !== null) && (
                    <span
                        key="clear"
                        className="item-filter-clear"
                        onClick={() => {
                            form.resetFields();
                            dispatch(updateFilters({}));
                            setFiltersText({});
                        }}
                    >
                        {t("Limpar todos")}
                    </span>
                )}
            </div>

            <div className="tiktok-influencers">
                <p className="subtitle">
                    {t("Resultados")} <br />
                    <span className="subtitle-description">{t("A busca retornará no máximo 30 resultados.")}</span>
                </p>

                <Pagination
                    pageSize={10}
                    total={count}
                    current={current}
                    isDisabled={status === StateStatus.loading || status === StateStatus.failed}
                    onChange={(page) => {
                        const object = buildFormObject(page);

                        dispatch(fetchInfluencers(object));
                    }}
                />

                <span className="text">
                    {count} {t("influenciadores encontrados")}
                </span>

                <Select
                    outline={true}
                    defaultValue={order}
                    options={orderOptions}
                    onChange={({ value }) => setOrder(value)}
                />

                {status === StateStatus.succeeded && (
                    <div className="influencers">
                        {influencers.map((influencer) => (
                            <CardTiktoker
                                key={influencer.username}
                                influencer={influencer}
                                onOpen={() => {
                                    dispatch(updateInfluencer(influencer));
                                    dispatch(updateInfluencerSelected(influencer.username));

                                    // dispatch(
                                    //     updateFilters(Object.keys(filtersText).map((filter) => filtersText[filter]))
                                    // );
                                }}
                                onAdd={() => onAdd(influencer.username)}
                            />
                        ))}
                    </div>
                )}

                {status === StateStatus.loading && <Loading />}

                {status === StateStatus.failed && <p className="text-err">{t("Tente novamente mais tarde")}</p>}
            </div>

            <ModalValue
                min={minModalValue}
                max={maxModalValue}
                open={modalOpen}
                title={titlesModal[keyModalValue]}
                onCancel={() => setModalOpen(null)}
                onUpdate={({ min, max }) => {
                    if (keyModalValue === "followers") {
                        form.setFieldsValue({ min_followers: min });
                        form.setFieldsValue({ max_followers: max });

                        setFiltersText({
                            ...filtersText,
                            ...{ [keyModalValue]: `${addPoints(min)} - ${addPoints(max)}` },
                        });
                    } else if (keyModalValue === "views") {
                        form.setFieldsValue({ min_avg_views: min });
                        form.setFieldsValue({ max_avg_views: max });

                        setFiltersText({
                            ...filtersText,
                            ...{ [keyModalValue]: `${addPoints(min)} - ${addPoints(max)}` },
                        });
                    } else if (keyModalValue === "engagement_rate") {
                        form.setFieldsValue({ engagement_rate: `${min}-${max}` });

                        setFiltersText({ ...filtersText, ...{ [keyModalValue]: `${min}-${max}` } });
                    }

                    setModalOpen(null);
                }}
            />
        </div>
    );
};

SearchTiktoker.propTypes = {
    onBack: PropTypes.func,
    onAdd: PropTypes.func.isRequired,
};

export default SearchTiktoker;
