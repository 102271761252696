import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
//
import { changeObjAlert, showAlert } from "../../../../common/components/Alert/alertSlice"
import { getInvoices, selectConfiguration, loadInvoice, clearStatus } from "../../ConfigurationSlice"
import StateStatus from "../../../../utils/stateStatus"
//
import { Spin } from "antd"
import Loading from "../../../../common/components/Loading/Loading"
import Pagination from "../../../../common/components/Pagination/Pagination"
// 
import { AirUtils } from "../../../../utils/utils"
import "./Billing.scss"

const ConfigBilling = () => {
	const { t } = useTranslation()
	// 
	const dispatch = useDispatch()
	const airUtils = new AirUtils()
	// 
	const config = useSelector(selectConfiguration)
	const invoices = config.objInvoices
	// 
	const [page, setPage] = useState(0);
	const [indexLoad, setIndexLoad] = useState();
	// 
	const titles = [t("Data"), t("Número Fatura"), t("Valor"), t("Baixar Fatura")]

	const alert = (type, title, text, question, cancelText, okText, onOk) => {
		dispatch(
			changeObjAlert({
				show: true,
				type,
				title,
				text,
				okText,
				question,
				cancelText,
				onCancel: () => {
					dispatch(showAlert(false))
				},
				onOk: onOk || (() => { }),
			})
		)
	}

	useEffect(() => {
		dispatch(getInvoices(page))
	}, [page])

	useEffect(() => {
		if (config.statusInvoices === StateStatus.failed) alert("error", "erro", t(config.msg))
	}, [config.statusInvoices])

	useEffect(() => {
		if (config.statusLoadInvoice === StateStatus.failed) alert("error", "erro", t(config.msg))
		if (config.statusLoadInvoice === StateStatus.succeeded) {
			const h = screen.height;
			const w = screen.width;

			const win = window.open("", t("Fatura"), `toolbar=no ,location=0, status=no, titlebar=no, menubar=no, width=${w}, height=${h}`);

			if (win) {
				win.document.body.innerHTML = config.invoice
				win.print()
			}
		}

		dispatch(clearStatus())
	}, [config.statusLoadInvoice])

	return (
		<>
			{(config.statusInvoices === StateStatus.loading || config.statusInvoices === StateStatus.idle) && <Loading />}

			{(config.statusInvoices === StateStatus.failed) && <p className="text-error">ERRO</p>}

			{config.statusInvoices === StateStatus.succeeded && <div className="card-payment-history">
				<header className="card-p-h-header">
					<span className="text-header">{t("Histórico de pagamento")}</span>

					{/* TODO: criar input de procura */}
				</header>

				<p className="text-p-h">{t("Veja seu histórico de pagamento e gerencie suas faturas")}</p>

				<table className="table-p-h">
					<thead>
						<tr>
							{titles.map(item => <th key={item}>{t(item)}</th>)}
						</tr>
					</thead>

					<tbody>
						{config.invoices.map((item, key) => <tr key={`${item.payment_id}${key}`}>
							<td>{airUtils.buildValue("date", item.payment_date)}</td>

							<td>{item.invoice_id}</td>

							<td>{airUtils.buildValue("currency", item.value)}</td>

							<td className="td-pdf" onClick={() => {
								if (config.StatusLoadInvoice === StateStatus.loading) {
									alert("error", "erro", t("Aguarde o download que está em adamento acabar"))
								} else {
									setIndexLoad(key);
									dispatch(loadInvoice(item.invoice_id))
								}
							}}>
								<a className="a-pdf" href={item.pdf} target="_blank" rel="noreferrer">PDF</a>
								{key === indexLoad && config.StatusLoadInvoice === StateStatus.loading && <Spin />}
							</td>
						</tr>)}
					</tbody>
				</table>
				
				{!invoices || invoices.pagination.total < 1 && <p className="empty-data">{t("Sem faturas")}</p>}

				{invoices.pagination.count > 0 &&
					<Pagination 
						current={page + 1}
						total={invoices.pagination.count}
						pageSize={invoices.pagination.items_per_page}
						isDisabled={config.statusInvoices === StateStatus.loading}
						onChange={(page) => setPage(page)}
					/>
				}
			</div>}
		</>
	)
}

export default ConfigBilling
