class CampaignSummary {
    funnel = {}
    myCampaigns = {}
    linkTracking = {}

    fromJson(data) {
        const campaign = data.campaign_counters;
        const counters = data.store_counters;
        const funnel = data.funnel_counters

        data = null

        this.myCampaigns = campaign ? campaign : [
            {
                label: "active_campaigns",
                value: 0,
                performance: null
            },
            {
                label: "total_investment",
                value: 0,
                performance: null
            },
            {
                label: "mean_investment",
                value: 0,
                performance: null
            },
            {
                label: "influencers",
                value: 0,
                performance: null
            },
            {
                label: "mean_influencers",
                value: 0,
                performance: null
            },
            {
                label: "impressions",
                value: 0,
                performance: 0,
            },
            {
                label: "mean_impressions",
                value: 0,
                performance: 0,
            },
            {
                label: "mean_engagement",
                value: 0,
                performance: 0,
            },
            {
                label: "mean_cpi",
                value: 0,
                performance: 0,
            },
            {
                label: "total_conversions",
                value: 0,
                performance: 0,
            },
            {
                label: "connected_profiles",
                value: 0,
                performance: 0,
            }
        ];

        this.linkTracking = counters ? counters : [
            {
                label: "page_tracks",
                value: 0,
                performance: 0,
            },
            {
                label: "traks",
                value: 0,
                performance: 0,
            },
            {
                label: "total_investment",
                value: 0,
                performance: 0,
            },
            {
                label: "mean_investment",
                value: 0,
                performance: 0,
            },
            {
                label: "ga_traffic",
                value: 0,
                performance: 0,
            },
            {
                label: "mean_traffic",
                value: 0,
                performance: 0,
            },
            {
                label: "total_clicks",
                value: 0,
                performance: 0,
            },
            {
                label: "cost_per_click",
                value: 0,
                performance: 0,
            },
            {
                label: "cost_per_traffic",
                value: 0,
                performance: 0,
            },
        ]

        this.funnel =
          funnel ? funnel : [
            ({ label: 'reach_potential', value: 0 },
            { label: 'impressions', value: 0 },
            { label: 'interactions', value: 0 },
            { label: 'traffic_link', value: 0 },
            { label: 'content_estimate', value: 0 })
          ]

        return this;
    }
}

export default CampaignSummary;