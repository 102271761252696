import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
//
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { selectProfile, fetchProfile } from "../../../features/Login/LoginSlice";
import { clearRegistration } from "../../../features/Register/RegisterSlice";
import StateStatus from "../../../utils/stateStatus";
//
import Calendar from "../Calendar/Calendar";
import DropDown from "../DropDown/DropDown";
import Tooltip from "../Tooltip/Tooltip";
import TranslateModal from "./components/TranslateModal";
//
import "./Header.scss";

const Header = ({ title, back, funcDate, subHeader, limitEndDate, limitStartDate }) => {
    const { t } = useTranslation();
    const profile = useSelector(selectProfile);
    const dispatch = useDispatch();
    const history = useHistory();

    const [showModal, setShowModal] = useState(false);
    const [openCalendar, setOpenCalendar] = useState(false);
    const [selectedDate, setDate] = useState();

    const optionsDropDown = [
        { key: "account-settings", value: t("Configurar conta") },
        { key: "lang", value: t("Idioma") },
        { key: "exit", value: t("Sair") },
    ];

    const runAction = (action = "") => {
        if (action.key == "account-settings") {
            history.replace("/configuration");
        } else if (action.key == "lang") {
            setShowModal(true);
        } else if (action.key == "exit") {
            dispatch(clearRegistration());
            window.localStorage.removeItem("access_token");
            window.localStorage.removeItem("refresh_token");
            history.replace("/login");
        }
    };

    useEffect(() => {
        if (profile.status.getProfile !== StateStatus.succeeded) {
            dispatch(fetchProfile());
        }
    }, []);

    return (
        <div className="junction-header">
            <div className="header-container">
                <header className="header">
                    {back && (
                        <div
                            className="previous-page"
                            onClick={() => {
                                if (history.length > 0) history.goBack();
                                else history.push("/");
                            }}
                        >
                            <i className="icon icon-back" />
                            {t("Voltar")}
                        </div>
                    )}

                    <h2 className="title">{title}</h2>

                    <div className="user">
                        <DropDown
                            options={optionsDropDown}
                            onClick={runAction}
                            child={
                                <div className="content-dropdown">
                                    <div className="circle-user">
                                        {profile.data.name ? profile.data.name.slice(0, 1) : "-"}
                                    </div>
                                    <span className="name-user">{profile.data.name}</span>
                                    <i className="icon icon-down" />
                                </div>
                            }
                        />

                        <div className="divider" />

                        <a
                            className="href-icon"
                            href="http://help.airfluencers.com/pt-BR/collections/502787-hypolake"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <i className="icon icon-help" />
                        </a>
                    </div>
                </header>

                <TranslateModal show={showModal} hideModal={() => setShowModal(false)} />
            </div>

            {subHeader !== undefined && (
                <div className="subheader-container">
                    <div className="header">
                        {subHeader}

                        <div className="subheader-right">
                            <div
                                className="input-date"
                                onClick={() => {
                                    setOpenCalendar(!openCalendar);
                                    !openCalendar && document.querySelector(".ant-picker").click();
                                }}
                            >
                                {selectedDate ? selectedDate : t("Selecionar período")}

                                <i
                                    className={`icon ${selectedDate ? "icon-close" : "icon-down"}`}
                                    onClick={(evt) => {
                                        if (selectedDate) {
                                            evt.preventDefault();
                                            evt.stopPropagation();

                                            setDate(null);
                                            if (funcDate) {
                                                funcDate(null);
                                            }
                                        }
                                    }}
                                />
                            </div>

                            <Calendar
                                value={null}
                                placeholderStartDate={t("Selecionar")}
                                placeholderEndDate={t("período")}
                                disabledEndDate={limitEndDate}
                                disabledStartDate={limitStartDate}
                                onChange={(date, dateString) => {
                                    setDate(`${dateString[0]} - ${dateString[1]}`);

                                    if (funcDate) funcDate(date, dateString);
                                }}
                            />

                            <Tooltip child={t("Filtre a campanha por um período específico.")} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

Header.propTypes = {
    title: PropTypes.any,
    back: PropTypes.bool,
    funcDate: PropTypes.func,
    subHeader: PropTypes.any,
    limitEndDate: PropTypes.any,
    limitStartDate: PropTypes.any,
};

export default Header;
